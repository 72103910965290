<template>
  <div>
    <UiCard
      :class="['history-card', { 'history-card-loading': !loaded }]"
      :loading="!loaded"
    >
      <template #title>
        <span class="title">{{ $t('history') }}</span>
        <span class="count text-xs font-medium leading-long">
          {{ totalCount }}
        </span>
      </template>
      <div
        v-if="totalCount"
        class="history-wrapper"
        data-test-id="record__product-history"
      >
        <div v-for="item in data" :key="item.key" class="history-item">
          <div>
            <component
              :is="part.tag"
              v-for="(part, partIdx) in item.name"
              v-bind="part.props"
              :key="partIdx"
              :class="part.class"
            >
              {{ part.value }}
              <icon-ui
                v-if="part.class?.includes('link')"
                class="link-icon"
                name="navigation/external_link"
                fill="#1890ff"
                size="16"
              />
            </component>
          </div>
          <div>{{ item.employee }}</div>
          <Date :value="item.date" />
        </div>
        <InfiniteLoader
          :key="page"
          :has-more="hasMore"
          :on-enter="handleInfiniteLoader"
        />
        <LinearLoader
          v-if="hasMore"
          :style="{ width: '100%', margin: '2em 0 1em 0' }"
        />
      </div>
      <div v-else class="history-empty">
        <UiEmpty :description="$t('empty')" />
      </div>
    </UiCard>
  </div>
</template>

<script setup lang="ts">
import type { Component, Ref } from 'vue';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { EventType, PaymentRate, SkuEvent } from '@/api';
import InfiniteLoader from '@/components/InfiniteLoader.vue';
import PaymentRateChip from '@/components/PaymentRateChip.vue';
import { useEventManager } from '@/composables/useEventManager';
import { useIsInViewWrapper } from '@/composables/useIsInView';
import { AppEvents } from '@/features/Common';
import { shootingTypeToColor } from '@/pages/requests/data';
import { Chip, CopyButton, Date, LinearLoader, UiCard, UiEmpty } from '@/ui';

import { useSkuHistory } from '../composables';

interface MessagePartition {
  props?: any;
  value: string | number;
  tag?: string | Component;
  class?: string;
}

type MessageFormatter = (e: SkuEvent) => Array<MessagePartition>;

const props = defineProps({
  skuId: {
    type: Number,
    required: true,
  },
});

const { t } = useI18n();

const {
  loaded,
  items,
  hasMore,
  totalCount,
  page,
  handleLoadMore,
  handleUpdateHistory,
  initialItemsLoad,
} = useSkuHistory(props.skuId);

const wrapperRef: Ref<HTMLElement> = ref(document.body);

useIsInViewWrapper(wrapperRef);

const handleInfiniteLoader = () => {
  handleLoadMore();
};

const parts = {
  text: (value: string, className?: string) => ({
    value,
    tag: 'div',
    class: className,
  }),
  paymentRateChip: (rate: PaymentRate) => ({
    value: '',
    tag: PaymentRateChip,
    props: { rate },
    class: 'ml-1 mt-1',
  }),
  chip: (value: string, color: string, className?: string) => ({
    value,
    tag: Chip,
    props: { value, color },
    class: className,
  }),
  date: (value: number) => ({
    value,
    tag: Date,
    props: { value },
  }),
  link: (value: string, to: object) => ({
    value,
    tag: 'router-link',
    props: {
      value,
      to,
      target: '_blank',
      'data-test-id': 'button__link-product-history',
    },
    class: 'link block',
  }),
  copy: (textToCopy: string, value = '') => ({
    tag: CopyButton,
    class: 'copy',
    props: {
      text: textToCopy,
      tooltipPlacement: 'right',
    },
    value,
  }),
} satisfies Record<string, (...args: any) => MessagePartition>;

const formatters = {
  invoice: (event) => {
    return [
      parts.link(
        t('sku.history.types.invoice', {
          id: event.data.id,
        }),
        {
          name: 'Invoice',
          params: {
            id: event.data.id,
          },
        },
      ),
      parts.text(
        t(`sku.history.invoice event types.${event.data.type}`),
        'faded inline',
      ),
    ];
  },
  request: ({ data }) => {
    const result: Array<MessagePartition> = [
      parts.link(
        t('sku.history.types.request', {
          id: data.id,
        }),
        {
          name: 'Requests',
          params: {
            id: data.id,
          },
        },
      ),
    ];

    if (data.shootingTypes?.length) {
      const chips = data.shootingTypes.map((type) =>
        parts.chip(t(type), shootingTypeToColor[type], 'inline mr-1'),
      );

      result.push(...chips);
    }

    const [categoryType] = data.categoryShootingTypes || [];
    const [chosenType] = data.chosenShootingTypes || [];

    if (categoryType && chosenType && categoryType !== chosenType) {
      result.push(parts.chip(t('type changed'), 'gray inline mr-1'));
    }

    return result;
  },
  requestDeleted: (event) => {
    const result = [
      parts.text(t('sku.history.types.removed from')),
      parts.link(
        t('sku.history.types.request', {
          id: event.data.id,
        }),
        {
          name: 'Requests',
          params: {
            id: event.data.id,
          },
        },
      ),
    ];

    return result;
  },
  invoiceDeleted: (event) => {
    const result = [
      parts.text(t('sku.history.types.removed from')),
      parts.link(
        t('sku.history.types.invoice', {
          id: event.data.id,
        }),
        {
          name: 'Invoice',
          params: {
            id: event.data.id,
          },
        },
      ),
    ];

    return result;
  },
  defected: () => {
    return [parts.text(t('sku.history.types.defect'))];
  },
  defectDeleted: () => {
    return [parts.text(t('sku.history.types.defect deleted'))];
  },
  defectChangedReason: () => {
    return [parts.text(t('sku.history.types.defect changed reason'))];
  },
  reservationChangedStatus: (event) => {
    return [
      parts.text(
        `${t(
          'sku.history.types.reservation',
        )} №${event.data.reservationId.slice(0, 8)}...`,
        'inline',
      ),
      parts.copy(event.data.reservationId),
      parts.text(
        t(`sku.history.reservation event status.${event.data.status}`),
        'faded inline',
      ),
    ];
  },
} satisfies Record<string, MessageFormatter>;

const EventTypeToMessage: Partial<Record<EventType, MessageFormatter>> = {
  [EventType.SkuAddedToInvoice]: formatters.invoice,
  [EventType.SkuDeletedFromInvoice]: formatters.invoiceDeleted,
  [EventType.SkuAddedToPhotoRequest]: formatters.request,
  [EventType.SkuDeletedFromPhotoRequest]: formatters.requestDeleted,
  [EventType.SkuDefected]: formatters.defected,
  [EventType.SkuDefectDeleted]: formatters.defectDeleted,
  [EventType.SkuDefectChangedReason]: formatters.defectChangedReason,
  [EventType.SkuReservationItemAdd]: formatters.reservationChangedStatus,
  [EventType.SkuReservationItemChangeStatus]:
    formatters.reservationChangedStatus,
};

const data = computed(() => {
  return items.value
    .filter((e) => Boolean(e.type))
    .map((e, idx) => {
      const name = [
        ...(EventTypeToMessage[e.type]?.(e) as MessagePartition[]),
        parts.paymentRateChip(e.data?.itemPaymentRate ?? PaymentRate.Free),
      ];

      return {
        key: idx + 1,
        name,
        employee: `${e.user.firstName} ${e.user.lastName}`,
        date: e.createdAt,
      };
    });
});

useEventManager(AppEvents.sku.updateHistory, handleUpdateHistory);

onMounted(async () => {
  await initialItemsLoad();
});
</script>

<style lang="stylus" scoped>


.history-card:not(.history-card-loading)
  :deep()
    .ant-card-body
      padding 10px 0

.history-card
  height 100%

.history-wrapper
  height 100%
  width 100%
  overflow-y auto

.history-empty
  position absolute
  top 50%
  left 50%
  transform translate(-50%, -50%)

.history-item
  display grid
  grid-template-columns 3fr 3fr 1fr
  grid-auto-flow column
  align-items center
  padding 6px 14px
  font-size 0.8rem
  line-height 1.2rem
  border-bottom 1px solid #eee
  &:last-child
    border-bottom 0
  :deep() .date-wrapper
    justify-content flex-end
  :deep() .chip
    margin-top 4px

.faded
  color Gray

.inline
  display inline-block

.copy
  margin-left 4px

a
  color Gray(DK28)
  line-height 1.5rem

.title
  display inline-block
  margin-right 5px

.count
  background white
  padding 2px 6px
  border 1px solid Gray(LT40)
  border-radius 4px
</style>
