<template>
  <div class="table-title">
    <icon-ui
      name="navigation/chevron-up_line"
      size="17"
      :class="{ chevron: true, 'chevron-opened': isOpened }"
      :data-test-id="`icon__${type.toLowerCase()}`"
    />
    <span class="title text-s font-semi-bold leading-short">{{ title }}</span>
    <Skeleton v-if="loading" :paragraph="false" style="width: 50px" active />
    <div
      v-else
      class="count text-xs font-medium leading-long"
      data-test-id="count__element"
    >
      {{ count }}
    </div>
    <slot v-if="withCreation" name="title-create">
      <tooltip placement="top" :text="createTooltipText">
        <ui-button
          type="text"
          variant="icon"
          size="small"
          data-test-id="button__element-create"
        >
          <template #icon>
            <icon-ui
              class="add-button"
              name="operations/add_line"
              @click.stop="onCreateClick"
            />
          </template>
        </ui-button>
      </tooltip>
    </slot>
  </div>
</template>
<script lang="ts">
import { Skeleton } from 'ant-design-vue';
import { defineComponent, PropType } from 'vue';

import { Tooltip, UiButton } from '@/ui';

export default defineComponent({
  name: 'table-title',
  components: {
    UiButton,
    Tooltip,
    Skeleton,
  },
  props: {
    isOpened: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
    withCreation: {
      type: Boolean,
      default: false,
    },
    onCreateClick: {
      type: Function as PropType<() => void>,
      default: () => {},
    },
    createTooltipText: {
      type: String,
      default: '',
    },
  },
  setup() {},
});
</script>
<style lang="stylus" scoped>
.table-title
  display flex
  justify-content start
  align-items center
  gap 5px
  cursor pointer

.count
  background white
  padding 2px 6px
  border 1px solid Gray(LT40)
  border-radius 4px

.chevron
  transition 0.2s transform ease
  fill Gray(LT8)

.chevron-opened
  transform rotate(180deg)

.add-button
  fill Black(A60)
</style>
