import { onUnmounted, reactive, toRefs } from 'vue';

import { skuApi, SkuPhotoRequestDefect } from '@/api';
import {
  SkuEvent,
  SkuEventListResponse,
} from '@/api/frontend-api-erp-photo-studio/models';
import { uniq } from '@/utils/collection';

interface IState {
  items: SkuEvent[];
  defects: SkuPhotoRequestDefect[];
  loading: boolean;
  loaded: boolean;
  page: number;
  totalCount: number;
  hasMore: boolean;
}

const initialState: IState = {
  items: [],
  defects: [],
  loading: false,
  loaded: false,
  page: 0,
  totalCount: 0,
  hasMore: true,
};

const PER_PAGE = 100;
const state = reactive<IState>({ ...initialState });

export function useSkuHistory(skuID: number) {
  const handleResponse = (response: SkuEventListResponse) => {
    if (response) {
      state.page = response.page + 1;
      state.items = uniq([...state.items, ...response.items]);
      state.hasMore = state.page < response.totalPages;
      state.totalCount = response.totalItems;

      const newDefects = response.items?.reduce(
        (acc: SkuPhotoRequestDefect[], item) => {
          const defectsResponse = item.data?.defects ?? [];

          if (defectsResponse.length) {
            const defects = defectsResponse.map((defect) => ({
              ...defect,
              requestId: item.data?.id,
            }));

            return [...acc, ...defects];
          }

          return acc;
        },
        [],
      );

      state.defects = [...state.defects, ...newDefects];
    }
  };

  const handleUpdateHistory = async () => {
    state.loading = true;

    try {
      const response = await skuApi.skuHistory({
        skuID,
        page: 0,
        size: 1,
      });

      state.items = uniq([...response.items, ...state.items]);
    } finally {
      state.loading = false;
    }
  };

  const getItems = async () => {
    state.loading = true;

    try {
      const response = await skuApi.skuHistory({
        skuID,
        page: state.page,
        size: PER_PAGE,
      });

      handleResponse(response);
    } catch (e) {
      state.hasMore = false;
    } finally {
      state.loading = false;
    }
  };

  const handleLoadMore = async () => {
    if (state.items.length > 0 && state.hasMore && !state.loading) {
      await getItems();
    }
  };

  const resetState = () => {
    state.items = initialState.items;
    state.defects = initialState.defects;
    state.loading = initialState.loading;
    state.loaded = initialState.loaded;
    state.page = initialState.page;
    state.totalCount = initialState.totalCount;
    state.hasMore = initialState.hasMore;
  };

  const initialItemsLoad = async () => {
    for (let i = 0; i < 3; i++) {
      if (!state.hasMore) break;
      await getItems();
    }

    state.loaded = true;
  };

  onUnmounted(() => {
    resetState();
  });

  return {
    ...toRefs(state),
    initialItemsLoad,
    handleLoadMore,
    handleUpdateHistory,
    resetState,
  };
}
