/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InvoiceType,
    InvoiceTypeFromJSON,
    InvoiceTypeFromJSONTyped,
    InvoiceTypeToJSON,
} from './InvoiceType';

/**
 * 
 * @export
 * @interface InvoiceTemplate
 */
export interface InvoiceTemplate {
    /**
     * 
     * @type {number}
     * @memberof InvoiceTemplate
     */
    boxId: number;
    /**
     * 
     * @type {InvoiceType}
     * @memberof InvoiceTemplate
     */
    type?: InvoiceType;
}

export function InvoiceTemplateFromJSON(json: any): InvoiceTemplate {
    return InvoiceTemplateFromJSONTyped(json, false);
}

export function InvoiceTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceTemplate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'boxId': json['boxId'],
        'type': !exists(json, 'type') ? undefined : InvoiceTypeFromJSON(json['type']),
    };
}

export function InvoiceTemplateToJSON(value?: InvoiceTemplate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'boxId': value.boxId,
        'type': InvoiceTypeToJSON(value.type),
    };
}

