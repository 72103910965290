<template>
  <tr>
    <th v-if="withSelection" :class="`header-${color}`">
      <input type="checkbox" :checked="allAreChecked" @click="toggleAll" />
    </th>
    <base-th
      v-for="column in columns"
      :key="column.id"
      :column="column"
      :class="`header-${color} text-${textColor}`"
    />
  </tr>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';

import BaseTh from './heads/base-thead.vue';
import type {
  IColumn,
  THeaderColor,
  THeaderTextColor,
  TSorting,
} from './types';

export default defineComponent({
  components: {
    BaseTh,
  },
  props: {
    columns: prop<IColumn[]>({
      type: Object,
      required: true,
    }),
    withSelection: {
      type: Boolean,
      default: false,
    },
    withHeaderPadding: {
      type: Boolean,
      default: true,
    },
    allAreChecked: {
      type: Boolean,
      default: false,
    },
    toggleAll: {
      type: Function as PropType<() => void>,
      default: () => {},
    },
    color: prop<THeaderColor>({
      type: String as () => THeaderColor,
      default: 'white',
    }),
    textColor: prop<THeaderTextColor>({
      type: String as () => THeaderTextColor,
      default: 'gray',
    }),
    sorting: {
      type: Object as PropType<TSorting>,
      default: () => {},
    },
  },
});
</script>
<style lang="stylus" scoped>
th
  position sticky
  top 0
  z-index 1

.header-white
  @apply bg-white text-s font-medium leading-short
  text-align start
  padding 10px
  &:before {
    width 40
  }
  box-shadow inset 0 1px 0 Gray(LT40),
  inset 0 -1px 0 Gray(LT40)

.header-padding
  width 40px
  padding 0

.text-black
  color Gray(D28)

.text-gray
  color Gray(LT16)
</style>
