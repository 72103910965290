/* eslint-disable camelcase */
import { mapFactory } from 'parakeet-mapper';

import {
  ConvertAuthUserInfo,
  IAuthUserInfo,
  IServerAuthUserInfo,
} from './AuthUserInfoDto';

export interface IServerAuthAccountDto {
  access_token: string;
  token_type: string;
  refresh_token: string;
  scope: string;
  expires_in: number;
  userInfo: IServerAuthUserInfo;
}

export interface IAuthAccountDto {
  accessToken: string;
  tokenType: string;
  refreshToken: string;
  scope: string;
  expiresIn: number;
  userInfo: IAuthUserInfo;
}

export const ConvertAuthAccount = mapFactory<
  IServerAuthAccountDto,
  IAuthAccountDto
>({
  accessToken: 'access_token',
  expiresIn: 'expires_in',
  refreshToken: 'refresh_token',
  tokenType: 'token_type',
  scope: true,
  userInfo: [ConvertAuthUserInfo],
});
