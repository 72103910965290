<template>
  <div>
    <Tooltip :text="$t('photographer flow start')" placement="left">
      <UiButton
        type="default"
        size="large"
        shape="square"
        @click="visible = true"
      >
        <template #icon>
          <CameraOutlined />
        </template>
      </UiButton>
    </Tooltip>
    <div data-intro="flow-start" />
  </div>

  <UiModal v-model:open="visible" centered destroy-on-close>
    <PhotographerFlowModalContent @sku="handleSku" />
  </UiModal>
  <SkuModal
    v-if="isShowSkuModal && skuModalId"
    :id="skuModalId"
    :photo-request-id="skuModalPhotoRequestId"
    is-new-flow
    should-open-refs
    @close="handleCloseSkuModal"
  />
</template>

<script setup lang="ts">
import { CameraOutlined } from '@ant-design/icons-vue';
import { ref } from 'vue';

import { SkuDetailed } from '@/api';
import { useEventManager } from '@/composables/useEventManager';
import { AppEvents } from '@/features/Common/app';
import { EModalTypes, useRoutedModal } from '@/features/Modals';
import SkuModal from '@/features/Modals/sku/index.vue';
import { Tooltip, UiButton, UiModal } from '@/ui';

import PhotographerFlowModalContent from './PhotographerFlowModalContent.vue';

const visible = ref(false);
const isShowSkuModal = ref<boolean>(false);
const skuModalId = ref<number | null>(null);
const skuModalPhotoRequestId = ref<number | undefined>();
const [openRequestModal] = useRoutedModal(EModalTypes.REQUEST);

async function handleSku(sku: SkuDetailed) {
  if (!sku.photoRequestId) return;

  await openRequestModal({ id: sku.photoRequestId });
  eventManager.emit(AppEvents.barcode.success);
  isShowSkuModal.value = true;
  skuModalId.value = sku.id;
  skuModalPhotoRequestId.value = sku.photoRequestId;
  visible.value = false;
}

const handleCloseSkuModal = () => {
  isShowSkuModal.value = false;
  skuModalId.value = null;
  skuModalPhotoRequestId.value = undefined;
};

useEventManager(AppEvents.modals.sku.close, handleCloseSkuModal);
</script>
