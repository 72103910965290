import { AppEvents } from '@/features/Common';

export interface IBarcodeData {
  debounceGuard: number;
  debounceGuardInterval: number;
  data: string[];
  input: string;
}

const inputData = {
  debounceGuard: NaN,
  debounceGuardInterval: 100,
  data: [],
  input: '',
} as IBarcodeData;

export type TBarcodeCallback = (input: string) => void;

const BARCODE_HALT_TOKENS = ['EnterArrowDown', 'Enter'];
const BARCODE_HALT_SYMBOL = '\n';
const BARCODE_UPPER_TOKEN = 'Shift';

export const isEAN13 = (code: string) => {
  const ean13Length = 13;

  return code.length === ean13Length;
};

export const isInvoiceBarcode = (code: string) => {
  const isLikeInvoice = /^11\d{11}\n?$/.test(code);

  return isEAN13(code.replace(BARCODE_HALT_SYMBOL, '')) && isLikeInvoice;
};

export const isSKUBarcode = (code: string) => {
  const isLikeProduct = /^\d{13}\n?$/.test(code);

  return isEAN13(code.replace(BARCODE_HALT_SYMBOL, '')) && isLikeProduct;
};

export const isPhotoBoxBarcode = (code: string) => {
  const isLikePhotoBox = /^83-\d{10}\n?$/.test(code);

  return isEAN13(code.replace(BARCODE_HALT_SYMBOL, '')) && isLikePhotoBox;
};

export const isOversizedBarcode = (code: string) => {
  return (
    isEAN13(code.replace(BARCODE_HALT_SYMBOL, '')) &&
    /^82-\d{10}\n?$/.test(code)
  );
};

export const isBoxesBarcode = (code: string) => {
  const isLikeBox = /^80|81|82|83-\d{10}\n?$/.test(code);

  return isEAN13(code.replace(BARCODE_HALT_SYMBOL, '')) && isLikeBox;
};

export const isTransferBoxBarcode = (code: string) => {
  return (
    isEAN13(code.replace(BARCODE_HALT_SYMBOL, '')) &&
    /^80-\d{10}\n?$/.test(code)
  );
};

export enum EBarcodeEvents {
  INVOICE = 'INVOICE',
  SKU = 'SKU',
  BOXES = 'BOXES',
  REQUEST = 'REQUEST',
  ERROR = 'ERROR',
}

const handleBarcodeEmit = (lastCode: string) => {
  const validLastCode = lastCode.replace(BARCODE_HALT_SYMBOL, '');

  if (isEAN13(validLastCode)) {
    Object.values(EBarcodeEvents).forEach((e) => {
      eventManager.emit(e, validLastCode);
    });
  } else if (validLastCode.length) {
    eventManager.emit(EBarcodeEvents.ERROR, validLastCode);
  }
};

export const setupBarcode = () => {
  eventManager.on(AppEvents.barcode.emit, handleBarcodeEmit);

  document.body.addEventListener('keyup', (event) => {
    if (
      event.target instanceof HTMLInputElement ||
      event.target instanceof HTMLTextAreaElement
    ) {
      // Костыль, чтобы не триггериться на инпуты у которых родитель имеет атрибут `data-scanner="disabled"`
      // if (
      // event.target.parentNode?.getAttribute('data-scanner') === 'disabled'
      // )
      // return;
      // TODO this is not right but users want this. Users are stupid. Need use return
    } else if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }

    inputData.input += event.key;

    if (inputData.debounceGuard) {
      clearTimeout(inputData.debounceGuard);
    }

    inputData.debounceGuard = window.setTimeout(() => {
      const BARCODE_HALT_TOKEN = BARCODE_HALT_TOKENS.find((token) =>
        inputData.input.includes(token),
      );

      if (BARCODE_HALT_TOKEN) {
        const upperRegExp = new RegExp(`${BARCODE_UPPER_TOKEN}(\\w)`, 'g');

        const lastCode = inputData.input
          .replace(BARCODE_HALT_TOKEN, BARCODE_HALT_SYMBOL)
          .replace(upperRegExp, (match, $1) => $1.toUpperCase());

        inputData.data.push(lastCode);
        eventManager.emit(AppEvents.barcode.emit, lastCode);
      }

      inputData.input = '';

      clearTimeout(inputData.debounceGuard);
    }, inputData.debounceGuardInterval);
  });
};
