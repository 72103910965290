/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InvoiceNotAddedItem,
    InvoiceNotAddedItemFromJSON,
    InvoiceNotAddedItemFromJSONTyped,
    InvoiceNotAddedItemToJSON,
} from './InvoiceNotAddedItem';

/**
 * 
 * @export
 * @interface InvoiceNotAddedItemList
 */
export interface InvoiceNotAddedItemList {
    /**
     * 
     * @type {number}
     * @memberof InvoiceNotAddedItemList
     */
    photoRequestID: number;
    /**
     * 
     * @type {Array<InvoiceNotAddedItem>}
     * @memberof InvoiceNotAddedItemList
     */
    items: Array<InvoiceNotAddedItem>;
}

export function InvoiceNotAddedItemListFromJSON(json: any): InvoiceNotAddedItemList {
    return InvoiceNotAddedItemListFromJSONTyped(json, false);
}

export function InvoiceNotAddedItemListFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceNotAddedItemList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'photoRequestID': json['photoRequestID'],
        'items': ((json['items'] as Array<any>).map(InvoiceNotAddedItemFromJSON)),
    };
}

export function InvoiceNotAddedItemListToJSON(value?: InvoiceNotAddedItemList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'photoRequestID': value.photoRequestID,
        'items': ((value.items as Array<any>).map(InvoiceNotAddedItemToJSON)),
    };
}

