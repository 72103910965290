<template>
  <div class="date-wrapper text-m font-regular">
    <template v-if="!isEmpty">
      <span class="date">
        {{ date }}
      </span>
      <span class="time">{{ time }}</span>
    </template>
    <template v-else>
      {{ $t('no data') }}
    </template>
  </div>
</template>
<script lang="ts">
import { computed, ComputedRef, defineComponent } from 'vue';

import { LOCALE } from '@/config';

const MS_IN_SECOND = 1000;

const dateFormat = {
  day: '2-digit',
  year: '2-digit',
  month: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
};

export default defineComponent({
  name: 'date',
  props: {
    /* Timestamp in seconds */
    value: {
      type: Number,
      default: 0,
    },
    format: {
      type: Object,
      default: dateFormat,
    },
  },
  setup(props) {
    const locale = LOCALE || 'ru-RU';

    if (props.value !== undefined && props.value > 0) {
      const valueAsDate: ComputedRef<Date> = computed(() => {
        return new Date(props.value * MS_IN_SECOND);
      });

      const dateFormatted: ComputedRef<string[]> = computed(() =>
        valueAsDate.value
          .toLocaleString(locale, {
            ...dateFormat,
            ...props.format,
          } as any)
          .replace(',', '')
          .split(' '),
      );

      const date: ComputedRef<string> = computed(() => dateFormatted.value[0]);
      const time: ComputedRef<string> = computed(() => dateFormatted.value[1]);

      return {
        date,
        time,
        isEmpty: false,
      };
    } else
      return {
        isEmpty: true,
      };
  },
});
</script>
<style lang="stylus" scoped>
.date-wrapper
  color Gray(DK8)
  display flex

.date
  color Gray(DK40)
  margin-right 4px
</style>
