/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaymentRate,
    PaymentRateFromJSON,
    PaymentRateFromJSONTyped,
    PaymentRateToJSON,
} from './PaymentRate';
import {
    Sku,
    SkuFromJSON,
    SkuFromJSONTyped,
    SkuToJSON,
} from './Sku';

/**
 * 
 * @export
 * @interface InvoiceItem
 */
export interface InvoiceItem {
    /**
     * 
     * @type {number}
     * @memberof InvoiceItem
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceItem
     */
    expectedAmount: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceItem
     */
    actualAmount: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceItem
     */
    created: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceItem
     */
    updated: number;
    /**
     * 
     * @type {Sku}
     * @memberof InvoiceItem
     */
    sku?: Sku;
    /**
     * 
     * @type {PaymentRate}
     * @memberof InvoiceItem
     */
    paymentRate?: PaymentRate;
}

export function InvoiceItemFromJSON(json: any): InvoiceItem {
    return InvoiceItemFromJSONTyped(json, false);
}

export function InvoiceItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'expectedAmount': json['expectedAmount'],
        'actualAmount': json['actualAmount'],
        'created': json['created'],
        'updated': json['updated'],
        'sku': !exists(json, 'sku') ? undefined : SkuFromJSON(json['sku']),
        'paymentRate': !exists(json, 'paymentRate') ? undefined : PaymentRateFromJSON(json['paymentRate']),
    };
}

export function InvoiceItemToJSON(value?: InvoiceItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'expectedAmount': value.expectedAmount,
        'actualAmount': value.actualAmount,
        'created': value.created,
        'updated': value.updated,
        'sku': SkuToJSON(value.sku),
        'paymentRate': PaymentRateToJSON(value.paymentRate),
    };
}

