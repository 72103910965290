<template>
  <PageLayout class="invoice" filled-header>
    <ScannerEmulator v-if="isDevelopment" />
    <template #header>
      <PageHeader
        :title="$t('modal.invoice.title', { id: `${props.id}` })"
        :is-loading-animation="state.loading"
        name="invoice"
        :class="[
          'invoice-header',
          {
            'invoice-header_no-actions': isDone || isCreated,
          },
        ]"
      >
        <template #prefix>
          <RouterLink
            data-test-id="button__back-page"
            :to="{
              name: AppRoutes.invoices.name,
            }"
            variant="text"
            replace
          >
            <ArrowLeft />
          </RouterLink>
        </template>
        <div v-if="!state.loading" class="info">
          <dl
            v-if="state.box?.barcode && !isDone"
            data-test-id="text__box-barcode-invoice"
          >
            <dt>{{ $t('box barcode') }}</dt>
            <dd>
              {{ state.box?.barcode }}
              <CopyButton :text="state.box?.barcode" />
            </dd>
          </dl>
          <dl v-if="isAcceptance">
            <dt>{{ $t('sender') }}</dt>
            <dd>
              {{ $t('warehouse') }}
            </dd>
          </dl>
          <dl>
            <dt>{{ $t('date created') }}</dt>
            <dd>
              <Date :value="state.invoice?.created" />
            </dd>
          </dl>
          <dl>
            <dt>{{ $t('date updated') }}</dt>
            <dd>
              <Date :value="state.invoice?.updated" />
            </dd>
          </dl>
        </div>
        <template v-if="canEditInvoices">
          <template v-if="isAcceptance">
            <UiButton
              v-if="isInCreated"
              data-test-id="button__accept-invoice"
              type="primary"
              @click.stop="onAcceptClick"
            >
              {{ $t('modal.invoice.actions.accept') }}
            </UiButton>
            <UiButton
              v-else-if="isInAccounting"
              data-test-id="button__finish-counting"
              type="primary"
              @click.stop="onFinishClick"
            >
              {{ $t('modal.invoice.actions.finish accounting') }}
            </UiButton>
            <UiButton
              v-else-if="!isDone"
              :disabled="isStartAccountingDisabled"
              class="start-acceptance-button"
              data-test-id="button__start-counting"
              type="primary"
              @click.stop="onStartInvoiceAccounting"
            >
              {{ $t('modal.invoice.actions.start accounting') }}
            </UiButton>
          </template>
          <template v-else>
            <div v-if="isDraft && !state.loading" class="draft-buttons">
              <UiTooltip
                :title="totalNotAddedProducts ? $t('modal.invoice.hint') : ''"
                placement="bottom"
              >
                <div class="cursor-pointer">
                  <UiButton
                    data-test-id="button__save-invoice"
                    :disabled="!canSave"
                    type="primary"
                    @click="onSaveClick"
                  >
                    {{ $t('button.save') }}
                  </UiButton>
                </div>
              </UiTooltip>

              <UiButton
                data-test-id="button__delete-invoice"
                type="primary"
                danger
                @click="onDeleteClick"
              >
                {{ $t('button.delete') }}
              </UiButton>
            </div>
            <div v-else-if="isCreated" class="sending-wrapper">
              <Tooltip :text="`${$t('transition to status')} ${$t('SENT')}`">
                <UiButton
                  class="sending-created"
                  data-test-id="button__send-invoice"
                  type="primary"
                  @click.stop="onChangeStatusToDone"
                >
                  {{ $t('modal.invoice.actions.send') }}
                </UiButton>
              </Tooltip>
            </div>
          </template>
        </template>
      </PageHeader>
      <div class="wrapper">
        <UiTag
          v-if="state.invoice"
          data-test-id="icon__invoice-status"
          :color-name="
            invoiceStatusColor[state.invoice.type][state.invoice.status]
          "
        >
          {{ $t(`${state.invoice?.type}_${state.invoice?.status}`) }}
        </UiTag>
        <UiTag
          v-if="state.invoice"
          :color-name="invoiceTypeColor[state.invoice.type]"
          data-test-id="icon__invoice-type"
          is-text-colored
        >
          {{ $t(`${state.invoice?.type}`) }}
        </UiTag>
        <div>
          <UiTabs
            v-if="isSending && isDraft"
            v-model:activeKey="activeTab"
            :default-active-key="1"
            :tab-bar-gutter="24"
            size="large"
            destroy-inactive-tab-pane
            class="invoice__tabs"
          >
            <UiTabPane :key="Tabs.ITEMS">
              <template #tab>
                {{
                  !state.invoiceItems.items.length
                    ? $t('products')
                    : $t('pluralization.product', state.invoiceItems.totalCount)
                }}
              </template>
              <TableInAccounting
                v-if="isInAccounting"
                :state="state"
                :has-count="hasCount"
                :is-sending="isSending"
                :is-draft="isDraft"
                :handle-row-click="customRow"
                :handle-remove-item="handleRemoveInvoiceItem"
                :is-accounting-disabled="isAccountingDisabled"
                :accept-invoice-item="acceptInvoiceItem"
                :handle-infinite-loader="handleInfiniteLoader"
                :get-photorequests="getPhotorequests"
                :create-photorequest="createPhotorequest"
                :delete-photorequest="deletePhotorequest"
                :add-photorequest-item="addPhotorequestItem"
                :delete-photorequest-item="deletePhotorequestItem"
                :subscribe-sku-scan="subscribe"
                :unsubscribe-sku-scan="unsubscribe"
                :toggle-active-photorequest="toggleActivePhotorequest"
                :get-invoice-item-by-barcode="getInvoiceItemByBarcode"
              />
              <TableInDraft
                v-else-if="isDraft"
                :state="state"
                :has-count="hasCount"
                :handle-row-click="customRow"
                :handle-remove-item="handleRemoveInvoiceItem"
                :is-accounting-disabled="isAccountingDisabled"
                :accept-invoice-item="acceptInvoiceItem"
                :handle-infinite-loader="handleInfiniteLoader"
                :bulk-delete-invoice-item="bulkDeleteInvoiceItem"
                :cancel-defect="cancelDefect"
                :bulk-cancel-defect="bulkCancelDefect"
                :bulk-mark-defect="bulkMarkDefect"
                :get-defected-row-class="getDefectedRowClass"
                :has-defect="hasDefect"
                :get-defect-title="getDefectTitle"
              />
              <TableDefault
                v-else
                :state="state"
                :items-source="state.invoiceItems"
                :has-count="hasCount"
                :is-sending="isSending"
                :is-draft="isDraft"
                :handle-row-click="customRow"
                :handle-remove-item="handleRemoveInvoiceItem"
                :is-accounting-disabled="isAccountingDisabled"
                :accept-invoice-item="acceptInvoiceItem"
                :handle-infinite-loader="handleInfiniteLoader"
                :get-defected-row-class="getDefectedRowClass"
                :has-defect="hasDefect"
                :get-defect-title="getDefectTitle"
                :get-requests="fetchAllPhotoRequests"
              />
            </UiTabPane>
            <UiTabPane :key="Tabs.NOT_ADDED_ITEMS">
              <template #tab>
                <div class="not-added-items-tab">
                  {{ $t('notAddedProducts') }}
                  <div
                    v-if="!state.notAddedInvoiceItems.loading"
                    class="not-added-items-counter"
                  >
                    {{ totalNotAddedProducts }}
                  </div>
                </div>
              </template>
              <table-default-by-request
                v-bind="table.props"
                :items-source="state.notAddedInvoiceItems"
              />
            </UiTabPane>
          </UiTabs>
          <div v-else>
            <TableInAccounting
              v-if="isInAccounting"
              :state="state"
              :has-count="hasCount"
              :is-sending="isSending"
              :is-draft="isDraft"
              :handle-row-click="customRow"
              :handle-remove-item="handleRemoveInvoiceItem"
              :is-accounting-disabled="isAccountingDisabled"
              :accept-invoice-item="acceptInvoiceItem"
              :handle-infinite-loader="handleInfiniteLoader"
              :get-photorequests="getPhotorequests"
              :create-photorequest="createPhotorequest"
              :delete-photorequest="deletePhotorequest"
              :add-photorequest-item="addPhotorequestItem"
              :delete-photorequest-item="deletePhotorequestItem"
              :subscribe-sku-scan="subscribe"
              :unsubscribe-sku-scan="unsubscribe"
              :toggle-active-photorequest="toggleActivePhotorequest"
              :get-invoice-item-by-barcode="getInvoiceItemByBarcode"
            />
            <TableInDraft
              v-else-if="isDraft"
              :state="state"
              :has-count="hasCount"
              :handle-row-click="customRow"
              :handle-remove-item="handleRemoveInvoiceItem"
              :is-accounting-disabled="isAccountingDisabled"
              :accept-invoice-item="acceptInvoiceItem"
              :handle-infinite-loader="handleInfiniteLoader"
              :bulk-delete-invoice-item="bulkDeleteInvoiceItem"
              :cancel-defect="cancelDefect"
              :bulk-cancel-defect="bulkCancelDefect"
              :bulk-mark-defect="bulkMarkDefect"
              :get-defected-row-class="getDefectedRowClass"
              :has-defect="hasDefect"
              :get-defect-title="getDefectTitle"
            />
            <TableDefault
              v-else
              :state="state"
              :items-source="state.invoiceItems"
              :has-count="hasCount"
              :is-sending="isSending"
              :is-draft="isDraft"
              :handle-row-click="customRow"
              :handle-remove-item="handleRemoveInvoiceItem"
              :is-accounting-disabled="isAccountingDisabled"
              :accept-invoice-item="acceptInvoiceItem"
              :handle-infinite-loader="handleInfiniteLoader"
              :get-defected-row-class="getDefectedRowClass"
              :has-defect="hasDefect"
              :get-defect-title="getDefectTitle"
              :get-requests="fetchAllPhotoRequests"
            />
          </div>
        </div>
      </div>
    </template>

    <ConfirmModal
      v-model="isDeleteInvoiceItemConfirmVisible"
      :handler="handleDeleteInvoiceItemConfirm"
    >
      <template #header>{{ $t('confirm.invoice sku.title') }}</template>
      {{ $t('confirm.invoice sku.body') }}
      <template #confirm>{{ $t('button.confirm delete') }}</template>
    </ConfirmModal>

    <ConfirmModal
      v-model="isDeleteDraftConfirmVisible"
      :handler="handleDeleteDraftConfirm"
    >
      <template #header>{{ $t('confirm.invoice draft.title') }}</template>
      {{ $t('confirm.invoice draft.body') }}
      <template #confirm>{{ $t('button.confirm delete') }}</template>
    </ConfirmModal>
  </PageLayout>
  <discrepancy-modal
    v-if="isShowDiscrepancyModal"
    :id="invoiceID"
    :on-accept="onSaveWithDiscrepancy"
    :on-update="updateInvoiceStatus"
    @close="isShowDiscrepancyModal = false"
  />
  <sku-modal
    v-if="isShowSkuModal && skuId"
    :id="skuId"
    @close="handleCloseSkuModal"
  />
</template>

<script lang="ts">
import { computed, defineComponent, Ref, ref, watch, watchEffect } from 'vue';
export default defineComponent({ name: 'invoice-page' });
</script>

<script setup lang="ts">
import { ArrowLeftOutlined as ArrowLeft } from '@ant-design/icons-vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

import { InvoiceItem, InvoiceStatus, InvoiceType } from '@/api';
import { useQRCodePrint } from '@/composables/usePrint';
import {
  playBrak,
  playError,
  playSuccess,
  useSoundSignal,
} from '@/composables/useSoundSignal';
import { IS_DEVELOPMENT as isDevelopment } from '@/config';
import { useAuth } from '@/features/Auth';
import {
  EBarcodeEvents,
  isPhotoBoxBarcode,
  useBarcodeScan,
} from '@/features/Barcode';
import { AppEvents } from '@/features/Common';
import SkuModal from '@/features/Modals/sku/index.vue';
import { notification } from '@/features/Notifications';
import { ScannerEmulator } from '@/features/ScannerEmulator';
import TableDefaultByRequest from '@/pages/invoice/components/tableDefaultByRequest.vue';
import { useInvoicesApi } from '@/pages/invoices/composables';
import { AppRoutes } from '@/router/data';
import { PageHeader, PageLayout } from '@/template';
import {
  ConfirmModal,
  CopyButton,
  Date,
  Tooltip,
  UiButton,
  UiTabPane,
  UiTabs,
  UiTag,
  UiTooltip,
} from '@/ui';

import { qrNotification } from '../invoiceCreation/notification';
import DiscrepancyModal from './components/discrepancyModal/index.vue';
import TableDefault from './components/tableDefault.vue';
import TableInAccounting from './components/tableInAccounting.vue';
import TableInDraft from './components/tableInDraft.vue';
import { useInvoiceItemsApi } from './composables';
import { invoiceStatusColor, invoiceTypeColor } from './data';

const props = defineProps({
  id: {
    type: Number,
    required: true,
  },
});

enum Tabs {
  ITEMS = 'items',
  NOT_ADDED_ITEMS = 'not_added_items',
}
const activeTab = ref(Tabs.ITEMS);

const {
  getInvoiceById: updateTableInvoice,
  deleteInvoice: deleteTableInvoice,
} = useInvoicesApi();

const { t } = useI18n();
const router = useRouter();
const { canEditInvoices } = useAuth();

const TOAST_IDS = {
  accepted: 'accepted_invoice',
};

const invoiceID = ref(+props.id);
const wrapSound = useSoundSignal();
const isShowSkuModal = ref<boolean>(false);
const skuId = ref<number | null>(null);

const {
  state,
  totalNotAddedProducts,
  acceptInvoiceItem,
  acceptInvoiceItemByBarcode,
  deleteInvoiceItem,
  startInvoiceAccounting,
  finishInvoiceAccounting,
  changeStatusToDone,
  changeStatusToCreated,
  expressAccept,
  deleteInvoice,
  handleLoadMore,
  addSku,
  updateInvoiceStatus,
  getPhotorequests,
  createPhotorequest,
  deletePhotorequest,
  addPhotorequestItem,
  deletePhotorequestItem,
  toggleActivePhotorequest,
  bulkDeleteInvoiceItem,
  cancelDefect,
  bulkCancelDefect,
  bulkMarkDefect,
  getInvoiceItemByBarcode,
  getDefectedRowClass,
  hasDefect,
  getDefectTitle,
  fetchAllPhotoRequests,
  getSkuByBarcode,
  getSkuDetailed,
  getNotAddedInvoiceItems,
} = useInvoiceItemsApi(invoiceID.value, updateTableInvoice);

const handleCloseSkuModal = () => {
  isShowSkuModal.value = false;
  skuId.value = null;
};

const handleInfiniteLoader = async () => {
  await handleLoadMore();
};

const handleUpdate = () => {
  if (state.invoice) {
    updateTableInvoice(state.invoice.id);
  }
};

const isStartAccountingDisabled = computed(
  () =>
    !(
      state.invoice &&
      [InvoiceStatus.Incoming, InvoiceStatus.InAccounting].includes(
        state.invoice.status,
      )
    ),
);

const isAcceptance = computed(
  () => state.invoice?.type === InvoiceType.Acceptance,
);

const isSending = computed(() => state.invoice?.type === InvoiceType.Sending);
const isDraft = computed(() => state.invoice?.status === InvoiceStatus.Draft);

const isInCreated = computed(
  () => state.invoice?.status === InvoiceStatus.Created,
);

const isInAccounting = computed(
  () => state.invoice?.status === InvoiceStatus.InAccounting,
);

const isDone = computed(() => state.invoice?.status === InvoiceStatus.Done);

const isCreated = computed(
  () =>
    state.invoice?.type === InvoiceType.Sending &&
    state.invoice?.status === InvoiceStatus.Created,
);

const isSaving = ref(false);

const canSave = computed(
  () =>
    !!state.invoiceItems?.items.length &&
    !isSaving.value &&
    !totalNotAddedProducts.value,
);

const onSaveClick = async () => {
  try {
    isSaving.value = true;
    await changeStatusToCreated();
    showCreatedToast(invoiceID.value);
  } finally {
    isSaving.value = false;
  }
};

const showCreatedToast = (id: number) =>
  notification.success(t('notifications.invoice.created', { id }), {
    key: TOAST_IDS.accepted,
  });

const isAccountingDisabled = computed(
  () => !(state.invoice && state.invoice.status === InvoiceStatus.InAccounting),
);

const hasCount = computed(
  () => isInAccounting.value || (isDone.value && !isSending.value),
);

const onStartInvoiceAccounting = async () => {
  await startInvoiceAccounting();
  notification.success(
    t('notifications.invoice.on accounting', { id: state.invoice?.id }),
    { key: TOAST_IDS.accepted },
  );
};

const showAcceptedToast = (id: number) =>
  notification.success(t('notifications.invoice.accepted', { id }), {
    key: TOAST_IDS.accepted,
  });

const onAcceptClick = async () => {
  await expressAccept();

  if (state.invoice) {
    showAcceptedToast(state.invoice.id);
  }
};

const onSaveWithDiscrepancy = async () => {
  await finishInvoiceAccounting(true);
  notification.success(
    t('notifications.invoice.finishAcceptance', {
      id: invoiceID.value,
    }),
  );

  handleUpdate();
};

const onFinishClick = () => {
  const acceptInvoiceItemWatcher = watchEffect(async () => {
    if (!state.invoiceItems.loading) {
      try {
        await finishInvoiceAccounting().then((res) => {
          if (res) {
            notification.success(
              t('notifications.invoice.finishAcceptance', {
                id: invoiceID.value,
              }),
            );
          }
        });
      } catch (err) {
        notification.error(String(err));
      }

      acceptInvoiceItemWatcher && acceptInvoiceItemWatcher();
    }
  });
};

const table = computed(() => {
  const commonProps = {
    state,
    hasCount: hasCount.value,
    isSending: isSending.value,
    isDraft: isDraft.value,
    handleRowClick: customRow,
    handleRemoveItem: handleRemoveInvoiceItem,
    isAccountingDisabled: isAccountingDisabled.value,
    acceptInvoiceItem,
    handleInfiniteLoader,
  };

  if (isInAccounting.value) {
    return {
      component: TableInAccounting,
      props: {
        ...commonProps,
        getPhotorequests,
        createPhotorequest,
        deletePhotorequest,
        addPhotorequestItem,
        deletePhotorequestItem,
        subscribe,
        unsubscribe,
        toggleActivePhotorequest,
        getInvoiceItemByBarcode,
      },
    };
  } else if (isDraft.value) {
    return {
      component: TableInDraft,
      props: {
        ...commonProps,
        bulkDeleteInvoiceItem,
        cancelDefect,
        bulkCancelDefect,
        bulkMarkDefect,
        getDefectedRowClass,
        hasDefect,
        getDefectTitle,
      },
    };
  } else {
    return {
      component: TableDefault,
      props: {
        ...commonProps,
        getDefectedRowClass,
        hasDefect,
        getDefectTitle,
        getRequests: fetchAllPhotoRequests,
      },
    };
  }
});

watch(
  () => state.discrepancyError,
  (val) => {
    if (val) {
      isShowDiscrepancyModal.value = true;
    }
  },
);

const onChangeStatusToDone = async () => {
  await changeStatusToDone();
};

const printQrcode = useQRCodePrint();

const handleAddSku = async (barcode: string) => {
  try {
    const sku = await getSkuByBarcode(barcode);

    await addSku(sku);

    const detailed = await getSkuDetailed(sku.id);

    if (detailed.identifier) {
      qrNotification(detailed, () => printQrcode(detailed.identifier));
      playBrak();
    } else {
      eventManager.emit(AppEvents.barcode.success);
      playSuccess();
    }
  } catch (error) {
    playError();
    throw error;
  }
};

const handleBarcodeScan = async (barcode: string) => {
  if (!barcode || !canEditInvoices.value) return;
  handleCloseSkuModal();

  if (isSending.value) {
    if (isDraft.value) {
      await handleAddSku(barcode);
    }
  } else {
    if (!isAccountingDisabled.value) {
      if (isPhotoBoxBarcode(barcode)) {
        const photorequest = state.photorequests.find(
          (el) => el.barcode === barcode,
        );

        if (photorequest) {
          await wrapSound(
            new Promise((resolve) =>
              resolve(toggleActivePhotorequest(photorequest)),
            ),
          );
        }
      } else {
        const invoiceItem = await wrapSound(
          acceptInvoiceItemByBarcode(barcode),
        );

        if (invoiceItem?.sku) {
          isShowSkuModal.value = true;
          skuId.value = invoiceItem?.sku?.id;
        }
      }
    }
  }

  handleUpdate();
};

const [subscribe, unsubscribe] = useBarcodeScan(EBarcodeEvents.SKU, (barcode) =>
  handleBarcodeScan(barcode),
);

const customRow = (row: any) => {
  return {
    onClick: () => {
      if (row.sku?.id) {
        isShowSkuModal.value = true;
        skuId.value = row?.sku?.id;
      }
    },
    'data-test-id': 'row__invoice',
  };
};

const isDeleteInvoiceItemConfirmVisible = ref(false);
const removeInvoiceItemPointer: Ref<InvoiceItem | null> = ref(null);
const isShowDiscrepancyModal = ref(false);

const handleRemoveInvoiceItem = (item: InvoiceItem | null) => {
  removeInvoiceItemPointer.value = item;
  isDeleteInvoiceItemConfirmVisible.value = true;
};

const handleDeleteInvoiceItemConfirm = async () => {
  if (removeInvoiceItemPointer.value) {
    const { id, sku } = removeInvoiceItemPointer.value;
    const response = await deleteInvoiceItem(id);

    if (response) {
      notification.success(
        t('notifications.invoice.removeInvoiceItem', {
          name: sku?.title,
        }),
      );
      await getNotAddedInvoiceItems(invoiceID.value);
    }
  }

  isDeleteInvoiceItemConfirmVisible.value = false;
  handleUpdate();
};

const isDeleteDraftConfirmVisible = ref(false);

const onDeleteClick = () => {
  isDeleteDraftConfirmVisible.value = true;
};

const handleDeleteDraftConfirm = async () => {
  await deleteInvoice();

  if (state.invoice) {
    await deleteTableInvoice(state.invoice);
  }

  notification.success(t('notifications.invoice.draft_deleted'));
  await router.replace({
    name: AppRoutes.invoices.name,
  });
};
</script>

<style lang="stylus" scoped>
:deep() .page-title-wrapper
  margin-right 40px

@media(max-width: 1200px)
  :deep().page-title-wrapper
    margin-right 20px



.invoice__tabs
  margin-top 30px

.page-header
  gap: unset;

.invoice-chip
  border-radius 2px
  font-weight 500
  background-color #d3f7ef
  display inline-block
  padding 2px 8px
  margin-right 12px

.info
  display flex
  flex-grow 1

  dl
    padding 0 15px
    dt
      color Gray(DK10)

    dd
      margin 0
      line-height 20px
      margin-top 8px
      font-weight 500
      color Gray(DK32)



  @media(max-width 1200px)
    dl
      padding 0 8px


.draft-buttons
  display grid
  grid-template-columns repeat(2, auto)
  grid-column-gap 10px
  margin-left auto
.not-added-items-tab
  display: flex
  align-items center

.not-added-items-counter
  font-size 0.5rem
  width 24px
  height 24px
  color #fff
  background-color #1677ff
  border-radius 50%
  line-height 24px
  text-align center
  margin-left 5px
</style>
