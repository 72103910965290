<template>
  <UiTooltip :title="tooltip ? $t(`reservationStatus.${status}`) : ''">
    <div class="icon-container" :class="status.toLowerCase()">
      <component :is="statusToIcon[status]" />
    </div>
  </UiTooltip>
</template>

<script setup lang="ts">
import {
  CarOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  LockFilled,
} from '@ant-design/icons-vue';
import { type Component } from 'vue';

import { ReservationStatus } from '@/api';
import { UiTooltip } from '@/ui';

interface Props {
  status: ReservationStatus;
  tooltip?: boolean;
}

withDefaults(defineProps<Props>(), { tooltip: true });

const statusToIcon: Record<ReservationStatus, Component | undefined> = {
  [ReservationStatus.Created]: ClockCircleOutlined,
  [ReservationStatus.Reserved]: LockFilled,
  [ReservationStatus.InShipping]: CarOutlined,
  [ReservationStatus.Canceled]: CloseOutlined,
  [ReservationStatus.Completed]: CheckOutlined,
  [ReservationStatus.Unspecified]: undefined,
};
</script>

<style scoped lang="stylus">
.icon-container
    display inline-flex
    justify-content center
    align-items center
    width 28px
    height 28px
    border-radius 50%
    font-size 16px

.created
    background-color geekblue-1
    color geekblue-6

.reserved
    background-color orange-1
    color orange-6

.in_shipping
    background-color blue-1
    color blue-6

.canceled
    background-color red-1
    color red-6

.completed
    background-color green-1
    color green-6
</style>
