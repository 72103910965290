/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BoxError,
    BoxErrorFromJSON,
    BoxErrorFromJSONTyped,
    BoxErrorToJSON,
} from './BoxError';
import {
    BoxTransfer,
    BoxTransferFromJSON,
    BoxTransferFromJSONTyped,
    BoxTransferToJSON,
} from './BoxTransfer';

/**
 * 
 * @export
 * @interface CreateBoxTransferListResponse
 */
export interface CreateBoxTransferListResponse {
    /**
     * Короба для передачи
     * @type {Array<BoxTransfer>}
     * @memberof CreateBoxTransferListResponse
     */
    items: Array<BoxTransfer>;
    /**
     * Короба с ошибками
     * @type {Array<BoxError>}
     * @memberof CreateBoxTransferListResponse
     */
    boxErrors: Array<BoxError>;
}

export function CreateBoxTransferListResponseFromJSON(json: any): CreateBoxTransferListResponse {
    return CreateBoxTransferListResponseFromJSONTyped(json, false);
}

export function CreateBoxTransferListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateBoxTransferListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(BoxTransferFromJSON)),
        'boxErrors': ((json['boxErrors'] as Array<any>).map(BoxErrorFromJSON)),
    };
}

export function CreateBoxTransferListResponseToJSON(value?: CreateBoxTransferListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(BoxTransferToJSON)),
        'boxErrors': ((value.boxErrors as Array<any>).map(BoxErrorToJSON)),
    };
}

