<template>
  <ModalWrapper
    :title="getLang(state.sku?.description ?? '')"
    class="sku-modal-wrapper"
    :class="{ 'sku-modal-wrapper-mr': isReferencesOpen }"
    @close="$emit('close')"
  >
    <template #headerControls>
      <ReferencesButton
        v-if="state.sku && state.sku.category"
        v-model:open="isReferencesOpen"
        :category-id="state.sku.category.id"
        :category-title="state.sku.category.title"
      />
    </template>
    <div v-if="state.sku && !state.loading" class="sku-modal-content">
      <div
        v-if="state.sku?.category?.fullTitle"
        class="sku-modal-category text-m"
      >
        {{ localizedCategories(filteredCategories) }}
      </div>
      <div v-if="state.hasHistory" class="sku-modal-history-alert">
        <InfoCircleFilled class="sku-modal-history-alert-icon" />
        <span class="sku-modal-history-alert-text">
          {{ $t('modal.sku.has history') }}
        </span>
      </div>
      <div v-if="getParentDefectInfo.defect" class="sku-modal-defect">
        <div class="sku-modal-defect-title">
          <span class="sku-modal-defect-title-desc">
            {{ $t('modal.sku.request defect') }}:
          </span>
          <UiTag :color="getParentDefectInfo.color">
            {{ getParentDefectInfo.defectReason?.title }}
          </UiTag>
        </div>
        <div
          v-if="getParentDefectInfo.defect?.comment"
          class="sku-modal-defect-comment"
        >
          {{ getParentDefectInfo.defect?.comment }}
        </div>
      </div>
      <div class="sku-modal-info-wrapper">
        <div class="sku-modal-image-wrapper">
          <UiImagePreview
            v-if="state.sku.images.length"
            :current="0"
            :preview-src-list="imageOrigSrcList"
            :src="imageSrc"
            :width="170"
            :height="226"
          >
            <template #previewMask>
              <EyeOutlined class="preview-icon" />
            </template>
            <template #title="{ index }">
              <UiTag v-if="isArchived(index)" color-name="Yellow">
                {{ $t('photo archived') }}
              </UiTag>
            </template>
          </UiImagePreview>
          <BaseImage v-else :url="state.sku.photoURL" :width="170" />

          <UiTooltip
            v-if="shouldShowPhotoShootButton"
            :title="
              photoShootButtonDisabled
                ? $t('modal.sku.start photo shoot tooltip')
                : ''
            "
          >
            <div class="start-photoshoot-button">
              <UiButton
                :disabled="photoShootButtonDisabled"
                type="primary"
                block
                data-intro="start-photoshoot"
                @click="startPhotoShoot"
              >
                {{
                  photoShootButtonDisabled
                    ? $t('modal.sku.photo shoot already started')
                    : $t('modal.sku.start photo shoot')
                }}
              </UiButton>
            </div>
          </UiTooltip>
        </div>
        <div class="sku-modal-info-inner">
          <div class="sku-modal-info-inner-content">
            <div class="sku-modal-info">
              <span class="info-label">
                {{ $t('status') }}
              </span>
              <span class="info-value">
                <UiTag
                  data-test-id="icon__product-status"
                  :color-name="skuStatusColor[state.sku?.status]"
                >
                  {{ $t(`sku.${state.sku?.status}`) }}
                </UiTag>
                <UiTag
                  v-if="state.defect"
                  color-name="PalePink"
                  data-test-id="icon__product-defect"
                >
                  {{ $t(`sku.DEFECT`) }}
                </UiTag>
              </span>
              <span class="info-label">SKU</span>
              <span class="info-value">
                {{ state.sku.title }}
                <CopyButton :text="state.sku.title" tooltip-placement="top" />
              </span>
              <span class="info-label">
                {{ $t('barcode') }}
              </span>
              <span class="info-value">
                {{ state.sku.barcode }}
                <CopyButton
                  :text="state.sku.barcode"
                  tooltip-placement="bottom"
                />
              </span>
              <span class="info-label">
                {{ $t('modal.sku.category id') }}
              </span>
              <span class="info-value">
                {{ state.sku?.category?.externalID }}
              </span>
              <span class="info-label">
                {{ $t('cost') }}
              </span>
              <span v-if="state.sku" class="price info-value">
                <Price :price="state.sku?.price" />
              </span>
            </div>
            <div class="sku-modal-links">
              <router-link
                class="sku-modal-link"
                data-test-id="button__link-modal-product"
                target="_blank"
                :to="{
                  name: AppRoutes.product.name,
                  params: {
                    id: state.sku?.id,
                  },
                }"
              >
                {{ $t('modal.sku.go to sku page') }}
                <icon-ui
                  class="sku-modal-link-icon"
                  name="navigation/external_link"
                  fill="#1890ff"
                  size="12"
                />
              </router-link>
              <a
                v-if="state.sku?.productId"
                class="sku-modal-link"
                data-test-id="button__link-modal-product-admin-panel"
                :href="`${ADMIN_PANEL_PRODUCT_URL}${state.sku?.productId}`"
                target="_blank"
              >
                {{ $t('modal.sku.go to admin panel') }}
                <icon-ui
                  class="sku-modal-link-icon"
                  name="navigation/external_link"
                  fill="#1890ff"
                  size="12"
                />
              </a>
            </div>
          </div>
          <div class="sku-modal-controls">
            <UiButton
              v-if="canMarkDefects && isSkuAccepted"
              data-test-id="button__defects"
              :disabled="!!state.defect"
              type="default"
              @click="handleDefectClick"
            >
              {{ $t('modal.sku.mark as defected short') }}
            </UiButton>
            <UiButton
              v-if="canPrintSkuBarcode"
              data-test-id="button__print"
              type="default"
              @click="handlePrintClick"
            >
              {{ $t('print barcode') }}
              <UiSpin v-if="isBarcodeLoading">
                <template #indicator>
                  <LoadingOutlined class="icon" />
                </template>
              </UiSpin>
              <PrinterFilled v-else />
            </UiButton>
            <Qrcode :identifier="state.sku?.identifier" />
          </div>
        </div>
      </div>
    </div>
  </ModalWrapper>
  <SkuDefectModal
    v-if="isShowDefectsModal && state.sku"
    :sku="state.sku"
    @close="isShowDefectsModal = false"
  />
</template>

<script lang="ts">
import { computed, defineComponent, ref, watchEffect } from 'vue';

export default defineComponent({ name: 'sku-modal' });
</script>

<script setup lang="ts">
import {
  EyeOutlined,
  InfoCircleFilled,
  LoadingOutlined,
  PrinterFilled,
} from '@ant-design/icons-vue';
import { useI18n } from 'vue-i18n';

import {
  ImageStatus,
  Invoice,
  PhotoRequestDetailed,
  PhotoRequestItemDefectReason,
  PhotoRequestItemDefectReasonType,
  PhotoRequestItemReviewStatus,
  PhotoRequestStatus,
  SkuStatus,
} from '@/api';
import Price from '@/components/Price.vue';
import { useEventManager } from '@/composables/useEventManager';
import { usePrint } from '@/composables/usePrint';
import { ADMIN_PANEL_PRODUCT_URL } from '@/config';
import { useAuth } from '@/features/Auth';
import { AppEvents } from '@/features/Common';
import { EIntroKeys, useFeatureIntro } from '@/features/FeatureIntro';
import ModalWrapper from '@/features/Modals/ModalWrapper.vue';
import { reviewStatusColor } from '@/features/Modals/request/data';
import { notification } from '@/features/Notifications';
import {
  StartPhotoShootReturn,
  usePhotographerFlow,
} from '@/features/PhotographerFlow';
import Qrcode from '@/pages/sku/components/Qrcode.vue';
import { skuStatusColor } from '@/pages/sku/data';
import { AppRoutes } from '@/router/data';
import {
  BaseImage,
  CopyButton,
  UiButton,
  UiImagePreview,
  UiSpin,
  UiTag,
  UiTooltip,
} from '@/ui';
import { originalImageSrc, productImageSrc } from '@/utils/imageUrl';
import { getLang, localizedCategories } from '@/utils/lang';

import SkuDefectModal from './components/skuDefect/index.vue';
import { useSkuApi } from './composables';
import ReferencesButton from './ReferencesButton.vue';

interface Props {
  id: number;
  invoice?: Invoice;
  photoRequestId?: number;
  isNewFlow?: boolean;
  shouldOpenRefs?: boolean;
  defectReasons?: PhotoRequestItemDefectReason[];
}

interface Emits {
  (e: 'close'): void;

  (e: 'photoshoot-started', v: StartPhotoShootReturn): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();
const skuId = Number(props.id);

const { state, getSkuBarcode, setSkuDefect, handleStartPhotoShoot } = useSkuApi(
  skuId,
  props.invoice,
  props.photoRequestId,
);

const { t } = useI18n();
const print = usePrint();
const [showCarouselIntro] = useFeatureIntro(EIntroKeys.SKU_IMAGE_CAROUSEL);

const [showStartPhotoshootIntro] = useFeatureIntro(
  EIntroKeys.PHOTOGRAPHER_FLOW_START_PHOTOSHOOT,
);

const isSkuAccepted = computed(() => state.sku?.status === SkuStatus.Accepted);

const { canMarkDefects, accountId, isPhotographer, canPrintSkuBarcode } =
  useAuth();

const isBarcodeLoading = ref(false);
const isShowDefectsModal = ref(false);

const handlePrintClick = async () => {
  if (isBarcodeLoading.value) return;

  isBarcodeLoading.value = true;

  try {
    const barcode = await getSkuBarcode(skuId);

    if (barcode) {
      print(barcode);
    } else {
      notification.error(t('modal.error.codes.printError'));
    }
  } finally {
    isBarcodeLoading.value = false;
  }
};

const filteredCategories = computed(() =>
  state.sku?.category?.fullTitle.replace('Все категории / ', ''),
);

const handleDefectClick = () => {
  isShowDefectsModal.value = true;
};

watchEffect(() => {
  if (state.sku && !state.loading && props.photoRequestId) {
    setTimeout(() => {
      showCarouselIntro();

      if (props.isNewFlow && isPhotographer.value) {
        showStartPhotoshootIntro();
      }
    }, 300);
  }
});

const imageSrc = computed<string>(() =>
  productImageSrc(
    state.sku?.previewImage?.key,
    state.sku?.previewImage?.urlPrefix,
  ),
);

const statusToOrder = {
  [ImageStatus.Active]: 1,
  [ImageStatus.Archived]: 2,
  [ImageStatus.SkuArchived]: 2,
};

const sortedImages = computed(() =>
  state.sku?.images.toSorted(
    (a, b) =>
      statusToOrder[a.status] - statusToOrder[b.status] ||
      a.ordering - b.ordering,
  ),
);

const imageOrigSrcList = computed(() => {
  return sortedImages.value?.map((image) =>
    originalImageSrc(image.key, image.urlPrefix),
  );
});

const isArchived = (index: number) =>
  [ImageStatus.Archived, ImageStatus.SkuArchived].includes(
    sortedImages.value?.at(index)?.status as ImageStatus,
  );

const isReferencesOpen = ref(props.shouldOpenRefs);

useEventManager(AppEvents.sku.defect, setSkuDefect);

const isOnPhotoShoot = (request: PhotoRequestDetailed): boolean =>
  [
    PhotoRequestStatus.OnPhotoShoot,
    PhotoRequestStatus.OnPhotoShootPanoramic,
  ].includes(request.status);

const shouldShowPhotoShootButton = computed(
  () =>
    props.isNewFlow &&
    !!state.request &&
    isOnPhotoShoot(state.request) &&
    state.request.photographerID === accountId.value,
);

const { lastScanned } = usePhotographerFlow();

const photoShootButtonDisabled = computed(
  () => lastScanned.value?.sku.id === props.id,
);

const startPhotoShoot = async () => {
  if (!state.request) return;

  const data = await handleStartPhotoShoot(state.request);

  eventManager.emit(AppEvents.barcode.success);

  if (!data) return;

  emit('photoshoot-started', data);
};

const getParentDefectInfo = computed(() => {
  const defect =
    state.requestItem &&
    state.requestItem.reviewStatus !== PhotoRequestItemReviewStatus.Accepted
      ? state.requestItem.defects?.find(
          (el) => el.itemId === state.requestItem?.parentId,
        )
      : null;

  const defectReason = props.defectReasons?.find(
    (el) => el.id === defect?.reasonId,
  );

  let reviewStatus = PhotoRequestItemReviewStatus.Unspecified;

  if (defectReason?.type === PhotoRequestItemDefectReasonType.Retouch) {
    reviewStatus = PhotoRequestItemReviewStatus.RetouchingDefect;
  } else if (defectReason?.type === PhotoRequestItemDefectReasonType.Photo) {
    reviewStatus = PhotoRequestItemReviewStatus.PhotoDefect;
  }

  return {
    defect,
    defectReason,
    reviewStatus,
    color: reviewStatusColor[reviewStatus],
  };
});
</script>

<style lang="stylus">
.sku-modal-wrapper
  .modal-title
    @apply text-xl font-semi-bold leading-long
    overflow hidden
    margin-right 10px

  .modal-content
    background White()
    width 680px
    min-height 300px
    border-radius 8px
    padding 0 32px 24px 32px
    transition margin-right 0.3s ease

  &-mr
    transition margin-right 0.3s cubic-bezier(0.23, 1, 0.32, 1)
    margin-right 500px

  .modal-header
    @apply text-s leading-short font-regular
    height unset
    max-width 680px
    padding 24px 32px 0px 32px
    display flex
    justify-content space-between
    align-items flex-start
    border none

    &-controls
      display flex
      align-items center

  .modal-footer
    display none
</style>

<style lang="stylus" scoped>


color-blue = #1890FF

.sku-modal-category
  display flex
  align-items center

.sku-modal-content
  padding-top 6px

.sku-modal-history-alert
  padding 12px 17px
  background-color #E6F7FF
  color #141414
  border 1px solid color-blue
  border-radius 2px
  margin 24px 0

  &-icon
    color color-blue

  &-text
    margin-left 10px

.sku-modal-info-wrapper
  display grid
  grid-template-columns auto 1fr
  grid-column-gap 23px
  margin-top 24px

.sku-modal-image-wrapper
  display flex
  flex-direction column
  justify-content space-between

.sku-modal-info-inner
  display flex
  flex-direction column

  &-content
    flex-grow 1

.sku-modal-info
  display grid
  grid-template-columns 1fr 3fr
  grid-auto-rows min-content
  grid-column-gap 10px
  grid-row-gap 10px
  align-items center

.info-label
  color Gray(DK8)

.info-value
  color Gray(DK16)
  overflow-wrap anywhere
  line-height 26px

.info-value,
.info-label
  @apply leading-short text-m

.sku-modal-links
  margin-top 24px
  display flex
  flex-direction column
  align-items flex-start
  gap 10px

.sku-modal-link
  color color-blue
  cursor pointer

  &-icon
    margin-left 6px

.sku-modal-controls
  display flex
  margin-top 16px
  gap 10px


.sku-modal-defect
  padding 12px
  background-color gray-3
  border-radius 4px

  &-title
    display flex
    align-items center
    column-gap 18px

    &-desc
      font-weight bold

  &-comment
    margin-top 12px

.price
  white-space nowrap

.preview-icon
  font-size 24px

.icon
  font-size 14px
  margin-left 8px

.start-photoshoot-button
  margin-top auto
</style>
