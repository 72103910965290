/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PhotoModelListResponse,
    PhotoModelListResponseFromJSON,
    PhotoModelListResponseToJSON,
    PhotoModelWithAgency,
    PhotoModelWithAgencyFromJSON,
    PhotoModelWithAgencyToJSON,
    ResponseError,
    ResponseErrorFromJSON,
    ResponseErrorToJSON,
} from '../models';

export interface PhotoModelDetailRequest {
    photoModelID: number;
}

export interface PhotoModelsListRequest {
    page: number;
    size: number;
    ids?: Array<number>;
}

/**
 * 
 */
export class PhotoModelApi extends runtime.BaseAPI {

    /**
     * Получить фотомодель
     */
    async photoModelDetailRaw(requestParameters: PhotoModelDetailRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PhotoModelWithAgency>> {
        if (requestParameters.photoModelID === null || requestParameters.photoModelID === undefined) {
            throw new runtime.RequiredError('photoModelID','Required parameter requestParameters.photoModelID was null or undefined when calling photoModelDetail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/photomodels/{photoModelID}`.replace(`{${"photoModelID"}}`, encodeURIComponent(String(requestParameters.photoModelID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhotoModelWithAgencyFromJSON(jsonValue));
    }

    /**
     * Получить фотомодель
     */
    async photoModelDetail(requestParameters: PhotoModelDetailRequest, initOverrides?: RequestInit): Promise<PhotoModelWithAgency> {
        const response = await this.photoModelDetailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получить список моделей.
     */
    async photoModelsListRaw(requestParameters: PhotoModelsListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PhotoModelListResponse>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling photoModelsList.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling photoModelsList.');
        }

        const queryParameters: any = {};

        if (requestParameters.ids) {
            queryParameters['ids'] = requestParameters.ids;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/photomodels`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhotoModelListResponseFromJSON(jsonValue));
    }

    /**
     * Получить список моделей.
     */
    async photoModelsList(requestParameters: PhotoModelsListRequest, initOverrides?: RequestInit): Promise<PhotoModelListResponse> {
        const response = await this.photoModelsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
