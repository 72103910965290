<template>
  <div
    v-show="props.text"
    style="display: inline-block; width: fit-content"
    :class="props.class"
  >
    <Tooltip :text="tooltip" :placement="tooltipPlacement">
      <button
        class="btn"
        @click.prevent.stop="copy()"
        @mouseleave="tooltip = $t('copy')"
      >
        <slot />
        <slot name="icon">
          <CopyOutlined class="icon" data-test-id="icon__copy-id" />
        </slot>
      </button>
    </Tooltip>
  </div>
</template>

<script setup lang="ts">
import { CopyOutlined } from '@ant-design/icons-vue';
import { Placement } from '@popperjs/core';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { Tooltip } from '@/ui';

interface Props {
  text: string;
  tooltipPlacement?: Placement;
  class?: string;
}

const props = withDefaults(defineProps<Props>(), {
  tooltipPlacement: 'bottom',
});

const { t } = useI18n();
const tooltip = ref(t('copy'));

const copy = () => {
  navigator.clipboard.writeText(props.text);
  tooltip.value = t('copied');
};
</script>

<style lang="stylus" scoped>
.btn
  display flex
  justify-content center
  color blue-6
  width 20px
  cursor pointer
  height 20px
</style>
