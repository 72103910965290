/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ChangeRequestStatusResponseItem,
    ChangeRequestStatusResponseItemFromJSON,
    ChangeRequestStatusResponseItemFromJSONTyped,
    ChangeRequestStatusResponseItemToJSON,
} from './ChangeRequestStatusResponseItem';

/**
 * 
 * @export
 * @interface ChangeRequestStatusBulkResponse
 */
export interface ChangeRequestStatusBulkResponse {
    /**
     * 
     * @type {Array<ChangeRequestStatusResponseItem>}
     * @memberof ChangeRequestStatusBulkResponse
     */
    items: Array<ChangeRequestStatusResponseItem>;
}

export function ChangeRequestStatusBulkResponseFromJSON(json: any): ChangeRequestStatusBulkResponse {
    return ChangeRequestStatusBulkResponseFromJSONTyped(json, false);
}

export function ChangeRequestStatusBulkResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeRequestStatusBulkResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(ChangeRequestStatusResponseItemFromJSON)),
    };
}

export function ChangeRequestStatusBulkResponseToJSON(value?: ChangeRequestStatusBulkResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(ChangeRequestStatusResponseItemToJSON)),
    };
}

