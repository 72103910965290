<template>
  <div v-show="scanActive" class="scanner-effect" />
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue';

import { AppEvents } from '@/features/Common';

const scanActive = ref(false);

const toggleScanEffect = () => {
  scanActive.value = true;
  setTimeout(() => {
    scanActive.value = false;
  }, 800);
};

onMounted(() => {
  eventManager.on(AppEvents.barcode.success, toggleScanEffect);
});

onUnmounted(() => {
  eventManager.off(AppEvents.barcode.success, toggleScanEffect);
});
</script>

<style scoped lang="stylus">
.scanner-effect {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  pointer-events: none; /* Чтобы не мешать взаимодействию с приложением */
  background-color: transparent;
  animation: screen-scan 800ms ease-in-out;
}

@keyframes screen-scan {
  0%,
  100% {
    background-color: transparent;
  }
  50% {
    background-color: rgba(82, 196, 26, 0.6);
  }
}
</style>
