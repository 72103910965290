import { InvoiceStatus, InvoiceType } from '@/api';

export const invoiceTypeColor: Record<InvoiceType, string> = {
  [InvoiceType.Acceptance]: 'StrongViolet',
  [InvoiceType.Sending]: 'BrightPink',
  [InvoiceType.Unspecified]: 'BrightPink',
};

export const invoiceStatusColor: Record<InvoiceType, Record<string, string>> = {
  [InvoiceType.Acceptance]: {
    [InvoiceStatus.Created]: 'PalePink',
    [InvoiceStatus.Incoming]: 'Yellow',
    [InvoiceStatus.InAccounting]: 'LightOrange',
    [InvoiceStatus.Done]: 'SoftGreen',
  },
  [InvoiceType.Sending]: {
    [InvoiceStatus.Created]: 'LightOrange',
    [InvoiceStatus.Draft]: 'LightGray',
    [InvoiceStatus.Done]: 'SoftGreen',
  },
  [InvoiceType.Unspecified]: {},
};
