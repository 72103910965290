<template>
  <div class="wrapper">
    <span class="title text-m font-medium leading-short">{{ sku.title }}</span>
    <div class="info-wrapper">
      <div class="info-item">
        <span class="label">SKU</span>
        <span class="value">
          {{ sku.id }}
        </span>
      </div>
      <div class="info-item">
        <span class="label">
          {{ $t('barcode') }}
        </span>
        <span class="value">
          {{ sku.barcode }}
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { Sku } from '@/api';

export default defineComponent({
  props: {
    sku: {
      type: Object as PropType<Sku>,
      required: true,
    },
  },
});
</script>

<style lang="stylus" scoped>


.wrapper
  display grid
  grid-row-gap 10px
  padding 14px 8px 8px

.info-wrapper
  display grid
  grid-row-gap 4px

.info-item
  display grid
  justify-content start
  grid-auto-flow column
  grid-column-gap 4px

.title
  color Gray(DK28)
  white-space nowrap

.label
  @apply text-s leading-short
  color Gray(LT8)

.value
  @apply text-s leading-short
  color Gray(DK16)
</style>
