<template>
  <sortable-head v-if="column.sortable" :column="column" v-bind="$attrs">
    <slot :name="column.Header">{{ column.Header }}</slot>
  </sortable-head>
  <th
    v-else
    class="th"
    v-bind="$attrs"
    :style="
      column.widthRatio && {
        width: Number(column.widthRatio)
          ? `${column.widthRatio}%`
          : column.widthRatio,
        minWidth: !Number(column.widthRatio) ? column.widthRatio : null,
      }
    "
  >
    <slot :name="column.Header">{{ column.Header }}</slot>
  </th>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { IColumn } from '../types';
import SortableHead from './sortable-head.vue';

export default defineComponent({
  components: {
    SortableHead,
  },
  props: {
    column: {
      type: Object as PropType<IColumn>,
      required: true,
    },
  },
});
</script>
<style lang="stylus" scoped>
::v-deep(.th)
  position sticky
  top 0
  z-index 1
</style>
