/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SkuPhotoRequestDefect
 */
export interface SkuPhotoRequestDefect {
    /**
     * Комментарий
     * @type {string}
     * @memberof SkuPhotoRequestDefect
     */
    comment: string;
    /**
     * Причина брака
     * @type {string}
     * @memberof SkuPhotoRequestDefect
     */
    reason: string;
    /**
     * 
     * @type {number}
     * @memberof SkuPhotoRequestDefect
     */
    userId: number;
    /**
     * Дата создания брака
     * @type {number}
     * @memberof SkuPhotoRequestDefect
     */
    createdAt: number;
}

export function SkuPhotoRequestDefectFromJSON(json: any): SkuPhotoRequestDefect {
    return SkuPhotoRequestDefectFromJSONTyped(json, false);
}

export function SkuPhotoRequestDefectFromJSONTyped(json: any, ignoreDiscriminator: boolean): SkuPhotoRequestDefect {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'comment': json['comment'],
        'reason': json['reason'],
        'userId': json['userId'],
        'createdAt': json['createdAt'],
    };
}

export function SkuPhotoRequestDefectToJSON(value?: SkuPhotoRequestDefect | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'comment': value.comment,
        'reason': value.reason,
        'userId': value.userId,
        'createdAt': value.createdAt,
    };
}

