<template>
  <div ref="wrapperRef" class="wrapper">
    <div
      class="table"
      :style="{
        height: !state.items.length ? '100%' : undefined,
      }"
    >
      <UiTable
        :data-source="state.items"
        :columns="columns"
        :row-key="(record) => record.id"
        bordered
        :custom-header-row="() => ({ 'data-test-id': 'table__header' })"
        @change="handleTableChange"
      >
        <template v-if="state.loading || error" #empty>
          <LinearLoader
            v-if="state.loading"
            :style="{ width: '100%', margin: '2em 0 1em 0' }"
          />
          <LoadError v-else-if="error" @retry="getInitial" />
        </template>
        <template #customFilterDropdown>
          <SearchPopup
            ref="searchPopup"
            :placeholder="$t('search barcode')"
            :mask="props.mask"
            @accept="handleAcceptSearch"
            @reset="handleResetSearch"
          />
        </template>
        <template #customFilterIcon>
          <div :title="$t('search')">
            <SearchOutlined :class="{ 'active-filter-color': query }" />
          </div>
        </template>
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'barcode'">
            {{ record.barcode }}
          </template>
          <template v-if="column.key === 'created'">
            <div class="created-cell">
              <DateCell no-padding :value="record.created" />
              <UiButton
                variant="light"
                :loading="record.requestPending"
                data-test-id="button__print"
                @click="handlePrintBarcode(record)"
              >
                <template #icon>
                  <PrinterFilled class="print-icon" />
                </template>
              </UiButton>
            </div>
          </template>
        </template>
      </UiTable>
    </div>

    <InfiniteLoader
      :key="state.page"
      :has-more="state.hasMore"
      :on-enter="handleInfiniteLoader"
    />

    <LinearLoader
      v-if="state.items.length && state.hasMore"
      :style="{ width: '100%', margin: '2em 0 1em 0' }"
    />
  </div>
</template>

<script setup lang="ts">
import { PrinterFilled, SearchOutlined } from '@ant-design/icons-vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

import InfiniteLoader from '@/components/InfiniteLoader.vue';
import DateCell from '@/components/table/cells/date-cell.vue';
import TextCell from '@/components/table/cells/text-cell.vue';
import LinearLoader from '@/components/ui/linear-loader/index.vue';
import { useEventManager } from '@/composables/useEventManager';
import { useIsInViewWrapper } from '@/composables/useIsInView';
import { usePrint } from '@/composables/usePrint';
import {
  EBarcodeEvents,
  isOversizedBarcode,
  isPhotoBoxBarcode,
  useBarcodeScan,
} from '@/features/Barcode';
import { AppEvents } from '@/features/Common';
import { CopyButton, LoadError, SearchPopup, UiButton, UiTable } from '@/ui';
import { oversizedBarcodeMask, photorequestBarcodeMask } from '@/utils/barcode';

import { EGenerateTypes } from '../data';

const { t } = useI18n();

const props = defineProps({
  composable: {
    type: Function,
    default: () => null,
  },
  mask: {
    type: String as () =>
      | typeof oversizedBarcodeMask
      | typeof photorequestBarcodeMask,
    required: true,
  },
  generateType: {
    type: String as () => EGenerateTypes,
    required: true,
  },
});

const {
  state,
  handleLoadMore,
  error,
  getInitial,
  query,
  sorting,
  printBarcode,
  addBox,
} = props.composable();

useBarcodeScan(EBarcodeEvents.BOXES, (barcode) => handleBarcodeScan(barcode));

const handleBarcodeScan = async (barcode: string) => {
  handleAcceptSearch(barcode);
};

const wrapperRef = ref();

useIsInViewWrapper(wrapperRef);

const handleInfiniteLoader = () => {
  handleLoadMore();
};

const handleTableChange = (pagination, filters, sorter) => {
  const { order, columnKey } = sorter;

  if (order) {
    if (columnKey === 'created') {
      sorting.field = 'createdAt';
    }

    if (order === 'descend') {
      sorting.direction = 'Desc';
    } else {
      sorting.direction = 'Asc';
    }
  } else {
    sorting.direction = '';
  }
};

const handleAcceptSearch = (payload: string) => {
  const { mask } = props;

  const isValidBarcode =
    (mask === oversizedBarcodeMask && isOversizedBarcode(payload)) ||
    (mask === photorequestBarcodeMask && isPhotoBoxBarcode(payload));

  if (isValidBarcode) {
    query.value = payload;
  } else {
    searchPopup.value.isValid = false;
  }
};

const handleResetSearch = () => {
  query.value = '';
};

const print = usePrint();

const handlePrintBarcode = async (record: any) => {
  record.requestPending = true;

  printBarcode(record.id)
    .then((response) => {
      print(response);
    })
    .finally(() => {
      record.requestPending = false;
    });
};

const searchPopup = ref();

const columns = [
  {
    title: t('barcode'),
    key: 'barcode',
    customFilterDropdown: true,
    dataIndex: 'barcode',
    onFilterDropdownVisibleChange: (visible) => {
      setTimeout(() => {
        if (visible) {
          searchPopup.value.$refs.searchRef.$el.focus();

          if (!query.value) {
            searchPopup.value.searchValue = '';
          } else if (query.value !== searchPopup.value.searchValue) {
            searchPopup.value.searchValue = query.value;
          }
        }
      }, 0);
    },
    width: '50%',
  },
  {
    title: t('date and time created'),
    key: 'created',
    dataIndex: 'created',
    sorter: true,
    defaultSortOrder: 'descend',
    sortDirections: ['descend', 'ascend'],
    width: '50%',
  },
];

const handleAddBox = ({ box, type }) => {
  if (type === props.generateType) addBox(box);
};

useEventManager(AppEvents.modals.generateBarcode.update, handleAddBox);
</script>

<style lang="stylus" scoped>
.wrapper
  position relative
  overflow auto
  height 100%


.active-filter-color
  color #2495fe

.search-popup
  padding 8px

.search-field
  border-radius 4px
  margin-bottom 8px
  width 188px
  display block

.search-btn
  width 90px
  border-radius 4px
  &:not(:last-child)
    margin-right 8px

.created-cell
  display flex
  justify-content space-between
  align-items center
</style>
