<template>
  <div :class="!!label && 'withLabel'">
    <span v-if="!!label" class="text-m leading-short font-medium">
      {{ label }}
    </span>
    <div class="wrapper">
      <input
        ref="inputRef"
        :disabled="disabled"
        :class="{ input: true, invalid, success }"
        v-bind="$attrs"
        :value="$attrs.modelValue"
        @input="$emit('update:modelValue', $event.target.value.trim())"
      />
      <div v-if="hasIconSlot" class="icons-wrapper">
        <slot />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  ref,
  Slots,
  watch,
} from 'vue';

export default defineComponent({
  name: 'text-field',
  inheritAttrs: false,
  props: {
    invalid: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isAutoFocused: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { slots }) {
    const hasIconSlot = !!slots.default;
    const iconCount = ref(0);
    const gapCount = computed(() => iconCount.value || 1);

    const updateIconCount = (slotsContainer: Slots = slots) => {
      if (slotsContainer.default) {
        iconCount.value = slotsContainer
          .default()
          .filter((el) => el.shapeFlag !== 8).length;
      } else {
        iconCount.value = 0;
      }
    };

    const inputRef = ref<HTMLInputElement | null>(null);

    onMounted(() => {
      updateIconCount();
      nextTick(() => {
        if (!props.isAutoFocused) return;
        inputRef.value && inputRef.value.focus();
      });
    });

    watch(() => slots, updateIconCount, {
      deep: true,
      immediate: true,
    });

    return { hasIconSlot, iconCount, gapCount, inputRef };
  },
});
</script>
<style lang="stylus" scoped>


.wrapper
  position relative

.withLabel
  display grid
  grid-row-gap 8px

.icons-wrapper
  display grid
  grid-auto-flow column
  grid-column-gap 12px
  position absolute
  right 12px
  top 50%
  transform translateY(-50%)

.input
  @apply text-s font-medium leading-short
  width 100%
  overflow hidden
  color Gray(DK28)
  box-sizing border-box
  height 39px
  border-width 1px
  border-style solid
  border-color Gray(LT40)
  border-radius 4px
  padding 12px
  transition border-color 200ms ease
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button
    -webkit-appearance none
    margin 0
  &
    -moz-appearance textfield

  &:placeholder
    color Gray(DK8)

  &:hover
    border-color Gray(DK8)

  &:focus
    outline none
    border-color Violet()

  &:disabled
    border-color Gray(LT40)
    background-color Gray(LT48)
    color Gray(LT8)

.invalid
  border-color Red(DK10)
  &:hover,
  &:active
    border-color Red(DK10)

.success
  border-color Green(DK10)
  &:hover,
  &:active
    border-color Green(DK10)
</style>
