import { onMounted, onUnmounted, ref, watch } from 'vue';
import { RouteParams, RouteParamsRaw, useRoute, useRouter } from 'vue-router';

import { AppEvents } from '@/features/Common';
import { AppRoutes } from '@/router/data';
import { equal } from '@/utils/equal';

export enum ERoutedModalTypes {
  INVOICE = 'invoice',
  SKU = 'sku',
  REQUEST = 'request',
}

export enum ECommonModalTypes {
  ERROR = 'error',
  SKU = 'sku',
  INVOICE_CREATION = 'invoiceCreation',
  REQUEST_CREATION = 'requestCreation',
  BOX_TRANSFER_ERRORS = 'boxTransferErrors',
}

export type EModalTypes = ECommonModalTypes | ERoutedModalTypes;
export const EModalTypes = { ...ECommonModalTypes, ...ERoutedModalTypes };

const modalTypeToRoute = {
  [ERoutedModalTypes.INVOICE]: AppRoutes.invoices,
  [ERoutedModalTypes.SKU]: AppRoutes.products,
  [ERoutedModalTypes.REQUEST]: AppRoutes.requests,
};

const filterParams = (params: RouteParamsRaw): RouteParamsRaw => {
  const rawParams = { ...params };

  Object.keys(params).forEach((key) => {
    if (params[key] === '') {
      delete rawParams[key];
    }
  });

  return rawParams;
};

export interface UseModalHooks {
  onOpen?: () => void;
  onClose?: () => void;
}

let handleRouteProcessing = false;

export function useModal<
  Payload extends Record<string, unknown> = Record<string, unknown>,
>(
  type: EModalTypes,
  hooks?: UseModalHooks,
): [(payload?: Payload) => void, () => void] {
  onMounted(() => {
    if (hooks?.onOpen) {
      eventManager.on(AppEvents.modals[type].open, hooks.onOpen);
    }

    if (hooks?.onClose) {
      eventManager.on(AppEvents.modals[type].close, hooks.onClose);
    }
  });

  onUnmounted(() => {
    if (hooks?.onOpen) {
      eventManager.off(AppEvents.modals[type].open, hooks.onOpen);
    }

    if (hooks?.onClose) {
      eventManager.off(AppEvents.modals[type].close, hooks.onClose);
    }
  });

  const open = (payload?: Payload) => {
    eventManager.emit(AppEvents.modals[type].open, payload);
  };

  const close = () => {
    eventManager.emit(AppEvents.modals[type].close);
  };

  return [open, close];
}

export function useRoutedModal(
  type: ERoutedModalTypes,
  hooks?: UseModalHooks,
  props?: any,
): [(payload: RouteParamsRaw) => Promise<void>, () => Promise<void>] {
  const [openModal, closeModal] = useModal<RouteParamsRaw>(type, {
    onOpen: hooks?.onOpen,
    onClose: () => {
      hooks?.onClose && hooks?.onClose();
      closeRoutedModal();
    },
  });

  const route = useRoute();
  const router = useRouter();

  const openRoutedModal = async (params: RouteParamsRaw) => {
    await router.replace({
      ...modalTypeToRoute[type],
      params,
    });
  };

  const closeRoutedModal = async () => {
    if (route.name === modalTypeToRoute[type].name) {
      await router.replace(modalTypeToRoute[type]);
    }
  };

  const handleRouteChange = (params: RouteParams) => {
    try {
      const filteredParams = filterParams(params);

      if (!equal(filteredParams, {})) {
        openModal({ ...filteredParams, ...props });
      } else {
        closeModal();
      }
    } catch {
      // If the id in path is not convertable to number
      closeRoutedModal();
    }
  };

  const debouncedCallback = (callback): void => {
    if (handleRouteProcessing) return;

    handleRouteProcessing = true;

    callback();

    setTimeout(() => {
      handleRouteProcessing = false;
    }, 100);
  };

  watch(
    () => route.params,
    (params, oldParams) => {
      debouncedCallback(() => {
        if (!equal(params, oldParams)) {
          handleRouteChange(params);
        }
      });
    },
  );

  onMounted(() => {
    debouncedCallback(() => {
      handleRouteChange(route.params);
    });
  });

  return [openRoutedModal, closeRoutedModal];
}

const isBlocked = ref(false);

export function lockHooklessModals() {
  isBlocked.value = true;
}

export function unlockHooklessModals() {
  isBlocked.value = false;
}
