import ky from 'ky';

import {
  ConvertAuthAccount,
  IAuthAccountDto,
  IAuthBody,
  IServerAuthAccountDto,
} from './models';

export * from './models';

type TKyApi = typeof ky;

const WMS_AUTH_ENDPOINT = 'account/oauth/token';

export class AuthApi {
  constructor(private fetchApi: TKyApi) {}

  public login = async (authBody: IAuthBody): Promise<IAuthAccountDto> => {
    const formData = new FormData();

    formData.append('username', authBody.username);
    formData.append('password', authBody.password);
    formData.append('grant_type', authBody.grant_type);

    return this.fetchApi
      .post(WMS_AUTH_ENDPOINT, {
        body: formData,
      })
      .json<IServerAuthAccountDto>()
      .then(ConvertAuthAccount);
  };

  public refreshToken = async (refreshToken: string) => {
    const formData = new FormData();

    formData.append('refresh_token', refreshToken);
    formData.append('grant_type', 'refresh_token');

    return this.fetchApi
      .post(WMS_AUTH_ENDPOINT, {
        body: formData,
      })
      .json<IServerAuthAccountDto>()
      .then(ConvertAuthAccount);
  };
}
