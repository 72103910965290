<script setup lang="ts">
import { useI18n } from 'vue-i18n';

import { SkuPhotoRequestDefect } from '@/api';
import { AppRoutes } from '@/router';
import { Chip, Date, DotDivider, UiCard } from '@/ui';

const { t } = useI18n();

interface IProps {
  list: SkuPhotoRequestDefect[];
  currentRequestId: number | undefined;
}

const props = defineProps<IProps>();

const getColor = (itemRequestId: number) => {
  return props.currentRequestId && itemRequestId === props.currentRequestId
    ? '#FFA39E'
    : '#EAEAEB';
};
</script>

<template>
  <div>
    <UiCard v-for="item in list" :key="item.createdAt" class="sku-defect mb-3">
      <div>
        <Chip
          :style="{
            backgroundColor: getColor(item.requestId),
          }"
          :value="item.reason"
        />
      </div>
      <div class="my-3">
        {{ item.comment }}
      </div>
      <div class="sku-defect-footer">
        <RouterLink
          :to="{
            name: AppRoutes.requests.name,
            params: { id: item.requestId },
          }"
          target="_blank"
        >
          <span class="sku-defect-request">
            {{ t('modal.requests.title', { id: item.requestId }) }}
          </span>
        </RouterLink>
        <dot-divider />
        <Date class="sku-defect-date" :value="item.createdAt" />
      </div>
    </UiCard>
  </div>
</template>

<style lang="stylus">
.sku-defect .ant-card-body
  padding 20px 16px 8px 16px !important

.sku-defect-footer
  display flex
  align-items center
  padding 8px 16px 0 16px
  border-top 1px solid #D9D9D9
  margin 0 -16px

.sku-defect-date
  font-width 400px

.sku-defect-request
  color #1890FF
  cursor pointer
</style>
