/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BulkDeletePhotoRequestRequest
 */
export interface BulkDeletePhotoRequestRequest {
    /**
     * Идентификаторы фотозаявок
     * @type {Array<number>}
     * @memberof BulkDeletePhotoRequestRequest
     */
    ids: Array<number>;
}

export function BulkDeletePhotoRequestRequestFromJSON(json: any): BulkDeletePhotoRequestRequest {
    return BulkDeletePhotoRequestRequestFromJSONTyped(json, false);
}

export function BulkDeletePhotoRequestRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkDeletePhotoRequestRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ids': json['ids'],
    };
}

export function BulkDeletePhotoRequestRequestToJSON(value?: BulkDeletePhotoRequestRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ids': value.ids,
    };
}

