/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Seller
 */
export interface Seller {
    /**
     * 
     * @type {number}
     * @memberof Seller
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof Seller
     */
    externalId: number;
    /**
     * 
     * @type {boolean}
     * @memberof Seller
     */
    isOurSeller: boolean;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    patronymic: string;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof Seller
     */
    email: string;
}

export function SellerFromJSON(json: any): Seller {
    return SellerFromJSONTyped(json, false);
}

export function SellerFromJSONTyped(json: any, ignoreDiscriminator: boolean): Seller {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'externalId': json['externalId'],
        'isOurSeller': json['isOurSeller'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'patronymic': json['patronymic'],
        'phone': json['phone'],
        'email': json['email'],
    };
}

export function SellerToJSON(value?: Seller | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'externalId': value.externalId,
        'isOurSeller': value.isOurSeller,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'patronymic': value.patronymic,
        'phone': value.phone,
        'email': value.email,
    };
}

