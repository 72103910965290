<template>
  <IsInViewWrapper ref="container" class="table-container">
    <UiTable
      :columns="columns"
      :data-source="isLoading ? Array(20).fill({}) : reservationItems"
      :empty-text="$t('reservationsPage.table.emptyText')"
      bordered
      @change="handleChange"
    >
      <template v-if="error" #empty>
        <LoadError />
      </template>

      <template #bodyCell="{ column, record }">
        <Skeleton v-if="isLoading" :paragraph="false" active />

        <template v-else>
          <router-link
            v-if="column.key === 'id'"
            :to="{
              name: AppRoutes.product.name,
              params: { id: record.skuId },
            }"
            class="absolute inset-0 p-4"
            target="_blank"
          >
            {{ record.skuExternalId }}
          </router-link>

          <div
            v-else-if="column.key === 'status'"
            class="flex items-center gap-2"
          >
            <ReservationStatusIcon :status="record.status" class="shrink-0" />
            <span>
              {{
                record.error
                  ? $t(
                      'reservationPage.errors.' + record.error.code,
                      $t('unknown error'),
                    )
                  : $t('reservationStatus.' + record.status)
              }}
            </span>
          </div>

          <template v-else-if="column.key === 'user'">
            {{ record.user.firstName }} {{ record.user.lastName }}
          </template>

          <template v-else-if="['createdAt', 'updatedAt'].includes(column.key)">
            <UiDate :value="record[column.key]" />
          </template>
        </template>
      </template>
    </UiTable>

    <template v-if="reservationItems.length && hasNextPage">
      <InfiniteLoader :has-more="hasNextPage" @enter="fetchNextPage" />
      <LinearLoader class="loader" />
    </template>
  </IsInViewWrapper>
</template>

<script setup lang="ts">
import { Skeleton, TableColumnType } from 'ant-design-vue';
import { ComponentPublicInstance, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import InfiniteLoader from '@/components/InfiniteLoader.vue';
import IsInViewWrapper from '@/components/IsInViewWrapper.vue';
import ReservationStatusIcon from '@/components/ReservationStatusIcon.vue';
import { usePreserveScroll } from '@/composables/usePreserveScroll';
import { SortingDirection } from '@/composables/useSorting';
import { useReservation } from '@/features/Reservation';
import { AppRoutes } from '@/router';
import {
  Date as UiDate,
  LinearLoader,
  LoadError,
  UiTable,
  UiTableProps,
} from '@/ui';

const route = useRoute();
const { t } = useI18n();

const {
  reservationItems,
  isLoading,
  error,
  hasNextPage,
  sortingField,
  sortingDirection,
  fetchNextPage,
  setSorting,
} = useReservation(String(route.params.id));

const sortableFields = ['updatedAt'] as const;

type SortableField = (typeof sortableFields)[number];

const toSortOrder = (direction: SortingDirection) =>
  direction === SortingDirection.ASC ? 'ascend' : 'descend';

const columns: TableColumnType[] = [
  { key: 'id', width: 150 },
  { key: 'skuTitle' },
  { key: 'status', width: 300 },
  { key: 'updatedAt', width: 150 },
].map((x) => ({
  ...(x as TableColumnType),
  dataIndex: x.key,
  title: t(`reservationPage.table.columns.${x.key}`),
  sorter: sortableFields.includes(x.key as SortableField),
  sortDirections: ['descend', 'ascend', 'descend'],
  defaultSortOrder:
    x.key === sortingField.value && sortingDirection.value
      ? toSortOrder(sortingDirection.value)
      : undefined,
}));

const container = ref<ComponentPublicInstance>();

const handleChange: UiTableProps['onChange'] = (_, __, sorter) => {
  const { order, columnKey } = Array.isArray(sorter) ? sorter[0] : sorter;

  const orderMap: Record<NonNullable<typeof order>, SortingDirection> = {
    ascend: SortingDirection.ASC,
    descend: SortingDirection.DESC,
  };

  setSorting(columnKey as SortableField, orderMap[order ?? '']);

  container.value?.$el.scrollTo({ top: 0 });
};

usePreserveScroll(container);
</script>

<style lang="stylus" scoped>
.table-container
  height 100%
  overflow auto
  border 1px solid #f0f0f0
  border-radius 5px
  background-color #fff

  :deep()
    .ant-table-thead .ant-table-cell
      background-color gray-2

    table
      border-top 0 !important

    .ant-skeleton-title
      margin 0

.loader
  width 100%
  display flex
  align-items center
</style>
