/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PhotoModelAgency
 */
export interface PhotoModelAgency {
    /**
     * Идентификатор агентства
     * @type {number}
     * @memberof PhotoModelAgency
     */
    id: number;
    /**
     * Дата создания
     * @type {number}
     * @memberof PhotoModelAgency
     */
    created: number;
    /**
     * Дата последнего обновления
     * @type {number}
     * @memberof PhotoModelAgency
     */
    updated: number;
    /**
     * Наименование агентства
     * @type {string}
     * @memberof PhotoModelAgency
     */
    name: string;
}

export function PhotoModelAgencyFromJSON(json: any): PhotoModelAgency {
    return PhotoModelAgencyFromJSONTyped(json, false);
}

export function PhotoModelAgencyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhotoModelAgency {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created': json['created'],
        'updated': json['updated'],
        'name': json['name'],
    };
}

export function PhotoModelAgencyToJSON(value?: PhotoModelAgency | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': value.created,
        'updated': value.updated,
        'name': value.name,
    };
}

