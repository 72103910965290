/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BoxTransfer,
    BoxTransferFromJSON,
    BoxTransferFromJSONTyped,
    BoxTransferToJSON,
} from './BoxTransfer';
import {
    BoxTransferListAllOf,
    BoxTransferListAllOfFromJSON,
    BoxTransferListAllOfFromJSONTyped,
    BoxTransferListAllOfToJSON,
} from './BoxTransferListAllOf';
import {
    PaginationResponse,
    PaginationResponseFromJSON,
    PaginationResponseFromJSONTyped,
    PaginationResponseToJSON,
} from './PaginationResponse';

/**
 * 
 * @export
 * @interface BoxTransferList
 */
export interface BoxTransferList {
    /**
     * количество элементов на одной странице
     * @type {number}
     * @memberof BoxTransferList
     */
    pageSize: number;
    /**
     * номер страницы
     * @type {number}
     * @memberof BoxTransferList
     */
    page: number;
    /**
     * общее количество страниц
     * @type {number}
     * @memberof BoxTransferList
     */
    totalPages: number;
    /**
     * общее количество элементов
     * @type {number}
     * @memberof BoxTransferList
     */
    totalItems: number;
    /**
     * Короба для передачи
     * @type {Array<BoxTransfer>}
     * @memberof BoxTransferList
     */
    items: Array<BoxTransfer>;
}

export function BoxTransferListFromJSON(json: any): BoxTransferList {
    return BoxTransferListFromJSONTyped(json, false);
}

export function BoxTransferListFromJSONTyped(json: any, ignoreDiscriminator: boolean): BoxTransferList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageSize': json['pageSize'],
        'page': json['page'],
        'totalPages': json['totalPages'],
        'totalItems': json['totalItems'],
        'items': ((json['items'] as Array<any>).map(BoxTransferFromJSON)),
    };
}

export function BoxTransferListToJSON(value?: BoxTransferList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pageSize': value.pageSize,
        'page': value.page,
        'totalPages': value.totalPages,
        'totalItems': value.totalItems,
        'items': ((value.items as Array<any>).map(BoxTransferToJSON)),
    };
}

