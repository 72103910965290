/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ShootingType,
    ShootingTypeFromJSON,
    ShootingTypeFromJSONTyped,
    ShootingTypeToJSON,
} from './ShootingType';

/**
 * 
 * @export
 * @interface PhotoRequestTemplate
 */
export interface PhotoRequestTemplate {
    /**
     * 
     * @type {number}
     * @memberof PhotoRequestTemplate
     */
    photoBoxID?: number;
    /**
     * 
     * @type {number}
     * @memberof PhotoRequestTemplate
     */
    invoiceID?: number;
    /**
     * 
     * @type {ShootingType}
     * @memberof PhotoRequestTemplate
     */
    shootingType?: ShootingType;
}

export function PhotoRequestTemplateFromJSON(json: any): PhotoRequestTemplate {
    return PhotoRequestTemplateFromJSONTyped(json, false);
}

export function PhotoRequestTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhotoRequestTemplate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'photoBoxID': !exists(json, 'photoBoxID') ? undefined : json['photoBoxID'],
        'invoiceID': !exists(json, 'invoiceID') ? undefined : json['invoiceID'],
        'shootingType': !exists(json, 'shootingType') ? undefined : ShootingTypeFromJSON(json['shootingType']),
    };
}

export function PhotoRequestTemplateToJSON(value?: PhotoRequestTemplate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'photoBoxID': value.photoBoxID,
        'invoiceID': value.invoiceID,
        'shootingType': ShootingTypeToJSON(value.shootingType),
    };
}

