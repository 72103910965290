/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Box,
    BoxFromJSON,
    BoxToJSON,
    BoxListResponse,
    BoxListResponseFromJSON,
    BoxListResponseToJSON,
    CreateBox,
    CreateBoxFromJSON,
    CreateBoxToJSON,
    PrintBarcodes,
    PrintBarcodesFromJSON,
    PrintBarcodesToJSON,
    SortBoxes,
    SortBoxesFromJSON,
    SortBoxesToJSON,
} from '../models';

export interface CreateBoxesRequest {
    createBox?: CreateBox;
}

export interface GetBoxesRequest {
    page: number;
    size: number;
    barcode?: string;
    ids?: Array<number>;
    printAvailable?: boolean;
    onlyOversized?: boolean;
    sort?: Array<SortBoxes>;
}

export interface PrintBoxesBarcodeRequest {
    printBarcodes?: PrintBarcodes;
}

/**
 * 
 */
export class BoxesApi extends runtime.BaseAPI {

    /**
     * Создать короба
     */
    async createBoxesRaw(requestParameters: CreateBoxesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Box>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/boxes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateBoxToJSON(requestParameters.createBox),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BoxFromJSON));
    }

    /**
     * Создать короба
     */
    async createBoxes(requestParameters: CreateBoxesRequest = {}, initOverrides?: RequestInit): Promise<Array<Box>> {
        const response = await this.createBoxesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getBoxesRaw(requestParameters: GetBoxesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BoxListResponse>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getBoxes.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getBoxes.');
        }

        const queryParameters: any = {};

        if (requestParameters.barcode !== undefined) {
            queryParameters['barcode'] = requestParameters.barcode;
        }

        if (requestParameters.ids) {
            queryParameters['ids'] = requestParameters.ids;
        }

        if (requestParameters.printAvailable !== undefined) {
            queryParameters['printAvailable'] = requestParameters.printAvailable;
        }

        if (requestParameters.onlyOversized !== undefined) {
            queryParameters['onlyOversized'] = requestParameters.onlyOversized;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/boxes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BoxListResponseFromJSON(jsonValue));
    }

    /**
     */
    async getBoxes(requestParameters: GetBoxesRequest, initOverrides?: RequestInit): Promise<BoxListResponse> {
        const response = await this.getBoxesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Распечатать ШК для коробов негабарита
     */
    async printBoxesBarcodeRaw(requestParameters: PrintBoxesBarcodeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/boxes/barcodes/print`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PrintBarcodesToJSON(requestParameters.printBarcodes),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Распечатать ШК для коробов негабарита
     */
    async printBoxesBarcode(requestParameters: PrintBoxesBarcodeRequest = {}, initOverrides?: RequestInit): Promise<string> {
        const response = await this.printBoxesBarcodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
