<template>
  <ui-popover :trigger="['click']" no-padding @open-change="onChangeVisible">
    <filter-value :is-opened="isOpened">
      {{ title }}
    </filter-value>
    <template #content>
      <filter-body data-test-id="popup__filter">
        <template v-for="item in processedConfiguration" :key="item.label">
          <filter-option
            v-if="item.value"
            :class="isTitleOption(item) && 'title-option'"
            :selected="isSelected(item.value)"
            :disabled="isDisabled(item.value)"
            :on-change="() => onChange(item.value)"
            :data-test-id="`${item.dti}`"
          >
            <chip v-if="item.color" :color="item.color">
              {{ item.label }}
            </chip>
          </filter-option>

          <div v-else :class="item.class || 'title-option'">
            {{ item.label }}
          </div>
        </template>
      </filter-body>
    </template>
  </ui-popover>
</template>

<script setup lang="ts">
import { computed, PropType, Ref, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { Chip, UiPopover } from '@/ui';

import FilterBody from './filter-body.vue';
import FilterOption from './filter-option.vue';
import FilterValue from './filter-value.vue';
import { FilterConfiguration, FilterEntry } from './types';

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  configuration: {
    type: Object as PropType<FilterConfiguration<any>>,
    required: true,
  },
  onChange: {
    type: Function as PropType<(value: any) => void>,
    required: true,
  },
  isSelected: {
    type: Function as PropType<(value: any) => boolean>,
    required: true,
  },
  isDisabled: {
    type: Function as PropType<(value: any) => boolean>,
    required: false,
    default: () => false,
  },
  withTranslation: {
    type: Boolean,
    default: true,
  },
});

const { t } = useI18n();
const isTitleOption = (option: FilterEntry) => !option.value;
const isOpened: Ref<boolean> = ref(false);

const processedConfiguration = computed(() =>
  props.withTranslation
    ? props.configuration.map((entry) => {
        const values = [entry.value?.status, entry.value?.type].filter(Boolean);

        return {
          ...entry,
          label: t(entry.label),
          dti: `switch__${values.join('__')}`.toLowerCase(),
        };
      })
    : props.configuration,
);

const onChangeVisible = (event: boolean) => {
  isOpened.value = event;
};
</script>

<style lang="stylus" scoped>


.title-option
  display  flex
  align-items center
  height 42px
  padding-left 24px
  background  Gray(LT50)
  font-size  12px
  letter-spacing  -0.02em
</style>
