/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Reservation,
    ReservationFromJSON,
    ReservationFromJSONTyped,
    ReservationToJSON,
} from './Reservation';
import {
    ResponseError,
    ResponseErrorFromJSON,
    ResponseErrorFromJSONTyped,
    ResponseErrorToJSON,
} from './ResponseError';

/**
 * 
 * @export
 * @interface ReservationWithValidateError
 */
export interface ReservationWithValidateError {
    /**
     * 
     * @type {Reservation}
     * @memberof ReservationWithValidateError
     */
    reservation: Reservation;
    /**
     * 
     * @type {ResponseError}
     * @memberof ReservationWithValidateError
     */
    error?: ResponseError;
}

export function ReservationWithValidateErrorFromJSON(json: any): ReservationWithValidateError {
    return ReservationWithValidateErrorFromJSONTyped(json, false);
}

export function ReservationWithValidateErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReservationWithValidateError {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reservation': ReservationFromJSON(json['reservation']),
        'error': !exists(json, 'error') ? undefined : ResponseErrorFromJSON(json['error']),
    };
}

export function ReservationWithValidateErrorToJSON(value?: ReservationWithValidateError | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reservation': ReservationToJSON(value.reservation),
        'error': ResponseErrorToJSON(value.error),
    };
}

