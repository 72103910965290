/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaymentRate,
    PaymentRateFromJSON,
    PaymentRateFromJSONTyped,
    PaymentRateToJSON,
} from './PaymentRate';

/**
 * 
 * @export
 * @interface ReservationItemTemplateV1
 */
export interface ReservationItemTemplateV1 {
    /**
     * 
     * @type {number}
     * @memberof ReservationItemTemplateV1
     */
    skuId: number;
    /**
     * 
     * @type {boolean}
     * @memberof ReservationItemTemplateV1
     */
    needPaid?: boolean;
    /**
     * 
     * @type {PaymentRate}
     * @memberof ReservationItemTemplateV1
     */
    paymentRate?: PaymentRate;
    /**
     * 
     * @type {boolean}
     * @memberof ReservationItemTemplateV1
     */
    useOldTariff?: boolean;
}

export function ReservationItemTemplateV1FromJSON(json: any): ReservationItemTemplateV1 {
    return ReservationItemTemplateV1FromJSONTyped(json, false);
}

export function ReservationItemTemplateV1FromJSONTyped(json: any, ignoreDiscriminator: boolean): ReservationItemTemplateV1 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'skuId': json['skuId'],
        'needPaid': !exists(json, 'needPaid') ? undefined : json['needPaid'],
        'paymentRate': !exists(json, 'paymentRate') ? undefined : PaymentRateFromJSON(json['paymentRate']),
        'useOldTariff': !exists(json, 'useOldTariff') ? undefined : json['useOldTariff'],
    };
}

export function ReservationItemTemplateV1ToJSON(value?: ReservationItemTemplateV1 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'skuId': value.skuId,
        'needPaid': value.needPaid,
        'paymentRate': PaymentRateToJSON(value.paymentRate),
        'useOldTariff': value.useOldTariff,
    };
}

