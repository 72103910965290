<template>
  <BaseFilterWrapper>
    <template #header>
      <span v-if="enabled.length === statuses.length">
        {{ $t('requestsPage.statusesDropdownLabel') }}
      </span>
      <span v-else class="selected">
        {{ $t('pluralization.statuses selected', enabled.length) }}
      </span>
    </template>
    <template #content>
      <UiMenu v-model:selected-keys="enabled" class="menu" multiple>
        <li v-for="(group, index) in grouped" :key="index" class="group">
          <ul>
            <UiMenuItem
              v-for="option in group"
              :key="option.value"
              class="option"
            >
              {{ option.label }}
            </UiMenuItem>
          </ul>
        </li>
      </UiMenu>
    </template>
  </BaseFilterWrapper>
</template>

<script setup lang="ts">
import { DownOutlined } from '@ant-design/icons-vue';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { PhotoRequestStatus } from '@/api';
import { statusFlow } from '@/features/Requests';
import { UiButton, UiMenu, UiMenuItem, UiPopover } from '@/ui';
import BaseFilterWrapper from '@/ui/filter/base-filter-wrapper.vue';
import FilterValue from '@/ui/filter/filter-value.vue';
import { chunks } from '@/utils/collection';

interface Props {
  disabled: PhotoRequestStatus[];
}

interface Emits {
  (e: 'update:disabled', v: PhotoRequestStatus[]): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();
const { t } = useI18n();
const statuses = computed(() => statusFlow.statuses);
const isOpened = ref(false);

const enabled = computed({
  get: () => statuses.value.filter((s) => !props.disabled.includes(s)),
  set: (v) => {
    if (!v.length) return;

    const newDisabled = statuses.value.filter((s) => !v.includes(s));

    emit('update:disabled', newDisabled);
  },
});

const options = computed(() =>
  statuses.value.map((value) => ({
    value,
    label: t(`photorequest.${value}`),
  })),
);

const grouped = computed(() => chunks(options.value, 3));
</script>

<style lang="stylus" scoped>


.menu:deep()
  border-inline-end 0 !important

  .group
    padding 8px

    ul
      display flex
      gap 12px

    &:not(:last-child)
      border-bottom 1px solid gray-4

    .option
      height auto
      margin 0
      padding 4px 8px
      line-height 1.5
      color gray-10
      border-radius 2px

      &.ant-menu-item
        width auto
      &.ant-menu-item-selected
        color gray-1
        background-color blue-6

.button
  min-width 206px
  padding 8px 15px
  justify-content space-between
  font-size 14px
  color gray-10

  .selected
    color blue-6

.icon
  width 14px
  height 14px
  color gray-10
</style>
