import { Ref, ref } from 'vue';

import { equal } from '@/utils/equal';

interface IUseFilter<T> {
  filters: T[];
  isFilterReseted: Ref<boolean>;
  handleFilterChange: (value: T) => void;
  isFilterSelected: (value: T) => boolean;
  resetFilters: () => void;
}

export const useFilters = <T>(): IUseFilter<T> => {
  const filters = ref([]) as Ref<T[]>;
  const isReseted = ref(false);

  const handleFilterChange = (value: T) => {
    isReseted.value = false;

    const index = filters.value.findIndex((item) => equal(item, value));

    if (index !== -1) {
      filters.value.splice(index, 1);
    } else {
      filters.value.push(value);
    }
  };

  const isFilterSelected = (value: T) =>
    filters.value.some((item) => equal(item, value));

  const resetFilters = () => {
    isReseted.value = true;
    filters.value.length = 0;
  };

  return {
    filters: filters.value,
    isFilterReseted: isReseted,
    handleFilterChange,
    isFilterSelected,
    resetFilters,
  };
};
