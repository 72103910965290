<template>
  <PageLayout v-if="reservation">
    <template #header>
      <div class="header">
        <div class="header-content pt-4">
          <div class="flex items-baseline gap-4">
            <RouterLink
              :to="{ name: AppRoutes.reservations.name }"
              class="text-black text-xl"
            >
              <ArrowLeftOutlined />
            </RouterLink>

            <div>
              <div class="flex items-center gap-2">
                <h1 class="title" data-test-id="page__title">
                  {{ $t('reservationPage.title', { id: id.slice(0, 8) }) }}...
                </h1>

                <CopyButton :text="id" />
              </div>

              <p class="text-sm m-0 text-gray-7 font-medium">
                {{ totalItems }} SKU
              </p>
            </div>
          </div>

          <dl class="ml-auto mb-0 flex gap-8 pt-2">
            <DescriptionItem
              :title="$t('reservationPage.userTitle')"
              :value="fullName(reservation.user)"
            />

            <DescriptionItem :title="$t('reservationPage.rateTitle')">
              <div class="flex items-center gap-1">
                {{
                  $t(`reservationPage.paymentRate.${reservation.paymentRate}`)
                }}
                <PaymentRateChip
                  :rate="reservation.paymentRate"
                  :tooltip="false"
                />
              </div>
            </DescriptionItem>

            <DescriptionItem
              :title="$t('reservationPage.createdTitle')"
              :value="formatDateTime(reservation.createdAt)"
            />
          </dl>
        </div>
      </div>
    </template>

    <div class="body">
      <ReservationItemsTable />
    </div>
  </PageLayout>

  <div v-else-if="isLoading" class="h-full w-full grid place-items-center">
    <UiSpin />
  </div>

  <div v-else-if="error" class="h-full w-full grid place-items-center">
    <UiResult status="error" :title="$t('load error.title')">
      <template #extra>
        <div class="flex justify-center">
          <UiButton key="console" type="primary" @click="refetch()">
            {{ $t('load error.try again') }}
          </UiButton>
        </div>
      </template>
    </UiResult>
  </div>
</template>

<script setup lang="ts">
import { ArrowLeftOutlined } from '@ant-design/icons-vue';
import { Result as UiResult } from 'ant-design-vue';
import { useRoute } from 'vue-router';

import PaymentRateChip from '@/components/PaymentRateChip.vue';
import { useReservation } from '@/features/Reservation';
import { AppRoutes } from '@/router';
import { PageLayout } from '@/template';
import { CopyButton, UiButton, UiSpin } from '@/ui';
import { formatDateTime } from '@/utils/date';
import { fullName } from '@/utils/name';

import DescriptionItem from './components/DescriptionItem.vue';
import ReservationItemsTable from './components/ReservationItemsTable.vue';

const route = useRoute();
const id = String(route.params.id);

const { reservation, totalItems, isLoading, error, refetch } =
  useReservation(id);
</script>

<style lang="stylus" scoped>
.header
  display flex
  align-items center

.header-content
  flex 1
  display flex
  align-items center
  gap 8px

.title
  margin 0
  font-size 1.25rem
  font-weight 600

.body
  height 100%
  padding 18px 0

.new-reservation-btn
  margin-left auto
</style>
