<template>
  <UiModal
    v-if="shouldShowNewFlowGreeting"
    v-model:open="visible"
    :closable="false"
    :after-close="onAfterClose"
    width="400px"
    centered
  >
    <div class="modal-container">
      <div>
        <img src="@/assets/new-flow-greeting.jpg" />
      </div>

      <div>
        <h2>{{ $t('newFlowGreeting.title') }}</h2>
        <p>
          {{ $t('newFlowGreeting.text') }}
        </p>
      </div>

      <UiButton type="primary" @click="visible = false">
        {{ $t('newFlowGreeting.button') }}
      </UiButton>
    </div>
  </UiModal>
</template>

<script setup lang="ts">
import { useStorage } from '@vueuse/core';
import { computed, ref } from 'vue';

import { useAuth } from '@/features/Auth';
import { featureFlags } from '@/features/FeatureFlags';
import { EIntroKeys, useFeatureIntro } from '@/features/FeatureIntro';
import { UiButton, UiModal } from '@/ui';

const visible = ref(true);
const { isPhotographer } = useAuth();
const haveSeenNewFlowGreeting = useStorage('new-flow-greeting-shown', false);

const shouldShowNewFlowGreeting = computed(
  () =>
    !!featureFlags.photographerFlow &&
    isPhotographer.value &&
    !haveSeenNewFlowGreeting.value,
);

const [showFlowIntro] = useFeatureIntro(
  EIntroKeys.PHOTOGRAPHER_FLOW_GET_STARTED,
);

const onAfterClose = () => {
  showFlowIntro();
  haveSeenNewFlowGreeting.value = true;
};
</script>

<style scoped lang="stylus">


.modal-container
  padding 16px
  display flex
  flex-direction column
  align-items center
  gap 24px

  h2
    font-size 16px
    font-weight 600
    text-align center
    color gray-10

  p
    margin 0
    padding 0 8px
    font-weight 500
    color gray-9

  img
    width 100%
</style>
