<template>
  <tr
    :id="data?.id && `row-${data.id}`"
    :class="{
      'table-row': true,
      focused: isFocused(data),
      'table-row_clickable': !!onClick,
      [`align-${rowAlign}`]: !!rowAlign,
      'with-hovering': (!!onClick && !isFocused(data)) || hovered,
      'border-none': $slots['row-footer'],
    }"
    v-bind="$attrs"
    @click.stop="handleClick"
  >
    <td v-if="withSelection" class="table-cell">
      <input
        v-model="selected.items"
        type="checkbox"
        :value="data"
        @click.stop
      />
    </td>
    <td
      v-for="column in columns"
      :key="column.id"
      :class="['table-cell', column.class]"
      :colspan="1"
    >
      <slot
        v-if="column.Cell"
        :name="column.Cell"
        :item="getCellData(data, column.resolver)"
      />
      <template v-else>
        <div class="text-cell text-m leading-short">
          {{ getCellData(data, column.resolver) }}
        </div>
      </template>
    </td>
  </tr>

  <tr v-if="$slots['row-footer']" class="table-row">
    <td :colspan="columns?.length" class="table-cell">
      <div class="table-row-footer">
        <slot name="row-footer" :item="data" />
      </div>
    </td>
  </tr>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { safeInject } from '@/utils/inject';

import { useGetCellData } from './composables';
import { FocusKey, SelectionKey } from './symbols';
import type { IColumn, TRowBase } from './types';

export default defineComponent({
  name: 'table-row',
  props: {
    data: {
      type: Object as PropType<TRowBase>,
      required: true,
    },
    rowAlign: {
      type: String,
      default: '',
    },
    onClick: {
      type: Function as PropType<(data: TRowBase) => void>,
      default: undefined,
    },
    columns: prop<IColumn[]>({
      type: Object,
      required: true,
    }),
    hovered: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const getCellData = useGetCellData();

    const { isFocused, focusRow, unfocusRow, withFocusing } =
      safeInject(FocusKey);

    const { selected, withSelection } = safeInject(SelectionKey);

    const handleClick = () => {
      if (props.onClick) {
        props.onClick(props.data);
      }

      if (!withFocusing) return;

      if (isFocused && !isFocused(props.data)) {
        focusRow(props.data);
      } else {
        unfocusRow();
      }
    };

    return {
      getCellData,
      isFocused,
      selected,
      withSelection,
      handleClick,
    };
  },
});
</script>

<style lang="stylus" scoped>
.text-cell
  color Gray(DK28)
  padding 20px 14px

.table-row
  @apply bg-white
  height 100%
  &_clickable
    cursor pointer
  &:last-of-type
    .table-cell
      border none

  :deep() a
    color Gray(DK28)
    display flex
    height 100%

.with-hovering:hover
  background: Gray(LT48);

.align-top
  vertical-align top

.focused
  background: linear-gradient(to right,
  #1890ff 5px, LightBlue(LT47) 5px 100%)

  &:hover
    background: linear-gradient(to right,
    #1890ff 5px, LightBlue(LT47) 5px 100%)


.table-cell
  vertical-align top
  border-bottom 1px solid Gray(LT40)
  height 100%

  &:first-child
    border-left 1px solid Gray(LT40)

.table-padding
  width 40px

.wrap-anywhere
  overflow-wrap anywhere

.table-row-footer
  padding-bottom 16px

.border-none .table-cell
  border none
</style>
