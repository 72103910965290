/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StudioPhotoInfo,
    StudioPhotoInfoFromJSON,
    StudioPhotoInfoFromJSONTyped,
    StudioPhotoInfoToJSON,
} from './StudioPhotoInfo';

/**
 * 
 * @export
 * @interface StudioPhotoInfoAllResponse
 */
export interface StudioPhotoInfoAllResponse {
    /**
     * 
     * @type {Array<StudioPhotoInfo>}
     * @memberof StudioPhotoInfoAllResponse
     */
    photos: Array<StudioPhotoInfo>;
}

export function StudioPhotoInfoAllResponseFromJSON(json: any): StudioPhotoInfoAllResponse {
    return StudioPhotoInfoAllResponseFromJSONTyped(json, false);
}

export function StudioPhotoInfoAllResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StudioPhotoInfoAllResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'photos': ((json['photos'] as Array<any>).map(StudioPhotoInfoFromJSON)),
    };
}

export function StudioPhotoInfoAllResponseToJSON(value?: StudioPhotoInfoAllResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'photos': ((value.photos as Array<any>).map(StudioPhotoInfoToJSON)),
    };
}

