/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Box,
    BoxFromJSON,
    BoxFromJSONTyped,
    BoxToJSON,
} from './Box';

/**
 * 
 * @export
 * @interface BoxError
 */
export interface BoxError {
    /**
     * 
     * @type {Box}
     * @memberof BoxError
     */
    box: Box;
    /**
     * 
     * @type {string}
     * @memberof BoxError
     */
    error: string;
}

export function BoxErrorFromJSON(json: any): BoxError {
    return BoxErrorFromJSONTyped(json, false);
}

export function BoxErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): BoxError {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'box': BoxFromJSON(json['box']),
        'error': json['error'],
    };
}

export function BoxErrorToJSON(value?: BoxError | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'box': BoxToJSON(value.box),
        'error': value.error,
    };
}

