/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Кол-во коробов негабарита для создания
 * @export
 * @interface CreateBox
 */
export interface CreateBox {
    /**
     * 
     * @type {number}
     * @memberof CreateBox
     */
    quantity: number;
}

export function CreateBoxFromJSON(json: any): CreateBox {
    return CreateBoxFromJSONTyped(json, false);
}

export function CreateBoxFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateBox {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'quantity': json['quantity'],
    };
}

export function CreateBoxToJSON(value?: CreateBox | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'quantity': value.quantity,
    };
}

