<template>
  <BaseFilterWrapper>
    <template #header>
      <span v-if="modelValue.length" class="selected">
        <ShootingTypeChip :type="modelValue[0]" class="chip shrink-0" />
        <span v-if="modelValue.length > 1" class="count">
          {{ modelValue.slice(1).length }}
        </span>
      </span>
      <span v-else>
        {{ $t('shooting types') }}
      </span>
    </template>
    <template #content>
      <UiMenu
        :selected-keys="modelValue"
        multiple
        class="menu"
        @update:selected-keys="$emit('update:modelValue', $event)"
      >
        <UiMenuItem v-for="t in shootingTypes" :key="t">
          <ShootingTypeChip :type="t" />

          <CheckOutlined class="check" />
        </UiMenuItem>
      </UiMenu>
    </template>
  </BaseFilterWrapper>
</template>

<script setup lang="ts">
import { CheckOutlined, DownOutlined } from '@ant-design/icons-vue';

import { ShootingType } from '@/api';
import ShootingTypeChip from '@/components/ShootingTypeChip.vue';
import { UiButton, UiMenu, UiMenuItem, UiPopover } from '@/ui';
import BaseFilterWrapper from '@/ui/filter/base-filter-wrapper.vue';

interface Props {
  modelValue: ShootingType[];
}

interface Emits {
  (e: 'update:modelValue', v: ShootingType[]): void;
}

defineProps<Props>();
defineEmits<Emits>();

const shootingTypes = [
  ShootingType.Model,
  ShootingType.Detailed,
  ShootingType.Panoramic,
  ShootingType.Render,
] as const;
</script>

<style lang="stylus" scoped>


.button
  padding 8px 15px
  justify-content space-between
  font-size 14px
  color gray-10

  .selected
    display flex
    align-items center
    gap 10px

  .count
    padding 0 8px
    border-radius 50%
    font-weight 500
    background-color gray-4
    color #313235

  .chip
    line-height 22px

.icon
  width 14px
  height 14px

.menu
  border-inline-end 0 !important

  :deep()
    .ant-menu-item
      width 200px
      height auto
      margin 0
      padding 8px 12px
      line-height 1.5
      color gray-10

    .ant-menu-title-content
      display flex
      justify-content space-between
      align-items center

    .check
      display none

    &.ant-menu-item-selected
      background-color gray-2

      .check
        display inline-block
        color blue-6
</style>
