<template>
  {{ localizedPrice }}
  <span>{{ $t(localizedCurrency) }}</span>
</template>

<script setup lang="ts">
import { COUNTRY } from '@/config';

interface Props {
  price: number;
}

const props = defineProps<Props>();

const currency = {
  RU: 'rub',
  UZ: 'sum',
};

const localizedPrice = COUNTRY === 'RU' ? props.price / 100 : props.price;
const localizedCurrency = `currency.${currency[COUNTRY]}`;
</script>
