import debounce from 'lodash.debounce';
import { ComputedRef, Ref, ref, watch } from 'vue';

import { useBoundedString } from '@/composables/useBounded';
import { DEBOUNCE_DELAY } from '@/features/Common';

interface IUseQuery {
  query: Ref<string>;
  boundedQuery: ComputedRef<string | undefined>;
  resetQuery: () => void;
}

export const useQuery = (
  onQueryChange?: (query?: string) => void,
): IUseQuery => {
  const query = ref('');
  const boundedQuery = useBoundedString({ minLength: 0, valueRef: query });

  if (onQueryChange) {
    const handleQueryChange = () => onQueryChange(boundedQuery.value);

    watch(boundedQuery, debounce(handleQueryChange, DEBOUNCE_DELAY));
  }

  const resetQuery = (): void => {
    query.value = '';
  };

  return { query, boundedQuery, resetQuery };
};
