<template>
  <ModalWrapper
    v-if="isShow"
    :title="$t('generate barcodes.generated')"
    class="generate-wrapper"
    @close="$emit('close')"
  >
    <div v-if="state.barcode" class="modal-content">
      <icon-ui class="mb-9" name="misc/barcode" width="120" height="86" />
      <div class="text-xxl leading-long font-semi-bold">
        {{ state.barcode }}
        <CopyButton
          class="copy-btn-wrapper"
          :text="state.barcode"
          tooltip-placement="bottom"
        />
      </div>
      <div class="text-s leading-short font-regular mt-2">
        {{ description }}
      </div>
    </div>
    <div v-else class="modal-content">
      <CircullarLoader class="loader" />
    </div>
    <template #footer>
      <UiButton
        type="text"
        :loading="printRequestPending"
        @click="handlePrintBarcode"
      >
        <PrinterFilled class="icon" data-test-id="print__icon" />
        {{ $t('generate barcodes.print again') }}
      </UiButton>
      <UiButton
        type="text"
        :loading="createRequestPending"
        @click="handleGenerateBarcode"
      >
        <PlusOutlined class="icon" />
        {{ $t('generate barcodes.generate again') }}
      </UiButton>
    </template>
  </ModalWrapper>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted } from 'vue';
export default defineComponent({ name: 'generate-barcode-modal' });
</script>

<script setup lang="ts">
import { PlusOutlined, PrinterFilled } from '@ant-design/icons-vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { usePrint } from '@/composables/usePrint';
import { AppEvents } from '@/features/Common';
import { eventManager } from '@/features/EventManager';
import ModalWrapper from '@/features/Modals/ModalWrapper.vue';
import { notification } from '@/features/Notifications';
import { CircullarLoader, CopyButton, UiButton } from '@/ui';

import { EGenerateTypes, TGenerateType } from '../../data';
import useBoxes from './composables/useBoxes';
import usePhotoBoxes from './composables/usePhotoBoxes';

const props = defineProps({
  type: {
    type: String as () => TGenerateType,
    required: true,
  },
});

const emit = defineEmits(['close']);
const { t } = useI18n();
const print = usePrint();
const isShow = ref(false);

const { state, createBox, printBarcode } =
  props.type === EGenerateTypes.PHOTOREQUEST ? usePhotoBoxes() : useBoxes();

const description = computed(() => {
  return t(`generate barcodes.for ${props.type}`);
});

const handleGenerateBarcode = async () => {
  try {
    const box = await handleCreateBox();

    notification.success(
      `${t(`generate barcodes.generated`)} ${box.barcode} ${t(
        `generate barcodes.for ${props.type}`,
      ).toLowerCase()}`,
    );
    isShow.value = true;
  } catch {
    emit('close');
  }
};

const createRequestPending = ref(false);

const handleCreateBox = async () => {
  createRequestPending.value = true;

  try {
    let box = await createBox();

    eventManager.emit(AppEvents.modals.generateBarcode.update, {
      box: box,
      type: props.type,
    });

    return box;
  } finally {
    createRequestPending.value = false;
  }
};

const printRequestPending = ref(false);

const handlePrintBarcode = () => {
  printRequestPending.value = true;
  printBarcode()
    .then((printData) => {
      if (printData) {
        print(printData);
      }
    })
    .finally(() => {
      printRequestPending.value = false;
    });
};

onMounted(async () => {
  await handleGenerateBarcode();

  await handlePrintBarcode();
});
</script>
<style lang="stylus">
.generate-wrapper .modal-wrapper
  width 520px
  min-height 360px


.generate-wrapper
  .modal-header
    @apply text-l leading-short font-regular
    display flex
    text-align center
    align-items center
    padding 10px 26px
    height 51px
    border-bottom 1px solid Gray(L40)

  .modal-content
    padding 10px 26px
    flex 1
    text-align center
    display flex
    align-items center
    justify-content center
    flex-direction column
    .loader
      width 60px
      height 60px

  .modal-footer
    display flex
    justify-content space-between
    align-items center
    padding 10px 26px
    height 56px
    border-top 1px solid Gray(L40)
</style>

<style lang="stylus" scoped>
::v-deep(.btn)
  width 14px
  height 14px
  color #1890ff
  font-size 14px
</style>
