<template>
  <div class="filter-option text-s leading-long bg-white">
    <slot>
      {{ value }}
    </slot>
    <slider
      :value="props.selected"
      :disabled="props.disabled"
      @click="handleClick"
    />
  </div>
</template>

<script setup lang="ts">
import { PropType } from 'vue';

import { Slider } from '@/ui';

const props = defineProps({
  value: {
    type: String,
    default: '',
  },
  selected: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  onChange: {
    type: Function as PropType<() => void>,
    default: null,
  },
});

const handleClick = () => {
  if (props.disabled) return;
  props.onChange();
};
</script>

<style lang="stylus" scoped>


.filter-option
  display flex
  align-items center
  justify-content space-between
  padding 16px 20px 16px 32px
  transition background-color 0.2s ease
  border-bottom 1px solid Gray(A20)
  &:last-of-type
    border-bottom 0
</style>
