/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PhotoDateResponse
 */
export interface PhotoDateResponse {
    /**
     * timestamp UTC
     * @type {number}
     * @memberof PhotoDateResponse
     */
    photoDate: number;
}

export function PhotoDateResponseFromJSON(json: any): PhotoDateResponse {
    return PhotoDateResponseFromJSONTyped(json, false);
}

export function PhotoDateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhotoDateResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'photoDate': json['photoDate'],
    };
}

export function PhotoDateResponseToJSON(value?: PhotoDateResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'photoDate': value.photoDate,
    };
}

