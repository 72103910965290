<template>
  <div
    ref="wrapper"
    :style="{
      position: 'relative',
    }"
    :data-test-id="dataTestId"
  >
    <ASelect
      v-if="!isLoading && options.length"
      ref="element"
      :default-value="defaultValue"
      :options="options"
      :mode="mode"
      :class="[
        'select',
        { 'no-border': hasValue },
        { 'select-disabled': !editable },
        `select-${theme}`,
      ]"
      :dropdown-style="{
        zIndex: 9999,
      }"
      option-filter-prop="label"
      :show-arrow="false"
      :allow-clear="mode !== 'multiple'"
      :default-open="defaultOpen && !hasValue"
      :get-popup-container="() => $refs.wrapper"
      v-bind="$attrs"
      @change="handleChange"
      @blur="!hasValue && $emit('empty')"
      @select="emit('select', $event)"
      @deselect="emit('deselect', $event)"
    >
      <template #notFoundContent>
        <span data-test-id="block__not-found">
          {{ $t('invalid search') }}
        </span>
      </template>
      <template #dropdownRender="{ menuNode: menu }">
        <div data-test-id="dropdown__characters">
          <component :is="menu" />
        </div>
      </template>
      <template v-if="theme === 'chip'" #removeIcon>
        <icon-ui
          v-if="editable"
          class="remove-icon"
          name="navigation/dissmiss_line"
          size="14"
        />
      </template>
      <template #option="{ label }">
        <div data-test-id="row__item-character">{{ label }}</div>
      </template>
      <template v-for="slot of Object.keys($slots)" #[slot]="slotProps">
        <slot :name="slot" v-bind="slotProps" />
      </template>
    </ASelect>
  </div>
</template>

<script setup lang="ts">
import { Select as ASelect } from 'ant-design-vue';
import { computed, ref, toRefs, watch } from 'vue';

import { TSelectMode, TSelectTheme, TSelectValue } from './types';

interface Props {
  mode?: TSelectMode;
  defaultValue?: TSelectValue;
  list?: any[] | (() => any[] | Promise<any[]>);
  theme?: TSelectTheme;
  defaultOpen?: boolean;
  dataTestId?: string;
  editable?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  mode: 'default',
  defaultValue: undefined,
  list: () => [],
  theme: 'default',
  dataTestId: 'select',
  editable: true,
});

const emit = defineEmits<{
  (e: 'empty'): void;
  (e: 'change', payload: string): void;
  (e: 'select', payload: number): void;
  (e: 'deselect', payload: number): void;
}>();

const { list, defaultValue } = toRefs(props);
const options = ref<any[]>([]);
const isLoading = ref(false);

watch(
  list,
  async (newVal) => {
    if (Array.isArray(newVal)) {
      options.value = newVal;
    } else {
      isLoading.value = true;
      options.value = await newVal();
      isLoading.value = false;
    }
  },
  {
    immediate: true,
  },
);

const selectedValue = ref(defaultValue.value);

const handleChange = (val) => {
  selectedValue.value = val;
  emit('change', val);
};

const hasValue = computed(() =>
  typeof selectedValue.value === 'object'
    ? !!selectedValue.value.length
    : !!selectedValue.value,
);

watch(hasValue, (val) => {
  if (!val) {
    emit('empty');
  }
});

const element = ref(null);

watch(element, (val) => {
  if (val && !hasValue.value) {
    element.value.focus();
  }
});
</script>

<style lang="stylus" scoped>

.select
  width 100%
  &-disabled
    pointer-events none

.select-chip.ant-select-multiple
  :deep() .dropdown
    z-index 9999

  :deep() .ant-select-selection-overflow
    display block
    &-item
      display block
      width max-content
      &:nth-last-child(2)
        display inline-block
      &-suffix
        display none


  :deep() .ant-select-selector
    padding 1px 4px
    transition-duration 0s

  :deep() .ant-select-selection-item
    background-color Gray(LT40)
    border-radius 20px
    height 24px
    line-height 24px
    margin-top 2px
    margin-bottom 2px
    padding-inline-start 8px
    padding-inline-end 4px
    display flex
    max-width 100%

  :deep() .ant-select-clear
    background: transparent;

  :deep() .ant-select-clear
    opacity 1
    width 14px
    height 14px
  :deep() .remove-icon
    cursor pointer
    height 100%
    path
      fill Gray(LT20 ST5)
    &:hover
      path
        fill Gray(DK40)

  &.no-border
    :deep() .ant-select-selector
      padding 0
      border none
    :deep() .ant-select-selection-overflow
      &-item-suffix
        padding-left 4px

.select-chip.ant-select-open
  :deep() .ant-select-selection-overflow-item-suffix
    display inline-block
</style>
