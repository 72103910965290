<template>
  <PageLayout>
    <template #header>
      <PageHeader
        :title="$t('products')"
        :subtitle="
          skus.loading ? ' ' : $t('pluralization.product', skus.totalCount)
        "
      >
        <BaseFilter
          :title="$t('status')"
          :configuration="skuStatusFilterConfiguration"
          :on-change="handleFilterChange"
          :is-selected="isFilterSelected"
        />
        <Search
          v-model="query"
          :with-barcode="true"
          class="search"
          :placeholder="$t('search product')"
          data-test-id="input__search"
        />
      </PageHeader>
    </template>

    <BaseTable
      ref="table"
      :columns="columns"
      :data="skus.items"
      :on-pop-row="popSku"
      :is-grouped="false"
      :has-more="skus.hasMore"
      :handle-load-more="handleLoadMore"
      :load-error="loadError"
      row-class="products-table-row"
      row-hovered
    >
      <EmptyPlaceholder v-if="!skus.hasMore" />
      <template #image-cell="{ item }">
        <RouterLink class="px-2 py-4" :to="productRoute(item.id)">
          <UiImagePreview
            :current="0"
            :src="item.value"
            :width="54"
            :height="74"
            hide-preview
          />
        </RouterLink>
      </template>
      <template #status-cell="{ item }">
        <RouterLink :to="productRoute(item.id)">
          <ChipCell
            :color="skuStatusToColor[item.value]"
            :value="$t(`sku.${item.value}`)"
          />
        </RouterLink>
      </template>
      <template #text-cell="{ item }">
        <RouterLink :to="productRoute(item.id)">
          <TextCell>{{ getLang(item.value) }}</TextCell>
        </RouterLink>
      </template>
      <template #copyable-cell="{ item }">
        <RouterLink :to="productRoute(item.id)">
          <TextCell :copyable="item.value">
            {{ item.value }}
          </TextCell>
        </RouterLink>
      </template>
    </BaseTable>

    <ScannerEmulator v-if="isDevelopment" />
  </PageLayout>
</template>

<script lang="ts">
import { defineComponent, nextTick, onMounted, ref } from 'vue';
export default defineComponent({ name: 'products-page' });
</script>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { onBeforeRouteLeave, useRoute } from 'vue-router';

import { SkuDetailed } from '@/api';
import ChipCell from '@/components/table/cells/chip-cell.vue';
import TextCell from '@/components/table/cells/text-cell.vue';
import EmptyPlaceholder from '@/components/table/empty-placeholder.vue';
import BaseTable from '@/components/table/index.vue';
import type { IColumn } from '@/components/table/types';
import { usePreserveScroll } from '@/composables/usePreserveScroll';
import { IS_DEVELOPMENT as isDevelopment } from '@/config';
import {
  EBarcodeEvents,
  isSKUBarcode,
  useBarcodeScan,
} from '@/features/Barcode';
import { ScannerEmulator } from '@/features/ScannerEmulator';
import { AppRoutes } from '@/router/data';
import { PageHeader, PageLayout } from '@/template';
import { BaseFilter, UiImagePreview } from '@/ui';
import { Search } from '@/ui';
import { getLang } from '@/utils/lang';

import { useSkusApi } from './composables';
import { skuStatusFilterConfiguration, skuStatusToColor } from './data';

type TBaseTable = InstanceType<typeof BaseTable>;

const { t } = useI18n();
const table = ref<TBaseTable>();
const route = useRoute();

usePreserveScroll(table);

const {
  skus,
  loadError,
  loaded,
  getInitialSkus,
  resetSkusFilters,
  getSkuByBarcode,
  popSku,
  handleFilterChange,
  isFilterSelected,
  handleLoadMore,
  query,
  resetSkus,
} = useSkusApi();

const handleBarcodeScan = async (barcode: string) => {
  const sku = await getSkuByBarcode(barcode);

  table.value?.pop(sku);
};

useBarcodeScan(EBarcodeEvents.SKU, handleBarcodeScan);

interface IRoute {
  name: string;
  params: Record<string, unknown>;
}

const productRoute = (id: number): IRoute => {
  return {
    name: 'Product',
    params: {
      id: id,
    },
  };
};

const columns: IColumn<SkuDetailed>[] = [
  {
    Header: t('photo'),
    id: 'photo',
    resolver: (sku) => ({
      value: sku.photoURL,
      id: sku.id,
    }),
    Cell: 'image-cell',
  },
  {
    Header: t('nomenclature'),
    id: 'name',
    resolver: (sku) => ({
      value: sku.description,
      id: sku.id,
    }),
    class: 'wrap-anywhere',
    Cell: 'text-cell',
  },
  {
    Header: t('status'),
    id: 'status',
    resolver: (sku) => ({
      value: sku.status,
      id: sku.id,
    }),
    Cell: 'status-cell',
  },
  {
    Header: t('barcode'),
    id: 'barcode',
    resolver: (sku) => ({
      value: sku.barcode,
      id: sku.id,
    }),
    Cell: 'copyable-cell',
  },

  {
    Header: 'SKU',
    id: 'sku',
    resolver: (sku) => ({
      value: sku.title,
      id: sku.id,
    }),
    Cell: 'text-cell',
  },
  {
    Header: t('on photostudio'),
    id: 'onPhotoStudioCount',
    resolver: (sku) => ({
      value: sku.onPhotoStudioCount,
      id: sku.id,
    }),
    Cell: 'text-cell',
  },
];

onBeforeRouteLeave((to, _, next) => {
  if (to.name === AppRoutes.product.name) return next();
  nextTick(() => {
    resetSkusFilters();
    resetSkus();
  });
  next();
});

onMounted(async () => {
  if (!loaded) {
    resetSkusFilters();
    await getInitialSkus();
  }

  const barcode = String(route.query.barcode);

  if (route.query.barcode && isSKUBarcode(barcode)) {
    handleBarcodeScan(barcode);
  }
});
</script>

<style lang="stylus" scoped>
::v-deep(.search)
  width 325px

::v-deep(.products-table-row)
  cursor pointer
</style>
