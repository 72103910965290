/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Box,
    BoxFromJSON,
    BoxToJSON,
    CreatePhotoBox,
    CreatePhotoBoxFromJSON,
    CreatePhotoBoxToJSON,
    PhotoBoxListResponse,
    PhotoBoxListResponseFromJSON,
    PhotoBoxListResponseToJSON,
    PrintBarcodes,
    PrintBarcodesFromJSON,
    PrintBarcodesToJSON,
    SortPhotoBoxes,
    SortPhotoBoxesFromJSON,
    SortPhotoBoxesToJSON,
} from '../models';

export interface CreatePhotoBoxesRequest {
    createPhotoBox?: CreatePhotoBox;
}

export interface GetPhotoBoxesRequest {
    page: number;
    size: number;
    barcode?: string;
    ids?: Array<number>;
    sort?: Array<SortPhotoBoxes>;
}

export interface PrintPhotoBoxesBarcodeRequest {
    printBarcodes?: PrintBarcodes;
}

export interface UntiePhotoBoxesRequest {
    photoBoxID: number;
}

/**
 * 
 */
export class PhotoBoxesApi extends runtime.BaseAPI {

    /**
     * Создать короба для фотозаявок
     */
    async createPhotoBoxesRaw(requestParameters: CreatePhotoBoxesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Box>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/photoboxes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePhotoBoxToJSON(requestParameters.createPhotoBox),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BoxFromJSON));
    }

    /**
     * Создать короба для фотозаявок
     */
    async createPhotoBoxes(requestParameters: CreatePhotoBoxesRequest = {}, initOverrides?: RequestInit): Promise<Array<Box>> {
        const response = await this.createPhotoBoxesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPhotoBoxesRaw(requestParameters: GetPhotoBoxesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PhotoBoxListResponse>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getPhotoBoxes.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getPhotoBoxes.');
        }

        const queryParameters: any = {};

        if (requestParameters.barcode !== undefined) {
            queryParameters['barcode'] = requestParameters.barcode;
        }

        if (requestParameters.ids) {
            queryParameters['ids'] = requestParameters.ids;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/photoboxes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhotoBoxListResponseFromJSON(jsonValue));
    }

    /**
     */
    async getPhotoBoxes(requestParameters: GetPhotoBoxesRequest, initOverrides?: RequestInit): Promise<PhotoBoxListResponse> {
        const response = await this.getPhotoBoxesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Распечатать ШК для коробов фотозаявок
     */
    async printPhotoBoxesBarcodeRaw(requestParameters: PrintPhotoBoxesBarcodeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/photoboxes/barcodes/print`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PrintBarcodesToJSON(requestParameters.printBarcodes),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Распечатать ШК для коробов фотозаявок
     */
    async printPhotoBoxesBarcode(requestParameters: PrintPhotoBoxesBarcodeRequest = {}, initOverrides?: RequestInit): Promise<string> {
        const response = await this.printPhotoBoxesBarcodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async untiePhotoBoxesRaw(requestParameters: UntiePhotoBoxesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.photoBoxID === null || requestParameters.photoBoxID === undefined) {
            throw new runtime.RequiredError('photoBoxID','Required parameter requestParameters.photoBoxID was null or undefined when calling untiePhotoBoxes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/photoboxes/{photoBoxID}/clear`.replace(`{${"photoBoxID"}}`, encodeURIComponent(String(requestParameters.photoBoxID))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async untiePhotoBoxes(requestParameters: UntiePhotoBoxesRequest, initOverrides?: RequestInit): Promise<void> {
        await this.untiePhotoBoxesRaw(requestParameters, initOverrides);
    }

}
