<template>
  <div class="sku-wrapper">
    <div class="sku-header" data-test-id="header__sku">
      <UiImagePreview
        :current="0"
        :preview-src-list="imageOrigSrcList"
        :src="imageSrc"
        :width="75"
        :height="104"
      >
        <template #previewMask>
          <EyeOutlined class="preview-icon" />
        </template>
        <template #title="{ index }">
          <UiTag v-if="isArchived(index)" color-name="Yellow">
            {{ $t('photo archived') }}
          </UiTag>
        </template>
      </UiImagePreview>
      <div class="sku-info">
        <div class="sku-info-title">
          {{ getLang(sku?.description ?? '') }}
        </div>
        <div class="sku-info-category">
          {{ localizedCategories(filteredCategories) }}
          <DotDivider />
          <span v-if="sku" class="price">
            <Price :price="sku.price" />
          </span>
        </div>
        <div class="sku-info-footer">
          <div class="sku-info-statuses">
            <UiTag
              data-test-id="icon__product-status"
              :color-name="skuStatusColor[sku?.status ?? '']"
            >
              {{ sku?.status ? $t(`sku.${sku.status}`) : '' }}
            </UiTag>
            <ShootingTypeChip
              v-for="st in sku?.shootingTypes"
              :key="st"
              :type="st"
              class="shooting-type-chip"
            />
            <UiTag
              v-if="defect"
              color-name="PalePink"
              data-test-id="icon__product-defect"
            >
              {{ $t(`sku.DEFECT`) }}
            </UiTag>
          </div>
          <div class="sku-info-footer-inner">
            <div class="sku-info-footer-item">
              <span class="info-label text-m leading-short">
                SKU Title:&nbsp;
              </span>
              <span class="info-value text-m leading-short">
                {{ sku?.title }}
                <CopyButton :text="sku?.title ?? ''" />
              </span>
            </div>
            <div class="sku-info-footer-item">
              <span class="info-label text-m leading-short">SKU ID:&nbsp;</span>
              <span class="info-value text-m leading-short">
                {{ sku?.externalSkuId }}
                <CopyButton :text="String(sku?.externalSkuId) ?? ''" />
              </span>
            </div>
            <div class="sku-info-footer-item">
              <span class="info-label text-m leading-short">
                {{ $t('barcode') }}:&nbsp;
              </span>
              <span class="info-value text-m leading-short">
                {{ sku?.barcode }}
                <CopyButton :text="sku?.barcode ?? ''" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sku-detailed-info">
      <UiCard :loading="!photoRequest && loading">
        <template #title>
          {{ $t('request') }}
        </template>
        <div
          v-if="photoRequest"
          class="card-content"
          data-test-id="block__request"
        >
          <router-link
            class="card-subtitle"
            target="_blank"
            :to="{
              name: 'Requests',
              params: {
                id: photoRequest?.id,
              },
            }"
          >
            {{ $t('modal.request.title', { id: photoRequest?.id }) }}
            <icon-ui
              name="navigation/external_link"
              data-test-id="icon__active-request"
              fill="#1890ff"
              size="16"
            />
          </router-link>

          <UiTag
            :color-name="photoRequestStatusColor[photoRequest?.status]"
            is-text-colored
          >
            {{ $t(`photorequestStatus.${photoRequest?.status}`) }}
          </UiTag>

          <PaymentRateChip
            v-if="sku?.photoRequestItemPaymentRate"
            :rate="sku?.photoRequestItemPaymentRate"
          />

          <Date :value="photoRequest?.updated" class="card-date color-gray" />
        </div>
        <div v-else data-test-id="block__no-request">
          {{ $t('sku.no photorequest') }}
        </div>
      </UiCard>
      <UiCard :loading="!invoice && loading">
        <template #title>
          {{ $t('invoice') }}
        </template>
        <div v-if="invoice" class="card-content" data-test-id="block__invoice">
          <router-link
            class="card-subtitle"
            target="_blank"
            :to="{
              name: 'Invoice',
              params: {
                id: invoice?.id,
              },
            }"
          >
            {{ $t('modal.invoice.title', { id: invoice?.id }) }}
            <icon-ui
              class="link-icon"
              data-test-id="icon__active-invoice"
              name="navigation/external_link"
              fill="#1890ff"
              size="16"
            />
          </router-link>

          <UiTag
            :color-name="invoiceStatusColor[invoice?.type][invoice?.status]"
          >
            {{ $t(`${invoice?.type}_${invoice?.status}`) }}
          </UiTag>

          <Date :value="invoice?.updated" class="card-date color-gray" />
        </div>
        <div v-else data-test-id="block__no-invoice">
          {{ $t('sku.no invoice') }}
        </div>
      </UiCard>
      <UiCard :loading="!sku">
        <template #title>
          {{ $t('seller') }}
        </template>
        <div class="card-content" data-test-id="block__seller">
          <div class="card-subtitle card-subtitle--small">{{ sellerName }}</div>
          <div class="color-gray">
            {{ sku?.seller.phone }}
          </div>
          <div class="color-gray">
            {{ sku?.seller.email }}
          </div>
        </div>
      </UiCard>
    </div>
  </div>
</template>

<script setup lang="ts">
import { EyeOutlined } from '@ant-design/icons-vue';
import { computed } from 'vue';

import {
  DefectDetailed,
  ImageStatus,
  Invoice,
  PhotoRequest,
  SkuDetailed,
} from '@/api';
import PaymentRateChip from '@/components/PaymentRateChip.vue';
import Price from '@/components/Price.vue';
import ShootingTypeChip from '@/components/ShootingTypeChip.vue';
import { photoRequestStatusColor } from '@/features/Modals/request/data';
import { invoiceStatusColor } from '@/pages/invoice/data';
import { skuStatusColor } from '@/pages/sku/data';
import { UiImagePreview } from '@/ui';
import { CopyButton, Date, DotDivider, UiCard, UiTag } from '@/ui';
import { originalImageSrc, productImageSrc } from '@/utils/imageUrl';
import { getLang, localizedCategories } from '@/utils/lang';

interface Props {
  sku: SkuDetailed | null;
  invoice: Invoice | null;
  photoRequest: PhotoRequest | null;
  defect: DefectDetailed | null;
  loading: boolean;
}

const props = defineProps<Props>();

const sellerName = computed(() =>
  [
    props.sku?.seller?.lastName,
    props.sku?.seller?.firstName,
    props.sku?.seller?.patronymic,
  ]
    .filter((nameEntry) => !!nameEntry)
    .join(' '),
);

const filteredCategories = computed(() =>
  props.sku?.category?.fullTitle.replace('Все категории / ', ''),
);

const imageSrc = computed(() =>
  productImageSrc(
    props.sku?.previewImage?.key,
    props.sku?.previewImage?.urlPrefix,
  ),
);

const statusToOrder = {
  [ImageStatus.Active]: 1,
  [ImageStatus.Archived]: 2,
  [ImageStatus.SkuArchived]: 2,
};

const sortedImages = computed(() =>
  props.sku?.images.toSorted(
    (a, b) =>
      statusToOrder[a.status] - statusToOrder[b.status] ||
      a.ordering - b.ordering,
  ),
);

const imageOrigSrcList = computed(() =>
  sortedImages.value?.map((image) =>
    originalImageSrc(image.key, image.urlPrefix),
  ),
);

const isArchived = (index: number) =>
  [ImageStatus.Archived, ImageStatus.SkuArchived].includes(
    sortedImages.value?.at(index)?.status as ImageStatus,
  );
</script>

<style lang="stylus" scoped>


color-blue = #1890FF

.sku-wrapper
  padding 4px 0
  margin-bottom 18px
.sku-header
  display grid
  grid-template-columns max-content auto

.sku-info
  margin-left 16px
  display flex
  flex-direction column
  justify-content space-between
  padding 4px 0
  grid-column 2

.sku-info-title
  font-size 20px
  line-height 28px
  font-weight 600
  overflow-wrap anywhere

.sku-info-category
  line-height 22px
  overflow-wrap anywhere
  display flex
  align-items center

.card-content
  a
    display block
    color Gray(DK28)
  & > *:not(:last-child)
    margin-bottom 12px

.card-subtitle
  font-weight 500
  font-size 1rem
  &--small
    font-size 0.875rem

.card-date
  margin-top 20px

.sku-info-footer
  display flex
  justify-content space-between
  align-items flex-start
  gap 20px
  margin-top 20px
  &-inner
    display flex
    justify-content flex-end
    align-items center
    flex-wrap wrap
    column-gap 40px

.sku-info-statuses
  display flex
  align-items center
  gap 8px

  > *
    margin 0

.info-label
  color Gray(DK16)

.info-value
  color Gray(DK16)
  overflow-wrap anywhere
  margin-right 4px

.sku-detailed-info
  margin-top 18px
  display grid
  grid-template-columns 1fr 1fr 1fr
  grid-auto-flow column
  column-gap 12px

.color-gray
  color Gray(DK16)
  :deep() .date
    color Gray(DK16)

.price
  white-space nowrap

.shooting-type-chip
  border-radius 2px
  padding 0 7px
  line-height 26px
</style>
