import { onMounted, reactive } from 'vue';

import {
  DefectDetailed,
  EventType,
  Image,
  Invoice,
  photoRequestApi,
  PhotoRequestDetailed,
  PhotoRequestItem,
  skuApi,
  SkuDetailed,
  SkuEvent,
  SkuStatus,
} from '@/api';
import { usePhotographerFlow } from '@/features/PhotographerFlow';
import { clone } from '@/utils/collection';

import { lockHooklessModals, unlockHooklessModals } from '../../useModal';

interface IState {
  loading: boolean;
  sku: SkuDetailed | null;
  defect: DefectDetailed | null;
  images: Image[];
  requestItem: PhotoRequestItem | null;
  request?: PhotoRequestDetailed | null;
  hasHistory?: boolean;
  error?: string;
  history: {
    items: SkuEvent[];
    page: number;
    totalCount: number;
    hasMore: boolean;
  };
}

const initialState: IState = {
  loading: false,
  sku: null,
  defect: null,
  images: [],
  requestItem: null,
  request: null,
  history: {
    items: [],
    page: 0,
    totalCount: 0,
    hasMore: true,
  },
};

const PER_PAGE = 10;

const getSkuBarcode = async (id: number) => {
  try {
    const response = await skuApi.getSkuBarcodes({
      printBarcodes: { ids: [id] },
    });

    return response;
  } catch (e) {
    if (
      e instanceof Error &&
      e.message === 'Request failed with status code 403'
    ) {
      throw Error(e.message);
    }

    return null;
  }
};

const getSkuDefect = async (skuID: number): Promise<DefectDetailed | null> => {
  try {
    const defects = await skuApi.getDefects({
      skuID,
      page: 0,
      size: 1,
      isSent: false,
    });

    if (!defects.items.length) {
      return null;
    } else {
      return defects.items[0];
    }
  } catch {
    return null;
  }
};

const getRequestItem = async (
  photoRequestID: number,
  skuID: number,
): Promise<PhotoRequestItem> => {
  const {
    items: [item],
  } = await photoRequestApi.photoRequestItemsList({
    photoRequestID,
    skuID,
    size: 1,
    page: 0,
  });

  return item;
};

const getRequest = async (
  photoRequestID: number,
): Promise<PhotoRequestDetailed> =>
  photoRequestApi.photoRequestDetail({ photoRequestID });

export function useSkuApi(
  skuID: number,
  invoice?: Invoice,
  photoRequestId?: number,
) {
  const state = reactive<IState>(clone(initialState));

  const loadSku = async () => {
    state.sku = await skuApi.getSkuByID({ skuID });

    if (state.sku.status !== SkuStatus.Sent) {
      lockHooklessModals();
      state.defect = await getSkuDefect(state.sku.id);
      unlockHooklessModals();
    }
  };

  const loadHistory = async () => {
    state.hasHistory = await checkSkuHistory(skuID);
  };

  const loadRequestItem = async () => {
    if (photoRequestId) {
      state.requestItem = await getRequestItem(photoRequestId, skuID);
    }
  };

  const loadRequest = async () => {
    if (photoRequestId) {
      state.request = await getRequest(photoRequestId);
    }
  };

  const loadData = async () => {
    state.loading = true;

    const promises = [
      loadSku(),
      loadHistory(),
      loadRequestItem(),
      loadRequest(),
    ];

    await Promise.all(promises);

    state.loading = false;
  };

  const checkSkuHistory = async (skuID: number): Promise<boolean> => {
    try {
      const history = await skuApi.skuHistory({
        skuID,
        page: state.history.page,
        size: PER_PAGE,
      });

      state.history.page = history.page + 1;
      state.history.hasMore = state.history.page < history.totalPages;

      const invoices = history.items.filter(
        (el) =>
          el.type === EventType.SkuAddedToInvoice && el.data.id !== invoice?.id,
      );

      if (!invoices.length && state.history.hasMore) {
        return await checkSkuHistory(skuID);
      }

      return !!invoices.length;
    } catch {
      return false;
    }
  };

  const { startPhotoShoot } = usePhotographerFlow();

  const handleStartPhotoShoot = async (request: PhotoRequestDetailed) => {
    if (!state.sku) return;

    const data = await startPhotoShoot(state.sku, request);

    if (!data) return;

    state.requestItem = data.requestItem;

    return data;
  };

  const setSkuDefect = (defect: DefectDetailed) => {
    state.defect = defect;
  };

  const resetState = () => {
    state.sku = initialState.sku;
    state.loading = initialState.loading;
    state.error = initialState.error;
    state.history = clone(initialState.history);
  };

  onMounted(() => {
    loadData();
  });

  return {
    state,
    loadData,
    getSkuBarcode,
    resetState,
    setSkuDefect,
    handleStartPhotoShoot,
  };
}
