/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ReservationStatus {
    Unspecified = 'UNSPECIFIED',
    Created = 'CREATED',
    Reserved = 'RESERVED',
    Canceled = 'CANCELED',
    Completed = 'COMPLETED',
    InShipping = 'IN_SHIPPING'
}

export function ReservationStatusFromJSON(json: any): ReservationStatus {
    return ReservationStatusFromJSONTyped(json, false);
}

export function ReservationStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReservationStatus {
    return json as ReservationStatus;
}

export function ReservationStatusToJSON(value?: ReservationStatus | null): any {
    return value as any;
}

