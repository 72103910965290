<template>
  <div class="page-layout">
    <slot name="header" v-bind="{ isCollapsed, toggleCollapsed }" />

    <div class="page-content">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({ name: 'page-layout' });
</script>

<script setup lang="ts">
import { useSidebar } from '@/components/template/layout';

const { isCollapsed, toggleCollapsed } = useSidebar();
</script>

<style lang="stylus" scoped>
.page-layout
  grid-template-rows auto minmax(0, 1fr)
  height 100%
  display grid
  overflow-y auto

.page-content
  background LightBlue(LT47)

  &--padded
    padding 0 40px 20px 46px
</style>
