import { InvoiceStatus, InvoiceType } from '@/api';
import { FilterConfiguration, TChipColor } from '@/ui';

import { StatusFilter } from './types';

export const statusToColor: Record<InvoiceStatus, TChipColor> = {
  [InvoiceStatus.Created]: 'purple',
  [InvoiceStatus.Incoming]: 'yellow',
  [InvoiceStatus.InAccounting]: 'orange',
  [InvoiceStatus.Done]: 'green',
  [InvoiceStatus.Draft]: 'gray',
};

export const statusFilterConfiguration: FilterConfiguration<StatusFilter> = [
  {
    label: 'ACCEPTANCE',
  },
  {
    label: 'ACCEPTANCE_CREATED',
    color: statusToColor[InvoiceStatus.Created],
    value: {
      status: InvoiceStatus.Created,
      type: InvoiceType.Acceptance,
    },
  },
  {
    label: 'ACCEPTANCE_INCOMING',
    color: statusToColor[InvoiceStatus.Incoming],
    value: {
      status: InvoiceStatus.Incoming,
      type: InvoiceType.Acceptance,
    },
  },
  {
    label: 'ACCEPTANCE_IN_ACCOUNTING',
    color: statusToColor[InvoiceStatus.InAccounting],
    value: {
      status: InvoiceStatus.InAccounting,
      type: InvoiceType.Acceptance,
    },
  },
  {
    label: 'ACCEPTANCE_DONE',
    color: statusToColor[InvoiceStatus.Done],
    value: {
      status: InvoiceStatus.Done,
      type: InvoiceType.Acceptance,
    },
  },
  {
    label: 'SENDING',
  },
  {
    label: 'SENDING_DRAFT',
    color: statusToColor[InvoiceStatus.Draft],
    value: {
      status: InvoiceStatus.Draft,
      type: InvoiceType.Sending,
    },
  },
  {
    label: 'SENDING_CREATED',
    color: statusToColor[InvoiceStatus.Created],
    value: {
      status: InvoiceStatus.Created,
      type: InvoiceType.Sending,
    },
  },
  {
    label: 'SENDING_DONE',
    color: statusToColor[InvoiceStatus.Done],
    value: {
      status: InvoiceStatus.Done,
      type: InvoiceType.Sending,
    },
  },
];
