import { computed, nextTick, provide, reactive, ref, watch } from 'vue';

import { OpenedKey, PoppedKey } from '@/components/table/symbols';
import { AppEvents } from '@/features/Common';
import { equal } from '@/utils/equal';

import { FocusKey, SelectionKey } from './symbols';
import {
  ESortingDirection,
  TRow,
  TRowBase,
  TRowGroup,
  TSorting,
  TValueResolver,
} from './types';

export function useFocused(
  onFocusRow?: (row: TRowBase) => void,
  onUnfocusRow?: () => void,
  withFocusing?: boolean,
) {
  const focused = ref<TRowBase | null>(null);

  const isFocused = (data: TRowBase) => {
    return focused.value?.id === data.id;
  };

  const unfocusRow = () => {
    focused.value = null;
  };

  const focusRow = (data: TRowBase) => {
    focused.value = data;
  };

  watch(focused, () => {
    if (focused.value) {
      onFocusRow && onFocusRow(focused.value);
    } else {
      onUnfocusRow && onUnfocusRow();
    }
  });

  provide(FocusKey, {
    isFocused,
    focusRow,
    unfocusRow,
    withFocusing,
  });

  return { focusRow, unfocusRow };
}

export function useSelected(
  data: TRow[],
  isGrouped: boolean,
  withSelection?: boolean,
) {
  const flatData = isGrouped
    ? data.map((group) => group.items).flat()
    : (data as TRowBase[]);

  const selected = reactive<{ items: TRowBase[] }>({ items: [] });

  const allAreChecked = computed(
    () => selected.items.length === flatData.length,
  );

  const toggleAll = (event: Event) => {
    const checked = (event.target as HTMLInputElement).checked;

    if (checked) {
      selected.items = flatData;
    } else {
      selected.items = [];
    }
  };

  provide(SelectionKey, {
    selected,
    withSelection,
  });

  return { toggleAll, allAreChecked };
}

export function useOpened(data: TRowGroup[]) {
  const opened = reactive(
    data
      .map((group) => group.title)
      .reduce(
        (res, cur) => {
          res[cur] = false;

          return res;
        },
        {} as Record<string, boolean>,
      ),
  );

  const toggleOpened = (title: string, checked?: boolean) => {
    opened[title] = typeof checked === 'undefined' ? !opened[title] : checked;
    eventManager.emit(AppEvents.table.toggle, {
      [title]: opened[title],
    });
  };

  const isOpened = (title: string) => opened[title];

  provide(OpenedKey, {
    toggleOpened,
    isOpened,
  });
}

export function useGetCellData() {
  return (row: TRowBase, resolver: TValueResolver) => {
    if (typeof resolver === 'function') {
      return resolver(row);
    } else {
      return row[resolver];
    }
  };
}

export function usePopped(onPopRow: (data: TRowBase) => void) {
  const popped = ref<TRowBase | null>(null);

  const scrollTop = (data: TRowBase) => {
    const element = document.getElementById(`row-${data.id}`);

    element?.scrollIntoView({ block: 'center', behavior: 'smooth' });
  };

  const popRow = (data: TRowBase) => {
    popped.value = data;
    onPopRow(data);
    nextTick(() => {
      scrollTop(data);
    });
  };

  const unpopRow = () => {
    popped.value = null;
  };

  const itemsIncludePopped = (items: TRowBase[]) =>
    items.find((element) => equal(element, popped.value));

  const isPopped = (data: TRowBase) => equal(data, popped.value);

  provide(PoppedKey, {
    itemsIncludePopped,
    isPopped,
  });

  return { isPopped, popRow, unpopRow, popped, itemsIncludePopped };
}

export const useTableSorting = (sorting: TSorting) => {
  const handleSortingChange = (id: string) => () => {
    if (sorting.field !== id) {
      sorting.field = id;
      sorting.direction = ESortingDirection.ASC;
    } else if (sorting.field === id) {
      if (sorting.direction === ESortingDirection.ASC) {
        sorting.direction = ESortingDirection.DESC;
      } else {
        sorting.field = null;
        sorting.direction = null;
      }
    }
  };

  return { handleSortingChange };
};
