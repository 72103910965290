<template>
  <div v-show="false">
    <img v-for="(src, i) in images" :key="i" :src="src" />
  </div>

  <UiModal
    :visible="visible"
    :closable="false"
    width="100%"
    wrap-class-name="image-preview-modal"
    @close="onClose()"
  >
    <div class="ant-image-preview-wrap" @click.self="onClose()">
      <div class="ant-image-preview">
        <div class="ant-image-preview-content">
          <div class="ant-image-preview-body">
            <div class="ant-image-preview-operations">
              <button
                class="ant-image-preview-operations-operation"
                @click="onClose()"
              >
                <CloseOutlined class="ant-image-preview-operations-icon" />
              </button>
              <button
                :disabled="isMaxScale"
                :class="{
                  'ant-image-preview-operations-operation-disabled': isMaxScale,
                }"
                class="ant-image-preview-operations-operation"
                @click="zoomIn()"
              >
                <ZoomInOutlined class="ant-image-preview-operations-icon" />
              </button>
              <button
                :disabled="isInitialScale"
                class="ant-image-preview-operations-operation"
                @click="zoomOut()"
              >
                <ZoomOutOutlined class="ant-image-preview-operations-icon" />
              </button>

              <div v-if="$slots.title" class="title">
                <slot :index="index" name="title" />
              </div>

              <div v-if="images.length > 1" class="count">
                {{ index + 1 }} / {{ images.length }}
              </div>
            </div>

            <div class="ant-image-preview-img-wrapper preview">
              <div class="image-wrapper">
                <img
                  ref="el"
                  :key="index"
                  :src="images[index]"
                  :style="{
                    scale,
                    translate: `${translate.x}px ${translate.y}px`,
                  }"
                  :draggable="false"
                  class="ant-image-preview-img cursor-grab"
                />
              </div>

              <div v-if="$slots.description" class="description">
                <slot :index="index" name="description" />
              </div>
            </div>

            <template v-if="images.length > 1">
              <div class="ant-image-preview-switch-left" @click="prev()">
                <LeftOutlined />
              </div>
              <div class="ant-image-preview-switch-right" @click="next()">
                <RightOutlined />
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </UiModal>
</template>

<script setup lang="ts">
import {
  CloseOutlined,
  LeftOutlined,
  RightOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons-vue';
import { watch } from 'vue';

import { UiModal } from '@/ui';

import { useImagePreview } from './composables';

interface Props {
  images: string[];
  visible: boolean;
  initialIndex?: number;
}

interface Emits {
  (e: 'close'): void;
  (e: 'change', payload: number): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const {
  el,
  index,
  scale,
  isMaxScale,
  isInitialScale,
  translate,
  close,
  prev,
  next,
  zoomIn,
  zoomOut,
} = useImagePreview(props.images, {
  initialIndex: () => props.initialIndex ?? 0,
});

const onClose = () => {
  close();
  emit('close');
};

watch(index, (val) => {
  emit('change', val);
});
</script>

<style lang="stylus" scoped>
.image-wrapper
  height calc(100% - 50px)
  display flex
  justify-content center
  align-items center
  overflow hidden

.preview
  margin-top 50px
  &::before
    content none

.description
  display flex
  justify-content center
  align-items center
  position absolute
  inset auto 0 0 0
  height 90px
  padding 12px 0
  background rgba(gray-9, 0.5)
  color gray-1
  pointer-events auto
  user-select text

.count
  margin-right auto
  padding 12px

.title
  position absolute
  left 50%
  transform translate(-50%, -50%)
  top 50%
</style>

<style lang="stylus">
.image-preview-modal
  overflow hidden
  .ant-modal
    max-width 100%
    top 0
    padding-bottom 0
    margin 0

  .ant-image-preview-operations
    position absolute
    width 100%
    top 0
    left 0
    z-index 1

  .ant-modal-content
    overflow hidden
    background none
    user-select none
    padding 0
    display flex
    flex-direction column
    height 100vh

  .ant-modal-body
    flex 1

  .ant-image-preview-img-wrapper.preview
    width 100%
    height 100vh
    position absolute
    left 50%
    top 50%
    transform translate(-50%, -50%)
    img
      max-height calc(100vh - 50px)

  .ant-image-preview-operations-operation
    &:hover
      background-color transparent
</style>
