<template>
  <PageLayout class="product-page">
    <template #header>
      <PageHeader>
        <template #prefix>
          <RouterLink
            data-test-id="button__back"
            :to="{
              name: AppRoutes.products.name,
            }"
            variant="text"
            replace
          >
            <ArrowLeft />
          </RouterLink>
        </template>
        <div class="product-controls">
          <a
            :href="`${ADMIN_PANEL_PRODUCT_URL}${state.sku?.productId}`"
            target="_blank"
            data-test-id="button__link-product-admin-panel"
          >
            {{ $t('sku.admin panel link') }}
            <icon-ui name="navigation/external_link" fill="#1890ff" size="12" />
          </a>

          <Qrcode :identifier="state.sku?.identifier" />

          <UiButton
            type="default"
            data-test-id="button__print"
            :disabled="!canPrintSkuBarcode"
            :loading="isBarcodeLoading"
            @click="handlePrintClick"
          >
            <template #icon>
              <PrinterFilled />
            </template>

            {{ $t('print barcode') }}
          </UiButton>

          <UiButton
            v-if="canMarkDefects && isSkuAccepted"
            type="default"
            :disabled="state.loading || state.defectError || !!state.defect"
            data-test-id="button__defects"
            :title="
              state.defectError ? $t('notifications.sku.defect load error') : ''
            "
            @click="handleDefectClick"
          >
            {{ $t('modal.sku.mark as defected short') }}
          </UiButton>
        </div>
      </PageHeader>
    </template>
    <div class="product-content-wrapper">
      <Info v-bind="state" />
      <div class="grid grid-cols-3 gap-x-[12px] h-full">
        <History
          :sku-id="skuId"
          class="product-history"
          :class="defects.length ? 'col-span-2' : 'col-span-3'"
        />
        <Defects
          v-if="defects.length"
          :list="defects"
          class="col-span-1"
          :current-request-id="state.sku?.photoRequestId"
        />
      </div>
    </div>
    <ScannerEmulator v-if="isDevelopment" />
  </PageLayout>
  <SkuDefectModal
    v-if="isShowSkuDefectsModal && state.sku"
    :sku="state.sku"
    @close="isShowSkuDefectsModal = false"
  />
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from 'vue';
export default defineComponent({ name: 'sku-page' });
</script>

<script setup lang="ts">
import {
  ArrowLeftOutlined as ArrowLeft,
  LoadingOutlined,
  PrinterFilled,
} from '@ant-design/icons-vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

import { DefectDetailed, SkuStatus } from '@/api';
import { usePrint } from '@/composables/usePrint';
import {
  ADMIN_PANEL_PRODUCT_URL,
  IS_DEVELOPMENT as isDevelopment,
} from '@/config';
import { useAuth } from '@/features/Auth';
import {
  EBarcodeEvents,
  isSKUBarcode,
  useBarcodeScan,
} from '@/features/Barcode';
import { AppEvents } from '@/features/Common';
import SkuDefectModal from '@/features/Modals/sku/components/skuDefect/index.vue';
import { notification } from '@/features/Notifications';
import { ScannerEmulator } from '@/features/ScannerEmulator';
import Defects from '@/pages/sku/components/Defects.vue';
import { AppRoutes } from '@/router/data';
import { PageHeader, PageLayout } from '@/template';
import { UiButton, UiSpin } from '@/ui';

import History from './components/History.vue';
import Info from './components/Info.vue';
import Qrcode from './components/Qrcode.vue';
import { useSkuApi, useSkuHistory } from './composables';

const props = defineProps({
  id: {
    type: Number,
    required: true,
  },
});

const skuId = ref(+props.id);
const isShowSkuDefectsModal = ref<boolean>(false);
const { t } = useI18n();
const router = useRouter();
const { state, getSkuBarcode, resetState } = useSkuApi(skuId.value);
const { defects } = useSkuHistory(skuId.value);
const print = usePrint();
const { canMarkDefects, canPrintSkuBarcode } = useAuth();
const isSkuAccepted = computed(() => state.sku?.status === SkuStatus.Accepted);

const handleBarcodeScan = async (barcode: string) => {
  if (barcode && isSKUBarcode(barcode)) {
    router.replace({
      name: AppRoutes.products.name,
      query: {
        barcode: barcode,
      },
    });
  }
};

useBarcodeScan(EBarcodeEvents.SKU, handleBarcodeScan);

const isBarcodeLoading = ref(false);

const handlePrintClick = async () => {
  if (isBarcodeLoading.value) return;
  isBarcodeLoading.value = true;

  try {
    const barcode = await getSkuBarcode(skuId.value);

    if (barcode) {
      print(barcode);
    } else {
      notification.error(t('modal.error.codes.printError'));
    }
  } finally {
    isBarcodeLoading.value = false;
  }
};

const handleDefectClick = () => {
  isShowSkuDefectsModal.value = true;
};

watch(
  () => state.defectError,
  (val) => {
    if (val) {
      notification.error(t('notifications.sku.defect load error'));
    }
  },
);

onMounted(() => {
  eventManager.on(AppEvents.sku.defect, addSkuDefect);
});
onUnmounted(() => {
  eventManager.off(AppEvents.sku.defect, addSkuDefect);
  resetState();
});

const addSkuDefect = (defect: DefectDetailed) => {
  state.defect = defect;
  eventManager.emit(AppEvents.sku.updateHistory);
};
</script>

<style lang="stylus" scoped>

.product-controls
  display flex
  align-items center
  gap 16px

.product-content-wrapper
  display flex
  flex-direction column
  height 100%

.product-history
  height 100%
  flex 1

.icon
  font-size 14px
  margin-left 8px
</style>
