import { onMounted, reactive } from 'vue';

import { Reference, ReferenceListResponse, referencesApi } from '@/api';
import { clone } from '@/utils/collection';

interface IReferencesState {
  references: Reference[];
  loading: boolean;
}

const defaultState = {
  references: [],
  loading: false,
};

export const useReferencesApi = (categoryId: number) => {
  const state = reactive<IReferencesState>(clone(defaultState));

  const handleReferencesResponse = (response: ReferenceListResponse): void => {
    state.references = response.references;
  };

  const getReferences = async (): Promise<void> => {
    state.loading = true;

    const response = await referencesApi.referencesByCategoryID({
      categoryID: categoryId,
    });

    handleReferencesResponse(response);

    state.loading = false;
  };

  onMounted(() => {
    getReferences();
  });

  return { state };
};
