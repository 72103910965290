<template>
  <div
    :class="{
      'slider-wrapper': true,
      'wrapper-active': !disabled & value,
      'wrapper-disabled': disabled,
    }"
  >
    <div :class="{ 'slider-value': true, 'value-active': !disabled & value }" />
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'slider',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
});
</script>
<style lang="stylus" scoped>


.slider-wrapper
  width 36px
  height 18px
  background-color Gray(LT16)
  border-radius 9px
  cursor pointer
  position relative

.slider-value
  @apply bg-white
  top 2px
  bottom 2px
  width 14px
  height 14px
  border-radius 7px
  transform translateX(2px)
  position absolute
  transition transform 0.1s ease

.wrapper-active
  background-color Violet()

.value-active
  transform translateX(20px)

.wrapper-disabled
  background-color Gray(LT40)
</style>
