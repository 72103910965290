import { Result } from 'true-myth';

import {
  photoRequestApi,
  PhotoRequestDetailed,
  PhotoRequestItem,
  PhotoRequestStatus,
  skuApi,
  SkuDetailed,
} from '@/api';

export const startPhotoShoot = async (
  skuOrBarcode: SkuDetailed | string,
  photographerId: number,
  request?: PhotoRequestDetailed,
): Promise<Result<StartPhotoShootReturn, StartPhotoShootError>> => {
  const sku =
    typeof skuOrBarcode === 'string'
      ? await getSkuByBarcode(skuOrBarcode)
      : skuOrBarcode;

  if (!sku) return Result.err({ type: 'SkuNotFound' });

  if (!sku.photoRequestId) return Result.err({ type: 'SkuNotInRequest' });

  request ??= await getPhotoRequest(sku.photoRequestId);

  if (request.id !== sku.photoRequestId)
    return Result.err({ type: 'SkuNotInCurrentRequest' });

  if (!VALID_STATUSES.includes(request.status))
    return Result.err({ type: 'InvalidStatus', status: request.status });

  if (
    request.photographerID !== null &&
    request.photographerID !== photographerId
  )
    return Result.err({ type: 'RequestIsTaken' });

  request = await changeStatus(request, PhotoRequestStatus.OnPhotoShoot);
  request = await changePhotographer(request, photographerId);
  const requestItem = await addPhotoDate(request.id, sku.id);

  return Result.ok({ request, sku, requestItem });
};

export type StartPhotoShootReturn = {
  request: PhotoRequestDetailed;
  sku: SkuDetailed;
  requestItem: PhotoRequestItem;
};

export type StartPhotoShootError =
  | {
      type: 'SkuNotFound';
    }
  | {
      type: 'SkuNotInRequest';
    }
  | {
      type: 'SkuNotInCurrentRequest';
    }
  | {
      type: 'RequestIsTaken';
    }
  | {
      type: 'InvalidStatus';
      status: PhotoRequestStatus;
    };

const VALID_STATUSES = [
  PhotoRequestStatus.Created,
  PhotoRequestStatus.OnPhotoShoot,
  PhotoRequestStatus.OnPhotoShootPanoramic,
];

const getSkuByBarcode = async (
  barcode: string,
): Promise<SkuDetailed | null> => {
  const {
    items: [sku],
  } = await skuApi.skuList({
    page: 0,
    size: 1,
    barcode,
  });

  if (!sku) return null;

  const detailed = await skuApi.getSkuByID({ skuID: sku.id });

  return detailed;
};

const getPhotoRequest = async (
  photoRequestID: number,
): Promise<PhotoRequestDetailed> => {
  const request = await photoRequestApi.photoRequestDetail({
    photoRequestID,
  });

  return request;
};

const isOnPhotoShoot = (request: PhotoRequestDetailed): boolean =>
  [
    PhotoRequestStatus.OnPhotoShoot,
    PhotoRequestStatus.OnPhotoShootPanoramic,
  ].includes(request.status);

const changeStatus = async (
  request: PhotoRequestDetailed,
  status: PhotoRequestStatus,
) => {
  if (isOnPhotoShoot(request)) return request;

  return await photoRequestApi.changePhotoRequestStatus({
    photoRequestID: request.id,
    changePhotoRequestStatus: { status },
  });
};

const changePhotographer = async (
  request: PhotoRequestDetailed,
  id: number,
) => {
  if (id === request.photographerID) return request;

  return await photoRequestApi.changePhotoRequestPhotographerID({
    photoRequestID: request.id,
    changePhotoRequestPhotographerID: { id },
  });
};

const addPhotoDate = async (
  photoRequestID: number,
  skuID: number,
): Promise<PhotoRequestItem> => {
  const {
    items: [item],
  } = await photoRequestApi.photoRequestItemsList({
    photoRequestID,
    skuID,
    size: 1,
    page: 0,
  });

  const { photoDate } = await photoRequestApi.addPhotoRequestItemPhotoDate({
    itemID: item.id,
  });

  return {
    ...item,
    photoDates: [...item.photoDates, photoDate],
  };
};
