<template>
  <ui-drawer
    :class="['references-drawer']"
    root-class-name="references-root"
    placement="right"
    :open="true"
    :width="drawerWidth"
    :mask="false"
    :title="title"
    @close="$emit('close')"
  >
    <div v-if="state.references.length" class="references-body">
      <ui-tabs
        v-model:active-key="activeReferenceId"
        :class="[
          'references-tabs',
          { 'references-tabs-hidden': state.references.length === 1 },
        ]"
        :tab-bar-gutter="0"
        centered
      >
        <ui-tab-pane
          v-for="(reference, idx) in state.references"
          :key="reference.id"
          force-render
        >
          <template #tab>
            <div
              :class="[
                'references-tab',
                { 'references-tab-hidden': state.references.length === 1 },
              ]"
            >
              {{ $t('references.tab name', { num: idx + 1 }) }}
            </div>
          </template>
          <div
            v-if="activeReference"
            :key="activeReference.id"
            class="reference-tab-content"
          >
            <div class="reference-name">
              {{
                isReferenceNameTruncated
                  ? truncatedReferenceName(reference.name)
                  : reference.name
              }}
              <span
                v-if="isReferenceNameTruncated"
                class="reference-name-uncover"
                type="link"
                @click="toggleReferenceNameTruncate()"
              >
                {{ $t('references.uncover') }}
              </span>
            </div>
            <div
              v-if="activeReference.photoLinks.length"
              class="carousel-wrapper"
            >
              <ui-carousel
                arrows
                dots-class="slick-dots slick-thumb"
                :before-change="handleBeforeImageChange"
              >
                <template #customPaging="pagProps">
                  <a>
                    <img :src="activeReference.photoLinks[pagProps.i]" />
                  </a>
                </template>
                <template #prevArrow>
                  <ui-button type="text" style="left: 10px; z-index: 1">
                    <left-circle-outlined />
                  </ui-button>
                </template>
                <template #nextArrow>
                  <ui-button type="text" style="right: 10px">
                    <right-circle-outlined />
                  </ui-button>
                </template>
                <div
                  v-for="photoLink in activeReference.photoLinks"
                  :key="photoLink + activeReference.id"
                  class="carousel-image-wrapper"
                >
                  <img :src="photoLink" />
                </div>
              </ui-carousel>
            </div>
            <div v-else>{{ $t('references.no photo') }}</div>
          </div>
        </ui-tab-pane>
      </ui-tabs>
    </div>
    <div v-else-if="!state.loading">{{ $t('references.empty') }}</div>
  </ui-drawer>
</template>

<script setup lang="ts">
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons-vue';
import { Grid } from 'ant-design-vue';
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { Reference } from '@/api';
import { UiButton, UiCarousel, UiDrawer, UiTabPane, UiTabs } from '@/ui';
import { getLang } from '@/utils/lang';

import { useReferencesApi } from './composables';

const REFERENCE_NAME_LENGTH = 34;

interface Props {
  categoryId: number;
  categoryTitle: string;
}

const props = defineProps<Props>();

defineEmits(['close']);
const { t } = useI18n();
const { state } = useReferencesApi(props.categoryId);
const useBreakpoint = Grid.useBreakpoint;
const screens = useBreakpoint();

const drawerWidth = computed(() => {
  if (!screens.value.xl) {
    return 400;
  }

  return 500;
});

onMounted(() => {
  getImagesWrapperRef();
});

const title = `${t('references.title')}: ${getLang(props.categoryTitle)}`;
const activeReferenceId = ref<number>();

const activeReference = computed<Reference>(() => {
  const currentReference = state.references.find(
    (el) => el.id === activeReferenceId.value,
  );

  return currentReference ? currentReference : state.references[0];
});

const isReferenceNameTruncated = ref(true);

const toggleReferenceNameTruncate = (truncateState?: boolean): void => {
  if (typeof truncateState === 'undefined') {
    isReferenceNameTruncated.value = !isReferenceNameTruncated.value;
  } else {
    isReferenceNameTruncated.value = truncateState;
  }
};

const truncatedReferenceName = (name: string): string => {
  return `${name.substr(0, REFERENCE_NAME_LENGTH)}...`;
};

watch(activeReference, (reference: Reference): void => {
  if (reference && reference.name.length <= REFERENCE_NAME_LENGTH) {
    toggleReferenceNameTruncate(false);
  } else if (!isReferenceNameTruncated.value) {
    toggleReferenceNameTruncate();
  }

  getImagesWrapperRef();
});

const imagesWrapperRef = ref();
const currentImagesListRef = ref();

const getImagesWrapperRef = (): void => {
  imagesWrapperRef.value?.removeEventListener('wheel', dotsListScrollEvent);

  nextTick(() => {
    imagesWrapperRef.value = document.querySelector(
      '.ant-tabs-tabpane-active .slick-dots.slick-thumb',
    );
    imagesWrapperRef.value?.addEventListener('wheel', dotsListScrollEvent);

    currentImagesListRef.value =
      imagesWrapperRef.value?.querySelectorAll('img');
  });
};

const dotsListScrollEvent = (e: WheelEvent): void => {
  e.preventDefault();

  imagesWrapperRef.value.scrollBy({
    left: e.deltaY < 0 ? -30 : 30,
  });
};

const handleBeforeImageChange = (from: number, to: number): void => {
  currentImagesListRef.value?.[to]?.scrollIntoView();
};
</script>

<style lang="stylus">



.references-root
  z-index 9999
  .ant-drawer-header-title
    flex-direction row-reverse
    .ant-drawer-close
      margin-right 0
      margin-left 12px
  .ant-drawer-body
    padding-top 0

  .references-tabs-hidden
    .ant-tabs-nav
      height 0

  .references-tab
    width 90px
    text-align center

  .references-tab-hidden
    display none

  .references-body
    height 100%

  .reference-name
    font-size 16px
    font-weight 600
    margin-bottom 12px
    display flex
    &-uncover
      color blue-6
      font-weight normal
      margin-left 4px
      cursor pointer

  .ant-tabs,
  .ant-tabs-content,
  .reference-tab-content,
  .slick-slider,
  .slick-list,
  .slick-track
  .slick-slide,
  .slick-slide *
    height 100%

  .carousel-image-wrapper
    position relative
    img
      position absolute
      top 50%
      left 50%
      transform translate(-50%, -50%)
      height auto
      max-height 100%

  .slick-slider
    display flex
    flex-direction column

  .reference-tab-content
    display flex
    flex-direction column

  .carousel-wrapper
    flex 1
    position relative

  .ant-carousel
    position absolute
    top 0
    left 0
    height 100%
    width 100%
    .slick-track
      display flex
      align-items center
    .slick-dots
      overflow auto
      min-height 122px
      height 122px
      position relative
      justify-content flex-start
      margin 0

    .slick-arrow
      position absolute
      color rgba(38, 38, 38, 0.15)
      font-size 40px
      top 10px
      width 88px
      height calc(100% - 122px)
      &:hover
        background rgba(0, 0, 0, 0.1)
        color rgba(38, 38, 38, 0.25)

    .slick-prev
      left 0 !important

    .slick-next
      right 0 !important

    .slick-thumb
      bottom 0px
      margin-top 22px
      li
        width 75px
        min-width 75px
        height 100px
        img
          width 100%
          height 100%
          display block
          border-radius 4px
          border 2px solid transparent

    .slick-thumb li.slick-active img
      border-color blue-6

.ant-tabs-dropdown.ant-tabs-dropdown-placement-bottomRight
  z-index 9999
</style>
