// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TRowBase = { id: string | number } & Record<string, any>;

export enum ESortingDirection {
  ASC = 'Asc',
  DESC = 'Desc',
}

export type TSorting = {
  field?: string | null;
  direction?: ESortingDirection | null;
};

export type TRowFocus = {
  isFocused: (row: TRowBase) => boolean;
  focusRow: (row: TRowBase) => void;
  unfocusRow: () => void;
  withFocusing?: boolean;
};

export type TRowSelection = {
  selected: {
    items: TRowBase[];
  };
  withSelection?: boolean;
};

export type TGroupOpened = {
  toggleOpened: (title: string, checked?: boolean) => void;
  isOpened: (title: string) => boolean;
};

export type TRowPopped = {
  itemsIncludePopped: (rows: TRowBase[]) => TRowBase | undefined;
  isPopped: (row: TRowBase) => boolean;
};

export type TRowGroup<D extends TRowBase = TRowBase> = {
  type: string;
  title: string;
  count?: number;
  hasMore?: boolean;
  loading?: boolean;
  handleLoadMore?: () => void;
  items: D[];
  withCreation?: boolean;
  onCreateClick?: () => void;
  createTooltipText?: string;
};

export type TRow<D extends TRowBase = TRowBase> = TRowGroup<D> | D;

type TResolver<T> = (item: T) => unknown;

type TIndexer<T> = number | keyof T | string;

export type TValueResolver<D extends TRowBase = TRowBase> =
  | TIndexer<D>
  | TResolver<D>;

export interface IColumn<D extends TRowBase = TRowBase> {
  Header: string;
  id: string;
  resolver: TValueResolver<D>;
  Cell?: string;
  onClick?: (row: D) => void;
  sortable?: boolean;
  widthRatio?: number | string;
  class?: string;
}

export type THeaderColor = 'white' | 'blue';

export type THeaderTextColor = 'black' | 'gray';
