/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DefectDetailed,
    DefectDetailedFromJSON,
    DefectDetailedFromJSONTyped,
    DefectDetailedToJSON,
} from './DefectDetailed';

/**
 * 
 * @export
 * @interface BulkCreateOrChangeReasonDefectsResponse
 */
export interface BulkCreateOrChangeReasonDefectsResponse {
    /**
     * Отбраковки
     * @type {Array<DefectDetailed>}
     * @memberof BulkCreateOrChangeReasonDefectsResponse
     */
    defects: Array<DefectDetailed>;
    /**
     * Ошибки при создании
     * @type {Array<string>}
     * @memberof BulkCreateOrChangeReasonDefectsResponse
     */
    errors: Array<string>;
}

export function BulkCreateOrChangeReasonDefectsResponseFromJSON(json: any): BulkCreateOrChangeReasonDefectsResponse {
    return BulkCreateOrChangeReasonDefectsResponseFromJSONTyped(json, false);
}

export function BulkCreateOrChangeReasonDefectsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkCreateOrChangeReasonDefectsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'defects': ((json['defects'] as Array<any>).map(DefectDetailedFromJSON)),
        'errors': json['errors'],
    };
}

export function BulkCreateOrChangeReasonDefectsResponseToJSON(value?: BulkCreateOrChangeReasonDefectsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'defects': ((value.defects as Array<any>).map(DefectDetailedToJSON)),
        'errors': value.errors,
    };
}

