/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Category,
    CategoryFromJSON,
    CategoryFromJSONTyped,
    CategoryToJSON,
} from './Category';
import {
    Image,
    ImageFromJSON,
    ImageFromJSONTyped,
    ImageToJSON,
} from './Image';
import {
    PaymentRate,
    PaymentRateFromJSON,
    PaymentRateFromJSONTyped,
    PaymentRateToJSON,
} from './PaymentRate';
import {
    Seller,
    SellerFromJSON,
    SellerFromJSONTyped,
    SellerToJSON,
} from './Seller';
import {
    ShootingType,
    ShootingTypeFromJSON,
    ShootingTypeFromJSONTyped,
    ShootingTypeToJSON,
} from './ShootingType';
import {
    Sku,
    SkuFromJSON,
    SkuFromJSONTyped,
    SkuToJSON,
} from './Sku';
import {
    SkuDetailedAllOf,
    SkuDetailedAllOfFromJSON,
    SkuDetailedAllOfFromJSONTyped,
    SkuDetailedAllOfToJSON,
} from './SkuDetailedAllOf';
import {
    SkuStatus,
    SkuStatusFromJSON,
    SkuStatusFromJSONTyped,
    SkuStatusToJSON,
} from './SkuStatus';

/**
 * 
 * @export
 * @interface SkuDetailed
 */
export interface SkuDetailed {
    /**
     * 
     * @type {number}
     * @memberof SkuDetailed
     */
    id: number;
    /**
     * Штрих-код
     * @type {string}
     * @memberof SkuDetailed
     */
    barcode: string;
    /**
     * 
     * @type {string}
     * @memberof SkuDetailed
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof SkuDetailed
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof SkuDetailed
     */
    photoURL: string;
    /**
     * 
     * @type {Image}
     * @memberof SkuDetailed
     */
    previewImage?: Image;
    /**
     * 
     * @type {SkuStatus}
     * @memberof SkuDetailed
     */
    status: SkuStatus;
    /**
     * 
     * @type {string}
     * @memberof SkuDetailed
     */
    identifier?: string;
    /**
     * 
     * @type {Array<Image>}
     * @memberof SkuDetailed
     */
    images: Array<Image>;
    /**
     * 
     * @type {Array<ShootingType>}
     * @memberof SkuDetailed
     */
    shootingTypes: Array<ShootingType>;
    /**
     * 
     * @type {number}
     * @memberof SkuDetailed
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof SkuDetailed
     */
    updatedAt: number;
    /**
     * 
     * @type {number}
     * @memberof SkuDetailed
     */
    photoStudioId: number;
    /**
     * 
     * @type {number}
     * @memberof SkuDetailed
     */
    onPhotoStudioCount: number;
    /**
     * 
     * @type {number}
     * @memberof SkuDetailed
     */
    expectedCount: number;
    /**
     * 
     * @type {number}
     * @memberof SkuDetailed
     */
    writeOffCount: number;
    /**
     * 
     * @type {number}
     * @memberof SkuDetailed
     */
    sentCount: number;
    /**
     * 
     * @type {Seller}
     * @memberof SkuDetailed
     */
    seller: Seller;
    /**
     * 
     * @type {number}
     * @memberof SkuDetailed
     */
    externalSkuId: number;
    /**
     * 
     * @type {number}
     * @memberof SkuDetailed
     */
    photoRequestId?: number;
    /**
     * 
     * @type {PaymentRate}
     * @memberof SkuDetailed
     */
    photoRequestItemPaymentRate?: PaymentRate;
    /**
     * 
     * @type {Category}
     * @memberof SkuDetailed
     */
    category?: Category;
    /**
     * 
     * @type {number}
     * @memberof SkuDetailed
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof SkuDetailed
     */
    productId: number;
}

export function SkuDetailedFromJSON(json: any): SkuDetailed {
    return SkuDetailedFromJSONTyped(json, false);
}

export function SkuDetailedFromJSONTyped(json: any, ignoreDiscriminator: boolean): SkuDetailed {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'barcode': json['barcode'],
        'title': json['title'],
        'description': json['description'],
        'photoURL': json['photoURL'],
        'previewImage': !exists(json, 'previewImage') ? undefined : ImageFromJSON(json['previewImage']),
        'status': SkuStatusFromJSON(json['status']),
        'identifier': !exists(json, 'identifier') ? undefined : json['identifier'],
        'images': ((json['images'] as Array<any>).map(ImageFromJSON)),
        'shootingTypes': ((json['shootingTypes'] as Array<any>).map(ShootingTypeFromJSON)),
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'photoStudioId': json['photoStudioId'],
        'onPhotoStudioCount': json['onPhotoStudioCount'],
        'expectedCount': json['expectedCount'],
        'writeOffCount': json['writeOffCount'],
        'sentCount': json['sentCount'],
        'seller': SellerFromJSON(json['seller']),
        'externalSkuId': json['externalSkuId'],
        'photoRequestId': !exists(json, 'photoRequestId') ? undefined : json['photoRequestId'],
        'photoRequestItemPaymentRate': !exists(json, 'photoRequestItemPaymentRate') ? undefined : PaymentRateFromJSON(json['photoRequestItemPaymentRate']),
        'category': !exists(json, 'category') ? undefined : CategoryFromJSON(json['category']),
        'price': json['price'],
        'productId': json['productId'],
    };
}

export function SkuDetailedToJSON(value?: SkuDetailed | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'barcode': value.barcode,
        'title': value.title,
        'description': value.description,
        'photoURL': value.photoURL,
        'previewImage': ImageToJSON(value.previewImage),
        'status': SkuStatusToJSON(value.status),
        'identifier': value.identifier,
        'images': ((value.images as Array<any>).map(ImageToJSON)),
        'shootingTypes': ((value.shootingTypes as Array<any>).map(ShootingTypeToJSON)),
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'photoStudioId': value.photoStudioId,
        'onPhotoStudioCount': value.onPhotoStudioCount,
        'expectedCount': value.expectedCount,
        'writeOffCount': value.writeOffCount,
        'sentCount': value.sentCount,
        'seller': SellerToJSON(value.seller),
        'externalSkuId': value.externalSkuId,
        'photoRequestId': value.photoRequestId,
        'photoRequestItemPaymentRate': PaymentRateToJSON(value.photoRequestItemPaymentRate),
        'category': CategoryToJSON(value.category),
        'price': value.price,
        'productId': value.productId,
    };
}

