<template>
  <span class="dot-divider" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({ name: 'dot-divider' });
</script>

<style lang="stylus" scoped>
.dot-divider
  display inline-block
  width 6px
  height 6px
  border-radius 50%
  background-color #d7d8d7
  margin 0 16px
</style>
