<template>
  <IsInViewWrapper
    ref="container"
    class="container"
    :style="{
      height: data.items.length || error ? undefined : '100%',
      overflow: !data.items.length ? 'hidden' : 'auto',
    }"
  >
    <UiTable
      :columns="columns"
      :data-source="
        isLoading && !data.items.length ? Array(20).fill(null) : data.items
      "
      :loading="isLoading"
      :empty-text="$t('requestsPage.table.emptyText')"
      :custom-row="() => ({ 'data-test-id': 'row__table' })"
      :custom-header-row="() => ({ 'data-test-id': 'table__header' })"
      bordered
      @change="handleChange"
    >
      <template v-if="error" #empty>
        <LoadError @retry="refetch()" />
      </template>

      <template #bodyCell="{ column, record }">
        <template v-if="isLoading && !data.items.length">
          <template v-if="column.key === 'id'">
            <div class="id-column">
              <Skeleton :paragraph="false" active />
              <Skeleton :paragraph="false" style="width: 100px" active />
            </div>
          </template>

          <template v-if="column.key === 'itemCount'">
            <Skeleton
              :paragraph="false"
              style="width: 80px; margin-left: auto"
              active
            />
          </template>

          <template v-else-if="column.key === 'boxBarcode'">
            <Skeleton :paragraph="false" style="width: 120px" active />
          </template>

          <template v-else-if="column.key === 'createdAt'">
            <Skeleton :paragraph="false" style="width: 110px" active />
          </template>

          <template v-else-if="column.key === 'updatedAt'">
            <Skeleton :paragraph="false" style="width: 110px" active />
          </template>
        </template>

        <template v-else>
          <template v-if="column.key === 'id'">
            <div class="id-column">
              <UiButton
                type="link"
                class="link"
                data-test-id="button__number_request"
              >
                {{ record.id }}
              </UiButton>
              <div class="flex items-center gap-[6px]">
                <div
                  v-if="record.shootingTypes.length"
                  class="shooting-types"
                  data-test-id="block__info_shooting_types"
                >
                  <ShootingTypeChip
                    v-for="st in record.shootingTypes.slice(0, 3)"
                    :key="st"
                    :type="st"
                  />
                </div>

                <Tooltip
                  v-if="record.parentId ? record.parentId !== 0 : false"
                  :text="`${$t('returned-from-defect', {
                    requestId: record.parentId,
                  })}`"
                  @click.stop="openRequestModal({ id: record.parentId })"
                >
                  <div class="returned-from-defect">
                    <icon-ui name="status/returned" size="12" class="control" />
                  </div>
                </Tooltip>
              </div>
            </div>
          </template>

          <template v-else-if="column.key === 'boxBarcode'">
            {{ record.box?.barcode }}
          </template>

          <template v-else-if="column.key === 'createdAt'">
            <Date :value="record.created" />
          </template>

          <template v-else-if="column.key === 'updatedAt'">
            <Date :value="record.updated" />
          </template>
        </template>
      </template>
    </UiTable>

    <template v-if="data.items.length && hasNextPage">
      <InfiniteLoader :has-more="hasNextPage" @enter="fetchNextPage()" />

      <LinearLoader class="loader" />
    </template>
  </IsInViewWrapper>
</template>

<script setup lang="ts">
import { Skeleton, TableColumnType } from 'ant-design-vue';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { PhotoRequestStatus } from '@/api';
import InfiniteLoader from '@/components/InfiniteLoader.vue';
import IsInViewWrapper from '@/components/IsInViewWrapper.vue';
import ShootingTypeChip from '@/components/ShootingTypeChip.vue';
import { ESortingDirection, TSorting } from '@/components/table/types';
import { usePreserveScroll } from '@/composables/usePreserveScroll';
import { SortingDirection } from '@/composables/useSorting';
import { EModalTypes, useRoutedModal } from '@/features/Modals';
import { useRequests, useRequestsParams } from '@/features/Requests';
import {
  CopyButton,
  Date,
  LinearLoader,
  LoadError,
  Tooltip,
  UiButton,
  UiTable,
  UiTableProps,
} from '@/ui';
import IconUi from '@/ui/icons/component-next.vue';

interface Props {
  status: PhotoRequestStatus;
}

const props = defineProps<Props>();
const { t } = useI18n();
const [openRequestModal] = useRoutedModal(EModalTypes.REQUEST);

const { data, error, isLoading, hasNextPage, fetchNextPage, refetch } =
  useRequests(props.status);

const params = useRequestsParams();

const toSortOrder = (direction: SortingDirection) =>
  direction === SortingDirection.ASC ? 'ascend' : 'descend';

const getItemCountTitle = (count: number) => {
  const title = t('requestsPage.table.columns.itemCount');

  return count ? `${title} (${count})` : title;
};

const columns = computed<TableColumnType[]>(() => [
  {
    dataIndex: 'id',
    key: 'id',
    title: t('requestsPage.table.columns.id'),
    width: '30%',
    customCell: (record) => ({
      onClick: () => openRequestModal({ id: record.id }),
    }),
  },
  {
    dataIndex: 'itemCount',
    key: 'itemCount',
    title: getItemCountTitle(data.value.totalItemCount),
    align: 'right',
    width: '17.5%',
    customCell: () => ({ 'data-test-id': 'block__info_quantity' }) as any,
  },
  {
    dataIndex: 'boxBarcode',
    key: 'boxBarcode',
    title: t('requestsPage.table.columns.boxBarcode'),
    width: '17.5%',
  },
  {
    dataIndex: 'created',
    key: 'createdAt',
    title: t('requestsPage.table.columns.created'),
    width: '17.5%',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'],
    defaultSortOrder:
      params.sorting.field === 'createdAt' && params.sorting.direction
        ? toSortOrder(params.sorting.direction)
        : undefined,
  },
  {
    dataIndex: 'updated',
    key: 'updatedAt',
    title: t('requestsPage.table.columns.updated'),
    width: '17.5%',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'],
    defaultSortOrder:
      params.sorting.field === 'updatedAt' && params.sorting.direction
        ? toSortOrder(params.sorting.direction)
        : undefined,
  },
]);

const handleChange: UiTableProps['onChange'] = (
  _pagination,
  _filters,
  sorter,
) => {
  const { order, columnKey } = Array.isArray(sorter) ? sorter[0] : sorter;

  if (!columnKey) return;

  const orderMap: Record<NonNullable<typeof order>, ESortingDirection> = {
    ascend: ESortingDirection.ASC,
    descend: ESortingDirection.DESC,
  };

  const sorting: TSorting = {
    field: String(columnKey),
    direction: order ? orderMap[order] : order,
  };

  params.sorting = sorting;
};

const container = ref<HTMLElement>();

usePreserveScroll(container, true);
</script>

<style lang="stylus" scoped>
.container
  border 1px solid #f0f0f0
  border-radius 5px

  :deep()
    .ant-table-thead .ant-table-cell
      background-color gray-2

    .ant-skeleton-title
      margin 0

    .ant-table-container
      border 0 !important

    .ant-table-cell
      height 58px

      &:last-child
        border-right 0 !important

    table
      border-top 0 !important

    tr:last-child td
      border-bottom 0 !important

    td:first-child
      cursor pointer

.id-column
  display grid
  grid-template-columns 25% auto
  place-items center start
  gap 10px

  .shooting-types
    display flex
    align-items center
    flex-wrap wrap
    gap 4px

.link
  padding 0

.returned-from-defect
  display flex
  align-items center
  justify-content center
  background rgba(255, 229, 143, 1)
  height 26px
  width 26px
  border-radius 20px

.loader
  width calc(100% - 40px)
  display flex
  align-items center
</style>
