/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PhotoRequestStatus,
    PhotoRequestStatusFromJSON,
    PhotoRequestStatusFromJSONTyped,
    PhotoRequestStatusToJSON,
} from './PhotoRequestStatus';

/**
 * 
 * @export
 * @interface ChangeRequestStatusBulkRequest
 */
export interface ChangeRequestStatusBulkRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof ChangeRequestStatusBulkRequest
     */
    ids: Array<number>;
    /**
     * 
     * @type {PhotoRequestStatus}
     * @memberof ChangeRequestStatusBulkRequest
     */
    status: PhotoRequestStatus;
}

export function ChangeRequestStatusBulkRequestFromJSON(json: any): ChangeRequestStatusBulkRequest {
    return ChangeRequestStatusBulkRequestFromJSONTyped(json, false);
}

export function ChangeRequestStatusBulkRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeRequestStatusBulkRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ids': json['ids'],
        'status': PhotoRequestStatusFromJSON(json['status']),
    };
}

export function ChangeRequestStatusBulkRequestToJSON(value?: ChangeRequestStatusBulkRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ids': value.ids,
        'status': PhotoRequestStatusToJSON(value.status),
    };
}

