/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaymentRate,
    PaymentRateFromJSON,
    PaymentRateFromJSONTyped,
    PaymentRateToJSON,
} from './PaymentRate';
import {
    Reservation,
    ReservationFromJSON,
    ReservationFromJSONTyped,
    ReservationToJSON,
} from './Reservation';
import {
    ReservationInfoAllOf,
    ReservationInfoAllOfFromJSON,
    ReservationInfoAllOfFromJSONTyped,
    ReservationInfoAllOfToJSON,
} from './ReservationInfoAllOf';
import {
    ReservationStatusInfo,
    ReservationStatusInfoFromJSON,
    ReservationStatusInfoFromJSONTyped,
    ReservationStatusInfoToJSON,
} from './ReservationStatusInfo';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface ReservationInfo
 */
export interface ReservationInfo {
    /**
     * 
     * @type {string}
     * @memberof ReservationInfo
     */
    id: string;
    /**
     * timestamp UTC
     * @type {number}
     * @memberof ReservationInfo
     */
    createdAt: number;
    /**
     * timestamp UTC
     * @type {number}
     * @memberof ReservationInfo
     */
    updatedAt: number;
    /**
     * 
     * @type {PaymentRate}
     * @memberof ReservationInfo
     */
    paymentRate: PaymentRate;
    /**
     * 
     * @type {Array<ReservationStatusInfo>}
     * @memberof ReservationInfo
     */
    statusInfoList: Array<ReservationStatusInfo>;
    /**
     * 
     * @type {User}
     * @memberof ReservationInfo
     */
    user: User;
    /**
     * 
     * @type {number}
     * @memberof ReservationInfo
     */
    itemsCount: number;
}

export function ReservationInfoFromJSON(json: any): ReservationInfo {
    return ReservationInfoFromJSONTyped(json, false);
}

export function ReservationInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReservationInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'paymentRate': PaymentRateFromJSON(json['paymentRate']),
        'statusInfoList': ((json['statusInfoList'] as Array<any>).map(ReservationStatusInfoFromJSON)),
        'user': UserFromJSON(json['user']),
        'itemsCount': json['itemsCount'],
    };
}

export function ReservationInfoToJSON(value?: ReservationInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'paymentRate': PaymentRateToJSON(value.paymentRate),
        'statusInfoList': ((value.statusInfoList as Array<any>).map(ReservationStatusInfoToJSON)),
        'user': UserToJSON(value.user),
        'itemsCount': value.itemsCount,
    };
}

