export const safeValue = (envVariable: string) => {
  return envVariable === 'undefined' ? undefined : envVariable;
};

export const safeBoolean = (envVariable: string) => {
  return Boolean(
    ['false', 'true'].indexOf((safeValue(envVariable) || '').toLowerCase()) ||
      false,
  );
};
