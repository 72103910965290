/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PhotoRequestItemReviewStatus,
    PhotoRequestItemReviewStatusFromJSON,
    PhotoRequestItemReviewStatusFromJSONTyped,
    PhotoRequestItemReviewStatusToJSON,
} from './PhotoRequestItemReviewStatus';

/**
 * 
 * @export
 * @interface PhotoRequestItemReviewStatusChange
 */
export interface PhotoRequestItemReviewStatusChange {
    /**
     * 
     * @type {PhotoRequestItemReviewStatus}
     * @memberof PhotoRequestItemReviewStatusChange
     */
    old: PhotoRequestItemReviewStatus;
    /**
     * 
     * @type {PhotoRequestItemReviewStatus}
     * @memberof PhotoRequestItemReviewStatusChange
     */
    _new: PhotoRequestItemReviewStatus;
}

export function PhotoRequestItemReviewStatusChangeFromJSON(json: any): PhotoRequestItemReviewStatusChange {
    return PhotoRequestItemReviewStatusChangeFromJSONTyped(json, false);
}

export function PhotoRequestItemReviewStatusChangeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhotoRequestItemReviewStatusChange {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'old': PhotoRequestItemReviewStatusFromJSON(json['old']),
        '_new': PhotoRequestItemReviewStatusFromJSON(json['new']),
    };
}

export function PhotoRequestItemReviewStatusChangeToJSON(value?: PhotoRequestItemReviewStatusChange | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'old': PhotoRequestItemReviewStatusToJSON(value.old),
        'new': PhotoRequestItemReviewStatusToJSON(value._new),
    };
}

