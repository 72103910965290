import { photoApi, PhotoSize } from '@/api';
import { IMAGE_HOST_URL } from '@/config';

export const imageSrc = (
  key: string,
  name: string,
  urlPrefix?: string | undefined,
) => {
  if (!key) return '';
  const url = urlPrefix ? urlPrefix.replace(/\/$/, '') : IMAGE_HOST_URL;

  return `${url}/${key}/${name}.jpg`;
};

export const originalImageSrc = (
  key: string | undefined,
  urlPrefix?: string | undefined,
) => (key ? imageSrc(key, 'original', urlPrefix) : '');

export const productImageSrc = (
  key: string | undefined,
  urlPrefix?: string | undefined,
) => (key ? imageSrc(key, 't_product_240_high', urlPrefix) : '');

export const getPhotoPreview = async (path: string, photoSize: PhotoSize) => {
  const photoBlob = await photoApi.getPhotoPreview({
    filePath: path,
    photoSize,
  });

  return URL.createObjectURL(photoBlob);
};
