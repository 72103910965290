/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EventType,
    EventTypeFromJSON,
    EventTypeFromJSONTyped,
    EventTypeToJSON,
} from './EventType';
import {
    SkuEventData,
    SkuEventDataFromJSON,
    SkuEventDataFromJSONTyped,
    SkuEventDataToJSON,
} from './SkuEventData';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface SkuEvent
 */
export interface SkuEvent {
    /**
     * 
     * @type {EventType}
     * @memberof SkuEvent
     */
    type: EventType;
    /**
     * 
     * @type {number}
     * @memberof SkuEvent
     */
    createdAt: number;
    /**
     * 
     * @type {User}
     * @memberof SkuEvent
     */
    user: User;
    /**
     * 
     * @type {SkuEventData}
     * @memberof SkuEvent
     */
    data: SkuEventData;
}

export function SkuEventFromJSON(json: any): SkuEvent {
    return SkuEventFromJSONTyped(json, false);
}

export function SkuEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): SkuEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': EventTypeFromJSON(json['type']),
        'createdAt': json['createdAt'],
        'user': UserFromJSON(json['user']),
        'data': SkuEventDataFromJSON(json['data']),
    };
}

export function SkuEventToJSON(value?: SkuEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': EventTypeToJSON(value.type),
        'createdAt': value.createdAt,
        'user': UserToJSON(value.user),
        'data': SkuEventDataToJSON(value.data),
    };
}

