<template>
  <div class="notification-container" @mouseenter="pause" @mouseleave="resume">
    <slot>
      <div class="notification-content">
        <component
          :is="iconComponent"
          v-if="iconComponent"
          :class="`notification-icon ant-notification-notice-icon-${type}`"
        />

        <div v-if="message" class="notification-message">
          {{ message }}
        </div>
        <div v-if="button" class="ml-auto">
          <ui-button
            type="primary"
            class="primary-button"
            @click="button.callback"
          >
            {{ button.text }}
          </ui-button>
        </div>
      </div>
    </slot>

    <AProgress
      :percent="percent"
      :show-info="false"
      :stroke-width="4"
      stroke-linecap="square"
      status="normal"
      class="notification-progress"
    />
  </div>
</template>

<script setup lang="ts">
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons-vue';
import { useInterval, whenever } from '@vueuse/shared';
import { Progress as AProgress } from 'ant-design-vue';
import { computed, VNode } from 'vue';

import { NotificationButtonOption } from '@/features/Notifications/index';
import { BaseButton, UiButton } from '@/ui';

const SECOND = 1000;
const PER_SECOND = 20;
const INTERVAL = SECOND / PER_SECOND;

interface Props {
  duration: number;
  message?: string;
  icon: VNode | (() => VNode) | undefined;
  type?: 'success' | 'info' | 'warning' | 'error';
  button?: NotificationButtonOption;
}

interface Emits {
  (e: 'close'): void;
}

const typeToIcon = {
  success: CheckCircleOutlined,
  info: InfoCircleOutlined,
  warning: ExclamationCircleOutlined,
  error: CloseCircleOutlined,
};

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const {
  counter: elapsed,
  pause,
  resume,
} = useInterval(INTERVAL, { controls: true });

const left = computed(() => props.duration - elapsed.value / PER_SECOND);
const percent = computed(() => (left.value / props.duration) * 100);
const done = computed(() => left.value <= 0);

const iconComponent = computed(() => {
  if (props.icon) return props.icon;

  return props.type && props.type in typeToIcon ? typeToIcon[props.type] : null;
});

whenever(done, () => emit('close'));
</script>

<style lang="stylus">
.ant-notification
  z-index 10000

.ant-notification-notice-message,
.ant-notification-notice-icon
  display none

.ant-notification-notice-description
  margin-left 0!important

.notification-container
  position relative
  padding 16px 24px 20px

.notification-content
  display flex
  align-items center
  gap 20px

.notification-message
  font-size 14px

.notification-icon
  font-size 24px

.notification-progress.ant-progress
  display flex
  position absolute
  bottom 0
  left 0
  margin-bottom 0
.ant-progress-bg
  transition-duration 0.05s

.ant-progress-outer
  display flex
</style>
