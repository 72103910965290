/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ReferenceListResponse,
    ReferenceListResponseFromJSON,
    ReferenceListResponseToJSON,
    ResponseError,
    ResponseErrorFromJSON,
    ResponseErrorToJSON,
} from '../models';

export interface ReferencesByCategoryIDRequest {
    categoryID: number;
}

/**
 * 
 */
export class ReferencesApi extends runtime.BaseAPI {

    /**
     * Получить референсы по id категории
     */
    async referencesByCategoryIDRaw(requestParameters: ReferencesByCategoryIDRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ReferenceListResponse>> {
        if (requestParameters.categoryID === null || requestParameters.categoryID === undefined) {
            throw new runtime.RequiredError('categoryID','Required parameter requestParameters.categoryID was null or undefined when calling referencesByCategoryID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/references/category/{categoryID}`.replace(`{${"categoryID"}}`, encodeURIComponent(String(requestParameters.categoryID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferenceListResponseFromJSON(jsonValue));
    }

    /**
     * Получить референсы по id категории
     */
    async referencesByCategoryID(requestParameters: ReferencesByCategoryIDRequest, initOverrides?: RequestInit): Promise<ReferenceListResponse> {
        const response = await this.referencesByCategoryIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
