import { computed } from 'vue';

import { PhotoRequestDetailed, PhotoRequestStatus, ShootingType } from '@/api';
import { IS_DEVELOPMENT } from '@/config';
import { featureFlags } from '@/features/FeatureFlags';

const allStatuses = computed(() => {
  return [
    PhotoRequestStatus.Draft,
    PhotoRequestStatus.Created,
    PhotoRequestStatus.OnPhotoShoot,
    ...(featureFlags.statusPanoramic
      ? [PhotoRequestStatus.OnPhotoShootPanoramic]
      : []),
    ...(featureFlags.photographerFlow
      ? [PhotoRequestStatus.PhotoLoading, PhotoRequestStatus.PhotoLoadingError]
      : []),
    PhotoRequestStatus.Photographed,
    PhotoRequestStatus.InReviewBeforeRetouch,
    PhotoRequestStatus.ReadyToRetouch,
    PhotoRequestStatus.OnRetoushing,
    ...(featureFlags.qualityControl
      ? [
          PhotoRequestStatus.Retouched,
          PhotoRequestStatus.InReview,
          PhotoRequestStatus.Reviewed,
        ]
      : []),
    PhotoRequestStatus.Done,
  ];
});

const enabledStatuses = () => {
  const statuses = new Set(allStatuses.value);

  if (!featureFlags.qualityControl) {
    statuses.delete(PhotoRequestStatus.Retouched);
    statuses.delete(PhotoRequestStatus.InReview);
    statuses.delete(PhotoRequestStatus.Reviewed);
  }

  return Array.from(statuses);
};

const next = (
  { shootingTypes, status }: PhotoRequestDetailed,
  isNewFlow: boolean,
): PhotoRequestStatus => {
  if (status === PhotoRequestStatus.PhotoLoadingError) {
    return PhotoRequestStatus.PhotoLoading;
  }

  const flowSet = new Set(enabledStatuses());

  flowSet.delete(PhotoRequestStatus.PhotoLoadingError);

  if (
    !shootingTypes.includes(ShootingType.Panoramic) &&
    status !== PhotoRequestStatus.OnPhotoShootPanoramic
  ) {
    flowSet.delete(PhotoRequestStatus.OnPhotoShootPanoramic);
  }

  if (!isNewFlow) {
    flowSet.delete(PhotoRequestStatus.PhotoLoading);

    if (status !== PhotoRequestStatus.OnPhotoShootPanoramic) {
      flowSet.delete(PhotoRequestStatus.OnPhotoShootPanoramic);
    }
  }

  const flow = Array.from(flowSet);
  const idx = flow.indexOf(status);

  return flow[idx + 1];
};

const gt = (status: PhotoRequestStatus, other: PhotoRequestStatus): boolean =>
  allStatuses.value.indexOf(status) > allStatuses.value.indexOf(other);

const gte = (status: PhotoRequestStatus, other: PhotoRequestStatus): boolean =>
  allStatuses.value.indexOf(status) >= allStatuses.value.indexOf(other);

const lt = (status: PhotoRequestStatus, other: PhotoRequestStatus): boolean =>
  allStatuses.value.indexOf(status) < allStatuses.value.indexOf(other);

const lte = (status: PhotoRequestStatus, other: PhotoRequestStatus): boolean =>
  allStatuses.value.indexOf(status) <= allStatuses.value.indexOf(other);

const between = (
  status: PhotoRequestStatus,
  from: PhotoRequestStatus,
  to: PhotoRequestStatus,
): boolean => gte(status, from) && lte(status, to);

export const statusFlow = {
  get statuses() {
    return enabledStatuses();
  },
  next,
  gt,
  gte,
  lt,
  lte,
  between,
};

const BASE_URL =
  'http://94.141.76.235:5000/index.cgi?launchApp=SYNO.SDS.App.FileStation3.Instance&launchParam=openfile';

const DEV_BASE_PATH =
  '=/photo/PhotoLibrary/Папка Фотостудии/Автоматизация Тест';

const PROD_BASE_PATH = '=/photo/PhotoLibrary/Папка Фотостудии/Автоматизация';
const BASE_PATH = IS_DEVELOPMENT ? DEV_BASE_PATH : PROD_BASE_PATH;

export const nextcloudUrl = (path: string) => {
  const encodedPath = encodeURIComponent(`${BASE_PATH}/${path}`);

  return `${BASE_URL}${encodedPath}`;
};

export const isPanoramic = (request: PhotoRequestDetailed): boolean =>
  request.status === PhotoRequestStatus.OnPhotoShootPanoramic;
