import { InjectionKey } from 'vue';

import {
  IColumn,
  TGroupOpened,
  TRowFocus,
  TRowPopped,
  TRowSelection,
  TSorting,
} from './types';

export const SortingKey: InjectionKey<TSorting> = Symbol('Sorting');

export const FocusKey: InjectionKey<TRowFocus> = Symbol('Focus');

export const ColumnsKey: InjectionKey<IColumn[]> = Symbol('Columns');

export const SelectionKey: InjectionKey<TRowSelection> = Symbol('Selection');

export const OpenedKey: InjectionKey<TGroupOpened> = Symbol('Opened');

export const PoppedKey: InjectionKey<TRowPopped> = Symbol('Popped');
