<template>
  <div class="mr-2">
    <tooltip
      :text="
        !isVisible
          ? $t('references.open tooltip')
          : $t('references.close tooltip')
      "
    >
      <ui-button variant="icon" class="open-references" @click="toggleDrawer()">
        <icon-ui name="actions/ref" />
      </ui-button>
    </tooltip>
  </div>

  <references-drawer
    v-if="isVisible"
    :category-id="categoryId"
    :category-title="categoryTitle"
    @close="toggleDrawer(false)"
  />
</template>

<script setup lang="ts">
import { useToggle, useVModel } from '@vueuse/core';
import { onMounted } from 'vue';

import { EIntroKeys, useFeatureIntro } from '@/features/FeatureIntro';
import { ReferencesDrawer } from '@/features/References';
import { Tooltip, UiButton } from '@/ui';

interface Props {
  categoryId: number;
  categoryTitle: string;
  open?: boolean;
}

const props = defineProps<Props>();
const emit = defineEmits<{ 'update:open': [v: boolean] }>();
const isVisible = useVModel(props, 'open', emit);
const toggleDrawer = useToggle(isVisible);
const [showIntro] = useFeatureIntro(EIntroKeys.OPEN_REFERENCES_INTRO);

onMounted(() => {
  showIntro();
});
</script>

<style lang="stylus">
.open-references
  font-size 20px
  width 32px
</style>
