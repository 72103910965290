<template>
  <div class="wrapper" data-test-id="block__not-found">
    {{ $t('nothing found') }}
  </div>
</template>
<style lang="stylus" scoped>
.wrapper
  display flex
  justify-content center
  padding 20px
</style>
