<template>
  <PageLayout>
    <template #header>
      <PageHeader
        :title="$t('users.title')"
        :subtitle="
          !state.users.length
            ? ' '
            : $t('pluralization.user', filteredUsers.length)
        "
      >
        <UiButton type="primary" @click="handleOpenUserForm()">
          {{ $t('users.create') }}
        </UiButton>
      </PageHeader>
    </template>
    <div class="content-wrapper">
      <div class="table-wrapper">
        <div
          :style="{
            height: !filteredUsers.length ? '100%' : '',
          }"
        >
          <UiTable
            :data-source="filteredUsers"
            :columns="columns"
            :custom-header-row="() => ({ 'data-test-id': 'table__header' })"
            :row-key="(record) => record.id"
            bordered
            @change="handleTableChange"
          >
            <template v-if="state.loading || state.error" #empty>
              <LinearLoader
                v-if="state.loading"
                :style="{ width: '100%', margin: '2em 0 1em 0' }"
              />
              <LoadError v-else-if="state.error" @retry="getUsers" />
            </template>
            <template #bodyCell="{ column, text, record }">
              <template v-if="column.key === 'fullName'">
                <span class="text-wrap">
                  {{ text }}
                </span>
              </template>
              <template v-if="column.key === 'phone'">
                {{ convertPhone(text) }}
              </template>
              <template v-if="column.key === 'createdTimestamp'">
                <div class="action-wrapper">
                  <DateCell :value="text" />
                  <UiPopover
                    v-model:open="isRowMenuVisible[record.id]"
                    placement="bottomLeft"
                    trigger="click"
                  >
                    <div>
                      <EllipsisOutlined class="ellipsis-icon" />
                    </div>
                    <template #content>
                      <div class="popover-content">
                        <div
                          class="popover-item"
                          @click="handleEditUser(record)"
                        >
                          <EditOutlined class="popover-item-icon" />
                          {{ $t('users.edit') }}
                        </div>
                        <UiPopover
                          v-model:open="warningVisible[record.id]"
                          placement="left"
                          trigger="click"
                        >
                          <div class="popover-item popover-item-danger">
                            <DisconnectOutlined class="popover-item-icon" />
                            {{ $t('users.delete') }}
                          </div>
                          <template #content>
                            <div class="delete-warning">
                              <div class="delete-warning-content">
                                <DisconnectOutlined
                                  class="delete-warning-icon"
                                />
                                <div>
                                  {{
                                    $t('users.delete warning', {
                                      companyName: COMPANY_NAME,
                                    })
                                  }}
                                </div>
                              </div>
                              <div class="delete-warning-footer">
                                <UiButton
                                  type="primary"
                                  danger
                                  @click="handleDeleteUser(record)"
                                >
                                  {{ $t('users.delete') }}
                                </UiButton>
                                <UiButton
                                  type="default"
                                  @click="hideWarning(record.id)"
                                >
                                  {{ $t('users.cancel') }}
                                </UiButton>
                              </div>
                            </div>
                          </template>
                        </UiPopover>
                      </div>
                    </template>
                  </UiPopover>
                </div>
              </template>
            </template>
            <template #customFilterDropdown="{ column }">
              <template v-if="column.key === 'fullName'">
                <SearchPopup
                  ref="fullnameSearch"
                  :placeholder="$t('form.fullname.title')"
                  maxlength="50"
                  @accept="
                    handleAcceptFilter($event, 'fullname', fullnameSearch)
                  "
                  @reset="handleResetFilter('fullname', fullnameSearch)"
                />
              </template>
              <template v-if="column.key === 'phone'">
                <SearchPopup
                  ref="phoneSearch"
                  :placeholder="phonePlaceholder"
                  :mask="phoneMask"
                  :unmask="unmaskPhone"
                  @accept="handleAcceptFilter($event, 'phone', phoneSearch)"
                  @reset="handleResetFilter('phone', phoneSearch)"
                />
              </template>
            </template>
            <template #customFilterIcon="{ column }">
              <template v-if="column.key === 'fullName'">
                <ui-tooltip :title="$t('search')">
                  <SearchOutlined
                    :class="{ 'active-filter-color': filters.fullname }"
                  />
                </ui-tooltip>
              </template>
              <template v-if="column.key === 'phone'">
                <ui-tooltip :title="$t('search')">
                  <SearchOutlined
                    :class="{ 'active-filter-color': filters.phone }"
                  />
                </ui-tooltip>
              </template>
              <template v-if="column.key === 'role'">
                <ui-tooltip :title="$t('filter')">
                  <FilterFilled
                    :class="{ 'active-filter-color': filters.roles.length }"
                  />
                </ui-tooltip>
              </template>
            </template>
          </UiTable>
        </div>
      </div>
    </div>
  </PageLayout>
  <UserFormModal
    v-if="isShowModal"
    :user="userModal"
    :roles="state.roles"
    :on-create="onCreateHandler"
    :on-edit="onEditHandler"
    @close="isShowModal = false"
  />
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, reactive, ref } from 'vue';
export default defineComponent({ name: 'administration-page' });
</script>

<script setup lang="ts">
import {
  DisconnectOutlined,
  EditOutlined,
  EllipsisOutlined,
  FilterFilled,
  SearchOutlined,
} from '@ant-design/icons-vue';
import { useI18n } from 'vue-i18n';

import DateCell from '@/components/table/cells/date-cell.vue';
import LinearLoader from '@/components/ui/linear-loader/index.vue';
import { COMPANY_NAME } from '@/config';
import { notification } from '@/features/Notifications';
import { PageHeader, PageLayout } from '@/template';
import {
  LoadError,
  SearchPopup,
  UiButton,
  UiPopover,
  UiTable,
  UiTooltip,
} from '@/ui';
import {
  convertPhone,
  phoneMask,
  phonePlaceholder,
  unmaskPhone,
} from '@/utils/phone';

import UserFormModal from './components/UserFormModal.vue';
import { useAdministrationApi } from './composables';
import { IChangeWmsAccount, ICreateWmsAccount, IWmsAccount } from './types';

const {
  state,
  createUser,
  deleteUser,
  editUser,
  filteredUsers,
  filters,
  sortDirection,
  getUsers,
} = useAdministrationApi();

const { t } = useI18n();

const handleOpenUserForm = (user?: IWmsAccount) => {
  isShowModal.value = true;
  userModal.value = user;
};

const phoneSearch = ref(null);
const fullnameSearch = ref(null);
const isShowModal = ref(false);
const userModal = ref<IWmsAccount | undefined>();

const onEditHandler = async (data: IChangeWmsAccount) => {
  try {
    await editUser(data);
    isShowModal.value = false;
    notification.success(t('users.edit success'));
  } catch (err) {
    showErrorNotification(err);
    throw err;
  }
};

const onCreateHandler = async (data: ICreateWmsAccount) => {
  try {
    await createUser(data);
    isShowModal.value = false;
    notification.success(t('users.create success'));
  } catch (err) {
    showErrorNotification(err);
    throw err;
  }
};

const handleAcceptFilter = (
  value: string,
  filter: string,
  model: object,
): void => {
  if (value) {
    filters[filter] = value;
  } else {
    model.isValid = false;
  }
};

const handleResetFilter = (filter: string, model: object): void => {
  filters[filter] = '';
  model.isValid = true;
};

const handleTableChange = (pagination, tableFilters, sorter) => {
  const { order } = sorter;

  if (order) {
    if (order === 'descend') {
      sortDirection.value = 'desc';
    } else {
      sortDirection.value = 'asc';
    }
  } else {
    sortDirection.value = '';
  }

  filters.roles = tableFilters.role || [];
};

const handleEditUser = async (user: IWmsAccount) => {
  handleOpenUserForm(user);
  isRowMenuVisible[user.id] = false;
};

const handleDeleteUser = async (user: IWmsAccount) => {
  try {
    await deleteUser(user.phone);
    notification.success(t('users.delete success'));
  } catch (err) {
    showErrorNotification(err);
  }
};

const isRowMenuVisible = reactive({});
const warningVisible = reactive({});

const hideWarning = (id) => {
  warningVisible[id] = false;
};

const showErrorNotification = (err) => {
  notification.error(String(err) || t('server error'));
};

const columns = computed(() => {
  return [
    {
      title: t('form.fullname.title'),
      key: 'fullName',
      dataIndex: 'fullName',
      width: '36%',
      customFilterDropdown: true,
    },
    {
      title: t('phone'),
      key: 'phone',
      dataIndex: 'phone',
      width: '20%',
      customFilterDropdown: true,
    },
    {
      title: t('form.role.title'),
      key: 'role',
      dataIndex: ['role', 'localizedName'],
      width: '20%',
      filters: state.roles.map((role) => ({
        value: role.name,
        text: role.localizedName,
      })),
    },
    {
      title: t('form.created.title'),
      key: 'createdTimestamp',
      dataIndex: 'createdTimestamp',
      width: '24%',
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['descend', 'ascend'],
    },
  ];
});

onMounted(async () => {
  await getUsers();
});
</script>

<style lang="stylus" scoped>
.content-wrapper
  border-bottom 1px solid #f0f0f0
  padding-bottom 25px
  height 100%
  padding-top 6px

.table-wrapper
  position relative
  overflow auto
  height 100%
  :deep() td
    padding-top 0
    padding-bottom 0

.action-wrapper
  display flex
  align-items center
  justify-content space-between

.ellipsis-icon
  font-size 20px
  margin 0 10px
  cursor pointer

.popover-content
  margin -8px -16px

.popover-item
  padding 5px 14px
  cursor pointer
  transition-duration .25s
  &:hover
    background-color #f5f5f5
  &-danger
    color Red()
  &-icon
    margin-right 5px

.delete-warning
  max-width 275px
  padding 3px
  &-content
    display flex
    justify-content space-between
    align-items center
    padding 0 5px 15px
  &-icon
    font-size 32px
    color Red()
    margin-right 20px
  &-footer
    display flex
    & > *
      margin-right 5px

.text-wrap
  word-wrap anywhere
</style>
