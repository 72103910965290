<template>
  <div
    class="image"
    :style="{
      backgroundImage: `url('${url ? url : placeholderUrl}')`,
      width: `${width}px`,
      height: `${ASPECT_RATIO * width}px`,
    }"
  />
</template>
<script lang="ts">
import { defineComponent } from 'vue';

import placeholderUrl from '@/assets/placeholder.svg';

const DEFAULT_IMAGE_WIDTH = 59;
const ASPECT_RATIO = 1.33;

export default defineComponent({
  name: 'image-div',
  props: {
    url: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      default: DEFAULT_IMAGE_WIDTH,
    },
  },
  setup() {
    return { ASPECT_RATIO, placeholderUrl };
  },
});
</script>
<style lang="stylus" scoped>
.image
  background-size cover
  background-position center
  border-radius 4px
</style>
