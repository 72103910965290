import { Directive } from 'vue';

function onScroll(e: Event): void {
  const el = e.target as HTMLElement;

  el.dataset.scrollTop = String(el.scrollTop);
}

export default <Directive>{
  created(el: HTMLElement) {
    el.addEventListener('scroll', onScroll);
  },
  unmounted(el: HTMLElement) {
    el.removeEventListener('scroll', onScroll);
  },
};
