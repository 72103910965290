<template>
  <div class="reject-panel">
    <div class="reject-panel-inner">
      <div class="count" data-test-id="count__product-invoice">
        {{ $t('modal.invoice.defect.selected') }}:
        {{ $t('pluralization.product', selectedAmount) }}
      </div>
      <div class="reject-controls">
        <UiDropdown
          v-if="canMarkDefects"
          placement="topRight"
          :trigger="['click']"
          destroy-popup-on-hide
        >
          <UiButton type="default" class="reject-panel-set-defect">
            {{ $t('modal.invoice.defect.mark defect') }}
            <icon-ui name="navigation/chevron-down_line" size="12" />
          </UiButton>
          <template #overlay>
            <UiMenu class="reject-type-menu">
              <UiMenuItem
                v-for="item in defectReasons"
                :key="item.id"
                @click.stop="setDefect(item.id)"
              >
                {{ item.title }}
              </UiMenuItem>
              <UiMenuItem
                v-if="canBulkUndoDefect"
                key="undo"
                class="action-undo-defect"
                @click.stop="unsetDefect"
              >
                {{ $t('modal.invoice.defect.types.undo mark defect') }}
              </UiMenuItem>
            </UiMenu>
          </template>
        </UiDropdown>
        <UiButton
          type="primary"
          danger
          class="bulk-delete"
          data-test-id="button__delete-item"
          @click="isDeleteInvoiceItemConfirmVisible = true"
        >
          {{ $t('modal.invoice.defect.delete') }}
        </UiButton>
      </div>
      <UiButton
        type="default"
        data-test-id="button__cancel"
        class="cancel-button"
        @click="onCancelSelection"
      >
        {{ $t('modal.invoice.defect.cancel') }}
      </UiButton>
    </div>
    <ConfirmModal
      v-model="isDeleteInvoiceItemConfirmVisible"
      :handler="onBulkDelete"
    >
      <template #header>{{ $t('confirm.invoice sku.title') }}</template>
      {{ $t('confirm.invoice sku.body') }}
      <template #confirm>{{ $t('button.confirm delete') }}</template>
    </ConfirmModal>
  </div>
</template>

<script setup lang="ts">
import { UpOutlined } from '@ant-design/icons-vue';
import { onMounted, PropType, ref } from 'vue';

import { DefectReason } from '@/api';
import { useAuth } from '@/features/Auth';
import { EIntroKeys, useFeatureIntro } from '@/features/FeatureIntro';
import { ConfirmModal, UiButton, UiDropdown, UiMenu, UiMenuItem } from '@/ui';

type Props = {
  selectedAmount: number;
  onBulkDelete: () => Promise<any>;
  onCancelSelection: () => void;
  defectReasons: DefectReason[];
  canBulkUndoDefect: boolean;
};

withDefaults(defineProps<Props>(), {
  selectedAmount: 0,
  onCancelSelection: () => {},
  canBulkUndoDefect: false,
});

const emit = defineEmits<{
  (e: 'set', payload: string): void;
  (e: 'unset'): void;
}>();

const [showChangeDefectIntro] = useFeatureIntro(
  EIntroKeys.INVOICE_CHANGE_DEFECT,
);

const { canMarkDefects } = useAuth();
const isDeleteInvoiceItemConfirmVisible = ref(false);

const setDefect = (reasonId: string) => {
  emit('set', reasonId);
};

const unsetDefect = () => {
  emit('unset');
};

onMounted(() => {
  showChangeDefectIntro();
});
</script>

<style lang="stylus" scoped>
.reject-panel-inner
  display flex
  align-items center
  padding 16px
  gap 32px
  border 1px solid #F0F0F0
  background White()

.count
  font-size 14px
  line-height 30px
  flex-shrink 0
  display flex
  align-items top

.reject-controls
  display flex
  align-items center
  gap 16px
  margin-left 32px


.cancel-button
  margin-left auto
</style>
