/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PhotoRequestPhotoLinkChange
 */
export interface PhotoRequestPhotoLinkChange {
    /**
     * Старая ссылка на фото
     * @type {string}
     * @memberof PhotoRequestPhotoLinkChange
     */
    old: string | null;
    /**
     * Новая ссылка на фото
     * @type {string}
     * @memberof PhotoRequestPhotoLinkChange
     */
    _new: string | null;
}

export function PhotoRequestPhotoLinkChangeFromJSON(json: any): PhotoRequestPhotoLinkChange {
    return PhotoRequestPhotoLinkChangeFromJSONTyped(json, false);
}

export function PhotoRequestPhotoLinkChangeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhotoRequestPhotoLinkChange {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'old': json['old'],
        '_new': json['new'],
    };
}

export function PhotoRequestPhotoLinkChangeToJSON(value?: PhotoRequestPhotoLinkChange | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'old': value.old,
        'new': value._new,
    };
}

