import {
  inject,
  nextTick,
  onMounted,
  onUnmounted,
  provide,
  Ref,
  ref,
  watch,
} from 'vue';

type Wrapper = HTMLElement | null | undefined;

export const useIsInView = (el: Ref<HTMLElement>, callback: () => void) => {
  const wrapper = inject<Ref<Wrapper>>('wrapper');

  const observer = ref<IntersectionObserver>(
    new IntersectionObserver(() => {}, {
      root: wrapper?.value,
      threshold: 0,
    }),
  );

  const initializeObserver = (root: HTMLElement) => {
    observer.value = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            nextTick(callback);
          }
        });
      },
      {
        root,
        threshold: 0,
      },
    );
  };

  const observeIntersection = (el: HTMLElement) => {
    observer.value?.observe(el);
  };

  watch(
    () => wrapper,
    (val) => {
      if (val?.value) {
        initializeObserver(val.value);
      }
    },
  );
  watch(el, (val) => {
    if (val) {
      observeIntersection(val);
    }
  });

  onMounted(() => {
    if (wrapper?.value) {
      initializeObserver(wrapper.value);
    }

    if (el.value) {
      observeIntersection(el.value);
    }
  });

  onUnmounted(() => {
    observer.value?.disconnect();
  });
};

export const useIsInViewWrapper = (wrapper: Ref<Wrapper>) => {
  provide('wrapper', wrapper);
};
