/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InvoiceType,
    InvoiceTypeFromJSON,
    InvoiceTypeFromJSONTyped,
    InvoiceTypeToJSON,
} from './InvoiceType';
import {
    PaymentRate,
    PaymentRateFromJSON,
    PaymentRateFromJSONTyped,
    PaymentRateToJSON,
} from './PaymentRate';
import {
    ShootingType,
    ShootingTypeFromJSON,
    ShootingTypeFromJSONTyped,
    ShootingTypeToJSON,
} from './ShootingType';
import {
    SkuEventInvoice,
    SkuEventInvoiceFromJSON,
    SkuEventInvoiceFromJSONTyped,
    SkuEventInvoiceToJSON,
} from './SkuEventInvoice';
import {
    SkuEventPhotoRequest,
    SkuEventPhotoRequestFromJSON,
    SkuEventPhotoRequestFromJSONTyped,
    SkuEventPhotoRequestToJSON,
} from './SkuEventPhotoRequest';
import {
    SkuEventReservation,
    SkuEventReservationFromJSON,
    SkuEventReservationFromJSONTyped,
    SkuEventReservationToJSON,
} from './SkuEventReservation';
import {
    SkuPhotoRequestDefect,
    SkuPhotoRequestDefectFromJSON,
    SkuPhotoRequestDefectFromJSONTyped,
    SkuPhotoRequestDefectToJSON,
} from './SkuPhotoRequestDefect';

/**
 * 
 * @export
 * @interface SkuEventData
 */
export interface SkuEventData {
    /**
     * 
     * @type {number}
     * @memberof SkuEventData
     */
    id: number;
    /**
     * 
     * @type {InvoiceType}
     * @memberof SkuEventData
     */
    type: InvoiceType;
    /**
     * 
     * @type {PaymentRate}
     * @memberof SkuEventData
     */
    itemPaymentRate?: PaymentRate;
    /**
     * 
     * @type {Array<ShootingType>}
     * @memberof SkuEventData
     */
    shootingTypes?: Array<ShootingType>;
    /**
     * 
     * @type {Array<ShootingType>}
     * @memberof SkuEventData
     */
    chosenShootingTypes?: Array<ShootingType>;
    /**
     * 
     * @type {Array<ShootingType>}
     * @memberof SkuEventData
     */
    categoryShootingTypes?: Array<ShootingType>;
    /**
     * Список типов дефектов
     * @type {Array<SkuPhotoRequestDefect>}
     * @memberof SkuEventData
     */
    defects?: Array<SkuPhotoRequestDefect>;
    /**
     * 
     * @type {string}
     * @memberof SkuEventData
     */
    reservationId: string;
    /**
     * 
     * @type {string}
     * @memberof SkuEventData
     */
    status: string;
}

export function SkuEventDataFromJSON(json: any): SkuEventData {
    return SkuEventDataFromJSONTyped(json, false);
}

export function SkuEventDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): SkuEventData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': InvoiceTypeFromJSON(json['type']),
        'itemPaymentRate': !exists(json, 'itemPaymentRate') ? undefined : PaymentRateFromJSON(json['itemPaymentRate']),
        'shootingTypes': !exists(json, 'shootingTypes') ? undefined : ((json['shootingTypes'] as Array<any>).map(ShootingTypeFromJSON)),
        'chosenShootingTypes': !exists(json, 'chosenShootingTypes') ? undefined : ((json['chosenShootingTypes'] as Array<any>).map(ShootingTypeFromJSON)),
        'categoryShootingTypes': !exists(json, 'categoryShootingTypes') ? undefined : ((json['categoryShootingTypes'] as Array<any>).map(ShootingTypeFromJSON)),
        'defects': !exists(json, 'defects') ? undefined : ((json['defects'] as Array<any>).map(SkuPhotoRequestDefectFromJSON)),
        'reservationId': json['reservationId'],
        'status': json['status'],
    };
}

export function SkuEventDataToJSON(value?: SkuEventData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': InvoiceTypeToJSON(value.type),
        'itemPaymentRate': PaymentRateToJSON(value.itemPaymentRate),
        'shootingTypes': value.shootingTypes === undefined ? undefined : ((value.shootingTypes as Array<any>).map(ShootingTypeToJSON)),
        'chosenShootingTypes': value.chosenShootingTypes === undefined ? undefined : ((value.chosenShootingTypes as Array<any>).map(ShootingTypeToJSON)),
        'categoryShootingTypes': value.categoryShootingTypes === undefined ? undefined : ((value.categoryShootingTypes as Array<any>).map(ShootingTypeToJSON)),
        'defects': value.defects === undefined ? undefined : ((value.defects as Array<any>).map(SkuPhotoRequestDefectToJSON)),
        'reservationId': value.reservationId,
        'status': value.status,
    };
}

