<template>
  <tr v-if="hasMore" ref="element">
    <td :colspan="colspan" class="loader-cell">
      <div
        class="table-data-loader flex items-center justify-center"
        data-test-id="loader__table"
      >
        <linear-loader />
      </div>
    </td>
  </tr>
</template>
<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';

import { useIsInView } from '@/composables/useIsInView';
import { LinearLoader } from '@/ui';

export default defineComponent({
  components: {
    LinearLoader,
  },
  props: {
    onEnter: {
      type: Function as PropType<() => void>,
      default: () => {},
    },
    colspan: {
      type: Number,
      required: true,
    },
    hasMore: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const element = ref();

    useIsInView(element, props.onEnter);

    return { element };
  },
});
</script>
<style lang="stylus" scoped>
.loader-cell
  padding 0!important
.table-data-loader
  height 40px
  background-color White()
</style>
