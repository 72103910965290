/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PhotoSize,
    PhotoSizeFromJSON,
    PhotoSizeToJSON,
} from '../models';

export interface GetPhotoPreviewRequest {
    filePath: string;
    photoSize?: PhotoSize;
}

/**
 * 
 */
export class PhotoApi extends runtime.BaseAPI {

    /**
     * Получить превью фото
     */
    async getPhotoPreviewRaw(requestParameters: GetPhotoPreviewRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.filePath === null || requestParameters.filePath === undefined) {
            throw new runtime.RequiredError('filePath','Required parameter requestParameters.filePath was null or undefined when calling getPhotoPreview.');
        }

        const queryParameters: any = {};

        if (requestParameters.filePath !== undefined) {
            queryParameters['filePath'] = requestParameters.filePath;
        }

        if (requestParameters.photoSize !== undefined) {
            queryParameters['photoSize'] = requestParameters.photoSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/photo/preview`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Получить превью фото
     */
    async getPhotoPreview(requestParameters: GetPhotoPreviewRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.getPhotoPreviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
