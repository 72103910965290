import debounce from 'lodash.debounce';
import { onMounted, onUnmounted } from 'vue';

import { DEBOUNCE_DELAY } from '@/features/Common';

import { EBarcodeEvents, TBarcodeCallback } from './barcode';

export function useBarcodeScan(
  type: EBarcodeEvents,
  callback: TBarcodeCallback,
  manualInitialization?: boolean,
  errorCallback?: TBarcodeCallback,
) {
  const debouncedCallback = debounce(callback, DEBOUNCE_DELAY, {
    leading: true,
    trailing: false,
  });

  const debouncedErrorCallback = debounce(
    errorCallback || (() => {}),
    DEBOUNCE_DELAY,
    {
      leading: true,
      trailing: false,
    },
  );

  const subscribe = () => {
    eventManager.on(type, debouncedCallback);
    eventManager.on(EBarcodeEvents.ERROR, debouncedErrorCallback);
  };

  const unsubscribe = () => {
    eventManager.off(type, debouncedCallback);
    eventManager.off(EBarcodeEvents.ERROR, debouncedErrorCallback);
  };

  onMounted(() => {
    if (!manualInitialization) {
      subscribe();
    }
  });

  onUnmounted(() => {
    unsubscribe();
  });

  return [subscribe, unsubscribe];
}
