<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

import { AppRoutes } from '@/router';
import { UiButton, UiResult } from '@/ui';

const router = useRouter();
const { t } = useI18n();

const backHandle = () => {
  const backRoute = router.resolve({
    path: `${router.options.history.state.back}`,
  });

  router.push(backRoute.meta.parent ?? AppRoutes.invoices);
};
</script>

<template>
  <ui-result :status="404" title="404" :sub-title="t('page not found')">
    <div class="flex justify-center">
      <ui-button type="primary" @click="backHandle">
        {{ $t('button.back') }}
      </ui-button>
    </div>
  </ui-result>
</template>
