import debounce from 'lodash.debounce';
import { onUnmounted, reactive, ref, watch } from 'vue';

import {
  Box,
  boxesApi,
  Invoice,
  invoiceApi,
  InvoiceListResponse,
  InvoiceStatus,
  InvoiceType,
  SortInvoices,
} from '@/api';
import { TSorting } from '@/components/table/types';
import { useFilters } from '@/composables/useFilters';
import { useQuery } from '@/composables/useQuery';
import { isTransferBoxBarcode } from '@/features/Barcode';
import { DEBOUNCE_DELAY } from '@/features/Common';
import {
  ENTITY_INITIAL_STATE,
  PAGE_SIZE,
  TPageableEntity,
} from '@/features/Common';
import { EInvoicePlacement } from '@/pages/invoices/types';
import { clone, groupBy, keyBy, keys, uniq, uniqBy } from '@/utils/collection';

import { StatusFilter } from './types';

type TInvoicesState = {
  [InvoiceType.Acceptance]: TPageableEntity<Invoice>;
  [InvoiceType.Sending]: TPageableEntity<Invoice>;
};

type TBoxesState = {
  entries: Record<number, Box>;
};

const TYPES = [InvoiceType.Acceptance, InvoiceType.Sending];

const invoices = reactive<TInvoicesState>({
  [InvoiceType.Acceptance]: clone(ENTITY_INITIAL_STATE),
  [InvoiceType.Sending]: clone(ENTITY_INITIAL_STATE),
});

const tableGroups = ref({});
const boxes = reactive<TBoxesState>({ entries: {} });
const loadError = ref(false);
const loaded = ref(false);
const sorting = reactive<TSorting>({});
const { query, resetQuery } = useQuery();

const { handleFilterChange, isFilterSelected, filters, resetFilters } =
  useFilters<StatusFilter>();

interface GetBoxesParams {
  barcode?: string;
  ids?: Array<number>;
  page?: number;
  size?: number;
}

const getBoxes = async (
  requestParameters: GetBoxesParams,
): Promise<Array<Box>> => {
  const response = await boxesApi.getBoxes({
    page: 0,
    size: 1,
    ...requestParameters,
  });

  return response.items;
};

const getBoxesByIds = async (ids: Array<number>) => {
  return await getBoxes({
    ids: ids,
    size: ids.length,
  });
};

const getBoxById = async (boxId: number) => {
  const boxes = await getBoxes({
    ids: [boxId],
  });

  if (!boxes[0]) {
    throw new Error('Not valid box id');
  }

  return boxes[0];
};

const getBoxByBarcode = async (barcode: string) => {
  const boxes = await getBoxes({
    barcode,
  });

  if (!boxes[0]) {
    throw new Error('Not valid box barcode');
  }

  return boxes[0];
};

export function useInvoicesApi() {
  const handleInvoicesResponse = (
    response: InvoiceListResponse,
    type: InvoiceType,
  ) => {
    if (response) {
      invoices[type].page = response.page + 1;
      invoices[type].items = uniqBy(
        [...invoices[type].items, ...response.items],
        (item) => String(item.id),
      );
      invoices[type].entries = {
        ...invoices[type].entries,
        ...keyBy(response.items, (item) => String(item.id)),
      };
      invoices[type].hasMore = invoices[type].page < response.totalPages;
      invoices[type].totalCount = response.totalItems;
    }
  };

  const handleInvoiceResponse = (
    invoice: Invoice,
    position: EInvoicePlacement = EInvoicePlacement.LEADING,
  ) => {
    const filteredStatuses = filters.map((f) => f.status);

    if (filteredStatuses.length && !filteredStatuses.includes(invoice.status)) {
      return deleteInvoice(invoice);
    }

    const index = invoices[invoice.type].items.findIndex(
      (item) => item.id === invoice.id,
    );

    if (index !== -1) {
      switch (position) {
        case EInvoicePlacement.INPLACE: {
          invoices[invoice.type].items[index] = invoice;
          break;
        }

        default: {
          invoices[invoice.type].items = invoices[invoice.type].items.filter(
            (item) => item.id !== invoice.id,
          );
        }
      }
    } else {
      invoices[invoice.type].totalCount += 1;
    }

    switch (position) {
      case EInvoicePlacement.TRAILING: {
        invoices[invoice.type].items.push(invoice);
        break;
      }

      case EInvoicePlacement.INPLACE: {
        if (index !== -1) {
          break;
        }
      }

      // eslint-disable-next-line no-fallthrough
      case EInvoicePlacement.LEADING: {
        invoices[invoice.type].items.unshift(invoice);
        break;
      }
    }

    invoices[invoice.type].entries[invoice.id] = invoice;

    return invoice;
  };

  const requestBoxes = async (response: InvoiceListResponse) => {
    const existingBoxes = Object.values(boxes.entries).map((box) => box.id);
    const newBoxIds = response.items.map((invoice) => invoice.boxId);

    const idsToRequest = uniq(
      newBoxIds.filter((boxId) => !existingBoxes.includes(boxId)),
    );

    if (!idsToRequest.length) {
      return;
    }

    const newBoxes = await getBoxesByIds(idsToRequest);

    boxes.entries = {
      ...boxes.entries,
      ...keyBy(newBoxes, (box) => String(box.id)),
    };
  };

  const getInvoiceIdFromBoxBarcode = async (barcode: string) => {
    const {
      items: [box],
    } = await boxesApi.getBoxes({ barcode, page: 0, size: 1 });

    if (!box) return undefined;

    boxes.entries[box.id] = box;

    const {
      items: [invoice],
    } = await invoiceApi.invoiceList({
      boxId: box.id,
      page: 0,
      size: 1,
    });

    return invoice?.id;
  };

  const getInvoicesByType = async (
    type: InvoiceType,
    controller: AbortController | null = null,
  ) => {
    loadError.value = false;

    try {
      const status = filters
        ? groupBy(filters, (filter) => filter.type)[type]?.map(
            (filter) => filter.status,
          )
        : undefined;

      invoices[type].loading = true;

      const { field, direction } = sorting;
      let sort;

      if (field && direction) {
        sort = [`${field}${direction}` as SortInvoices];
      }

      const trimmedQuery = query.value.trim();
      const isBarcode = isTransferBoxBarcode(trimmedQuery);
      const isNumeric = !isNaN(Number(trimmedQuery));

      if (trimmedQuery && !isNumeric && !isBarcode) {
        const emptyResponse: InvoiceListResponse = {
          items: [],
          page: 0,
          pageSize: 0,
          totalPages: 0,
          totalItems: 0,
        };

        handleInvoicesResponse(emptyResponse, type);

        return;
      }

      const id = isBarcode
        ? await getInvoiceIdFromBoxBarcode(trimmedQuery)
        : Number(trimmedQuery) || undefined;

      const response = await invoiceApi.invoiceList(
        {
          page: invoices[type].page,
          size: PAGE_SIZE,
          ids: id ? [id] : undefined,
          type,
          status,
          sort,
        },
        {
          signal: controller?.signal,
        },
      );

      requestBoxes(response);
      handleInvoicesResponse(response, type);
    } catch (err) {
      if (err instanceof Error && err.name !== 'AbortError') {
        loadError.value = true;
      }
    } finally {
      invoices[type].loading = false;
    }
  };

  const invoiceExistsForStatus = async (
    type: InvoiceType,
    status: InvoiceStatus,
  ) => {
    const response = await invoiceApi.invoiceList({
      page: 0,
      size: 1,
      type: type,
      status: [status],
      ids: undefined,
    });

    if (response) {
      return response.totalItems > 0;
    }
  };

  const resetInvoices = () => {
    invoices[InvoiceType.Acceptance] = clone(ENTITY_INITIAL_STATE);
    invoices[InvoiceType.Sending] = clone(ENTITY_INITIAL_STATE);
    loaded.value = false;
    loadError.value = false;
  };

  const expressAccept = async (id: number, callback?: () => void) => {
    const invoice = await invoiceApi.changeInvoiceStatus({
      invoiceID: id,
      changeInvoiceStatus: { status: InvoiceStatus.Incoming },
    });

    const index = invoices[InvoiceType.Acceptance].items.findIndex(
      (invoice) => invoice.id === id,
    );

    // todo: change back to "= invoice" when backend will send correct expectedAmount
    invoices[InvoiceType.Acceptance].items[index].status = invoice.status;
    invoices[InvoiceType.Acceptance].entries[invoice.id].status =
      invoice.status;
    callback && callback();
  };

  const getInvoiceByBarcode = async (barcode: string) => {
    const box = await getBoxByBarcode(barcode);

    const invoices = await invoiceApi.invoiceList({
      boxId: box.id,
      page: 0,
      size: 1,
    });

    const invoice = invoices.items[0];

    boxes.entries[box.id] = box;

    return handleInvoiceResponse(invoice);
  };

  const getInvoiceById = async (id: number) => {
    const invoice = await invoiceApi.getInvoiceByID({ invoiceID: id });

    if (invoice.boxId) {
      const box = await getBoxById(invoice.boxId);

      boxes.entries = {
        ...boxes.entries,
        [box.id]: box,
      };
    }

    return handleInvoiceResponse(invoice, EInvoicePlacement.INPLACE);
  };

  const deleteInvoice = (invoice: Invoice) => {
    invoices[invoice.type].items = invoices[invoice.type].items.filter(
      (i) => i.id !== invoice.id,
    );
    delete invoices[invoice.type].entries[invoice.id];
    invoices[invoice.type].totalCount -= 1;
  };

  let abortInvoicesController: AbortController;

  const getInitialInvoices = () => {
    loaded.value = false;

    if (abortInvoicesController) {
      abortInvoicesController.abort();
    }

    abortInvoicesController = new AbortController();

    resetInvoices();
    let types: InvoiceType[];

    if (filters?.length) {
      types = uniq(filters.map((filter) => filter.type));
    } else {
      types = TYPES;
    }

    const ingoredTypes = TYPES.filter((type) => !types.includes(type));

    ingoredTypes.forEach((type) => (invoices[type].hasMore = false));
    Promise.all(
      types.map((type) => getInvoicesByType(type, abortInvoicesController)),
    );

    if (abortInvoicesController) {
      loaded.value = true;
    }
  };

  const popInvoice = (id: number) => {
    keys(invoices).forEach((key) => {
      const item = invoices[key].items.find((invoice) => invoice.id === id);

      if (item) {
        invoices[key].items = [
          item,
          ...invoices[key].items.filter((invoice) => invoice.id !== id),
        ];
      }
    });
  };

  const incrementTotalItems = (type: InvoiceType) => {
    invoices[type].totalCount += 1;
  };

  const handleLoadMoreByType = (type: InvoiceType) => () => {
    if (invoices[type].hasMore && !invoices[type].loading) {
      getInvoicesByType(type);
    }
  };

  watch(filters, getInitialInvoices);
  watch(sorting, getInitialInvoices);
  watch(query, debounce(getInitialInvoices, DEBOUNCE_DELAY));

  const updateTableGroups = (data) => {
    tableGroups.value = { ...tableGroups.value, ...data };
  };

  const resetInvoicesState = () => {
    resetFilters();
    resetQuery();
    resetInvoices();
  };

  onUnmounted(() => {
    if (abortInvoicesController) {
      abortInvoicesController.abort();
    }
  });

  return {
    invoices,
    boxes,
    loadError,
    loaded,
    getInitialInvoices,
    getInvoicesByType,
    expressAccept,
    getInvoiceByBarcode,
    getInvoiceById,
    invoiceExistsForStatus,
    resetInvoices,
    popInvoice,
    incrementTotalItems,
    handleFilterChange,
    isFilterSelected,
    handleLoadMoreByType,
    query,
    deleteInvoice,
    sorting,
    tableGroups,
    updateTableGroups,
    resetInvoicesState,
  };
}
