/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaymentRate,
    PaymentRateFromJSON,
    PaymentRateFromJSONTyped,
    PaymentRateToJSON,
} from './PaymentRate';
import {
    ReservationStatus,
    ReservationStatusFromJSON,
    ReservationStatusFromJSONTyped,
    ReservationStatusToJSON,
} from './ReservationStatus';
import {
    ResponseError,
    ResponseErrorFromJSON,
    ResponseErrorFromJSONTyped,
    ResponseErrorToJSON,
} from './ResponseError';
import {
    SkuStatus,
    SkuStatusFromJSON,
    SkuStatusFromJSONTyped,
    SkuStatusToJSON,
} from './SkuStatus';

/**
 * 
 * @export
 * @interface ReservationItem
 */
export interface ReservationItem {
    /**
     * 
     * @type {string}
     * @memberof ReservationItem
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof ReservationItem
     */
    skuId: number;
    /**
     * 
     * @type {number}
     * @memberof ReservationItem
     */
    skuExternalId: number;
    /**
     * 
     * @type {string}
     * @memberof ReservationItem
     */
    skuTitle: string;
    /**
     * timestamp UTC
     * @type {number}
     * @memberof ReservationItem
     */
    createdAt: number;
    /**
     * timestamp UTC
     * @type {number}
     * @memberof ReservationItem
     */
    updatedAt: number;
    /**
     * 
     * @type {PaymentRate}
     * @memberof ReservationItem
     */
    paymentRate: PaymentRate;
    /**
     * 
     * @type {ReservationStatus}
     * @memberof ReservationItem
     */
    status: ReservationStatus;
    /**
     * 
     * @type {SkuStatus}
     * @memberof ReservationItem
     */
    skuStatus: SkuStatus;
    /**
     * 
     * @type {ResponseError}
     * @memberof ReservationItem
     */
    error?: ResponseError;
}

export function ReservationItemFromJSON(json: any): ReservationItem {
    return ReservationItemFromJSONTyped(json, false);
}

export function ReservationItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReservationItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'skuId': json['skuId'],
        'skuExternalId': json['skuExternalId'],
        'skuTitle': json['skuTitle'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'paymentRate': PaymentRateFromJSON(json['paymentRate']),
        'status': ReservationStatusFromJSON(json['status']),
        'skuStatus': SkuStatusFromJSON(json['skuStatus']),
        'error': !exists(json, 'error') ? undefined : ResponseErrorFromJSON(json['error']),
    };
}

export function ReservationItemToJSON(value?: ReservationItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'skuId': value.skuId,
        'skuExternalId': value.skuExternalId,
        'skuTitle': value.skuTitle,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'paymentRate': PaymentRateToJSON(value.paymentRate),
        'status': ReservationStatusToJSON(value.status),
        'skuStatus': SkuStatusToJSON(value.skuStatus),
        'error': ResponseErrorToJSON(value.error),
    };
}

