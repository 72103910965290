/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Тип накладной
 * @export
 * @enum {string}
 */
export enum InvoiceType {
    Acceptance = 'ACCEPTANCE',
    Sending = 'SENDING',
    Unspecified = 'UNSPECIFIED'
}

export function InvoiceTypeFromJSON(json: any): InvoiceType {
    return InvoiceTypeFromJSONTyped(json, false);
}

export function InvoiceTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceType {
    return json as InvoiceType;
}

export function InvoiceTypeToJSON(value?: InvoiceType | null): any {
    return value as any;
}

