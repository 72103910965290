<template>
  <IsInViewWrapper ref="container" class="table-container">
    <UiTable
      :columns="columns"
      :data-source="isLoading ? Array(20).fill({}) : reservations"
      :empty-text="$t('reservationsPage.table.emptyText')"
      bordered
      @change="handleChange"
    >
      <template v-if="error" #empty>
        <LoadError @retry="refetch()" />
      </template>

      <template #bodyCell="{ column, record }">
        <Skeleton v-if="isLoading" :paragraph="false" active />

        <template v-else>
          <RouterLink
            v-if="column.key === 'id'"
            :to="'/reservations/' + record.id"
            class="link"
          >
            <span>
              {{ record.id.slice(0, 8) }}
              ...
            </span>

            <PaymentRateChip :rate="record.paymentRate" />
          </RouterLink>

          <ul v-else-if="column.key === 'statuses'" class="statuses">
            <li
              v-for="{status} in (<ReservationInfo>record).statusInfoList"
              :key="status"
            >
              <ReservationStatusIcon :status="status" />
            </li>
          </ul>

          <template v-else-if="column.key === 'user'">
            {{ record.user.firstName }} {{ record.user.lastName }}
          </template>

          <template v-else-if="['createdAt', 'updatedAt'].includes(column.key)">
            <UiDate :value="record[column.key]" />
          </template>
        </template>
      </template>
    </UiTable>

    <template v-if="reservations.length && hasNextPage">
      <InfiniteLoader :has-more="hasNextPage" @enter="fetchNextPage" />
      <LinearLoader class="loader" />
    </template>
  </IsInViewWrapper>
</template>

<script setup lang="ts">
import { Skeleton, TableColumnType } from 'ant-design-vue';
import { ComponentPublicInstance, computed, h, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { ReservationInfo, ReservationStatus } from '@/api';
import InfiniteLoader from '@/components/InfiniteLoader.vue';
import IsInViewWrapper from '@/components/IsInViewWrapper.vue';
import PaymentRateChip from '@/components/PaymentRateChip.vue';
import ReservationStatusIcon from '@/components/ReservationStatusIcon.vue';
import { usePreserveScroll } from '@/composables/usePreserveScroll';
import { SortingDirection } from '@/composables/useSorting';
import { useReservations } from '@/features/Reservation';
import {
  Date as UiDate,
  LinearLoader,
  LoadError,
  UiTable,
  UiTableProps,
} from '@/ui';

const { t } = useI18n();

const {
  reservations,
  error,
  isLoading,
  hasNextPage,
  filters,
  sortingField,
  sortingDirection,
  fetchNextPage,
  refetch,
  setSorting,
  setFilters,
} = useReservations();

const statuses = [
  ReservationStatus.Created,
  ReservationStatus.Reserved,
  ReservationStatus.InShipping,
  ReservationStatus.Canceled,
  ReservationStatus.Completed,
];

const statusFilters = statuses.map((value) => ({
  value,
  text: h(
    'span',
    { class: 'inline-flex w-full justify-between items-center gap-2' },
    [
      t(`reservationStatus.${value}`),
      h(ReservationStatusIcon, { status: value, tooltip: false }),
    ],
  ),
}));

const userFilters = [
  {
    value: 'mine',
    text: t('reservationsPage.table.filters.mine'),
  },
];

const sortableFields = ['createdAt', 'updatedAt'] as const;

type SortableField = (typeof sortableFields)[number];

const toSortOrder = (direction: SortingDirection) =>
  direction === SortingDirection.ASC ? 'ascend' : 'descend';

const columns = computed<TableColumnType[]>(() =>
  [
    { key: 'id', width: 200 },
    {
      key: 'statuses',
      width: 190,
      filters: statusFilters,
      defaultFilteredValue: filters.value.statuses,
    },
    { key: 'itemsCount', width: 150, minWidth: 110, align: 'right' },
    {
      key: 'user',
      filters: userFilters,
      defaultFilteredValue: filters.value.user,
    },
    { key: 'createdAt', width: 150 },
    { key: 'updatedAt', width: 150 },
  ].map((x) => ({
    ...(x as TableColumnType),
    dataIndex: x.key,
    title: t(`reservationsPage.table.columns.${x.key}`),
    sorter: sortableFields.includes(x.key as SortableField),
    sortDirections: ['descend', 'ascend', 'descend'],
    defaultSortOrder:
      x.key === sortingField.value && sortingDirection.value
        ? toSortOrder(sortingDirection.value)
        : undefined,
  })),
);

const container = ref<ComponentPublicInstance>();

const handleChange: UiTableProps['onChange'] = (_, filters, sorter) => {
  const { order, columnKey } = Array.isArray(sorter) ? sorter[0] : sorter;

  const orderMap: Record<NonNullable<typeof order>, SortingDirection> = {
    ascend: SortingDirection.ASC,
    descend: SortingDirection.DESC,
  };

  setFilters({
    ...filters,
  });

  setSorting(columnKey as SortableField, orderMap[order ?? '']);

  container.value?.$el.scrollTo({ top: 0 });
};

usePreserveScroll(container);
</script>

<style lang="stylus" scoped>


.table-container
  height 100%
  overflow auto
  border 1px solid #f0f0f0
  border-radius 5px
  background-color #fff

  :deep()
    .ant-table-thead .ant-table-cell
      background-color gray-2

    table
      border-top 0 !important

    .ant-skeleton-title
      margin 0


:global(.ant-dropdown-menu-title-content)
  display flex
  align-items center

:global(.ant-dropdown-menu-title-content > span)
  flex 1

.link
  position absolute
  inset 0
  padding 16px
  display grid
  grid-template-columns 1fr 44px
  justify-items flex-start
  align-items center
  gap 12px


.statuses
  margin 0
  display flex
  align-items center
  gap 4px

.loader
  width 100%
  display flex
  align-items center
</style>
