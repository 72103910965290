import { computed, onMounted, reactive, ref, watch } from 'vue';

import {
  Box,
  isIncompatibleShootingTypeError,
  PaymentRate,
  photoBoxesApi,
  photoRequestApi,
  PhotoRequestDetailed,
  PhotoRequestItem,
  PhotoRequestItemResponse,
  PhotoRequestStatus,
  SkuListEntry,
} from '@/api';
import { ENTITY_INITIAL_STATE, TPageableEntity } from '@/features/Common';
import { clone, keyBy, uniqBy } from '@/utils/collection';

type TState = {
  request: PhotoRequestDetailed | null;
  box: Box | null;
  requestItems: TPageableEntity<PhotoRequestItem>;
  loading: boolean;
  boxLoading: boolean;
};

const initialState: TState = {
  request: null,
  box: null,
  requestItems: clone(ENTITY_INITIAL_STATE),
  loading: true,
  boxLoading: true,
};

const REQUEST_ITEMS_PAGE_SIZE = 30;

const availableTariffs = [
  PaymentRate.Free,
  PaymentRate.Normal,
  PaymentRate.Higher,
];

export const useDraftRequest = (photoRequest: PhotoRequestDetailed) => {
  const state = reactive<TState>(
    clone({
      ...initialState,
      requestItems: clone(ENTITY_INITIAL_STATE),
    }),
  );

  const shootingType = computed(() => state.request?.shootingTypes[0]);

  const paymentRate = computed((): PaymentRate | null =>
    state.requestItems.items.reduce(
      (acc: PaymentRate | null, { paymentRate }) => {
        if (availableTariffs.includes(paymentRate)) acc = paymentRate;

        return acc;
      },
      null,
    ),
  );

  const untiePhotoBox = async (photoBoxID: number) => {
    await photoBoxesApi.untiePhotoBoxes({
      photoBoxID,
    });
  };

  const deletePhotoRequestItem = async (itemID: number) => {
    if (!state.request) return;

    await photoRequestApi.deletePhotoRequestItem({
      itemID,
    });

    state.requestItems.totalCount -= 1;

    state.requestItems.items = state.requestItems.items.filter(
      (i) => i.id !== itemID,
    );

    if (!state.requestItems.items.length) {
      state.request.shootingTypes = [];
    }
  };

  const handleRequestItemsResponse = (response: PhotoRequestItemResponse) => {
    if (!response) return;

    state.requestItems.page = response.page + 1;

    state.requestItems.items = uniqBy(
      [...state.requestItems.items, ...response.items],
      (item) => String(item.id),
    );

    state.requestItems.entries = {
      ...state.requestItems.entries,
      ...keyBy(response.items, (item) => String(item.id)),
    };

    state.requestItems.hasMore = state.requestItems.page < response.totalPages;

    state.requestItems.totalCount = response.totalItems;
  };

  const incompatibleSku = ref<SkuListEntry | null>(null);

  const addItemWithSku = async (
    sku: SkuListEntry,
    changeShootingTypes = false,
  ) => {
    if (!state.request) throw Error('Photo request is not exists');

    try {
      const photoRequestItem = await photoRequestApi.addItemToPhotoRequest(
        {
          photoRequestID: state.request.id,
          photoRequestItemTemplate: {
            skuID: sku.id,
            changeShootingTypes,
          },
        },
        {
          afterResponseHookOptions: {
            handleBadResponse: ({ responseError }) =>
              responseError?.code !== 1025,
          },
        },
      );

      const idx = state.requestItems.items.findIndex(
        (item) => item.id === photoRequestItem.id,
      );

      if (idx === -1) {
        state.requestItems.totalCount += 1;
        state.requestItems.items.unshift({ ...photoRequestItem, sku });
      } else {
        state.requestItems.items[idx] = { ...photoRequestItem, sku };
      }

      if (!state.request.shootingTypes.length) {
        state.request.shootingTypes = [photoRequestItem.shootingTypes[0]];
      }
    } catch (error) {
      if (await isIncompatibleShootingTypeError(error)) {
        incompatibleSku.value = sku;
      } else {
        throw error;
      }
    }
  };

  const save = async () => {
    if (!state.request) throw Error('Photo request is not exists');

    const { id } = state.request;

    const photoRequest = await photoRequestApi.changePhotoRequestStatus({
      photoRequestID: id,
      changePhotoRequestStatus: {
        status: PhotoRequestStatus.Created,
      },
    });

    state.request = photoRequest;

    return photoRequest;
  };

  const resetRequestItems = () => {
    state.requestItems = clone(ENTITY_INITIAL_STATE);
  };

  const resetState = () => {
    state.request = null;
    state.box = null;
    resetRequestItems();
  };

  const loadItems = async () => {
    state.requestItems.loading = true;

    const response = await photoRequestApi.photoRequestItemsList({
      photoRequestID: photoRequest.id,
      page: state.requestItems.page,
      size: REQUEST_ITEMS_PAGE_SIZE,
    });

    state.requestItems.loading = false;

    handleRequestItemsResponse(response);
  };

  const updatePaymentRate = (selectedPaymentRate: PaymentRate) => {
    if (!selectedPaymentRate || !availableTariffs.includes(selectedPaymentRate))
      return;

    state.requestItems.items = state.requestItems.items.map((item) => ({
      ...item,
      paymentRate: selectedPaymentRate,
    }));
  };

  const handleLoadMore = async () => {
    if (
      state.requestItems.items.length > 0 &&
      state.requestItems.hasMore &&
      !state.requestItems.loading
    ) {
      await loadItems();
    }
  };

  const loadBox = async () => {
    state.boxLoading = true;

    if (!state.request?.photoBoxID) return;

    const boxes = await photoBoxesApi.getPhotoBoxes({
      page: 0,
      size: 10,
      ids: [state.request.photoBoxID],
    });

    state.box = boxes.items[0];

    state.boxLoading = false;
  };

  const totalCount = computed(() => state.requestItems.totalCount);

  watch(totalCount, (newCount) => {
    if (!state.request) return;
    state.request.itemCount = newCount;
  });

  watch(
    () => state.request?.photoBoxID,
    () => {
      loadBox();
    },
  );

  onMounted(async () => {
    state.loading = true;
    state.request = photoRequest;
    await loadItems();
    state.loading = false;
  });

  return {
    state,
    totalCount,
    paymentRate,
    deletePhotoRequestItem,
    updatePaymentRate,
    addItemWithSku,
    save,
    resetState,
    untiePhotoBox,
    handleLoadMore,
    incompatibleSku,
    shootingType,
  };
};
