<template>
  <div class="amount-wrapper">
    <span v-if="hasActualAmount" class="amount">{{ actualAmount }}</span>
    <span v-if="hasActualAmount">/</span>
    <span class="amount">{{ expectedAmount }}</span>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  actualAmount: {
    type: Number,
    default: undefined,
  },
  expectedAmount: {
    type: Number,
    default: 0,
  },
});

const hasActualAmount = computed(() =>
  typeof props.actualAmount !== 'undefined' ? true : false,
);
</script>
<style lang="stylus" scoped>
.amount-wrapper
  display flex
  justify-content flex-end
  align-items center
  width 100%
  padding 0 10px

.amount
  padding 0 4px
</style>
