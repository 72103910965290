/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FailedReservation,
    FailedReservationFromJSON,
    FailedReservationFromJSONTyped,
    FailedReservationToJSON,
} from './FailedReservation';
import {
    FailedSku,
    FailedSkuFromJSON,
    FailedSkuFromJSONTyped,
    FailedSkuToJSON,
} from './FailedSku';
import {
    ReservedSku,
    ReservedSkuFromJSON,
    ReservedSkuFromJSONTyped,
    ReservedSkuToJSON,
} from './ReservedSku';
import {
    UpdateItemsStatusType,
    UpdateItemsStatusTypeFromJSON,
    UpdateItemsStatusTypeFromJSONTyped,
    UpdateItemsStatusTypeToJSON,
} from './UpdateItemsStatusType';

/**
 * 
 * @export
 * @interface UpdateReservationItemsStatus
 */
export interface UpdateReservationItemsStatus {
    /**
     * 
     * @type {UpdateItemsStatusType}
     * @memberof UpdateReservationItemsStatus
     */
    type: UpdateItemsStatusType;
    /**
     * 
     * @type {string}
     * @memberof UpdateReservationItemsStatus
     */
    idempotencyKey: string;
    /**
     * 
     * @type {Array<ReservedSku>}
     * @memberof UpdateReservationItemsStatus
     */
    reservedSkus?: Array<ReservedSku>;
    /**
     * 
     * @type {Array<FailedSku>}
     * @memberof UpdateReservationItemsStatus
     */
    failedSkus?: Array<FailedSku>;
    /**
     * 
     * @type {Array<FailedReservation>}
     * @memberof UpdateReservationItemsStatus
     */
    failedReservations?: Array<FailedReservation>;
    /**
     * 
     * @type {string}
     * @memberof UpdateReservationItemsStatus
     */
    cancelledItemWMSReservationKey?: string;
}

export function UpdateReservationItemsStatusFromJSON(json: any): UpdateReservationItemsStatus {
    return UpdateReservationItemsStatusFromJSONTyped(json, false);
}

export function UpdateReservationItemsStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateReservationItemsStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': UpdateItemsStatusTypeFromJSON(json['type']),
        'idempotencyKey': json['idempotencyKey'],
        'reservedSkus': !exists(json, 'reservedSkus') ? undefined : ((json['reservedSkus'] as Array<any>).map(ReservedSkuFromJSON)),
        'failedSkus': !exists(json, 'failedSkus') ? undefined : ((json['failedSkus'] as Array<any>).map(FailedSkuFromJSON)),
        'failedReservations': !exists(json, 'failedReservations') ? undefined : ((json['failedReservations'] as Array<any>).map(FailedReservationFromJSON)),
        'cancelledItemWMSReservationKey': !exists(json, 'cancelledItemWMSReservationKey') ? undefined : json['cancelledItemWMSReservationKey'],
    };
}

export function UpdateReservationItemsStatusToJSON(value?: UpdateReservationItemsStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': UpdateItemsStatusTypeToJSON(value.type),
        'idempotencyKey': value.idempotencyKey,
        'reservedSkus': value.reservedSkus === undefined ? undefined : ((value.reservedSkus as Array<any>).map(ReservedSkuToJSON)),
        'failedSkus': value.failedSkus === undefined ? undefined : ((value.failedSkus as Array<any>).map(FailedSkuToJSON)),
        'failedReservations': value.failedReservations === undefined ? undefined : ((value.failedReservations as Array<any>).map(FailedReservationToJSON)),
        'cancelledItemWMSReservationKey': value.cancelledItemWMSReservationKey,
    };
}

