/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PhotoRequestItemDefectReasonType {
    Photo = 'PHOTO',
    Retouch = 'RETOUCH',
    Autoretouch = 'AUTORETOUCH',
    Product = 'PRODUCT'
}

export function PhotoRequestItemDefectReasonTypeFromJSON(json: any): PhotoRequestItemDefectReasonType {
    return PhotoRequestItemDefectReasonTypeFromJSONTyped(json, false);
}

export function PhotoRequestItemDefectReasonTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhotoRequestItemDefectReasonType {
    return json as PhotoRequestItemDefectReasonType;
}

export function PhotoRequestItemDefectReasonTypeToJSON(value?: PhotoRequestItemDefectReasonType | null): any {
    return value as any;
}

