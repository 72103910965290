/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Короб
 * @export
 * @interface Box
 */
export interface Box {
    /**
     * 
     * @type {number}
     * @memberof Box
     */
    id: number;
    /**
     * ШК
     * @type {string}
     * @memberof Box
     */
    barcode: string;
    /**
     * Дата создания короба
     * @type {number}
     * @memberof Box
     */
    created: number;
}

export function BoxFromJSON(json: any): Box {
    return BoxFromJSONTyped(json, false);
}

export function BoxFromJSONTyped(json: any, ignoreDiscriminator: boolean): Box {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'barcode': json['barcode'],
        'created': json['created'],
    };
}

export function BoxToJSON(value?: Box | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'barcode': value.barcode,
        'created': value.created,
    };
}

