/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BulkDeleteInvoiceItemsRequest
 */
export interface BulkDeleteInvoiceItemsRequest {
    /**
     * Идентификаторы элементов накладной
     * @type {Array<number>}
     * @memberof BulkDeleteInvoiceItemsRequest
     */
    itemIds: Array<number>;
}

export function BulkDeleteInvoiceItemsRequestFromJSON(json: any): BulkDeleteInvoiceItemsRequest {
    return BulkDeleteInvoiceItemsRequestFromJSONTyped(json, false);
}

export function BulkDeleteInvoiceItemsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkDeleteInvoiceItemsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'itemIds': json['itemIds'],
    };
}

export function BulkDeleteInvoiceItemsRequestToJSON(value?: BulkDeleteInvoiceItemsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'itemIds': value.itemIds,
    };
}

