import { App, createApp, ref } from 'vue';

import { useAuth } from '@/features/Auth';
import { i18n } from '@/locales';
import { localStorageService } from '@/services/localStorage';

import { EIntroKeys, introOptions, IStep } from './data';
import IntroContainer from './ui/index.vue';
export { EIntroKeys } from './data';

export const useFeatureIntro = (key: EIntroKeys) => {
  const { account } = useAuth();
  const userId = account.value?.userInfo.id;
  const userIntroKey = `intro_user_${userId}`;
  const hasFirstLaunch = localStorageService.get('photostudio_first_launch');

  const {
    showSkip,
    skipLabel,
    delay,
    steps,
    dependentIntros,
    forceFirstLaunch,
  } = introOptions[key];

  const step = ref(0);
  const stepsHistory: IStep[] = [];

  const pushHistory = (step: IStep) => {
    stepsHistory.push(step);
  };

  const popHistory = (): IStep | undefined => {
    return stepsHistory.pop();
  };

  const hasNext = () => step.value < steps.length - 1;
  const hasPrev = () => !!stepsHistory.length;

  const openNextStep = (specificStep?: number) => {
    pushHistory(steps[step.value]);

    if (specificStep) {
      step.value = specificStep;
      createIntro();
    } else if (hasNext()) {
      ++step.value;
      createIntro();
    } else {
      resetStep();
    }
  };

  const openPrevStep = () => {
    if (hasPrev()) {
      const prevStep = popHistory();

      const stepIndex = steps.findIndex(
        (el) => el.fragment === prevStep?.fragment,
      );

      step.value = ~stepIndex ? stepIndex : 0;

      createIntro();
    }
  };

  const resetStep = () => {
    step.value = 0;
  };

  let wrapper = null as HTMLElement | null;
  const introContainer = document.createElement('div');
  let intro = null as App<Element> | null;

  const destroyIntro = () => {
    if (intro) {
      intro.unmount();
      intro = null;

      if (wrapper) {
        introContainer.innerHTML = '';
      }
    }
  };

  const skipIntro = (deep = true) => {
    destroyIntro();
    resetStep();
    saveIntroState(deep ? dependentIntros : null);
  };

  const createIntro = () => {
    if (intro) {
      destroyIntro();
    }

    const currentStep = steps[step.value];

    setTimeout(() => {
      wrapper = document.querySelector(currentStep.element);

      if (wrapper) {
        wrapper.style.position = 'relative';
        intro = createApp(IntroContainer, {
          wrapper: currentStep.popupWrapper ? wrapper : null,
          trigger: currentStep.trigger,
          placement: currentStep.placement,
          fragment: currentStep.fragment,
          arrowAtCenter: currentStep.arrowAtCenter,
          showSkip,
          skipLabel,
          hasNext:
            currentStep.hideNext || currentStep.hideControls
              ? false
              : hasNext(),
          hasPrev: hasPrev(),
          hideControls: currentStep.hideControls,
          openNextStep,
          openPrevStep,
          skip: skipIntro,
          navigation: currentStep.navigation,
          destroy: destroyIntro,
        }).use(i18n);

        intro.mount(introContainer);
        wrapper.append(introContainer);

        wrapper.scrollIntoView({ block: 'center' });
      }
    }, delay);
  };

  const getUserIntrosState = () => {
    const introStorage = localStorageService.get(userIntroKey);

    return introStorage ? JSON.parse(introStorage) : {};
  };

  const saveIntroState = (chainKeys?: EIntroKeys[] | null) => {
    const introsState = getUserIntrosState();

    introsState[key] = 1;

    if (chainKeys) {
      chainKeys.forEach((chainKey) => {
        introsState[chainKey] = 1;
      });
    }

    localStorageService.set(userIntroKey, JSON.stringify(introsState));
  };

  const clearIntroState = () => {
    const introsState = getUserIntrosState();

    delete introsState[key];
    localStorageService.set(userIntroKey, JSON.stringify(introsState));
  };

  const showIntro = () => {
    const introsState = getUserIntrosState();
    const isShown = introsState[key];

    if ((hasFirstLaunch || forceFirstLaunch) && !isShown) {
      createIntro();

      if (!hasNext()) {
        saveIntroState();
      }
    }
  };

  return [showIntro, clearIntroState];
};
