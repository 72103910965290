<template>
  <th :class="`th ${column.Cell}`">
    <div class="wrapper" @click.stop="handleClick">
      <slot />
      <icon-ui
        v-if="isAsc"
        name="navigation/chevron-up_line"
        size="24"
        class="control"
      />
      <icon-ui
        v-else-if="isDesc"
        name="navigation/chevron-down_line"
        size="24"
        class="control"
      />
    </div>
  </th>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';

import { safeInject } from '@/utils/inject';

import { useTableSorting } from '../composables';
import { SortingKey } from '../symbols';
import { ESortingDirection, IColumn, TSorting } from '../types';

export default defineComponent({
  props: {
    column: {
      type: Object as PropType<IColumn>,
      required: true,
    },
  },
  setup(props) {
    const sorting = safeInject<TSorting>(SortingKey);
    const { handleSortingChange } = useTableSorting(sorting);
    const handleClick = handleSortingChange(props.column.id);

    const isAsc = computed(
      () =>
        sorting.field === props.column.id &&
        sorting.direction === ESortingDirection.ASC,
    );

    const isDesc = computed(
      () =>
        sorting.field === props.column.id &&
        sorting.direction === ESortingDirection.DESC,
    );

    return { handleClick, isAsc, isDesc };
  },
});
</script>

<style lang="stylus" scoped>
.wrapper
  display grid
  grid-template-columns 1fr 15px
  align-items center

th.th
  padding 0 10px

th.date-cell
  width 153px

.control
  cursor pointer
  fill Gray()
  transition fill 0.2s ease

  &:hover
    fill Gray(DK28)

.control.active
  fill Black()
</style>
