<template>
  <Tooltip v-if="identifier" :text="$t('sku.print qrcode')" placement="bottom">
    <UiButton
      ref="qrcodeButton"
      type="default"
      class="qrcode-button"
      @click="printQrcode(identifier), blurQrcodeButton()"
    >
      <template #icon>
        <QrcodeOutlined />
      </template>
    </UiButton>
  </Tooltip>
</template>

<script setup lang="ts">
import { QrcodeOutlined } from '@ant-design/icons-vue';
import { ref } from 'vue';

import { useQRCodePrint } from '@/composables/usePrint';
import { Tooltip, UiButton } from '@/ui';

interface Props {
  identifier: string | undefined;
}

defineProps<Props>();

const qrcodeButton = ref();

const blurQrcodeButton = () => {
  qrcodeButton.value.$el.blur();
};

const printQrcode = useQRCodePrint();
</script>
