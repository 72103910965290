<template>
  <div v-if="hasMore" ref="element" />
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';

import { useIsInView } from '@/composables/useIsInView';

export default defineComponent({
  props: {
    onEnter: {
      type: Function as PropType<() => void>,
      default: () => {},
    },
    hasMore: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const element = ref();

    useIsInView(element, props.onEnter);

    return { element };
  },
});
</script>
