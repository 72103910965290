/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReservationItemTemplateV1,
    ReservationItemTemplateV1FromJSON,
    ReservationItemTemplateV1FromJSONTyped,
    ReservationItemTemplateV1ToJSON,
} from './ReservationItemTemplateV1';

/**
 * 
 * @export
 * @interface ReservationTemplateV1
 */
export interface ReservationTemplateV1 {
    /**
     * 
     * @type {Array<ReservationItemTemplateV1>}
     * @memberof ReservationTemplateV1
     */
    items: Array<ReservationItemTemplateV1>;
}

export function ReservationTemplateV1FromJSON(json: any): ReservationTemplateV1 {
    return ReservationTemplateV1FromJSONTyped(json, false);
}

export function ReservationTemplateV1FromJSONTyped(json: any, ignoreDiscriminator: boolean): ReservationTemplateV1 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(ReservationItemTemplateV1FromJSON)),
    };
}

export function ReservationTemplateV1ToJSON(value?: ReservationTemplateV1 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(ReservationItemTemplateV1ToJSON)),
    };
}

