/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReservationStatus,
    ReservationStatusFromJSON,
    ReservationStatusFromJSONTyped,
    ReservationStatusToJSON,
} from './ReservationStatus';

/**
 * 
 * @export
 * @interface ReservationStatusInfo
 */
export interface ReservationStatusInfo {
    /**
     * 
     * @type {ReservationStatus}
     * @memberof ReservationStatusInfo
     */
    status: ReservationStatus;
    /**
     * 
     * @type {number}
     * @memberof ReservationStatusInfo
     */
    count: number;
}

export function ReservationStatusInfoFromJSON(json: any): ReservationStatusInfo {
    return ReservationStatusInfoFromJSONTyped(json, false);
}

export function ReservationStatusInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReservationStatusInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': ReservationStatusFromJSON(json['status']),
        'count': json['count'],
    };
}

export function ReservationStatusInfoToJSON(value?: ReservationStatusInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': ReservationStatusToJSON(value.status),
        'count': value.count,
    };
}

