/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Seller,
    SellerFromJSON,
    SellerFromJSONTyped,
    SellerToJSON,
} from './Seller';
import {
    SellerListResponseAllOf,
    SellerListResponseAllOfFromJSON,
    SellerListResponseAllOfFromJSONTyped,
    SellerListResponseAllOfToJSON,
} from './SellerListResponseAllOf';

/**
 * 
 * @export
 * @interface SellerListResponse
 */
export interface SellerListResponse {
    /**
     * Список продавцов
     * @type {Array<Seller>}
     * @memberof SellerListResponse
     */
    items: Array<Seller>;
}

export function SellerListResponseFromJSON(json: any): SellerListResponse {
    return SellerListResponseFromJSONTyped(json, false);
}

export function SellerListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SellerListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(SellerFromJSON)),
    };
}

export function SellerListResponseToJSON(value?: SellerListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(SellerToJSON)),
    };
}

