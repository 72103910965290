/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InvoiceItem,
    InvoiceItemFromJSON,
    InvoiceItemFromJSONTyped,
    InvoiceItemToJSON,
} from './InvoiceItem';
import {
    InvoiceItemListResponseAllOf,
    InvoiceItemListResponseAllOfFromJSON,
    InvoiceItemListResponseAllOfFromJSONTyped,
    InvoiceItemListResponseAllOfToJSON,
} from './InvoiceItemListResponseAllOf';
import {
    PaginationResponse,
    PaginationResponseFromJSON,
    PaginationResponseFromJSONTyped,
    PaginationResponseToJSON,
} from './PaginationResponse';

/**
 * 
 * @export
 * @interface InvoiceItemListResponse
 */
export interface InvoiceItemListResponse {
    /**
     * количество элементов на одной странице
     * @type {number}
     * @memberof InvoiceItemListResponse
     */
    pageSize: number;
    /**
     * номер страницы
     * @type {number}
     * @memberof InvoiceItemListResponse
     */
    page: number;
    /**
     * общее количество страниц
     * @type {number}
     * @memberof InvoiceItemListResponse
     */
    totalPages: number;
    /**
     * общее количество элементов
     * @type {number}
     * @memberof InvoiceItemListResponse
     */
    totalItems: number;
    /**
     * Список элементов накладной
     * @type {Array<InvoiceItem>}
     * @memberof InvoiceItemListResponse
     */
    items: Array<InvoiceItem>;
}

export function InvoiceItemListResponseFromJSON(json: any): InvoiceItemListResponse {
    return InvoiceItemListResponseFromJSONTyped(json, false);
}

export function InvoiceItemListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceItemListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageSize': json['pageSize'],
        'page': json['page'],
        'totalPages': json['totalPages'],
        'totalItems': json['totalItems'],
        'items': ((json['items'] as Array<any>).map(InvoiceItemFromJSON)),
    };
}

export function InvoiceItemListResponseToJSON(value?: InvoiceItemListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pageSize': value.pageSize,
        'page': value.page,
        'totalPages': value.totalPages,
        'totalItems': value.totalItems,
        'items': ((value.items as Array<any>).map(InvoiceItemToJSON)),
    };
}

