/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaginationResponse,
    PaginationResponseFromJSON,
    PaginationResponseFromJSONTyped,
    PaginationResponseToJSON,
} from './PaginationResponse';
import {
    SkuEvent,
    SkuEventFromJSON,
    SkuEventFromJSONTyped,
    SkuEventToJSON,
} from './SkuEvent';
import {
    SkuEventListResponseAllOf,
    SkuEventListResponseAllOfFromJSON,
    SkuEventListResponseAllOfFromJSONTyped,
    SkuEventListResponseAllOfToJSON,
} from './SkuEventListResponseAllOf';

/**
 * 
 * @export
 * @interface SkuEventListResponse
 */
export interface SkuEventListResponse {
    /**
     * количество элементов на одной странице
     * @type {number}
     * @memberof SkuEventListResponse
     */
    pageSize: number;
    /**
     * номер страницы
     * @type {number}
     * @memberof SkuEventListResponse
     */
    page: number;
    /**
     * общее количество страниц
     * @type {number}
     * @memberof SkuEventListResponse
     */
    totalPages: number;
    /**
     * общее количество элементов
     * @type {number}
     * @memberof SkuEventListResponse
     */
    totalItems: number;
    /**
     * История изменений товара
     * @type {Array<SkuEvent>}
     * @memberof SkuEventListResponse
     */
    items: Array<SkuEvent>;
}

export function SkuEventListResponseFromJSON(json: any): SkuEventListResponse {
    return SkuEventListResponseFromJSONTyped(json, false);
}

export function SkuEventListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SkuEventListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageSize': json['pageSize'],
        'page': json['page'],
        'totalPages': json['totalPages'],
        'totalItems': json['totalItems'],
        'items': ((json['items'] as Array<any>).map(SkuEventFromJSON)),
    };
}

export function SkuEventListResponseToJSON(value?: SkuEventListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pageSize': value.pageSize,
        'page': value.page,
        'totalPages': value.totalPages,
        'totalItems': value.totalItems,
        'items': ((value.items as Array<any>).map(SkuEventToJSON)),
    };
}

