import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { PhotoRequestDetailed, SkuDetailed } from '@/api';
import { useAuth } from '@/features/Auth';
import { isSKUBarcode } from '@/features/Barcode';
import { notification } from '@/features/Notifications';

import {
  startPhotoShoot as baseStart,
  StartPhotoShootError,
  StartPhotoShootReturn,
} from '../core';
import { useServerTime } from './useServerTime';

const { setServerTime } = useServerTime();
const lastScanned = ref<StartPhotoShootReturn>();

export const usePhotographerFlow = () => {
  const { t } = useI18n();
  const { accountId, isPhotographer } = useAuth();

  const showError = (...keys: string[]) => {
    notification.error(keys.map((k) => t(k)).join(' '));
  };

  const handleError = (e: StartPhotoShootError) => {
    switch (e.type) {
      case 'SkuNotFound':
        return showError('sku not found');
      case 'SkuNotInRequest':
        return showError('sku not in request');
      case 'SkuNotInCurrentRequest':
        return showError('sku not in current request');
      case 'RequestIsTaken':
        return showError('request is taken');
      case 'InvalidStatus':
        return showError(
          'request has status',
          `photorequestStatus.${e.status}`,
        );
      default:
        return showError('unknown error');
    }
  };

  const startPhotoShoot = async (
    skuOrBarcode: SkuDetailed | string,
    request?: PhotoRequestDetailed,
  ) => {
    if (!isPhotographer.value || !accountId.value) return null;

    if (typeof skuOrBarcode === 'string' && !isSKUBarcode(skuOrBarcode))
      return showError('invalid sku barcode');

    const result = await baseStart(skuOrBarcode, accountId.value, request);

    if (result.isErr) {
      handleError(result.error);

      return null;
    }

    const data = result.value;
    const time = data.requestItem.photoDates.at(-1);

    lastScanned.value = data;

    if (time) setServerTime(time);

    return data;
  };

  return { startPhotoShoot, lastScanned: computed(() => lastScanned.value) };
};
