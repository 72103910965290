import { SkuStatus } from '@/api';

export const skuStatusColor: Record<string, string> = {
  [SkuStatus.Unknown]: 'LightGray',
  [SkuStatus.Created]: 'LightGray',
  [SkuStatus.Waiting]: 'PaleOrange',
  [SkuStatus.Accepted]: 'LightGrayishOrange',
  [SkuStatus.Sent]: 'LightGrayishCyan',
  [SkuStatus.Missing]: 'VeryPalePink',
};
