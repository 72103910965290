/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReservationDetailed,
    ReservationDetailedFromJSON,
    ReservationDetailedFromJSONTyped,
    ReservationDetailedToJSON,
} from './ReservationDetailed';
import {
    ReservationItemsResponse,
    ReservationItemsResponseFromJSON,
    ReservationItemsResponseFromJSONTyped,
    ReservationItemsResponseToJSON,
} from './ReservationItemsResponse';

/**
 * 
 * @export
 * @interface ReservationDetailedWithItems
 */
export interface ReservationDetailedWithItems {
    /**
     * 
     * @type {ReservationDetailed}
     * @memberof ReservationDetailedWithItems
     */
    reservation: ReservationDetailed;
    /**
     * 
     * @type {ReservationItemsResponse}
     * @memberof ReservationDetailedWithItems
     */
    items: ReservationItemsResponse;
}

export function ReservationDetailedWithItemsFromJSON(json: any): ReservationDetailedWithItems {
    return ReservationDetailedWithItemsFromJSONTyped(json, false);
}

export function ReservationDetailedWithItemsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReservationDetailedWithItems {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reservation': ReservationDetailedFromJSON(json['reservation']),
        'items': ReservationItemsResponseFromJSON(json['items']),
    };
}

export function ReservationDetailedWithItemsToJSON(value?: ReservationDetailedWithItems | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reservation': ReservationDetailedToJSON(value.reservation),
        'items': ReservationItemsResponseToJSON(value.items),
    };
}

