/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SkuPhotoInfoList,
    SkuPhotoInfoListFromJSON,
    SkuPhotoInfoListFromJSONTyped,
    SkuPhotoInfoListToJSON,
} from './SkuPhotoInfoList';

/**
 * 
 * @export
 * @interface StudioPhotoInfoResponse
 */
export interface StudioPhotoInfoResponse {
    /**
     * 
     * @type {Array<SkuPhotoInfoList>}
     * @memberof StudioPhotoInfoResponse
     */
    skuPhotos: Array<SkuPhotoInfoList>;
}

export function StudioPhotoInfoResponseFromJSON(json: any): StudioPhotoInfoResponse {
    return StudioPhotoInfoResponseFromJSONTyped(json, false);
}

export function StudioPhotoInfoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StudioPhotoInfoResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'skuPhotos': ((json['skuPhotos'] as Array<any>).map(SkuPhotoInfoListFromJSON)),
    };
}

export function StudioPhotoInfoResponseToJSON(value?: StudioPhotoInfoResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'skuPhotos': ((value.skuPhotos as Array<any>).map(SkuPhotoInfoListToJSON)),
    };
}

