/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ImageStatus,
    ImageStatusFromJSON,
    ImageStatusFromJSONTyped,
    ImageStatusToJSON,
} from './ImageStatus';

/**
 * 
 * @export
 * @interface Image
 */
export interface Image {
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    urlPrefix: string;
    /**
     * 
     * @type {number}
     * @memberof Image
     */
    ordering: number;
    /**
     * 
     * @type {ImageStatus}
     * @memberof Image
     */
    status: ImageStatus;
}

export function ImageFromJSON(json: any): Image {
    return ImageFromJSONTyped(json, false);
}

export function ImageFromJSONTyped(json: any, ignoreDiscriminator: boolean): Image {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'urlPrefix': json['urlPrefix'],
        'ordering': json['ordering'],
        'status': ImageStatusFromJSON(json['status']),
    };
}

export function ImageToJSON(value?: Image | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'urlPrefix': value.urlPrefix,
        'ordering': value.ordering,
        'status': ImageStatusToJSON(value.status),
    };
}

