import { onMounted, reactive } from 'vue';

import {
  AddDefectEntryRequest,
  Defect,
  DefectDetailed,
  DefectReason,
  skuApi,
} from '@/api';
import {
  lockHooklessModals,
  unlockHooklessModals,
} from '@/features/Modals/useModal';

interface IState {
  loading: boolean;
  defectReasons: DefectReason[] | null;
  defectDetailed: DefectDetailed | null;
  error: boolean;
}

const initialState: IState = {
  loading: false,
  defectReasons: null,
  defectDetailed: null,
  error: false,
};

export function useSkuDefectApi() {
  const state = reactive<IState>({ ...initialState });

  const init = async () => {
    state.loading = true;
    state.defectReasons = await getSkuDefectReasons();
    state.loading = false;
  };

  const getSkuDefectReasons = async (): Promise<DefectReason[] | null> => {
    try {
      lockHooklessModals();
      state.error = false;
      const defectReasons = await skuApi.getSkuDefectReasons();

      if (!defectReasons.length) {
        return null;
      } else {
        return defectReasons;
      }
    } catch {
      state.error = true;

      return null;
    } finally {
      unlockHooklessModals();
    }
  };

  const addSkuDefect = async (skuID: number, defect: Defect) => {
    const defectEntry: AddDefectEntryRequest = {
      skuID,
      defect,
    };

    try {
      lockHooklessModals();
      const response = await skuApi.addDefectEntry(defectEntry);

      if (!response) {
        return null;
      } else {
        state.defectDetailed = response;
      }
    } finally {
      unlockHooklessModals();
    }
  };

  const resetState = () => {
    state.loading = initialState.loading;
    state.defectReasons = initialState.defectReasons;
    state.defectDetailed = initialState.defectDetailed;
    state.error = initialState.error;
  };

  onMounted(() => {
    init();
  });

  return {
    state,
    getSkuDefectReasons,
    addSkuDefect,
    resetState,
  };
}
