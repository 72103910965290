import { Options } from 'ky';

import { afterResponseHook, beforeRequestHook, beforeRetryHook } from './hooks';

export const ApiConfiguration: Options = {
  retry: {
    limit: 2,
    statusCodes: [401, 408, 413, 429, 500, 502, 503, 504],
  },
  credentials: 'include',
  hooks: {
    beforeRequest: [beforeRequestHook],
    beforeRetry: [beforeRetryHook],
    afterResponse: [afterResponseHook],
  },
};
