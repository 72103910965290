import { Directive } from 'vue';

const focus: Directive = {
  mounted(el) {
    if (!(el instanceof HTMLElement)) return;

    const input =
      el instanceof HTMLInputElement ? el : el.querySelector('input');

    input?.focus();
  },
};

export default focus;
