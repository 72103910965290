/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EventType,
    EventTypeFromJSON,
    EventTypeFromJSONTyped,
    EventTypeToJSON,
} from './EventType';
import {
    PhotoRequestEventData,
    PhotoRequestEventDataFromJSON,
    PhotoRequestEventDataFromJSONTyped,
    PhotoRequestEventDataToJSON,
} from './PhotoRequestEventData';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface PhotoRequestEvent
 */
export interface PhotoRequestEvent {
    /**
     * 
     * @type {string}
     * @memberof PhotoRequestEvent
     */
    id: string;
    /**
     * 
     * @type {EventType}
     * @memberof PhotoRequestEvent
     */
    type: EventType;
    /**
     * 
     * @type {number}
     * @memberof PhotoRequestEvent
     */
    createdAt: number;
    /**
     * 
     * @type {User}
     * @memberof PhotoRequestEvent
     */
    user: User;
    /**
     * 
     * @type {PhotoRequestEventData}
     * @memberof PhotoRequestEvent
     */
    data: PhotoRequestEventData;
}

export function PhotoRequestEventFromJSON(json: any): PhotoRequestEvent {
    return PhotoRequestEventFromJSONTyped(json, false);
}

export function PhotoRequestEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhotoRequestEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': EventTypeFromJSON(json['type']),
        'createdAt': json['createdAt'],
        'user': UserFromJSON(json['user']),
        'data': PhotoRequestEventDataFromJSON(json['data']),
    };
}

export function PhotoRequestEventToJSON(value?: PhotoRequestEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': EventTypeToJSON(value.type),
        'createdAt': value.createdAt,
        'user': UserToJSON(value.user),
        'data': PhotoRequestEventDataToJSON(value.data),
    };
}

