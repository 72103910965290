/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum SkuStatus {
    Unknown = 'UNKNOWN',
    Created = 'CREATED',
    Waiting = 'WAITING',
    Missing = 'MISSING',
    Accepted = 'ACCEPTED',
    Sent = 'SENT'
}

export function SkuStatusFromJSON(json: any): SkuStatus {
    return SkuStatusFromJSONTyped(json, false);
}

export function SkuStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): SkuStatus {
    return json as SkuStatus;
}

export function SkuStatusToJSON(value?: SkuStatus | null): any {
    return value as any;
}

