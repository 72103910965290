import { ShootingType } from '@/api';
import { TChipColor } from '@/ui';

export const shootingTypeToColor: Record<ShootingType, TChipColor> = {
  [ShootingType.Model]: 'purple',
  [ShootingType.Detailed]: 'yellow',
  [ShootingType.Panoramic]: 'orange',
  [ShootingType.Render]: 'green',
  [ShootingType.Infographic]: 'pink',
  [ShootingType.Unspecified]: 'gray',
};

export const getTypeChipColor = (types: ShootingType[]): TChipColor => {
  if (types.includes(ShootingType.Render)) {
    return 'green';
  }

  if (types.includes(ShootingType.Model)) {
    return 'purple';
  }

  return 'yellow';
};
