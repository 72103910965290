<template>
  <div>
    <div class="content-header">
      <Count
        v-if="!state.loading"
        :actual-amount="props.state.invoice?.actualAmount"
        :expected-amount="props.state.invoice?.expectedAmount"
        :has-count="hasCount"
      />

      <ul v-if="requests.length && isDone" class="requests text-m">
        <li v-for="{ id } in requests" :key="id">
          <router-link
            :to="{ name: 'Requests', params: { id } }"
            target="_blank"
          >
            {{ `${$t('request')} №${id}` }}
          </router-link>
        </li>
      </ul>
    </div>
    <div
      ref="tableWrapperRef"
      :class="['content-table', { stretched: !props.itemsSource.items.length }]"
    >
      <UiTable
        :columns="columns"
        :data-source="props.itemsSource.items"
        bordered
        :custom-row="handleRowClick"
        :row-class-name="
          (record) => `${props.getDefectedRowClass(record)} row__invoice`
        "
        :loading="state.loading"
      >
        <template #bodyCell="{ column, text, record }">
          <template v-if="column.key === 'barcode'">
            <barcode-cell :state="state" :record="record" />
          </template>
          <template v-if="column.key === 'sku'">
            {{ record.sku.title }}
          </template>
          <template v-if="column.key === 'photo'">
            <BaseImage :url="text" />
          </template>
          <template v-if="column.key === 'nomenclature'">
            <div class="table-nomenclature">{{ getLang(text) }}</div>
            <span v-if="record.paymentRate" class="table-payment-rate">
              <PaymentRateChip
                :rate="record.paymentRate"
                :tooltip="false"
                use-title
              />
            </span>
            <UiTag
              v-if="props.hasDefect(record)"
              class="defect-tag"
              data-test-id="tag__defect-item-invoice"
            >
              {{ props.getDefectTitle(record) }}
            </UiTag>
          </template>
          <template v-if="column.key === 'amount'">
            <template v-if="!isSending">
              <amount-cell
                :item="record"
                :is-disabled="isAccountingDisabled"
                :accept-invoice-item="acceptInvoiceItem"
              />
            </template>
            <template v-else>
              <span>{{ $t('pc.', { count: text }) }}</span>
            </template>
          </template>
        </template>
      </UiTable>
      <InfiniteLoader
        :key="props.itemsSource.page"
        :has-more="props.itemsSource.hasMore"
        :on-enter="handleInfiniteLoader"
      />
      <LinearLoader
        v-if="props.itemsSource.hasMore"
        :style="{ width: '100%', margin: '0 0 1em 0' }"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, PropType, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import {
  DefectDetailed,
  InvoiceItem,
  InvoiceStatus,
  InvoiceType,
  PhotoRequest,
} from '@/api';
import InfiniteLoader from '@/components/InfiniteLoader.vue';
import PaymentRateChip from '@/components/PaymentRateChip.vue';
import LinearLoader from '@/components/ui/linear-loader/index.vue';
import { useIsInViewWrapper } from '@/composables/useIsInView';
import { TPageableEntity } from '@/features/Common';
import BarcodeCell from '@/pages/invoice/components/barcodeCell.vue';
import Qrcode from '@/pages/sku/components/Qrcode.vue';
import { BaseImage, CopyButton, UiTable, UiTag } from '@/ui';
import { getLang } from '@/utils/lang';

import AmountCell from '../amount-cell.vue';
import { TInvoiceItemsState } from '../composables';
import Count from './count.vue';

type PropsType = {
  itemsSource: TPageableEntity<InvoiceItem>;
  hasCount: boolean;
  isSending: boolean;
  isDraft: boolean;
  isAccountingDisabled: boolean;
  handleRowClick: (row: InvoiceItem) => any;
  handleRemoveItem: (item: any) => void;
  acceptInvoiceItem: (
    itemID: number,
    actualAmount: number,
  ) => Promise<InvoiceItem | undefined>;
  handleInfiniteLoader: () => void;
  getDefectedRowClass: (record: InvoiceItem) => string;
  hasDefect: (invoiceItem: InvoiceItem) => DefectDetailed | undefined;
  getDefectTitle: (invoiceItem: InvoiceItem) => string;
  getRequests: () => Promise<PhotoRequest[]>;
  state: TInvoiceItemsState;
  // Add other props as needed
};

const props = withDefaults(defineProps<PropsType>(), {
  hasCount: false,
  isSending: false,
  isDraft: false,
  isAccountingDisabled: true,
  handleRowClick: () => {},
  handleRemoveItem: () => {},
  acceptInvoiceItem: () => Promise.resolve(undefined),
  handleInfiniteLoader: () => {},
  getRequests: () => Promise.resolve([]),
});

const { t } = useI18n();
const tableWrapperRef = ref();

useIsInViewWrapper(tableWrapperRef);

const columns = computed(() => {
  return [
    {
      title: t('photo'),
      key: 'photo',
      dataIndex: ['sku', 'photoURL'],
      width: 86,
    },
    {
      title: t('nomenclature'),
      key: 'nomenclature',
      dataIndex: ['sku', 'description'],
    },
    {
      title: 'SKU',
      key: 'sku',
      dataIndex: ['title'],
      width: 210,
    },
    {
      title: t('barcode'),
      key: 'barcode',
      dataIndex: ['sku', 'barcode'],
      width: 170,
    },
    {
      title: t('checked'),
      key: 'amount',
      dataIndex: 'expectedAmount',
      width: 160,
    },
  ];
});

const isDone = computed(
  () => props.state.invoice?.status === InvoiceStatus.Done,
);

const requests = ref<PhotoRequest[]>([]);

onMounted(async () => {
  const allRequests = await props.getRequests();

  requests.value = allRequests.filter((r) => !!r.itemCount);
});
</script>

<style lang="stylus" scoped>
.content-wrapper
  overflow hidden
  height 100%
  display flex
  flex-direction column

.content-header
  margin-top 30px
  margin-bottom 20px
  display flex
  align-items flex-start
  justify-content space-between
  gap 20px

.content-table
  max-height 100%
  overflow auto
  :deep().table
    height auto
    :deep() .ant-empty
      min-height calc(100vh - 280px)
  &.stretched
    height 100%

.action
  cursor pointer
  border none
  background none
  height 80px
  opacity 0
  visibility hidden

.table-nomenclature
  overflow-wrap anywhere

.table-payment-rate
  display inline-block
  margin-top 10px

.table-barcode
  display flex
  align-items center
  gap 8px

.qr-code-container
  margin-bottom 46px

.defect-tag
  background-color #FADB14
  margin-top 10px

:deep().row-highlighted
  background #FEFFE6

.requests
  list-style none
  display flex
  justify-content flex-end
  flex-wrap wrap
  column-gap 16px
  row-gap 8px
  color blue-6
</style>
