<template>
  <PageLayout>
    <ScannerEmulator v-if="IS_DEVELOPMENT" />

    <template #header>
      <PageHeader :title="$t('invoices')">
        <BaseFilter
          :title="$t('status')"
          :configuration="statusFilterConfiguration"
          :on-change="handleFilterChange"
          :is-selected="isFilterSelected"
          :is-disabled="invoiceForStatusNotExist"
        />
        <search
          v-model="query"
          width="270px"
          :placeholder="$t('search invoice placeholder')"
          :with-barcode="true"
          data-test-id="input__search"
        />
      </PageHeader>
    </template>

    <BaseTable
      ref="table"
      v-preserve-scroll-top
      :columns="columns"
      :data="data"
      :on-focus-row="onFocusRow"
      :on-unfocus-row="onUnfocusRow"
      :on-pop-row="popInvoice"
      :load-error="loadError"
      row-class="invoices-table-row"
      :sorting="sorting"
      row-hovered
      class="invoices-table"
    >
      <template #status-cell="{ item }">
        <router-link :to="getInvoiceRoute(item.id)">
          <chip-cell
            :color="statusToColor[item.status]"
            :value="$t(`${item.type}_${item.status}`)"
          />
        </router-link>
      </template>
      <template #date-cell="{ item }">
        <router-link :to="getInvoiceRoute(item.id)">
          <date-cell :value="item.value" />
        </router-link>
      </template>
      <template #amount-cell="{ item }">
        <router-link :to="getInvoiceRoute(item.id)">
          <amount-cell
            :actual-amount="
              item.value.type === InvoiceType.Acceptance
                ? item.value.actualAmount
                : undefined
            "
            :expected-amount="item.value.expectedAmount"
          />
        </router-link>
      </template>
      <template #text-cell="{ item }">
        <router-link :to="getInvoiceRoute(item.id)">
          <text-cell>
            {{ item.value }}
          </text-cell>
        </router-link>
      </template>

      <template #barcode-cell="{ item }">
        <router-link :to="getInvoiceRoute(item.id)">
          <text-cell>
            {{ item.value }}
          </text-cell>
        </router-link>
      </template>
    </BaseTable>
  </PageLayout>
</template>

<script lang="ts">
import { computed, ComputedRef, defineComponent, onMounted, ref } from 'vue';
import { nextTick } from 'vue';
export default defineComponent({ name: 'invoices-page' });
</script>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { onBeforeRouteLeave, useRouter } from 'vue-router';

import { Invoice, InvoiceStatus, InvoiceType } from '@/api';
import AmountCell from '@/components/table/cells/amount-cell.vue';
import ChipCell from '@/components/table/cells/chip-cell.vue';
import DateCell from '@/components/table/cells/date-cell.vue';
import TextCell from '@/components/table/cells/text-cell.vue';
import BaseTable from '@/components/table/index.vue';
import { IColumn, TRowGroup } from '@/components/table/types';
import { useEventManager } from '@/composables/useEventManager';
import { usePreserveScroll } from '@/composables/usePreserveScroll';
import { IS_DEVELOPMENT } from '@/config';
import { useAuth } from '@/features/Auth';
import { EBarcodeEvents, useBarcodeScan } from '@/features/Barcode';
import { AppEvents } from '@/features/Common';
import { notification } from '@/features/Notifications';
import { ScannerEmulator } from '@/features/ScannerEmulator';
import { AppRoutes } from '@/router/data';
import { PageHeader, PageLayout } from '@/template';
import { BaseFilter, Search } from '@/ui';
import { keys } from '@/utils/collection';

import { useInvoicesApi } from './composables';
import { statusFilterConfiguration, statusToColor } from './data';
import { StatusFilter } from './types';

const TOAST_IDS = {
  focused: 'focused_invoice',
  accepted: 'accepted_invoice',
  failed: 'failed_invoice',
  created: 'created_invoice',
};

type TBaseTable = InstanceType<typeof BaseTable>;

const { t } = useI18n();
const { canEditInvoices } = useAuth();
const table = ref<TBaseTable>();

usePreserveScroll(table);

const router = useRouter();

useBarcodeScan(EBarcodeEvents.INVOICE, (barcode) => handleBarcodeScan(barcode));

const {
  invoices,
  boxes,
  loadError,
  expressAccept,
  getInvoiceByBarcode,
  invoiceExistsForStatus,
  popInvoice,
  handleFilterChange,
  isFilterSelected,
  handleLoadMoreByType,
  query,
  sorting,
  tableGroups,
  updateTableGroups,
  loaded,
  getInitialInvoices,
  resetInvoicesState,
} = useInvoicesApi();

onBeforeRouteLeave((to, _, next) => {
  if (to.name === AppRoutes.invoice.name) return next();
  nextTick(() => resetInvoicesState());
  next();
});

const showErrorToast = () =>
  notification.error(t('notifications.invoice.not_found'), {
    key: TOAST_IDS.failed,
  });

const showAcceptedToast = (id: number) =>
  notification.success(t('notifications.invoice.accepted', { id }), {
    key: TOAST_IDS.accepted,
  });

const openInvoice = (id: number) => {
  router.push(getInvoiceRoute(id));
};

const getInvoiceRoute = (id: number) => {
  return {
    name: AppRoutes.invoice.name,
    params: {
      id,
    },
  };
};

const showFocusToast = (id: number, status: InvoiceStatus) =>
  notification.info(t('notifications.invoice.focused', { id }), {
    button: {
      text:
        status === InvoiceStatus.Created
          ? t('button.accept')
          : t('button.open'),
      callback: () =>
        status === InvoiceStatus.Created
          ? expressAccept(id, () => showAcceptedToast(id))
          : openInvoice(id),
    },
    onClose: () => table.value?.unpop(),
    key: TOAST_IDS.focused,
    duration: 7,
  });

const onFocusRow = (row: Invoice) => {
  if (row.type === InvoiceType.Acceptance) {
    showFocusToast(row.id, row.status);
  }
};

const onUnfocusRow = () => {
  notification.close(TOAST_IDS.focused);
};

const handleBarcodeScan = async (barcode: string) => {
  try {
    const invoice = await getInvoiceByBarcode(barcode);

    if (invoice) {
      table.value?.pop(invoice);
    }
  } catch {
    showErrorToast();
  }
};

const invoicesExist: Map<InvoiceType, Map<InvoiceStatus, boolean>> = new Map<
  InvoiceType,
  Map<InvoiceStatus, boolean>
>([
  [InvoiceType.Acceptance, new Map()],
  [InvoiceType.Sending, new Map()],
]);

statusFilterConfiguration.forEach(async (statusEntry) => {
  if (statusEntry.value !== undefined) {
    const status = statusEntry.value.status;
    const type = statusEntry.value.type;
    const exists = await invoiceExistsForStatus(type, status);
    const statuses = invoicesExist.get(type);

    if (statuses != undefined) {
      statuses.set(status, !exists);
    }
  }
});

const invoiceForStatusNotExist = (value: StatusFilter) => {
  const statuses = invoicesExist.get(value.type);

  if (statuses != undefined) {
    return statuses.get(value.status);
  } else {
    return false;
  }
};

const data: ComputedRef<TRowGroup<Invoice>[]> = computed(() =>
  keys(invoices)
    .map((key) => ({
      type: key,
      title: t(key),
      loading: invoices[key].loading,
      count: invoices[key].totalCount,
      items: invoices[key].items,
      hasMore: invoices[key].hasMore,
      handleLoadMore: handleLoadMoreByType(key),
      withCreation: key === InvoiceType.Sending && canEditInvoices.value,
      onCreateClick: () => {
        if (key === InvoiceType.Sending) {
          router.push({
            name: AppRoutes.invoiceCreation.name,
          });
        }
      },
      createTooltipText:
        key === InvoiceType.Sending
          ? t('create new sending invoice')
          : undefined,
    }))
    .filter((i) => i.type !== InvoiceType.Unspecified),
);

const columns: IColumn<Invoice>[] = [
  {
    Header: t('name'),
    id: 'id',
    resolver: (invoice) => ({
      value: `${t('invoice')} №${invoice.id}`,
      id: invoice.id,
    }),
    widthRatio: 20,
    Cell: 'text-cell',
  },
  {
    Header: `${t('modal.invoice.actual')} / ${t('modal.invoice.expected')}`,
    id: 'amount',
    resolver: (invoice) => ({
      value: invoice,
      id: invoice.id,
    }),
    widthRatio: '140px',
    Cell: 'amount-cell',
  },
  {
    Header: t('modal.invoice.box barcode'),
    id: 'boxId',
    resolver: (invoice) => ({
      value: boxes.entries[invoice.boxId]?.barcode,
      id: invoice.id,
    }),
    widthRatio: 20,
    Cell: 'barcode-cell',
  },
  {
    Header: t('status'),
    id: 'status',
    resolver: (invoice) => invoice,
    Cell: 'status-cell',
  },
  {
    Header: t('modal.invoice.created'),
    id: 'createdAt',
    Cell: 'date-cell',
    resolver: (invoice) => ({
      value: invoice.created,
      id: invoice.id,
    }),
    sortable: true,
  },
  {
    Header: t('modal.invoice.updated'),
    id: 'updatedAt',
    Cell: 'date-cell',
    resolver: (invoice) => ({
      value: invoice.updated,
      id: invoice.id,
    }),
    sortable: true,
  },
];

const handleTableToggle = (data: any) => {
  updateTableGroups(data);
};

onMounted(() => {
  if (!loaded.value) {
    getInitialInvoices();
  }

  eventManager.emit(AppEvents.table.open, tableGroups.value);
});

useEventManager(AppEvents.table.toggle, handleTableToggle);
</script>
<style lang="stylus" scoped>
.invoices-table
  :deep()
    th.th:first-child
      border-left 1px solid #dfe0e2
    th.th:last-child
      border-right 1px solid Gray(LT40)
</style>
