<script lang="ts">
// eslint-ignore-next-line @typescript-eslint/ban-ts-comment
import { defineComponent, h } from 'vue';

const defaultIconSize = 24;
const className = 'ui-icon';

export default defineComponent({
  name: 'icon-ui',

  inheritAttrs: false,

  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: [String, Number],
      default: defaultIconSize,
    },
  },

  data() {
    return {
      template: '',
      tagName: 'svg', // as 'svg' | 'img'
    };
  },

  watch: {
    name: {
      handler(val) {
        val && this.loadTemplate(val);
      },
      immediate: true,
    },
  },

  methods: {
    async loadTemplate(name: string) {
      if (name.match(/^(f|ht)tps?:\/\//i)) {
        this.tagName = 'img';

        return;
      } else {
        this.tagName = 'svg';
      }

      try {
        const icons = import.meta.glob('./**/*.svg', { as: 'raw' });
        const match = icons[`./${name}.svg`];

        this.template = await match();
      } catch (error) {
        console.error(`icon ${name} not found.`, error);
      }
    },
  },

  render() {
    let options = {};
    const divElement = document.createElement('div');

    if (this.tagName === 'svg') {
      divElement.innerHTML = this.template;
      const svgElement = divElement.firstChild as HTMLElement;

      const attrs = {
        class: '',
        ...(svgElement && {
          ...Array.from(svgElement.attributes ?? []).reduce(
            (acc: Record<string, unknown>, el) => {
              acc[el.name] = el.value;

              return acc;
            },
            {},
          ),
        }),

        width: this.size,
        height: this.size,

        ...this.$attrs,
      };

      options = {
        ...attrs,
        class: [className, attrs.class, svgElement?.classList?.value].join(' '),
        innerHTML: svgElement?.innerHTML,
      };
    } else {
      options = {
        width: this.size,
        height: this.size,
        src: this.name,

        ...this.$attrs,
        class: className,
      };
    }

    return h(this.tagName, options);
  },
});
</script>
