import { onMounted, reactive } from 'vue';

import {
  invoiceApi,
  InvoiceItem,
  InvoiceItemListResponse,
  InvoiceStatus,
} from '@/api';
import {
  ENTITY_INITIAL_STATE,
  PAGE_SIZE,
  TPageableEntity,
} from '@/features/Common';
import { clone, keyBy, uniq } from '@/utils/collection';

type TState = {
  items: TPageableEntity<InvoiceItem>;
};

const initialState: TState = {
  items: clone(ENTITY_INITIAL_STATE),
};

export const useDiscrepancyApi = (
  invoiceID: number,
  onUpdate: (status: InvoiceStatus) => void,
) => {
  const state = reactive<TState>(initialState);

  const handleInvoiceItemsResponse = (response: InvoiceItemListResponse) => {
    if (response) {
      state.items.page = response.page + 1;
      state.items.items = uniq([...state.items.items, ...response.items]);
      state.items.entries = {
        ...state.items.entries,
        ...keyBy(response.items, (item) => String(item.id)),
      };
      state.items.hasMore = state.items.page < response.totalPages;
      state.items.totalCount = response.totalItems;
    }
  };

  const getInvoiceItems = async () => {
    state.items.loading = true;

    const response = await invoiceApi.getInvoiceItems({
      invoiceID,
      page: state.items.page,
      size: PAGE_SIZE,
      differentAmounts: true,
    });

    state.items.loading = false;
    handleInvoiceItemsResponse(response);
  };

  const getInitialinvoiceItems = async () => {
    state.items = clone(ENTITY_INITIAL_STATE);
    getInvoiceItems();
  };

  const changeStatus = async () => {
    const invoice = await invoiceApi.changeInvoiceStatus({
      invoiceID,
      changeInvoiceStatus: { status: InvoiceStatus.Done },
    });

    onUpdate(invoice.status);
  };

  const handleLoadMore = async () => {
    if (
      state.items.items.length > 0 &&
      state.items.hasMore &&
      !state.items.loading
    ) {
      getInvoiceItems();
    }
  };

  onMounted(() => getInitialinvoiceItems());

  return {
    state,
    changeStatus,
    handleLoadMore,
  };
};
