/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ImageStatus {
    Active = 'ACTIVE',
    Archived = 'ARCHIVED',
    SkuArchived = 'SKU_ARCHIVED'
}

export function ImageStatusFromJSON(json: any): ImageStatus {
    return ImageStatusFromJSONTyped(json, false);
}

export function ImageStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImageStatus {
    return json as ImageStatus;
}

export function ImageStatusToJSON(value?: ImageStatus | null): any {
    return value as any;
}

