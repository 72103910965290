<template>
  <div class="chip-cell" data-test-id="block__info_shooting_types">
    <chip :color="color" :value="value" />
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

import { Chip } from '@/ui';

export default defineComponent({
  components: {
    Chip,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      required: true,
    },
  },
});
</script>
<style lang="stylus" scoped>
.chip-cell
  padding 16px 8px 8px
</style>
