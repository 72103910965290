<template>
  <UiModal
    v-model:visible="computedModelValue"
    :after-close="afterCloseCb"
    @close="handleClose"
  >
    <div class="container" data-test-id="modal__confirm">
      <div class="header">
        <slot name="header" />
      </div>

      <div class="body">
        <div><slot /></div>

        <div class="actions">
          <UiButton block data-test-id="button__cancel" @click="handleClose">
            {{ $t('button.cancel') }}
          </UiButton>
          <UiButton
            block
            type="primary"
            :loading="requestPending"
            data-test-id="button__confirm"
            @click="handleConfirm"
          >
            <slot name="confirm" />
          </UiButton>
        </div>
      </div>
    </div>
  </UiModal>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
export default defineComponent({ name: 'confirm-modal' });
</script>

<script setup lang="ts">
import { computed } from 'vue';

import { UiButton, UiModal } from '@/ui';

const afterCloseEvent = ref<'confirm' | 'abort'>('abort');

type Props = {
  modelValue: boolean;
  handler: () => Promise<void>;
};
const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'confirm'): void;
  (e: 'abort'): void;
  (e: 'update:modelValue', value: boolean): void;
}>();

const computedModelValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

const handleClose = () => {
  emit('update:modelValue', false);
};

const requestPending = ref(false);

const handleConfirm = () => {
  requestPending.value = true;
  props
    .handler()
    .then(() => {
      emit('confirm');
      handleClose();
    })
    .finally(() => {
      requestPending.value = false;
    });
};

const afterCloseCb = () => {
  emit(afterCloseEvent.value);
};
</script>

<style lang="stylus" scoped>
.container
  font-size 14px

.header
  padding-bottom 20px
  margin-bottom 20px
  border-bottom 1px solid Gray(L40)
  display flex
  align-items center
  color Gray(DK8)
  @apply text-l
  font-weight 600
  line-height 1

.body
  text-align center

.actions
  display flex
  max-width 70%
  margin 36px auto 0 auto
  gap 12px
  button
    height 40px
</style>
