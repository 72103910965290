/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaymentRate,
    PaymentRateFromJSON,
    PaymentRateFromJSONTyped,
    PaymentRateToJSON,
} from './PaymentRate';
import {
    Reservation,
    ReservationFromJSON,
    ReservationFromJSONTyped,
    ReservationToJSON,
} from './Reservation';
import {
    ReservationDetailedAllOf,
    ReservationDetailedAllOfFromJSON,
    ReservationDetailedAllOfFromJSONTyped,
    ReservationDetailedAllOfToJSON,
} from './ReservationDetailedAllOf';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface ReservationDetailed
 */
export interface ReservationDetailed {
    /**
     * 
     * @type {string}
     * @memberof ReservationDetailed
     */
    id: string;
    /**
     * timestamp UTC
     * @type {number}
     * @memberof ReservationDetailed
     */
    createdAt: number;
    /**
     * timestamp UTC
     * @type {number}
     * @memberof ReservationDetailed
     */
    updatedAt: number;
    /**
     * 
     * @type {PaymentRate}
     * @memberof ReservationDetailed
     */
    paymentRate: PaymentRate;
    /**
     * 
     * @type {User}
     * @memberof ReservationDetailed
     */
    user: User;
}

export function ReservationDetailedFromJSON(json: any): ReservationDetailed {
    return ReservationDetailedFromJSONTyped(json, false);
}

export function ReservationDetailedFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReservationDetailed {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'paymentRate': PaymentRateFromJSON(json['paymentRate']),
        'user': UserFromJSON(json['user']),
    };
}

export function ReservationDetailedToJSON(value?: ReservationDetailed | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'paymentRate': PaymentRateToJSON(value.paymentRate),
        'user': UserToJSON(value.user),
    };
}

