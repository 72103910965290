<template>
  <text-field class="search" v-bind="$attrs">
    <icon-ui
      v-if="withBarcode"
      name="operations/qrcode_line"
      class="search-icon"
      :size="32"
    />
    <icon-ui name="navigation/search_line" class="search-icon" :size="32" />
  </text-field>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

import TextField from '../text-field/index.vue';

export default defineComponent({
  name: 'search',
  components: {
    TextField,
  },
  props: {
    withBarcode: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '100%',
    },
  },
});
</script>
<style lang="stylus" scoped>
::v-deep(.search)
  height 40px
  border 1px solid #d9d9d9
  background #fff
  border-radius 8px
  width: v-bind(width)
  color Gray(DK16)
  &::placeholder
    color: #aaaaaa
  &:focus
    border-color #1890ff
</style>
