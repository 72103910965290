import { onMounted, reactive, ref, watch } from 'vue';

import {
  PhotoBox,
  photoBoxesApi,
  PhotoBoxListResponse,
  SortPhotoBoxes,
} from '@/api';
import { ESortingDirection, TSorting } from '@/components/table/types';
import { useQuery } from '@/composables/useQuery';
import {
  ENTITY_INITIAL_STATE,
  PAGE_SIZE,
  TPageableEntity,
} from '@/features/Common';
import { clone, uniq } from '@/utils/collection';

type TBarcodesState = TPageableEntity<PhotoBox>;

export function usePhotorequestBarcodes() {
  const state = reactive<TBarcodesState>(clone(ENTITY_INITIAL_STATE));
  const error = ref(false);

  const handleResponse = (response: PhotoBoxListResponse) => {
    if (response) {
      state.page = response.page + 1;
      state.items = uniq([...state.items, ...response.items]);
      state.hasMore = state.page < response.totalPages;
      state.totalCount = response.totalItems;
    }
  };

  const get = async () => {
    state.loading = true;
    error.value = false;

    const { field, direction } = sorting;
    let sort;

    if (field && direction) {
      sort = [`${field}${direction}` as SortPhotoBoxes];
    }

    try {
      const response = await photoBoxesApi.getPhotoBoxes({
        page: state.page,
        size: PAGE_SIZE,
        sort,
        barcode: boundedQuery.value,
      });

      handleResponse(response);
    } catch (e) {
      error.value = true;
    } finally {
      state.loading = false;
    }
  };

  const resetState = () => {
    Object.assign(state, clone(ENTITY_INITIAL_STATE));
  };

  const handleLoadMore = async () => {
    if (state.items.length > 0 && state.hasMore && !state.loading) {
      get();
    }
  };

  const printBarcode = async (id: number) => {
    return await photoBoxesApi.printPhotoBoxesBarcode({
      printBarcodes: {
        ids: [id],
      },
    });
  };

  const addBox = (box: PhotoBox) => {
    if (!query.value) {
      state.items.unshift(box);
    }
  };

  const getInitial = async () => {
    resetState();
    await get();
  };

  const { query, boundedQuery } = useQuery(getInitial);

  const sorting = reactive<TSorting>({
    field: 'createdAt',
    direction: ESortingDirection.DESC,
  });

  watch(sorting, getInitial);

  onMounted(async () => {
    await getInitial();
  });

  return {
    state,
    get,
    getInitial,
    error,
    resetState,
    handleLoadMore,
    query,
    sorting,
    printBarcode,
    addBox,
  };
}
