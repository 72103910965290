import { AsyncComponentLoader, InjectionKey } from 'vue';

import { safeInject } from '@/utils/inject';

import { TSidebarContext } from './types';

export const DEFAULT_LAYOUT = 'default';
export * from './types';

export const layoutMap = {
  default: () => import(`./default.vue`),
  centered: () => import('./centered.vue'),
};

export type TLayoutName = keyof typeof layoutMap;

export const LoadLayout: (name: TLayoutName) => AsyncComponentLoader = (
  name = DEFAULT_LAYOUT,
) => layoutMap[name];

export const SidebarInjectionKey: InjectionKey<TSidebarContext> =
  Symbol('sidebar');

export const useSidebar = () => {
  return safeInject<TSidebarContext>(SidebarInjectionKey);
};
