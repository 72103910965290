import { nextTick, onBeforeUnmount, onMounted, Ref, watch } from 'vue';
import { useRoute } from 'vue-router';

function getElement(el: any): HTMLElement | null {
  if (el instanceof HTMLElement) return el;

  if (el && '$el' in el) return el.$el;

  return null;
}

const scrollPositions = new Map<string, number>();

export function usePreserveScroll(
  elementRef: Ref<HTMLElement | { $el: any } | null | undefined>,
  local = false,
) {
  const route = useRoute();

  if (local) {
    watch(
      () => [route.path, route.query],
      () => {
        const el = getElement(elementRef.value);

        if (!el) return;

        const { scrollTop } = el;

        nextTick(() => {
          el.scrollTop = scrollTop;
        });
      },
    );

    return;
  }

  const { path } = route;

  onMounted(() => {
    const el = getElement(elementRef.value);

    if (!el || !scrollPositions.has(path)) return;

    nextTick(() => {
      el.scrollTop = scrollPositions.get(path) ?? 0;
      scrollPositions.delete(path);
    });
  });

  onBeforeUnmount(() => {
    const el = getElement(elementRef.value);

    if (!el) return;

    scrollPositions.set(path, el.scrollTop);
  });
}
