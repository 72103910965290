<template>
  <UiModal
    v-for="modal in modals"
    :key="modal.id"
    class="global-modal"
    :visible="visibleList.includes(modal.id)"
    centered
    width="unset"
    :closable="false"
    @cancel="handleClose(modal.type)"
  >
    <component
      :is="modal.component"
      v-bind="modal.props"
      @close="handleClose(modal.type)"
    />
  </UiModal>
</template>

<script setup lang="ts">
import {
  defineAsyncComponent,
  onMounted,
  onUnmounted,
  ref,
  shallowRef,
  triggerRef,
} from 'vue';

import { UiModal } from '@/components/ui';
import { AppEvents } from '@/features/Common';
import { EModalTypes } from '@/features/Modals';

type IModalProp = Record<string, unknown>;

interface IModal {
  id: number;
  type: EModalTypes;
  component: any;
  props: IModalProp;
}

const visibleList = ref<number[]>([]);
const modals = shallowRef<IModal[]>([]);

const popModal = () => {
  modals.value.pop();
  triggerRef(modals);
};

const onOpen = (type: EModalTypes, id: number) => async (props: IModalProp) => {
  const component = defineAsyncComponent(() => import(`./${type}/index.vue`));

  if (
    modals.value.length > 1 &&
    modals.value[modals.value.length - 1].type === type
  ) {
    modals.value.pop();
  }

  visibleList.value.push(id);

  modals.value.push({
    id,
    type,
    component,
    props,
  });
  triggerRef(modals);
};

const onClose = (type: EModalTypes) => () => {
  if (modals.value[modals.value.length - 1]?.type === type) {
    popModal();
  }
};

const isVisible = ref(true);

const onHide = () => () => {
  isVisible.value = false;
};

const onShow = () => () => {
  isVisible.value = true;
};

onMounted(() => {
  Object.values(EModalTypes).forEach((type, id) => {
    eventManager.on(AppEvents.modals[type].open, onOpen(type, id));
    eventManager.on(AppEvents.modals[type].close, onClose(type));
  });
  eventManager.on(AppEvents.modals.hide, onHide());
  eventManager.on(AppEvents.modals.show, onShow());
});

onUnmounted(() => {
  Object.values(EModalTypes).forEach((type, id) => {
    eventManager.off(AppEvents.modals[type].open, onOpen(type, id));
    eventManager.off(AppEvents.modals[type].close, onClose(type));
  });
  eventManager.off(AppEvents.modals.hide, onHide());
  eventManager.off(AppEvents.modals.show, onShow());
});

const handleClose = (type: EModalTypes) => {
  if (modals.value[modals.value.length - 1].type === type) {
    visibleList.value.length = modals.value.length - 1;
    eventManager.emit(AppEvents.modals[type].close);
  }
};
</script>

<style lang="stylus">
.global-modal
  .ant-modal-content
    background none
    box-shadow none
    .ant-modal-body > *
      box-shadow 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05)
</style>
