<template>
  <div ref="wrapperRef" class="wrapper">
    <table class="table">
      <thead v-if="withHeader" data-test-id="table__header">
        <table-header
          :columns="columns"
          :with-selection="withSelection"
          :toggle-all="toggleAll"
          :all-are-checked="allAreChecked"
          :color="headerColor"
          :text-color="headerTextColor"
          :sorting="sorting"
        />
        <slot name="under-header" />
      </thead>
      <template v-if="isGrouped">
        <table-row-group
          v-for="group in data"
          :key="group.title"
          :data="group"
          :colspan="colspan"
          :on-row-click="onRowClick"
          :row-class="rowClass"
          :columns="columns"
          :hovered="rowHovered"
          :load-error="loadError"
        >
          <template v-for="(index, name) in slots" #[name]="props">
            <slot :name="name" v-bind="props" />
          </template>
        </table-row-group>
      </template>
      <template v-else>
        <tbody>
          <template v-for="row in data" :key="row.id">
            <table-row
              data-test-id="row__table"
              :data="row"
              :row-align="rowAlign"
              :on-click="onRowClick"
              :class="rowClass"
              :columns="columns"
              :hovered="rowHovered"
              @mouseover="$emit('row-mouseover', row)"
              @mouseleave="$emit('row-mouseleave', row)"
            >
              <template v-for="(index, name) in slots" #[name]="props">
                <slot :name="name" v-bind="props" />
              </template>
            </table-row>
          </template>
          <template v-if="!isGrouped">
            <table-data-loader
              v-if="!loadError"
              :on-enter="handleLoadMore"
              :has-more="hasMore"
              :colspan="colspan"
            />
            <table-load-error
              v-else
              :on-enter="handleLoadMore"
              :has-more="hasMore"
              :colspan="colspan"
            />
          </template>
        </tbody>
      </template>
    </table>
    <div v-if="data.length === 0 && loaded" class="wrapper">
      <slot />
    </div>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, PropType, provide, ref } from 'vue';

import { useIsInViewWrapper } from '@/composables/useIsInView';

import { useFocused, useOpened, usePopped, useSelected } from './composables';
import { ColumnsKey, SortingKey } from './symbols';
import TableDataLoader from './table-data-loader.vue';
import TableHeader from './table-header.vue';
import TableLoadError from './table-load-error.vue';
import TableRow from './table-row.vue';
import TableRowGroup from './table-row-group.vue';
import type {
  IColumn,
  THeaderColor,
  THeaderTextColor,
  TRow,
  TRowBase,
  TRowGroup,
  TSorting,
} from './types';

const PADDING_COLUMNS_COUNT = 2;

export default defineComponent({
  name: 'base-table',
  components: {
    TableRow,
    TableRowGroup,
    TableHeader,
    TableDataLoader,
    TableLoadError,
  },
  props: {
    /* Columns of table */
    columns: prop<IColumn[]>({
      type: Object,
      required: true,
    }),
    /* Data of table */
    data: prop<TRow[]>({
      type: Object,
      required: true,
    }),
    /* Select row is enabled */
    withSelection: {
      type: Boolean,
      default: false,
    },
    /* Focusing row is enabled */
    withFocusing: {
      type: Boolean,
      default: false,
    },
    withRowPadding: {
      type: Boolean,
      default: false,
    },
    /* Show header of table */
    withHeader: {
      type: Boolean,
      default: true,
    },
    /* Handle row focus */
    onFocusRow: {
      type: Function as PropType<(row: TRowBase) => void>,
      default: () => {},
    },
    /* Handle row unfocus */
    onUnfocusRow: {
      type: Function as PropType<() => void>,
      default: () => {},
    },
    /* Handle row pop */
    onPopRow: {
      type: Function as PropType<(row: TRowBase) => void>,
      default: () => {},
    },
    /* Has more items to load (Non grouped) */
    hasMore: {
      type: Boolean,
      default: false,
    },
    /* Load more items (Non grouped)*/
    handleLoadMore: {
      type: Function as PropType<() => void>,
      default: () => {},
    },
    /* Is data grouped */
    isGrouped: {
      type: Boolean,
      default: true,
    },
    /* rows align */
    rowAlign: {
      type: String,
      default: '',
    },
    /* color of header */
    headerColor: {
      type: String as () => THeaderColor,
      default: 'white',
    },
    headerTextColor: {
      type: String as () => THeaderTextColor,
      default: 'gray',
    },
    /* Callback on bottom scroll */
    onBottom: {
      type: Function as PropType<() => void>,
      default: () => {},
    },
    /* Handle row click */
    onRowClick: {
      type: Function as PropType<(data: TRowBase) => void>,
      default: undefined,
    },
    /* Additional row class */
    rowClass: {
      type: String,
      default: '',
    },
    /* Sorting */
    sorting: {
      type: Object as PropType<TSorting>,
      default: () => {},
    },
    rowHovered: {
      type: Boolean,
      default: false,
    },
    loaded: {
      type: Boolean,
      default: true,
    },
    loadError: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['row-mouseover', 'row-mouseleave'],
  setup(props, { slots }) {
    if (!props.data) throw new Error('No data rows provided');
    const wrapperRef = ref();

    useIsInViewWrapper(wrapperRef);

    const { focusRow, unfocusRow } = useFocused(
      props.onFocusRow,
      props.onUnfocusRow,
      props.withFocusing,
    );

    const { popRow, unpopRow } = usePopped(props.onPopRow);

    const selected = useSelected(
      props.data,
      props.isGrouped,
      props.withSelection,
    );

    if (props.isGrouped) {
      useOpened(props.data as TRowGroup[]);
    }

    const pop = (row: TRowBase) => {
      focusRow(row);
      popRow(row);
    };

    const unpop = () => {
      unfocusRow();
      unpopRow();
    };

    const colspan = computed(() => {
      if (!props.columns) throw new Error('No columns provided');

      return (
        props.columns.length +
        PADDING_COLUMNS_COUNT +
        (props.withSelection ? 1 : 0)
      );
    });

    provide(ColumnsKey, props.columns ?? []);
    provide(SortingKey, props.sorting);

    return {
      ...selected,
      pop,
      unpop,
      popRow,
      unpopRow,
      focusRow,
      unfocusRow,
      slots,
      colspan,
      wrapperRef,
    };
  },
});
</script>
<style lang="stylus" scoped>
.wrapper
  @apply font-medium text-l
  color: Black(A80)
  overflow auto
  max-height 100%
  display flex
  flex-direction column
  flex-grow 1
  background LightBlue(LT47)
  position relative
  height 100%
  min-height 100px

.table
  @apply text-m leading-long
  width 100%
  height 1px
  border-left: 1px solid Gray(LT40)
  border-right: 1px solid Gray(LT40)
  border-bottom: 1px solid Gray(LT40)
  :deep()
    td:last-child, th:last-child
      padding-right 32px
    td:first-child, th:first-child
      padding-left 32px

::v-deep(.table-load-error td)
  padding 0!important
</style>
