<template>
  <div class="text-cell text-m leading-short">
    <slot />
    <template v-if="copyable">
      <CopyButton v-if="copyable" :text="copyable" />
    </template>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

import { CopyButton } from '@/ui';

export default defineComponent({
  components: { CopyButton },
  props: {
    copyable: {
      type: [String],
      default: null,
    },
  },
});
</script>
<style lang="stylus" scoped>
.text-cell
  color Gray(DK28)
  padding 20px 14px
</style>
