<template>
  <div class="wrapper">
    <slot />
  </div>
</template>

<style lang="stylus" scoped>
.wrapper
  width 247px
</style>
