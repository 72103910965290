/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CategoryAllOf,
    CategoryAllOfFromJSON,
    CategoryAllOfFromJSONTyped,
    CategoryAllOfToJSON,
} from './CategoryAllOf';
import {
    MediaType,
    MediaTypeFromJSON,
    MediaTypeFromJSONTyped,
    MediaTypeToJSON,
} from './MediaType';
import {
    ShootingType,
    ShootingTypeFromJSON,
    ShootingTypeFromJSONTyped,
    ShootingTypeToJSON,
} from './ShootingType';

/**
 * 
 * @export
 * @interface Category
 */
export interface Category {
    /**
     * 
     * @type {number}
     * @memberof Category
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    title: string;
    /**
     * 
     * @type {number}
     * @memberof Category
     */
    parentID?: number;
    /**
     * 
     * @type {number}
     * @memberof Category
     */
    externalID: number;
    /**
     * 
     * @type {number}
     * @memberof Category
     */
    created: number;
    /**
     * 
     * @type {number}
     * @memberof Category
     */
    updated: number;
    /**
     * Может быть пустой строкой
     * @type {string}
     * @memberof Category
     */
    fullTitle: string;
    /**
     * 
     * @type {Array<ShootingType>}
     * @memberof Category
     */
    shootingTypes: Array<ShootingType>;
    /**
     * 
     * @type {MediaType}
     * @memberof Category
     */
    mediaType: MediaType;
}

export function CategoryFromJSON(json: any): Category {
    return CategoryFromJSONTyped(json, false);
}

export function CategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): Category {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'parentID': !exists(json, 'parentID') ? undefined : json['parentID'],
        'externalID': json['externalID'],
        'created': json['created'],
        'updated': json['updated'],
        'fullTitle': json['fullTitle'],
        'shootingTypes': ((json['shootingTypes'] as Array<any>).map(ShootingTypeFromJSON)),
        'mediaType': MediaTypeFromJSON(json['mediaType']),
    };
}

export function CategoryToJSON(value?: Category | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'parentID': value.parentID,
        'externalID': value.externalID,
        'created': value.created,
        'updated': value.updated,
        'fullTitle': value.fullTitle,
        'shootingTypes': ((value.shootingTypes as Array<any>).map(ShootingTypeToJSON)),
        'mediaType': MediaTypeToJSON(value.mediaType),
    };
}

