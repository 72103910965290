<template>
  <PageLayout>
    <ScannerEmulator v-if="isDevelopment" />
    <template #header>
      <div class="header-wrapper">
        <PageHeader :title="title" name="invoice">
          <template #prefix>
            <RouterLink
              data-test-id="button__back-page"
              :to="{
                name: AppRoutes.invoices.name,
              }"
              variant="text"
              replace
            >
              <ArrowLeft />
            </RouterLink>
          </template>

          <div class="info text-s leading-long">
            <dl
              v-if="state.box?.barcode"
              data-test-id="text__box-barcode-invoice"
            >
              <dt>{{ $t('box barcode') }}</dt>
              <dd>
                {{ state.box?.barcode }}
              </dd>
            </dl>
          </div>

          <div class="draft-buttons">
            <UiButton data-test-id="button__save-invoice" disabled>
              {{ $t('button.save') }}
            </UiButton>
            <UiButton
              type="primary"
              danger
              data-test-id="button__delete-invoice"
              :disabled="!state.invoice"
              @click="handleRemove()"
            >
              {{ $t('button.delete') }}
            </UiButton>
          </div>
        </PageHeader>
        <div class="subheader-wrapper">
          <div v-if="state.invoice" class="tags-wrapper">
            <UiTag
              data-test-id="icon__invoice-status"
              :color-name="
                invoiceStatusColor[state.invoice.type][state.invoice.status]
              "
            >
              {{ $t(`${state.invoice?.type}_${state.invoice?.status}`) }}
            </UiTag>
            <UiTag
              :color-name="invoiceTypeColor[state.invoice.type]"
              data-test-id="icon__invoice-type"
              is-text-colored
            >
              {{ $t(`${state.invoice?.type}`) }}
            </UiTag>
          </div>
          <div v-if="state.invoice" class="count-wrapper">
            <Count :expected-amount="0" class="wrapper" />
          </div>
        </div>
      </div>
    </template>

    <div class="content-wrapper">
      <div ref="tableWrapperRef" class="table-wrapper">
        <UiTable
          :columns="columns"
          bordered
          :row-selection="{
            selectedRowKeys: [],
            onChange: () => {},
          }"
        >
          <template #empty>
            <div class="empty text-s font-medium">
              <div class="frame-wrapper">
                <div :class="['frame', { inactive: isInvoiceCreated }]">
                  <icon-ui
                    class="frame-icon"
                    name="foundation/box__line"
                    :size="72"
                  />
                  <span class="text-xxl font-semi-bold leading-long">
                    {{ $t('modal.invoice-creation.scan-box.title') }}
                  </span>
                  <span
                    class="text-s font-regular leading-short w-[310px] m-auto"
                  >
                    {{ $t('modal.invoice-creation.scan-box.reason') }}
                  </span>
                </div>
                <UiButton
                  v-if="isInvoiceCreated"
                  class="remove-box"
                  data-test-id="button__remove-box"
                  @click="remove"
                >
                  {{ $t('button.cancel') }}
                </UiButton>
              </div>

              <icon-ui
                class="empty-arrow"
                name="navigation/arrow-right_line"
                :size="24"
              />

              <div :class="['frame', { inactive: !isInvoiceCreated }]">
                <icon-ui
                  class="frame-icon"
                  name="operations/barcode_line"
                  :size="72"
                />
                <span class="text-xxl font-semi-bold leading-long">
                  {{ $t('modal.invoice-creation.scan-sku.title') }}
                </span>
                <span
                  class="text-s font-regular leading-short w-[310px] m-auto"
                >
                  {{ $t('modal.invoice-creation.scan-sku.reason') }}
                </span>
              </div>
            </div>
          </template>
        </UiTable>
      </div>
    </div>
  </PageLayout>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, watch } from 'vue';
export default defineComponent({ name: 'invoice-creation-page' });
</script>

<script setup lang="ts">
import { ArrowLeftOutlined as ArrowLeft } from '@ant-design/icons-vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

import { useEventManager } from '@/composables/useEventManager';
import { useQRCodePrint } from '@/composables/usePrint';
import {
  playBrak,
  playError,
  playSuccess,
  useSoundSignal,
} from '@/composables/useSoundSignal';
import { IS_DEVELOPMENT as isDevelopment } from '@/config';
import { EBarcodeEvents, useBarcodeScan } from '@/features/Barcode';
import { AppEvents } from '@/features/Common';
import { EIntroKeys, useFeatureIntro } from '@/features/FeatureIntro';
import { ScannerEmulator } from '@/features/ScannerEmulator';
import Count from '@/pages/invoice/components/count.vue';
import { invoiceStatusColor, invoiceTypeColor } from '@/pages/invoice/data';
import { AppRoutes } from '@/router/data';
import { PageHeader, PageLayout } from '@/template';
import { UiButton, UiTable, UiTag } from '@/ui';

import { useInvoiceCreationApi } from './composables';
import { qrNotification } from './notification';

const { t } = useI18n();
const router = useRouter();
const [showIntro] = useFeatureIntro(EIntroKeys.INVOICE_MARK_DEFECT);

const {
  state,
  createInvoice,
  getSkuEntryByBarcode,
  getSkuDetailed,
  addSku,
  remove,
} = useInvoiceCreationApi();

const handleSave = () => {
  if (state.invoice) {
    router.replace({
      name: AppRoutes.invoice.name,
      params: {
        id: state.invoice.id,
      },
    });
  }
};

const handleBack = () => {
  router.replace({
    name: AppRoutes.invoices.name,
  });
};

const handleRemove = () => {
  remove().then(() => {
    handleBack();
  });
};

const wrapSound = useSoundSignal();
const printQrcode = useQRCodePrint();

const handleSkuScan = async (barcode: string) => {
  try {
    const sku = await getSkuEntryByBarcode(barcode);

    await addSku(sku);
    handleSave();

    const detailed = await getSkuDetailed(sku.id);

    if (detailed.identifier) {
      qrNotification(detailed, () => printQrcode(detailed.identifier));
      playBrak();
    } else {
      playSuccess();
    }
  } catch (error) {
    playError();
  }
};

const [subscribeSku, unsubscribeSku] = useBarcodeScan(
  EBarcodeEvents.SKU,
  handleSkuScan,
  true,
);

const [subscribeInvoice, unsubscribeInvoice] = useBarcodeScan(
  EBarcodeEvents.INVOICE,
  async (barcode) => {
    await wrapSound(createInvoice(barcode));
  },
);

watch(
  () => state.invoice,
  (val) => {
    if (val !== null) {
      unsubscribeInvoice();
      subscribeSku();
    } else {
      unsubscribeSku();
      subscribeInvoice();
    }
  },
);

const title = computed(() =>
  state.invoice === null
    ? t('modal.invoice.new')
    : t('modal.invoice.title', { id: state.invoice.id }),
);

const isInvoiceCreated = computed(() => state.invoice !== null);

const columns = computed(() => [
  {
    title: t('photo'),
    key: 'photo',
    dataIndex: 'photo',
    width: 86,
  },
  {
    title: t('nomenclature'),
    key: 'nomenclature',
    dataIndex: 'nomenclature',
  },
  {
    title: 'SKU',
    key: 'sku',
    dataIndex: 'sku',
    width: 210,
  },
  {
    title: t('barcode'),
    key: 'barcode',
    dataIndex: 'barcode',
    width: 170,
  },
  {
    title: t('checked'),
    key: 'amount',
    dataIndex: 'amount',
    width: 160,
  },
]);

useEventManager(AppEvents.pages.invoiceCreation.close, handleSave);

onMounted(() => {
  showIntro();
});
</script>

<style lang="stylus" scoped>


.header-wrapper
  PageContent()

.content-wrapper
  overflow auto
  height 100%

.subheader-wrapper
  padding 0 40px 0 46px
  min-height 49px

.count-wrapper
  margin-top 30px
  margin-bottom 20px

.tags-wrapper
  min-height 26px

.info
  display grid
  grid-auto-flow column
  grid-column-gap 38px

  dl
    display grid
    grid-template-rows auto 1fr
    grid-row-gap 8px
    margin 0

    dt
      color Gray(DK10)

    dd
      margin 0
      color Gray(DK32)

.draft-buttons
  display flex
  gap 10px

.edit-button
  width 130px

.disabled
  opacity 0.5

.table-wrapper
  height 100%

.empty
  display flex
  height 100%
  min-height calc(100vh - 260px)
  align-items center
  justify-content center
  &-arrow
    margin 0 32px

.inactive
  opacity 0.3

.frame-wrapper
  position relative
  .remove-box
    position absolute
    bottom 20px
    left 50%
    transform translateX(-50%)
    background-color #F6EDFC
    color #6935BD
    &:hover
      background-color darken(#F6EDFC, 3)

.frame
  width 310px
  display grid
  row-gap 12px
  justify-items center
  text-align center
  color Gray(D28)
  fill Gray(D28)
  position relative
  margin 120px 0

  &-icon
    position absolute
    top -100px
</style>
