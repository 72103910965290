<template>
  <div v-if="IS_DEVELOPMENT" class="emulator">
    <text-field
      v-model="input"
      data-test-id="input__emulator"
      @keypress.enter="emitBarcode"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue';

import { IS_DEVELOPMENT } from '@/config';
import { AppEvents } from '@/features/Common';
import { TextField } from '@/ui';

export default defineComponent({
  components: {
    TextField,
  },
  setup() {
    const input = ref('');

    const emitBarcode = () => {
      eventManager.emit(AppEvents.barcode.emit, input.value);
    };

    return { input, emitBarcode, IS_DEVELOPMENT };
  },
});
</script>
<style lang="stylus" scoped>
.emulator
  position fixed
  bottom 0
  right 0
  z-index 10000
</style>
