<template>
  <div class="container">
    <TextField
      ref="textfieldRef"
      :is-auto-focused="props.isAutoExpanded"
      :value="props.modelQuery"
      :data-test-id="`input__search-${name}`"
      @input="handleInput"
      @blur="handleBlur"
      @focus="isExpanded = true"
    />

    <div v-if="isExpanded" class="dropdown" data-test-id="dropdown__characters">
      <div v-if="props.options.length" class="inner">
        <div
          v-for="(option, idx) in props.options"
          :key="idx"
          class="item"
          data-test-id="row__item-character"
          @mousedown="handleSelect(option)"
        >
          <slot :option="option" :idx="idx" />
        </div>
      </div>
      <div v-else class="empty" data-test-id="block__not-found">
        {{ $t('invalid search') }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
export default defineComponent({ name: 'ui-select' });
</script>

<script setup lang="ts">
import { TextField } from '@/ui';

const props = defineProps({
  options: {
    type: Array,
    default: () => [],
  },
  modelQuery: {
    type: String,
    default: '',
  },
  value: {
    type: Object,
    default: () => ({}),
  },
  reduce: {
    type: Function,
    default: (param: unknown) => param,
  },
  label: {
    type: String,
    default: '',
  },
  isAutoExpanded: {
    type: Boolean,
    default: false,
  },
  name: {
    type: String,
    default: 'default',
  },
});

const emit = defineEmits<{
  (e: 'change', payload: any): void;
  (e: 'update:query', payload: any): void;
  (e: 'blur'): void;
}>();

const handleBlur = () => {
  isExpanded.value = false;
  emit('blur');
};

const isExpanded = ref(false);
const textfieldRef = ref<HTMLInputElement | null>(null);

const handleSelect = (value: any) => {
  emit('change', props.reduce(value));
};

const handleInput = (e) => {
  emit('update:query', e.target.value);
};
</script>

<style lang="stylus" scoped>


.container
  position relative

.dropdown
  z-index 1
  margin-top -2px
  position absolute
  left 0
  right 0
  border 1px solid #555
  border-top none
  padding-top 6px
  border-color Gray(LT40)
  border-radius 4px
  border-top-left-radius 0
  border-top-right-radius 0
  & .inner
    background white
    max-height 200px
    overflow auto

  & .empty
    background white
    font-size 12px
    padding 10px 20px

.item
  font-size 12px
  padding 10px 20px
  cursor pointer
  &:hover
    background #e9e9e9
</style>
