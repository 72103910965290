/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InvoiceStatus,
    InvoiceStatusFromJSON,
    InvoiceStatusFromJSONTyped,
    InvoiceStatusToJSON,
} from './InvoiceStatus';
import {
    InvoiceType,
    InvoiceTypeFromJSON,
    InvoiceTypeFromJSONTyped,
    InvoiceTypeToJSON,
} from './InvoiceType';

/**
 * 
 * @export
 * @interface Invoice
 */
export interface Invoice {
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    id: number;
    /**
     * 
     * @type {InvoiceStatus}
     * @memberof Invoice
     */
    status: InvoiceStatus;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    boxId: number;
    /**
     * 
     * @type {InvoiceType}
     * @memberof Invoice
     */
    type: InvoiceType;
    /**
     * Общее ожидаемое количество товара по накладной
     * @type {number}
     * @memberof Invoice
     */
    expectedAmount: number;
    /**
     * Общее фактическое количество товара по накладной
     * @type {number}
     * @memberof Invoice
     */
    actualAmount: number;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    created: number;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    updated: number;
}

export function InvoiceFromJSON(json: any): Invoice {
    return InvoiceFromJSONTyped(json, false);
}

export function InvoiceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Invoice {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'status': InvoiceStatusFromJSON(json['status']),
        'boxId': json['boxId'],
        'type': InvoiceTypeFromJSON(json['type']),
        'expectedAmount': json['expectedAmount'],
        'actualAmount': json['actualAmount'],
        'created': json['created'],
        'updated': json['updated'],
    };
}

export function InvoiceToJSON(value?: Invoice | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'status': InvoiceStatusToJSON(value.status),
        'boxId': value.boxId,
        'type': InvoiceTypeToJSON(value.type),
        'expectedAmount': value.expectedAmount,
        'actualAmount': value.actualAmount,
        'created': value.created,
        'updated': value.updated,
    };
}

