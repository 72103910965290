<template>
  <div
    :class="[
      'text-cell',
      'text-m',
      'leading-short',
      {
        'pa-0': noPadding,
      },
    ]"
  >
    <date :value="value" />
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

import { Date } from '@/ui';

export default defineComponent({
  components: {
    Date,
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
  },
});
</script>
<style lang="stylus" scoped>
.pa-0
  padding 0!important
.text-cell
  color Gray(DK28)
  padding 20px 14px
</style>
