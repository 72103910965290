/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BoxTransferList,
    BoxTransferListFromJSON,
    BoxTransferListToJSON,
    CreateBoxTransferListRequest,
    CreateBoxTransferListRequestFromJSON,
    CreateBoxTransferListRequestToJSON,
    CreateBoxTransferListResponse,
    CreateBoxTransferListResponseFromJSON,
    CreateBoxTransferListResponseToJSON,
    SortBoxTransfer,
    SortBoxTransferFromJSON,
    SortBoxTransferToJSON,
} from '../models';

export interface CheckItemBoxRequest {
    boxID: number;
}

export interface CreateBoxTransferListOperationRequest {
    createBoxTransferListRequest?: CreateBoxTransferListRequest;
}

export interface GetBoxTransfersByQueryRequest {
    page: number;
    size: number;
    ids?: Array<number>;
    idempotencyKeys?: Array<string>;
    boxIds?: Array<number>;
    barcodes?: Array<string>;
    sort?: Array<SortBoxTransfer>;
}

/**
 * 
 */
export class BoxTransferApi extends runtime.BaseAPI {

    /**
     * Проверить возможность отправки короба
     */
    async checkItemBoxRaw(requestParameters: CheckItemBoxRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.boxID === null || requestParameters.boxID === undefined) {
            throw new runtime.RequiredError('boxID','Required parameter requestParameters.boxID was null or undefined when calling checkItemBox.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/box-transfer/check-box/{boxID}`.replace(`{${"boxID"}}`, encodeURIComponent(String(requestParameters.boxID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Проверить возможность отправки короба
     */
    async checkItemBox(requestParameters: CheckItemBoxRequest, initOverrides?: RequestInit): Promise<void> {
        await this.checkItemBoxRaw(requestParameters, initOverrides);
    }

    /**
     * Создание нового акта передачи с коробами
     */
    async createBoxTransferListRaw(requestParameters: CreateBoxTransferListOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CreateBoxTransferListResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/box-transfer`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateBoxTransferListRequestToJSON(requestParameters.createBoxTransferListRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateBoxTransferListResponseFromJSON(jsonValue));
    }

    /**
     * Создание нового акта передачи с коробами
     */
    async createBoxTransferList(requestParameters: CreateBoxTransferListOperationRequest = {}, initOverrides?: RequestInit): Promise<CreateBoxTransferListResponse> {
        const response = await this.createBoxTransferListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получить короба
     */
    async getBoxTransfersByQueryRaw(requestParameters: GetBoxTransfersByQueryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BoxTransferList>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getBoxTransfersByQuery.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getBoxTransfersByQuery.');
        }

        const queryParameters: any = {};

        if (requestParameters.ids) {
            queryParameters['ids'] = requestParameters.ids;
        }

        if (requestParameters.idempotencyKeys) {
            queryParameters['idempotencyKeys'] = requestParameters.idempotencyKeys;
        }

        if (requestParameters.boxIds) {
            queryParameters['boxIds'] = requestParameters.boxIds;
        }

        if (requestParameters.barcodes) {
            queryParameters['barcodes'] = requestParameters.barcodes;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/box-transfer`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BoxTransferListFromJSON(jsonValue));
    }

    /**
     * Получить короба
     */
    async getBoxTransfersByQuery(requestParameters: GetBoxTransfersByQueryRequest, initOverrides?: RequestInit): Promise<BoxTransferList> {
        const response = await this.getBoxTransfersByQueryRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
