/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Box,
    BoxFromJSON,
    BoxFromJSONTyped,
    BoxToJSON,
} from './Box';
import {
    BoxListResponseAllOf,
    BoxListResponseAllOfFromJSON,
    BoxListResponseAllOfFromJSONTyped,
    BoxListResponseAllOfToJSON,
} from './BoxListResponseAllOf';
import {
    PaginationResponse,
    PaginationResponseFromJSON,
    PaginationResponseFromJSONTyped,
    PaginationResponseToJSON,
} from './PaginationResponse';

/**
 * 
 * @export
 * @interface BoxListResponse
 */
export interface BoxListResponse {
    /**
     * количество элементов на одной странице
     * @type {number}
     * @memberof BoxListResponse
     */
    pageSize: number;
    /**
     * номер страницы
     * @type {number}
     * @memberof BoxListResponse
     */
    page: number;
    /**
     * общее количество страниц
     * @type {number}
     * @memberof BoxListResponse
     */
    totalPages: number;
    /**
     * общее количество элементов
     * @type {number}
     * @memberof BoxListResponse
     */
    totalItems: number;
    /**
     * 
     * @type {Array<Box>}
     * @memberof BoxListResponse
     */
    items: Array<Box>;
}

export function BoxListResponseFromJSON(json: any): BoxListResponse {
    return BoxListResponseFromJSONTyped(json, false);
}

export function BoxListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BoxListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageSize': json['pageSize'],
        'page': json['page'],
        'totalPages': json['totalPages'],
        'totalItems': json['totalItems'],
        'items': ((json['items'] as Array<any>).map(BoxFromJSON)),
    };
}

export function BoxListResponseToJSON(value?: BoxListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pageSize': value.pageSize,
        'page': value.page,
        'totalPages': value.totalPages,
        'totalItems': value.totalItems,
        'items': ((value.items as Array<any>).map(BoxToJSON)),
    };
}

