/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeletePhotoRequestItemPhotos
 */
export interface DeletePhotoRequestItemPhotos {
    /**
     * 
     * @type {Array<string>}
     * @memberof DeletePhotoRequestItemPhotos
     */
    photos: Array<string>;
}

export function DeletePhotoRequestItemPhotosFromJSON(json: any): DeletePhotoRequestItemPhotos {
    return DeletePhotoRequestItemPhotosFromJSONTyped(json, false);
}

export function DeletePhotoRequestItemPhotosFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeletePhotoRequestItemPhotos {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'photos': json['photos'],
    };
}

export function DeletePhotoRequestItemPhotosToJSON(value?: DeletePhotoRequestItemPhotos | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'photos': value.photos,
    };
}

