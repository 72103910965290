import { SkuStatus } from '@/api';
import { StatusFilter } from '@/pages/products/types';
import { FilterConfiguration, TChipColor } from '@/ui';

export const skuStatusToColor: Record<SkuStatus, TChipColor> = {
  [SkuStatus.Unknown]: 'gray',
  [SkuStatus.Created]: 'gray',
  [SkuStatus.Waiting]: 'yellow',
  [SkuStatus.Accepted]: 'orange',
  [SkuStatus.Sent]: 'green',
  [SkuStatus.Missing]: 'pink',
};

export const skuStatusFilterConfiguration: FilterConfiguration<StatusFilter> = [
  {
    label: `sku.${SkuStatus.Waiting}`,
    color: skuStatusToColor[SkuStatus.Waiting],
    value: { status: SkuStatus.Waiting },
  },
  {
    label: `sku.${SkuStatus.Accepted}`,
    color: skuStatusToColor[SkuStatus.Accepted],
    value: { status: SkuStatus.Accepted },
  },
  {
    label: `sku.${SkuStatus.Sent}`,
    color: skuStatusToColor[SkuStatus.Sent],
    value: { status: SkuStatus.Sent },
  },
  {
    label: `sku.${SkuStatus.Missing}`,
    color: skuStatusToColor[SkuStatus.Missing],
    value: { status: SkuStatus.Missing },
  },
];
