import { reactive } from 'vue';
import { useI18n } from 'vue-i18n';

import {
  Box,
  boxesApi,
  Invoice,
  invoiceApi,
  InvoiceItem,
  InvoiceStatus,
  skuApi,
  SkuDetailed,
  SkuListEntry,
} from '@/api';
import { notification } from '@/features/Notifications';
import { useInvoicesApi } from '@/pages/invoices/composables';
import { clone } from '@/utils/collection';

type TState = {
  invoice: Invoice | null;
  box: Box | null;
  items: InvoiceItem[];
  isLoading: boolean;
};

const state = reactive<TState>({
  invoice: null,
  box: null,
  items: [],
  isLoading: false,
});

export const useInvoiceCreationApi = () => {
  const { getInvoiceById, deleteInvoice } = useInvoicesApi();
  const { t } = useI18n();

  const createInvoice = async (barcode: string) => {
    const boxes = await boxesApi.getBoxes({ barcode, page: 0, size: 1 });

    if (boxes.totalItems === 0) {
      notification.error(t(`modal.error.codes.unknownBoxBarcode`));
      throw Error('Not valid box barcode');
    }

    const box = boxes.items[0];

    const invoice = await invoiceApi.createInvoice({
      invoiceTemplate: {
        boxId: box.id,
      },
    });

    await getInvoiceById(invoice.id);

    state.box = box;
    state.invoice = invoice;
  };

  const getSkuEntryByBarcode = async (
    barcode: string,
  ): Promise<SkuListEntry> => {
    const {
      items: [sku],
    } = await skuApi.skuList({ page: 0, size: 1, barcode });

    if (!sku) {
      notification.error(t(`modal.error.codes.unknownSkuBarcode`));
      throw Error('not found');
    }

    return sku;
  };

  const getSkuDetailed = async (id: number): Promise<SkuDetailed> => {
    const sku = await skuApi.getSkuByID({ skuID: id });

    return sku;
  };

  const addSku = async (sku: SkuListEntry) => {
    if (!state.invoice) {
      throw Error('Invoice is not created');
    }

    const invoiceItem = await invoiceApi.addItemToInvoice({
      invoiceID: state.invoice.id,
      invoiceItemTemplate: {
        skuID: sku.id,
      },
    });

    const index = state.items.findIndex((item) => item.id === invoiceItem.id);

    if (index === -1) {
      state.items.unshift({ ...invoiceItem, sku });
    } else {
      state.items[index] = { ...invoiceItem, sku };
    }

    state.invoice.expectedAmount += 1;
  };

  const removeSku = async (itemID: number) => {
    try {
      await invoiceApi.deleteInvoiceItem({ itemID });
      state.items = state.items.filter((item) => item.id !== itemID);

      return true;
    } catch {
      return false;
    }
  };

  const save = async () => {
    const id = state.invoice?.id;

    if (id === undefined) throw Error('No valid invoice');

    const invoice = await invoiceApi.changeInvoiceStatus({
      invoiceID: id,
      changeInvoiceStatus: {
        status: InvoiceStatus.Created,
      },
    });

    state.invoice = invoice;
  };

  const remove = async () => {
    if (state.invoice) {
      await deleteInvoice(state.invoice);
      await invoiceApi.deleteInvoice({ invoiceID: state.invoice.id });
      resetState();
    }
  };

  const resetState = () => {
    state.invoice = null;
    state.box = null;
    state.items = [];
  };

  const loadable = <T extends Array<any>, U>(
    fn: (...args: T) => Promise<U>,
  ) => {
    return async (...args: T): Promise<U> => {
      state.isLoading = true;

      try {
        return await fn(...args);
      } finally {
        state.isLoading = false;
      }
    };
  };

  const popCreatedInvoice = () => {
    const invoice = clone(state.invoice);

    resetState();

    return invoice;
  };

  return {
    state,
    createInvoice: loadable(createInvoice),
    addSku: loadable(addSku),
    removeSku: loadable(removeSku),
    save: loadable(save),
    remove,
    resetState,
    popCreatedInvoice,
    getSkuEntryByBarcode,
    getSkuDetailed,
  };
};
