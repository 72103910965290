import dayjs from 'dayjs';
import dayjsRu from 'dayjs/locale/ru';
import { createI18n } from 'vue-i18n';

import ru from './ru/translations.json';

dayjs.locale(dayjsRu);

export const messages = {
  ru,
};

export const i18n = createI18n({
  legacy: false,
  locale: 'ru',
  fallbackLocale: 'ru',
  messages,
  pluralRules: {
    /**
     * @param choice {number} a choice index given by the input to $t: `$t('path.to.rule', choiceIndex)`
     * @param choicesLength {number} an overall amount of available choices
     * @returns a final choice index to select plural word by
     */
    ru: (choice, choicesLength) => {
      if (choice === 0) {
        return 0;
      }

      const additionalIndex = choicesLength < 4 ? 0 : 1;

      if (choice % 100 > 4 && choice % 100 < 20) {
        return 2 + additionalIndex;
      } else {
        return (
          [2, 0, 1, 1, 1, 2][choice % 10 < 5 ? Math.abs(choice) % 10 : 5] +
          additionalIndex
        );
      }
    },
  },
});
