<template>
  <div ref="wrapper">
    <slot />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

import { useIsInViewWrapper } from '@/composables/useIsInView';

const wrapper = ref();

useIsInViewWrapper(wrapper);
</script>
