import { noop } from '@vueuse/core';
import { notification as baseNotification } from 'ant-design-vue';
import { h, VNode } from 'vue';

import NotificationContainer from './NotificationContainer.vue';

const config: Parameters<typeof baseNotification.open>[0] = {
  placement: 'bottomLeft',
  duration: 0,
  style: {
    padding: 0,
    borderRadius: '4px',
    width: '350px',
  },
  icon: () => null,
  closeIcon: () => null,
  message: () => null,
};

const createKey = (type: string) => type + Date.now().toString();

type OpenNotificationType = 'success' | 'info' | 'warning' | 'error';
export type NotificationButtonOption = {
  text: string;
  callback: () => void;
};
type OpenNotificationOptions = NotificationOptions & {
  icon?: VNode | (() => VNode);
  type?: OpenNotificationType;
  message?: string;
  slot?: VNode | (() => VNode) | null;
};

const openNotification = (options: OpenNotificationOptions) => {
  const {
    type,
    message,
    slot,
    duration = 4,
    icon,
    button,
    key = createKey(type ?? 'open'),
    onClose = noop,
  } = options;

  const handleClose = () => {
    baseNotification.close(key);
    onClose();
  };

  baseNotification[type ?? 'open']({
    ...config,
    key,
    description: () =>
      h(
        NotificationContainer,
        {
          icon,
          type,
          duration,
          message,
          onClose: handleClose,
          button,
        },
        slot ? h(slot, { onClose: handleClose }) : undefined,
      ),
  });

  return key;
};

const open = (
  slot: VNode | (() => VNode) | null,
  options: OpenNotificationOptions = {},
) =>
  openNotification({
    ...options,
    slot,
  });

const openWithType =
  (type: OpenNotificationType) =>
  (message: string, options: NotificationOptions = {}) =>
    openNotification({ type, message, ...options });

const close = (key: string) => baseNotification.close(key);

export const notification = {
  success: openWithType('success'),
  info: openWithType('info'),
  warning: openWithType('warning'),
  error: openWithType('error'),
  open,
  close,
};

export type NotificationOptions = {
  key?: string;
  duration?: number;
  onClose?: () => void;
  button?: NotificationButtonOption;
};
