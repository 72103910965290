import { useIntervalFn } from '@vueuse/core';
import { computed, ref } from 'vue';

const SECOND = 1000;
const serverTime = ref<number | null>(null);

const inc = () => {
  if (!serverTime.value) return;
  serverTime.value = Date.now();
};

const { resume, pause } = useIntervalFn(inc, SECOND, { immediate: false });

const setServerTime = (unixTime: number) => {
  serverTime.value = unixTime * 1000;
  resume();
};

const clearServerTime = () => {
  serverTime.value = null;
  pause();
};

export const useServerTime = () => {
  return {
    serverTime: computed(() => serverTime.value),
    setServerTime,
    clearServerTime,
  };
};
