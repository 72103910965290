<template>
  <div :class="{ 'page-header': true, collapsed: isCollapsed }">
    <div class="page-title-wrapper">
      <span class="page-title" data-test-id="page__title">
        <span class="title-prefix">
          <slot name="prefix" />
        </span>
        <skeleton
          v-if="isLoadingAnimation"
          style="width: 200px"
          :paragraph="false"
          active
        />
        <span v-else :data-test-id="name ? `text__${name}-title` : null">
          {{ title }}
        </span>
      </span>
      <span v-if="subtitle" class="page-subtitle">
        {{ subtitle }}
      </span>
    </div>
    <slot />
  </div>
</template>
<script lang="ts">
import { Skeleton } from 'ant-design-vue';
import { defineComponent } from 'vue';

import { useSidebar } from '../layout';

export default defineComponent({
  name: 'page-header',
  components: {
    Skeleton,
  },
  props: {
    title: {
      type: String,
    },
    subtitle: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    isLoadingAnimation: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const sidebar = useSidebar();

    return {
      ...sidebar,
    };
  },
});
</script>
<style lang="stylus" scoped>
.menu-open
  position fixed
  left 40px
  top 20px
  fill Gray(LT16)

.page-header
  display flex
  align-items center
  gap 12px
  transition padding 0.2s ease
  margin-bottom 20px
.page-title-wrapper
  margin-right auto
  display: flex;
  flex-direction column

.page-title
  PageTitle()
  display flex
  align-items center

.page-subtitle
  color: #62656A
  font-size: 12px
  min-height: 20px

.title-prefix:not(:empty)
  margin-right 16px
</style>

<style lang="stylus">
.page-title .ant-skeleton-title
  margin 0
  height 25px
</style>
