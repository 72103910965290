<template>
  <table-wrapper
    :generateType="EGenerateTypes.OVERSIZED"
    :composable="useOversizedBarcodes"
    :mask="oversizedBarcodeMask"
  />
</template>

<script
  setup
  lang="ts"
>
import { useI18n } from 'vue-i18n';

import { useEventManager } from '@/composables/useEventManager';
import { AppEvents } from '@/features/Common';
import TableWrapper from '@/pages/barcodes/components/tableWrapper.vue';
import { oversizedBarcodeMask } from '@/utils/barcode';

import { useOversizedBarcodes } from '../composables';
import { EGenerateTypes } from '../data';

const { addBox } = useOversizedBarcodes();

const handleAddBox = ({ box, type }) => {
  if (type === EGenerateTypes.OVERSIZED) addBox(box);
};

useEventManager(AppEvents.modals.generateBarcode.update, handleAddBox);
</script>
