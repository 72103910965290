import { computed, ComputedRef } from 'vue';

import { equal } from './equal';

export const keyBy = <T>(
  array: T[],
  predicate: (element: T) => string,
): TDictionary<T> => {
  const result: TDictionary<T> = {};

  for (const item of array) {
    result[predicate(item)] = item;
  }

  return result;
};

export const groupBy = <T>(
  array: T[],
  predicate: (element: T) => string,
): TDictionary<T[]> => {
  const result: TDictionary<T[]> = {};

  for (const item of array) {
    const key = predicate(item);

    if (!result[key]) {
      result[key] = [item];
    } else {
      result[key].push(item);
    }
  }

  return result;
};

export const clone = <T>(object: T): T => JSON.parse(JSON.stringify(object));

export const keys = <TObj extends Record<PropertyKey, any>>(
  obj: TObj,
): Array<keyof TObj> => Object.keys(obj);

export const uniq = <T>(array: T[]): T[] => {
  const res: T[] = [];

  array.forEach((object) => {
    if (!res.some((uniqObject) => equal(uniqObject, object))) {
      res.push(object);
    }
  });

  return res;
};

export const uniqBy = <T>(
  array: T[],
  predicate: (element: T) => string | keyof T,
): T[] => {
  const getObjectField = (object: T) => {
    if (typeof predicate === 'function') {
      return predicate(object);
    } else {
      return object[predicate];
    }
  };

  const res: T[] = [];

  array.forEach((object) => {
    if (
      !res.some((uniqObject) =>
        equal(getObjectField(uniqObject), getObjectField(object)),
      )
    ) {
      res.push(object);
    }
  });

  return res;
};

export function* chunksGenerator<T>(arr: T[], n: number): Generator<T[], void> {
  for (let i = 0; i < arr.length; i += n) {
    yield arr.slice(i, i + n);
  }
}

export const chunks = <T>(arr: T[], n: number): T[][] =>
  Array.from(chunksGenerator(arr, n));
