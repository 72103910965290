import ky from 'ky';

import { LOCALE, WMS_API_URL } from '@/config';

export const wmsBaseApi = ky.create({
  prefixUrl: WMS_API_URL,
  headers: {
    Authorization: 'Basic bXktY2xpZW50Om15LXNlY3JldA==',
    'Accept-Language': LOCALE || 'ru-RU',
  },
});
