<template>
  <UiPopover v-model="isOpened" :trigger="['click']">
    <FilterValue :is-opened="isOpened" @click="isOpened = !isOpened">
      <slot name="header" />
    </FilterValue>
    <template #content>
      <slot name="content" />
    </template>
  </UiPopover>
</template>

<script
  setup
  lang="ts"
>
import { Ref, ref } from 'vue';

import { UiPopover } from '@/ui';

import FilterValue from './filter-value.vue';

const isOpened: Ref<boolean> = ref(false);
</script>

