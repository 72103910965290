/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Sku,
    SkuFromJSON,
    SkuFromJSONTyped,
    SkuToJSON,
} from './Sku';

/**
 * 
 * @export
 * @interface InvoiceNotAddedItem
 */
export interface InvoiceNotAddedItem {
    /**
     * 
     * @type {number}
     * @memberof InvoiceNotAddedItem
     */
    expectedAmount: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceNotAddedItem
     */
    actualAmount: number;
    /**
     * 
     * @type {Sku}
     * @memberof InvoiceNotAddedItem
     */
    sku?: Sku;
}

export function InvoiceNotAddedItemFromJSON(json: any): InvoiceNotAddedItem {
    return InvoiceNotAddedItemFromJSONTyped(json, false);
}

export function InvoiceNotAddedItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceNotAddedItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'expectedAmount': json['expectedAmount'],
        'actualAmount': json['actualAmount'],
        'sku': !exists(json, 'sku') ? undefined : SkuFromJSON(json['sku']),
    };
}

export function InvoiceNotAddedItemToJSON(value?: InvoiceNotAddedItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'expectedAmount': value.expectedAmount,
        'actualAmount': value.actualAmount,
        'sku': SkuToJSON(value.sku),
    };
}

