/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaginationResponse,
    PaginationResponseFromJSON,
    PaginationResponseFromJSONTyped,
    PaginationResponseToJSON,
} from './PaginationResponse';
import {
    PhotoBox,
    PhotoBoxFromJSON,
    PhotoBoxFromJSONTyped,
    PhotoBoxToJSON,
} from './PhotoBox';
import {
    PhotoBoxListResponseAllOf,
    PhotoBoxListResponseAllOfFromJSON,
    PhotoBoxListResponseAllOfFromJSONTyped,
    PhotoBoxListResponseAllOfToJSON,
} from './PhotoBoxListResponseAllOf';

/**
 * 
 * @export
 * @interface PhotoBoxListResponse
 */
export interface PhotoBoxListResponse {
    /**
     * количество элементов на одной странице
     * @type {number}
     * @memberof PhotoBoxListResponse
     */
    pageSize: number;
    /**
     * номер страницы
     * @type {number}
     * @memberof PhotoBoxListResponse
     */
    page: number;
    /**
     * общее количество страниц
     * @type {number}
     * @memberof PhotoBoxListResponse
     */
    totalPages: number;
    /**
     * общее количество элементов
     * @type {number}
     * @memberof PhotoBoxListResponse
     */
    totalItems: number;
    /**
     * 
     * @type {Array<PhotoBox>}
     * @memberof PhotoBoxListResponse
     */
    items: Array<PhotoBox>;
}

export function PhotoBoxListResponseFromJSON(json: any): PhotoBoxListResponse {
    return PhotoBoxListResponseFromJSONTyped(json, false);
}

export function PhotoBoxListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhotoBoxListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageSize': json['pageSize'],
        'page': json['page'],
        'totalPages': json['totalPages'],
        'totalItems': json['totalItems'],
        'items': ((json['items'] as Array<any>).map(PhotoBoxFromJSON)),
    };
}

export function PhotoBoxListResponseToJSON(value?: PhotoBoxListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pageSize': value.pageSize,
        'page': value.page,
        'totalPages': value.totalPages,
        'totalItems': value.totalItems,
        'items': ((value.items as Array<any>).map(PhotoBoxToJSON)),
    };
}

