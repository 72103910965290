<template>
  <BaseFilterWrapper>
    <template #header>
      <span v-if="selected" class="selected">
        {{ $t('requestsPage.dates.labelSelected') }}
      </span>
      <span v-else>
        {{ $t('requestsPage.dates.label') }}
      </span>
    </template>
    <template #content>
      <div ref="datesContainer" class="content">
        <div class="picker mb-4">
          {{ $t('requestsPage.dates.created') }}
          <UiRangePicker
            :placeholder="[
              $t('requestsPage.dates.from'),
              $t('requestsPage.dates.to'),
            ]"
            :get-popup-container="() => datesContainer"
            :allow-empty="[true, true]"
            :value="toDayjs(created)"
            format="DD.MM.YYYY"
            @update:value="handleUpdate('created', $event)"
          />
        </div>
        <div class="picker">
          {{ $t('requestsPage.dates.updated') }}
          <UiRangePicker
            :placeholder="[
              $t('requestsPage.dates.from'),
              $t('requestsPage.dates.to'),
            ]"
            :get-popup-container="() => datesContainer"
            :allow-empty="[true, true]"
            :value="toDayjs(updated)"
            format="DD.MM.YYYY"
            @update:value="handleUpdate('updated', $event)"
          />
        </div>
      </div>
    </template>
  </BaseFilterWrapper>
</template>

<script setup lang="ts">
import { DownOutlined } from '@ant-design/icons-vue';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { computed, ref } from 'vue';

import { UiButton, UiPopover, UiRangePicker } from '@/ui';
import BaseFilterWrapper from '@/ui/filter/base-filter-wrapper.vue';

dayjs.extend(utc);

type RangeValue = [number | undefined, number | undefined];

type Props = {
  created: RangeValue;
  updated: RangeValue;
};

type Key = keyof Props;

type Emits = {
  (e: `update:${Key}`, v: RangeValue): void;
};

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const selected = computed(() =>
  [props.created, props.updated].some((r) => r.some((v) => v !== undefined)),
);

const handleUpdate = (key: Key, range: [Dayjs | null, Dayjs | null] | null) => {
  const [from, to] = range ? fromDayjs(range) : [];

  emit(`update:${key}`, [from, to]);
};

const fromDayjs = (range: [Dayjs | null, Dayjs | null]) =>
  range.map((v) => (v ? v.startOf('date').valueOf() : undefined));

const toDayjs = (range: RangeValue) =>
  range.map((v) => (v !== undefined ? dayjs(v) : v));

const datesContainer = ref<HTMLElement>();
</script>

<style lang="stylus" scoped>
.button
  padding 8px 15px
  justify-content space-between
  font-size 14px
  color gray-10

  .selected
    color blue-6

.icon
  width 14px
  height 14px

.picker
  display grid
  grid-template-columns auto 240px
  align-items center
  gap 20px
</style>
