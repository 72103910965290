import { computed, Ref } from 'vue';

interface IUseBoundedProps {
  minLength?: number;
  maxLength?: number;
  defaultValue?: string;
  valueRef: Ref<string>;
}

export const useBoundedString = ({
  minLength,
  maxLength,
  defaultValue = undefined,
  valueRef,
}: IUseBoundedProps) => {
  const boundedValue = computed(() => {
    if (maxLength !== undefined && valueRef.value.length > maxLength) {
      return defaultValue;
    }

    if (minLength !== undefined && valueRef.value.length < minLength) {
      return defaultValue;
    }

    return valueRef.value;
  });

  return boundedValue;
};
