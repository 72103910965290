/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaginationResponse,
    PaginationResponseFromJSON,
    PaginationResponseFromJSONTyped,
    PaginationResponseToJSON,
} from './PaginationResponse';
import {
    ReservationItem,
    ReservationItemFromJSON,
    ReservationItemFromJSONTyped,
    ReservationItemToJSON,
} from './ReservationItem';
import {
    ReservationItemsResponseAllOf,
    ReservationItemsResponseAllOfFromJSON,
    ReservationItemsResponseAllOfFromJSONTyped,
    ReservationItemsResponseAllOfToJSON,
} from './ReservationItemsResponseAllOf';

/**
 * 
 * @export
 * @interface ReservationItemsResponse
 */
export interface ReservationItemsResponse {
    /**
     * количество элементов на одной странице
     * @type {number}
     * @memberof ReservationItemsResponse
     */
    pageSize: number;
    /**
     * номер страницы
     * @type {number}
     * @memberof ReservationItemsResponse
     */
    page: number;
    /**
     * общее количество страниц
     * @type {number}
     * @memberof ReservationItemsResponse
     */
    totalPages: number;
    /**
     * общее количество элементов
     * @type {number}
     * @memberof ReservationItemsResponse
     */
    totalItems: number;
    /**
     * 
     * @type {Array<ReservationItem>}
     * @memberof ReservationItemsResponse
     */
    items: Array<ReservationItem>;
}

export function ReservationItemsResponseFromJSON(json: any): ReservationItemsResponse {
    return ReservationItemsResponseFromJSONTyped(json, false);
}

export function ReservationItemsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReservationItemsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageSize': json['pageSize'],
        'page': json['page'],
        'totalPages': json['totalPages'],
        'totalItems': json['totalItems'],
        'items': ((json['items'] as Array<any>).map(ReservationItemFromJSON)),
    };
}

export function ReservationItemsResponseToJSON(value?: ReservationItemsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pageSize': value.pageSize,
        'page': value.page,
        'totalPages': value.totalPages,
        'totalItems': value.totalItems,
        'items': ((value.items as Array<any>).map(ReservationItemToJSON)),
    };
}

