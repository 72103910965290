<template>
  <PageLayout>
    <template #header>
      <PageHeader :title="$t('reservationsPage.title')">
        <NewReservationButton class="new-reservation-button" />
      </PageHeader>
    </template>

    <div class="body">
      <ReservationsTable />
    </div>
  </PageLayout>
</template>

<script setup lang="ts">
import { onBeforeRouteLeave } from 'vue-router';

import { useReservations } from '@/features/Reservation';
import { AppRoutes } from '@/router';
import { PageLayout } from '@/template';
import PageHeader from '@/template/page-header/index.vue';

import NewReservationButton from './components/NewReservationButton.vue';
import ReservationsTable from './components/ReservationsTable.vue';

const { resetSorting, resetFilters } = useReservations();

onBeforeRouteLeave((to, _, next) => {
  if (to.name !== AppRoutes.reservation.name) {
    resetSorting();
    resetFilters();
  }

  next();
});
</script>

<style lang="stylus" scoped>
.header-content
  flex 1
  margin-left auto
  margin-right auto
  display flex
  align-items center
  gap 8px


.title
  margin 0
  font-size 1.25rem
  font-weight 600

.body
  height 100%

.new-reservation-btn
  margin-left auto
</style>
