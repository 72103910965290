<template>
  <UiModal
    v-if="!isSuccess"
    :title="t('title')"
    :ok-text="t('order')"
    :cancel-text="$t('button.close')"
    :ok-button-props="{ disabled: !ids.length }"
    :confirm-loading="isLoading"
    class="!w-auto new-reservation-modal"
    centered
    padding
    footer
    @close="$emit('close')"
    @ok="handleOrder"
  >
    <div v-auto-animate="{ duration: 100 }" class="space-y-6">
      <div class="space-y-3">
        <h3 class="m-0">{{ t('rateTitle') }}</h3>

        <UiRadio.Group
          v-model:value="paymentRate"
          size="large"
          class="select-none"
        >
          <UiRadio.Button
            v-for="rate in availableTariffs"
            :key="rate"
            :value="rate"
          >
            <div class="flex items-center gap-2">
              {{ t(`paymentRate.${rate}`) }}
              <PaymentRateChip :rate="rate" :tooltip="false" />
            </div>
          </UiRadio.Button>
        </UiRadio.Group>
      </div>
      <div v-if="!!featureFlags.useOldTariffs">
        <label class="label">
          <UiCheckbox
            id="useNewTariff"
            v-model:checked="useNewTariff"
            class="checkbox"
            type="checkbox"
          />
          <span>
            {{ t('useNewTariff') }}
          </span>
        </label>
      </div>
      <div ref="dropZoneRef" class="space-y-3">
        <div class="flex items-center gap-2">
          <h3 class="m-0">{{ t('fileTitle') }}</h3>

          <UiTooltip>
            <QuestionCircleOutlined class="!text-gray-7" />

            <template #title>
              <div>{{ t('rulesTitle') }}</div>
              <ul
                class="mt-2 flex flex-col gap-1 text-xs list-disc list-inside"
              >
                <li v-for="i in 2" :key="i">{{ t('rules.' + (i - 1)) }}</li>
              </ul>
            </template>
          </UiTooltip>
        </div>

        <div v-auto-animate="{ duration: 100 }">
          <div v-if="file" class="flex gap-2 h-full items-center">
            <PaperClipOutlined class="text-black opacity-50" />
            <button
              class="text-blue-6 cursor-pointer"
              @click="openFileDialog()"
            >
              {{ file.name }}
            </button>
            <button
              class="text-red-5 flex items-center cursor-pointer"
              @click="file = undefined"
            >
              <DeleteOutlined />
            </button>
          </div>

          <UiButton
            v-else
            type="default"
            class="w-full"
            @click="openFileDialog()"
          >
            <UploadOutlined class="opacity-25" />
            {{ t('selectFile') }}
          </UiButton>
        </div>
      </div>

      <UiAlert v-if="fileError" type="error" show-icon>
        <template #icon>
          <CloseCircleOutlined style="font-size: 20px" />
        </template>

        <template #message>
          <div class="text-sm font-medium">
            {{ tError(fileError) }}
          </div>
        </template>
      </UiAlert>

      <template v-else-if="Array.isArray(creationError?.detail)">
        <UiAlert
          v-for="e in creationError?.detail"
          :key="e.code"
          type="error"
          show-icon
        >
          <template #icon>
            <CloseCircleOutlined style="font-size: 20px" />
          </template>

          <template #message>
            <div class="text-sm font-medium">
              {{ tError(e.code) }}
            </div>
          </template>

          <template v-if="e.code && e.detail" #description>
            <div class="text-xs">
              <div v-if="e.code === 1003">
                {{ e.detail.skuExternalIDs.join(', ') }}
              </div>

              <div v-else-if="e.code === 1017 || e.code === 1018">
                {{ e.detail.skuInfoList.map((x) => x.externalId).join(', ') }}
              </div>
            </div>
          </template>
        </UiAlert>
      </template>

      <UiAlert v-else-if="creationError" type="error" show-icon>
        <template #icon>
          <CloseCircleOutlined style="font-size: 20px" />
        </template>

        <template #message>
          <div class="text-sm font-medium">
            {{ tError(creationError?.code) }}
          </div>
        </template>

        <template
          v-if="creationError.code && creationError.detail"
          #description
        >
          <div class="text-xs">
            <div v-if="creationError.code === 1003">
              {{ creationError.detail.skuExternalIDs.join(', ') }}
            </div>

            <div
              v-else-if="
                creationError.code === 1017 || creationError.code === 1018
              "
            >
              {{
                creationError.detail.skuInfoList
                  .map((x) => x.externalId)
                  .join(', ')
              }}
            </div>
          </div>
        </template>
      </UiAlert>
    </div>

    <div
      v-if="isLoading"
      class="fixed inset-0 z-50 grid place-items-center bg-black/10"
    >
      <UiSpin />
    </div>
  </UiModal>

  <UiModal
    v-else-if="creationError?.detail"
    :title="t('notReservedProducts')"
    :cancel-text="$t('button.close')"
    :ok-button-props="{ style: 'display: none' }"
    class="!w-[560px]"
    centered
    padding
    footer
    @close="$emit('close')"
  >
    <div class="flex flex-col gap-2">
      <UiAlert
        v-for="e in (Array.isArray(creationError.detail)
          ? creationError.detail
          : [creationError]
        ).sort((a) => (a.code === 1003 ? -1 : 1))"
        :key="e.code"
        :type="e.code === 1003 ? 'error' : 'warning'"
        show-icon
      >
        <template #icon>
          <CloseCircleOutlined v-if="e.code === 1003" style="font-size: 20px" />
          <ExclamationCircleOutlined v-else style="font-size: 20px" />
        </template>

        <template #message>
          <div class="text-sm font-medium">
            {{ tError(e.code) }}
          </div>
        </template>

        <template v-if="e.code && e.detail" #description>
          <div class="text-xs">
            <div v-if="e.code === 1003">
              {{ e.detail.skuExternalIDs.join(', ') }}
            </div>

            <div v-else-if="e.code === 1017 || e.code === 1018">
              {{ e.detail.skuInfoList.map((x) => x.externalId).join(', ') }}
            </div>
          </div>
        </template>
      </UiAlert>
    </div>
  </UiModal>
</template>

<script setup lang="ts">
import {
  CloseCircleOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  PaperClipOutlined,
  QuestionCircleOutlined,
  UploadOutlined,
} from '@ant-design/icons-vue';
import { useDropZone, useFileDialog } from '@vueuse/core';
import { message, Radio as UiRadio } from 'ant-design-vue';
import { reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { PaymentRate } from '@/api';
import PaymentRateChip from '@/components/PaymentRateChip.vue';
import { featureFlags } from '@/features/FeatureFlags';
import {
  CreateReservationError,
  useNewReservation,
} from '@/features/Reservation';
import {
  UiAlert,
  UiButton,
  UiCheckbox,
  UiModal,
  UiSpin,
  UiTooltip,
} from '@/ui';
import { readFileAsText } from '@/utils/file';

interface Emits {
  (e: 'close'): void;
}

const emit = defineEmits<Emits>();
const { t: baseT } = useI18n();
const { Free, Normal, Higher } = PaymentRate;

const t = (key: string, defaultMsg = '') =>
  baseT(`reservationsPage.new.${key}`, defaultMsg);

const tError = (key?: string | number) =>
  t(`errors.${key}`, baseT('unknown error'));

const file = ref<File>();
const ids = ref<number[]>([]);
const paymentRate = ref(PaymentRate.Free);

const availableTariffs = featureFlags.useHigherTariff
  ? [Free, Normal, Higher]
  : [Free, Normal];

const useNewTariff = ref(!featureFlags.useOldTariffs);
const fileError = ref('');
const creationError = ref<CreateReservationError>();

watch(file, async (newFile) => {
  if (!newFile) {
    fileError.value = '';
    creationError.value = undefined;
    ids.value = [];

    return;
  }

  if (newFile.type !== 'text/csv') {
    fileError.value = 'invalidFileType';

    return;
  }

  ids.value = await processIds(newFile);

  if (!ids.value.length) {
    fileError.value = 'noValidValues';

    return;
  }

  fileError.value = '';
  creationError.value = undefined;
});

const processIds = async (file: File | undefined) => {
  if (!file) return [];

  const data = await readFileAsText(file);

  const processed = data
    .split('\n')
    .map((v) => v.split(',').at(0)?.trim())
    .map((v) => (v ? Number(v) : undefined))
    .filter((v): v is number => v !== undefined && !isNaN(v));

  return Array.from(new Set(processed));
};

const { open: openFileDialog, onChange } = useFileDialog({
  accept: '.csv',
  multiple: false,
});

onChange((files) => {
  file.value = files?.[0];
});

const dropZoneRef = ref<HTMLElement>();

useDropZone(dropZoneRef, (files) => {
  file.value = files?.[0];
});

const { createReservation, isLoading, isSuccess } = useNewReservation();

const handleOrder = () => {
  creationError.value = undefined;

  createReservation(reactive({ paymentRate, ids, useNewTariff }), {
    onError: (e) => {
      creationError.value = e;
      message.error(tError('notCreated'));
    },
    onSuccess: ({ error }) => {
      message.success(t('success'));

      if (!error?.code) {
        emit('close');
      } else {
        creationError.value = error;
      }
    },
  });
};
</script>

<style lang="stylus" scoped>
.label
  cursor pointer

.checkbox
  height 20px
  width 20px
  margin-right 4px
</style>

<style lang="stylus">
.new-reservation-modal .ant-modal-close-x
  font-size 18px
</style>
