<template>
  <UiButton type="primary" class="new-reservation-btn" @click="isOpen = true">
    {{ $t('reservationsPage.new.title') }}

    <NewReservation v-if="isOpen" @close="isOpen = false" />
  </UiButton>
</template>

<script setup lang="ts">
import { ref } from 'vue';

import { UiButton } from '@/ui';

import NewReservation from './NewReservation.vue';

const isOpen = ref(false);
</script>
