import flagsmith from 'flagsmith';
import type { IFlagsmith } from 'flagsmith/types';
import { reactive } from 'vue';

import { keys } from '@/utils/collection';

type FlagOption =
  | 'old_payment_rates_switch'
  | 'payment_rate_higher'
  | 'photo_upload'
  | 'photographer_flow'
  | 'quality_control'
  | 'status_panoramic';

type TraitOption = 'role';

const typedFlagsmith = flagsmith as IFlagsmith<FlagOption, TraitOption>;

const featureKeys = {
  qualityControl: 'quality_control',
  photoUpload: 'photo_upload',
  photographerFlow: 'photographer_flow',
  statusPanoramic: 'status_panoramic',
  useHigherTariff: 'payment_rate_higher',
  useOldTariffs: 'old_payment_rates_switch',
} satisfies Record<string, FlagOption>;

type FeatureKey = keyof typeof featureKeys;

const getFeatureValue = (key: FeatureKey): boolean =>
  typedFlagsmith.hasFeature(featureKeys[key]);

const getFeatureFlags = () =>
  Object.fromEntries(
    keys(featureKeys).map((key) => [key, getFeatureValue(key)]),
  ) as Record<FeatureKey, boolean>;

export const featureFlags = reactive(getFeatureFlags());

eventManager.on('flagsmith:change', () => {
  Object.assign(featureFlags, getFeatureFlags());
});
