/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaginationResponse,
    PaginationResponseFromJSON,
    PaginationResponseFromJSONTyped,
    PaginationResponseToJSON,
} from './PaginationResponse';
import {
    PhotoRequest,
    PhotoRequestFromJSON,
    PhotoRequestFromJSONTyped,
    PhotoRequestToJSON,
} from './PhotoRequest';
import {
    PhotoRequestListResponseAllOf,
    PhotoRequestListResponseAllOfFromJSON,
    PhotoRequestListResponseAllOfFromJSONTyped,
    PhotoRequestListResponseAllOfToJSON,
} from './PhotoRequestListResponseAllOf';

/**
 * 
 * @export
 * @interface PhotoRequestListResponse
 */
export interface PhotoRequestListResponse {
    /**
     * количество элементов на одной странице
     * @type {number}
     * @memberof PhotoRequestListResponse
     */
    pageSize: number;
    /**
     * номер страницы
     * @type {number}
     * @memberof PhotoRequestListResponse
     */
    page: number;
    /**
     * общее количество страниц
     * @type {number}
     * @memberof PhotoRequestListResponse
     */
    totalPages: number;
    /**
     * общее количество элементов
     * @type {number}
     * @memberof PhotoRequestListResponse
     */
    totalItems: number;
    /**
     * Список заявок на фотостудию
     * @type {Array<PhotoRequest>}
     * @memberof PhotoRequestListResponse
     */
    items: Array<PhotoRequest>;
    /**
     * Общее количество позиций на фото заявках
     * @type {number}
     * @memberof PhotoRequestListResponse
     */
    totalItemCount: number;
}

export function PhotoRequestListResponseFromJSON(json: any): PhotoRequestListResponse {
    return PhotoRequestListResponseFromJSONTyped(json, false);
}

export function PhotoRequestListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhotoRequestListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageSize': json['pageSize'],
        'page': json['page'],
        'totalPages': json['totalPages'],
        'totalItems': json['totalItems'],
        'items': ((json['items'] as Array<any>).map(PhotoRequestFromJSON)),
        'totalItemCount': json['totalItemCount'],
    };
}

export function PhotoRequestListResponseToJSON(value?: PhotoRequestListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pageSize': value.pageSize,
        'page': value.page,
        'totalPages': value.totalPages,
        'totalItems': value.totalItems,
        'items': ((value.items as Array<any>).map(PhotoRequestToJSON)),
        'totalItemCount': value.totalItemCount,
    };
}

