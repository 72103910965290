/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PhotoModelAgency,
    PhotoModelAgencyFromJSON,
    PhotoModelAgencyFromJSONTyped,
    PhotoModelAgencyToJSON,
} from './PhotoModelAgency';

/**
 * 
 * @export
 * @interface PhotoModelWithAgency
 */
export interface PhotoModelWithAgency {
    /**
     * Идентификатор фотомодели
     * @type {number}
     * @memberof PhotoModelWithAgency
     */
    id: number;
    /**
     * Дата создания
     * @type {number}
     * @memberof PhotoModelWithAgency
     */
    created: number;
    /**
     * Дата последнего обновления
     * @type {number}
     * @memberof PhotoModelWithAgency
     */
    updated: number;
    /**
     * Имя фотомодели
     * @type {string}
     * @memberof PhotoModelWithAgency
     */
    firstName: string;
    /**
     * Фамилия фотомодели
     * @type {string}
     * @memberof PhotoModelWithAgency
     */
    lastName: string;
    /**
     * Отчество фотомодели
     * @type {string}
     * @memberof PhotoModelWithAgency
     */
    patronymic: string;
    /**
     * Дополнительная информация о фотомодели
     * @type {string}
     * @memberof PhotoModelWithAgency
     */
    comment: string;
    /**
     * Ставка за час в минимальной денежной единице (в копейках/тийинах/...)
     * @type {number}
     * @memberof PhotoModelWithAgency
     */
    hourlyRate: number;
    /**
     * 
     * @type {PhotoModelAgency}
     * @memberof PhotoModelWithAgency
     */
    agency?: PhotoModelAgency;
}

export function PhotoModelWithAgencyFromJSON(json: any): PhotoModelWithAgency {
    return PhotoModelWithAgencyFromJSONTyped(json, false);
}

export function PhotoModelWithAgencyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhotoModelWithAgency {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'created': json['created'],
        'updated': json['updated'],
        'firstName': json['first_name'],
        'lastName': json['last_name'],
        'patronymic': json['patronymic'],
        'comment': json['comment'],
        'hourlyRate': json['hourlyRate'],
        'agency': !exists(json, 'agency') ? undefined : PhotoModelAgencyFromJSON(json['agency']),
    };
}

export function PhotoModelWithAgencyToJSON(value?: PhotoModelWithAgency | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': value.created,
        'updated': value.updated,
        'first_name': value.firstName,
        'last_name': value.lastName,
        'patronymic': value.patronymic,
        'comment': value.comment,
        'hourlyRate': value.hourlyRate,
        'agency': PhotoModelAgencyToJSON(value.agency),
    };
}

