/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ResponseError,
    ResponseErrorFromJSON,
    ResponseErrorFromJSONTyped,
    ResponseErrorToJSON,
} from './ResponseError';

/**
 * 
 * @export
 * @interface PhotoRequestMovePhotoResult
 */
export interface PhotoRequestMovePhotoResult {
    /**
     * 
     * @type {ResponseError}
     * @memberof PhotoRequestMovePhotoResult
     */
    error?: ResponseError;
    /**
     * timestamp UTC
     * @type {number}
     * @memberof PhotoRequestMovePhotoResult
     */
    createdAt?: number;
}

export function PhotoRequestMovePhotoResultFromJSON(json: any): PhotoRequestMovePhotoResult {
    return PhotoRequestMovePhotoResultFromJSONTyped(json, false);
}

export function PhotoRequestMovePhotoResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhotoRequestMovePhotoResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'error': !exists(json, 'error') ? undefined : ResponseErrorFromJSON(json['error']),
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
    };
}

export function PhotoRequestMovePhotoResultToJSON(value?: PhotoRequestMovePhotoResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'error': ResponseErrorToJSON(value.error),
        'createdAt': value.createdAt,
    };
}

