<template>
  <PageLayout>
    <ScannerEmulator v-if="isDevelopment" />
    <template #header>
      <PageHeader :title="$t('generate barcodes.title')">
        <div class="flex items-center gap-2">
          <span>Сгенерировать штрихкод на:</span>
          <div class="flex gap-1">
            <UiButton
              :disabled="!canEditBoxes"
              type="primary"
              @click="handleGenerateBarcode(EGenerateTypes.OVERSIZED)"
            >
              <template #icon>
                <plus-outlined />
              </template>
              {{ $t('generate barcodes.actions.oversized') }}
            </UiButton>
            <UiButton
              :disabled="!canEditBoxes"
              type="primary"
              @click="handleGenerateBarcode(EGenerateTypes.PHOTOREQUEST)"
            >
              <plus-outlined />
              {{ $t('generate barcodes.actions.photorequest') }}
            </UiButton>
          </div>
        </div>
      </PageHeader>
    </template>
    <div class="content-wrapper">
      <UiTabs v-model:activeKey="activeTab" size="large" class="content-tabs">
        <UiTabPane v-for="tab in tabs" :key="tab.key">
          <template #tab>
            <span data-test-id="tabs__header">
              {{ tab.title }}
            </span>
          </template>
          <component
            :is="tab.component"
            :data-test-id="activeTab === tab.key ? 'tabs__active' : ''"
          />
        </UiTabPane>
      </UiTabs>
    </div>
  </PageLayout>
  <generate-barcode-modal
    v-if="isShowBarcodeModal && !!barcodeModalType"
    :type="barcodeModalType"
    @close="handleCloseBarcodeModal"
  />
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
export default defineComponent({ name: 'barcodes-page' });
</script>

<script setup lang="ts">
import { PlusOutlined } from '@ant-design/icons-vue';
import { useI18n } from 'vue-i18n';

import { IS_DEVELOPMENT as isDevelopment } from '@/config';
import { useAuth } from '@/features/Auth';
import { ScannerEmulator } from '@/features/ScannerEmulator';
import GenerateBarcodeModal from '@/pages/barcodes/components/generateBarcode/index.vue';
import { PageHeader, PageLayout } from '@/template';
import { UiButton, UiTabPane, UiTabs } from '@/ui';

import OversizedTable from './components/oversizedTable.vue';
import PhotoRequestsTable from './components/photorequestsTable.vue';
import { EGenerateTypes, TGenerateType } from './data';

const { t } = useI18n();
const { canEditBoxes } = useAuth();

const tabs = [
  {
    key: 1,
    title: t('generate barcodes.tabs.oversized'),
    component: OversizedTable,
  },
  {
    key: 2,
    title: t('generate barcodes.tabs.photorequest'),
    component: PhotoRequestsTable,
  },
];

const activeTab = ref(tabs[0].key);
const isShowBarcodeModal = ref(false);
const barcodeModalType = ref<TGenerateType | undefined>();

const handleCloseBarcodeModal = () => {
  isShowBarcodeModal.value = false;
  barcodeModalType.value = undefined;
};

const handleGenerateBarcode = (type: TGenerateType) => {
  isShowBarcodeModal.value = true;
  barcodeModalType.value = type;
};
</script>

<style lang="stylus" scoped>
.header-actions
  display flex
  gap 12px

.content-wrapper
  border-bottom 1px solid #f0f0f0
  padding-bottom 25px
  height 100%

  :deep() .ant-tabs,
  :deep() .ant-tabs-content,
  :deep() .ant-tabs-tabpane
    height 100%

  :deep() .ant-tabs-nav
    border none
    padding 0 15px
    margin-bottom 0
  :deep() .ant-tabs-tab
    padding 16px 0
    color #8C8C8C
  :deep() .ant-tabs-ink-bar
    height 3px

.content-tabs
  background-color White()
</style>
