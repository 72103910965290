<template>
  <UiButton
    type="default"
    class="button filter-value__button"
    size="large"
    data-test-id="button__filter"
  >
    <slot />
    <icon-ui
      name="navigation/chevron-down_line"
      size="12"
      :class="{
        'filter-value-chevron': true,
        'filter-value-chevron-opened': props.isOpened,
      }"
    />
  </UiButton>
</template>

<script setup lang="ts">
import { DownOutlined } from '@ant-design/icons-vue';

import { BaseButton, UiButton } from '@/ui';

const props = defineProps({
  isOpened: {
    type: Boolean,
    required: true,
  },
});
</script>

<style lang="stylus" scoped>

.filter-value__button
  font-size 0.75rem!important
.filter-value-chevron
  fill Gray(LT16)
  transition transform 0.2s ease

.filter-value-chevron-opened
  transform rotate(180deg)
</style>
