/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Box,
    BoxFromJSON,
    BoxFromJSONTyped,
    BoxToJSON,
} from './Box';

/**
 * 
 * @export
 * @interface BoxTransfer
 */
export interface BoxTransfer {
    /**
     * 
     * @type {number}
     * @memberof BoxTransfer
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof BoxTransfer
     */
    idempotencyKey: string;
    /**
     * 
     * @type {Box}
     * @memberof BoxTransfer
     */
    box: Box;
    /**
     * 
     * @type {number}
     * @memberof BoxTransfer
     */
    created: number;
    /**
     * 
     * @type {number}
     * @memberof BoxTransfer
     */
    updated: number;
}

export function BoxTransferFromJSON(json: any): BoxTransfer {
    return BoxTransferFromJSONTyped(json, false);
}

export function BoxTransferFromJSONTyped(json: any, ignoreDiscriminator: boolean): BoxTransfer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'idempotencyKey': json['idempotencyKey'],
        'box': BoxFromJSON(json['box']),
        'created': json['created'],
        'updated': json['updated'],
    };
}

export function BoxTransferToJSON(value?: BoxTransfer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'idempotencyKey': value.idempotencyKey,
        'box': BoxToJSON(value.box),
        'created': value.created,
        'updated': value.updated,
    };
}

