/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaginationResponse,
    PaginationResponseFromJSON,
    PaginationResponseFromJSONTyped,
    PaginationResponseToJSON,
} from './PaginationResponse';
import {
    PhotoRequestItem,
    PhotoRequestItemFromJSON,
    PhotoRequestItemFromJSONTyped,
    PhotoRequestItemToJSON,
} from './PhotoRequestItem';
import {
    PhotoRequestItemResponseAllOf,
    PhotoRequestItemResponseAllOfFromJSON,
    PhotoRequestItemResponseAllOfFromJSONTyped,
    PhotoRequestItemResponseAllOfToJSON,
} from './PhotoRequestItemResponseAllOf';

/**
 * 
 * @export
 * @interface PhotoRequestItemResponse
 */
export interface PhotoRequestItemResponse {
    /**
     * количество элементов на одной странице
     * @type {number}
     * @memberof PhotoRequestItemResponse
     */
    pageSize: number;
    /**
     * номер страницы
     * @type {number}
     * @memberof PhotoRequestItemResponse
     */
    page: number;
    /**
     * общее количество страниц
     * @type {number}
     * @memberof PhotoRequestItemResponse
     */
    totalPages: number;
    /**
     * общее количество элементов
     * @type {number}
     * @memberof PhotoRequestItemResponse
     */
    totalItems: number;
    /**
     * Элемент заявки
     * @type {Array<PhotoRequestItem>}
     * @memberof PhotoRequestItemResponse
     */
    items: Array<PhotoRequestItem>;
}

export function PhotoRequestItemResponseFromJSON(json: any): PhotoRequestItemResponse {
    return PhotoRequestItemResponseFromJSONTyped(json, false);
}

export function PhotoRequestItemResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhotoRequestItemResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageSize': json['pageSize'],
        'page': json['page'],
        'totalPages': json['totalPages'],
        'totalItems': json['totalItems'],
        'items': ((json['items'] as Array<any>).map(PhotoRequestItemFromJSON)),
    };
}

export function PhotoRequestItemResponseToJSON(value?: PhotoRequestItemResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pageSize': value.pageSize,
        'page': value.page,
        'totalPages': value.totalPages,
        'totalItems': value.totalItems,
        'items': ((value.items as Array<any>).map(PhotoRequestItemToJSON)),
    };
}

