import brakSound from '@/assets/sounds/brak.wav';
import errorSound from '@/assets/sounds/error.wav';
import successSound from '@/assets/sounds/success.wav';

export const playSound = (sound: string) => {
  const audio = new Audio(sound);

  audio.play();
};

export const playSuccess = () => playSound(successSound);

export const playError = () => playSound(errorSound);

export const playBrak = () => playSound(brakSound);

export const useSoundSignal = () => {
  return <T>(promise: Promise<T>) =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async (resolve: (result: T) => void, reject) => {
      try {
        const result = await promise;

        playSuccess();
        resolve(result);
      } catch (e) {
        playError();
        reject(e);
      }
    });
};
