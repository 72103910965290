<template>
  <UiTooltip v-if="isVisible" :title="tooltip ? $t(`paymentRate.${rate}`) : ''">
    <div class="payment-rate-chip">
      <span>{{ paymentSymbol }}</span>
      <DoubleRightOutlined v-if="rate === Higher" class="payment-rate-icon" />
      <span v-if="useTitle">{{ title }}</span>
    </div>
  </UiTooltip>
</template>

<script setup lang="ts">
import { DoubleRightOutlined } from '@ant-design/icons-vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { PaymentRate } from '@/api';
import { COUNTRY } from '@/config';
import { featureFlags } from '@/features/FeatureFlags';
import { UiTooltip } from '@/ui';

interface Props {
  rate: PaymentRate;
  tooltip?: boolean | undefined;
  useTitle?: boolean | undefined;
}

const props = withDefaults(defineProps<Props>(), {
  tooltip: true,
  useTitle: false,
});

const { Normal, Higher } = PaymentRate;
const { t } = useI18n();

const availableTariffs = featureFlags.useHigherTariff
  ? [Normal, Higher]
  : [Normal];

const isVisible = computed(() => availableTariffs.includes(props.rate));
const title = computed(() => t(`modal.invoice.payment rate.${props.rate}`));
const paymentSymbol = COUNTRY === 'RU' ? '₽' : '$';
</script>

<style lang="stylus" scoped>
.payment-rate-chip
  height 26px
  padding 4px 8px
  border-radius 20px
  display inline-flex
  justify-content center
  align-items center
  gap 4px
  font-size 14px
  font-weight 500
  background-color geekblue-1
  color #000

.payment-rate-icon
  rotate -90deg
</style>
