/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PhotoRequestStatus,
    PhotoRequestStatusFromJSON,
    PhotoRequestStatusFromJSONTyped,
    PhotoRequestStatusToJSON,
} from './PhotoRequestStatus';
import {
    ShootingType,
    ShootingTypeFromJSON,
    ShootingTypeFromJSONTyped,
    ShootingTypeToJSON,
} from './ShootingType';

/**
 * 
 * @export
 * @interface PhotoRequest
 */
export interface PhotoRequest {
    /**
     * Идентификатор заявки на фото студию
     * @type {number}
     * @memberof PhotoRequest
     */
    id: number;
    /**
     * Идентификатор родительской заявки на фото студию
     * @type {number}
     * @memberof PhotoRequest
     */
    parentId?: number;
    /**
     * 
     * @type {PhotoRequestStatus}
     * @memberof PhotoRequest
     */
    status: PhotoRequestStatus;
    /**
     * 
     * @type {Array<ShootingType>}
     * @memberof PhotoRequest
     */
    shootingTypes: Array<ShootingType>;
    /**
     * Количество позиций на фото в заявке
     * @type {number}
     * @memberof PhotoRequest
     */
    itemCount: number;
    /**
     * 
     * @type {number}
     * @memberof PhotoRequest
     */
    photoBoxID: number | null;
    /**
     * Дата создания заявки
     * @type {number}
     * @memberof PhotoRequest
     */
    created: number;
    /**
     * Дата последнего обновления
     * @type {number}
     * @memberof PhotoRequest
     */
    updated: number;
    /**
     * 
     * @type {number}
     * @memberof PhotoRequest
     */
    producerID: number | null;
    /**
     * 
     * @type {number}
     * @memberof PhotoRequest
     */
    photographerID: number | null;
    /**
     * 
     * @type {number}
     * @memberof PhotoRequest
     */
    retoucherID: number | null;
    /**
     * 
     * @type {number}
     * @memberof PhotoRequest
     */
    photoModelID: number | null;
    /**
     * 
     * @type {string}
     * @memberof PhotoRequest
     */
    photoLink: string;
    /**
     * 
     * @type {string}
     * @memberof PhotoRequest
     */
    photoLinkBefore: string;
    /**
     * 
     * @type {string}
     * @memberof PhotoRequest
     */
    photoLinkAfter: string;
    /**
     * 
     * @type {number}
     * @memberof PhotoRequest
     */
    invoiceID: number | null;
}

export function PhotoRequestFromJSON(json: any): PhotoRequest {
    return PhotoRequestFromJSONTyped(json, false);
}

export function PhotoRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhotoRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'status': PhotoRequestStatusFromJSON(json['status']),
        'shootingTypes': ((json['shootingTypes'] as Array<any>).map(ShootingTypeFromJSON)),
        'itemCount': json['itemCount'],
        'photoBoxID': json['photoBoxID'],
        'created': json['created'],
        'updated': json['updated'],
        'producerID': json['producerID'],
        'photographerID': json['photographerID'],
        'retoucherID': json['retoucherID'],
        'photoModelID': json['photoModelID'],
        'photoLink': json['photoLink'],
        'photoLinkBefore': json['photoLinkBefore'],
        'photoLinkAfter': json['photoLinkAfter'],
        'invoiceID': json['invoiceID'],
    };
}

export function PhotoRequestToJSON(value?: PhotoRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'parentId': value.parentId,
        'status': PhotoRequestStatusToJSON(value.status),
        'shootingTypes': ((value.shootingTypes as Array<any>).map(ShootingTypeToJSON)),
        'itemCount': value.itemCount,
        'photoBoxID': value.photoBoxID,
        'created': value.created,
        'updated': value.updated,
        'producerID': value.producerID,
        'photographerID': value.photographerID,
        'retoucherID': value.retoucherID,
        'photoModelID': value.photoModelID,
        'photoLink': value.photoLink,
        'photoLinkBefore': value.photoLinkBefore,
        'photoLinkAfter': value.photoLinkAfter,
        'invoiceID': value.invoiceID,
    };
}

