/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PhotoRequestError,
    PhotoRequestErrorFromJSON,
    PhotoRequestErrorFromJSONTyped,
    PhotoRequestErrorToJSON,
} from './PhotoRequestError';

/**
 * 
 * @export
 * @interface BulkDeletePhotoRequestResponse
 */
export interface BulkDeletePhotoRequestResponse {
    /**
     * Ошибки при удалении фото заявок
     * @type {Array<PhotoRequestError>}
     * @memberof BulkDeletePhotoRequestResponse
     */
    errors: Array<PhotoRequestError>;
}

export function BulkDeletePhotoRequestResponseFromJSON(json: any): BulkDeletePhotoRequestResponse {
    return BulkDeletePhotoRequestResponseFromJSONTyped(json, false);
}

export function BulkDeletePhotoRequestResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkDeletePhotoRequestResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'errors': ((json['errors'] as Array<any>).map(PhotoRequestErrorFromJSON)),
    };
}

export function BulkDeletePhotoRequestResponseToJSON(value?: BulkDeletePhotoRequestResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'errors': ((value.errors as Array<any>).map(PhotoRequestErrorToJSON)),
    };
}

