<template>
  <div>
    <div
      v-if="!state.loading && state.invoice?.type === InvoiceType.Acceptance"
      class="content-header"
    >
      <Count :expected-amount="state.invoice?.expectedAmount" />
    </div>
    <div
      ref="tableWrapperRef"
      :class="[
        'content-table editable',
        { stretched: !state.invoiceItems.items.length },
      ]"
    >
      <UiTable
        :columns="columns"
        :data-source="state.invoiceItems.items"
        bordered
        :custom-row="handleRowClick"
        row-key="id"
        :row-selection="
          canEditInvoices
            ? {
                selectedRowKeys: selectedRowKeys,
                onChange: onSelectChange,
              }
            : null
        "
        :row-class-name="props.getDefectedRowClass"
        :loading="state.loading"
      >
        <template #bodyCell="{ column, text, record }">
          <template v-if="column.key === 'barcode'">
            <barcode-cell :state="state" :record="record" />
          </template>
          <template v-if="column.key === 'sku'">
            {{ record.sku.title }}
          </template>
          <template v-if="column.key === 'photo'">
            <BaseImage :url="text" />
          </template>
          <template v-if="column.key === 'nomenclature'">
            <div class="nomenclature">{{ getLang(text) }}</div>
            <span v-if="record.paymentRate" class="table-payment-rate">
              <PaymentRateChip
                :rate="record.paymentRate"
                :tooltip="false"
                use-title
              />
            </span>
            <UiTag
              v-if="props.hasDefect(record)"
              class="defect-tag"
              data-test-id="tag__defect-item-invoice"
            >
              {{ props.getDefectTitle(record) }}
            </UiTag>
          </template>
          <template v-if="column.key === 'amount'">
            <div class="amount-wrapper">
              <span>{{ $t('pc.', { count: text }) }}</span>
              <UiDropdown
                v-if="canEditInvoices"
                :trigger="['click']"
                placement="bottomRight"
                destroy-popup-on-hide
              >
                <UiButton @click.stop>
                  <template #icon>
                    <EllipsisOutlined
                      class="ellipsis-icon"
                      data-test-id="button__kebab-menu-invoice"
                    />
                  </template>
                </UiButton>

                <template #overlay>
                  <UiMenu
                    :selectable="false"
                    mode="vertical"
                    data-test-id="menu__item-invoice"
                  >
                    <UiSubMenu v-if="canMarkDefects" key="defects">
                      <template #title>
                        <div
                          class="action-submenu-title-wrap"
                          data-test-id="button__defect-item-invoice"
                        >
                          <div class="action-submenu-title">
                            {{ $t('modal.invoice.defect.mark defect') }}
                          </div>
                          <RightOutlined class="action-submenu-icon" />
                        </div>
                      </template>
                      <UiMenuItem
                        v-for="item in state.defectReasons.available"
                        :key="item.id"
                        @click.stop="setDefect(record, item.id)"
                      >
                        {{ item.title }}
                      </UiMenuItem>
                      <UiMenuItem
                        v-if="props.hasDefect(record)"
                        key="undo"
                        class="action-undo-defect"
                        @click.stop="unsetDefect(record)"
                      >
                        {{ $t('modal.invoice.defect.types.undo mark defect') }}
                      </UiMenuItem>
                    </UiSubMenu>
                    <UiMenuItem
                      class="action-delete-item"
                      data-test-id="button__delete-item-invoice"
                      @click.stop="handleRemoveItem(record)"
                    >
                      <DeleteOutlined />
                      <span class="action-delete-item-title">
                        {{ $t('modal.invoice.defect.delete') }}
                      </span>
                    </UiMenuItem>
                  </UiMenu>
                </template>
              </UiDropdown>
            </div>
          </template>
        </template>
      </UiTable>
      <InfiniteLoader
        :key="state.invoiceItems.page"
        :has-more="state.invoiceItems.hasMore"
        :on-enter="handleInfiniteLoader"
      />
      <LinearLoader
        v-if="state.invoiceItems.hasMore"
        :style="{ width: '100%', margin: '0 0 1em 0' }"
      />
    </div>
    <RejectPanel
      v-if="isRowSelected"
      class="reject-panel"
      data-test-id="panel__action-invoice"
      :selected-amount="selectedRowKeys.length"
      :on-bulk-delete="onBulkDelete"
      :on-cancel-selection="onCancelSelection"
      :defect-reasons="state.defectReasons.available"
      :can-bulk-undo-defect="canBulkUndoDefect"
      @set="setBulkDefect"
      @unset="unsetBulkDefect"
    />
  </div>
</template>

<script setup lang="ts">
import {
  DeleteFilled,
  DeleteOutlined,
  EllipsisOutlined,
  RightOutlined,
} from '@ant-design/icons-vue';
import { computed, h, PropType, reactive, ref, toRefs, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';

import { DefectDetailed, InvoiceItem, InvoiceType } from '@/api';
import InfiniteLoader from '@/components/InfiniteLoader.vue';
import PaymentRateChip from '@/components/PaymentRateChip.vue';
import LinearLoader from '@/components/ui/linear-loader/index.vue';
import { useIsInViewWrapper } from '@/composables/useIsInView';
import { useAuth } from '@/features/Auth';
import { EIntroKeys, useFeatureIntro } from '@/features/FeatureIntro';
import { notification } from '@/features/Notifications';
import BarcodeCell from '@/pages/invoice/components/barcodeCell.vue';
import Qrcode from '@/pages/sku/components/Qrcode.vue';
import {
  BaseImage,
  CopyButton,
  UiButton,
  UiDropdown,
  UiMenu,
  UiMenuItem,
  UiSubMenu,
  UiTable,
  UiTag,
} from '@/ui';
import { getLang } from '@/utils/lang';

import { TInvoiceItemsState } from '../composables';
import Count from './count.vue';
import RejectPanel from './reject-panel.vue';

const props = defineProps({
  state: {
    type: Object as PropType<TInvoiceItemsState>,
    default: null,
  },
  hasCount: {
    type: Boolean,
    default: false,
  },
  isAccountingDisabled: {
    type: Boolean,
    default: true,
  },
  handleRowClick: {
    type: Function as PropType<
      (row: Record<string, unknown>) => Record<string, unknown>
    >,
    default: () => {},
  },
  handleRemoveItem: {
    type: Function as PropType<(item: InvoiceItem) => void>,
    default: () => {},
  },
  acceptInvoiceItem: {
    type: Function as PropType<
      (itemID: number, actualAmount: number) => Promise<InvoiceItem | undefined>
    >,
    default: () => {},
  },
  handleInfiniteLoader: {
    type: Function as PropType<() => void>,
    default: () => {},
  },
  bulkDeleteInvoiceItem: {
    type: Function as PropType<(itemIds: number[]) => Promise<number>>,
    default: () => {},
  },
  cancelDefect: {
    type: Function as PropType<(defectId: number) => Promise<void>>,
    default: () => {},
  },
  bulkCancelDefect: {
    type: Function as PropType<(skuIds: number[]) => Promise<void>>,
    default: () => {},
  },
  bulkMarkDefect: {
    type: Function as PropType<
      (skuIds: number[], reasonId: string) => Promise<number>
    >,
    default: () => {},
  },
  getDefectedRowClass: {
    type: Function as PropType<(record: InvoiceItem) => string>,
    default: () => {},
  },
  hasDefect: {
    type: Function as PropType<
      (invoiceItem: InvoiceItem) => DefectDetailed | undefined
    >,
    default: () => {},
  },
  getDefectTitle: {
    type: Function as PropType<(invoiceItem: InvoiceItem) => string>,
    default: () => {},
  },
});

const [showActionIntro] = useFeatureIntro(EIntroKeys.INVOICE_ACTION);
const [showBulkActionIntro] = useFeatureIntro(EIntroKeys.INVOICE_BULK_ACTION);
const { t } = useI18n();
const { canMarkDefects, canEditInvoices } = useAuth();
const tableWrapperRef = ref();

useIsInViewWrapper(tableWrapperRef);

const tableState = reactive<{
  selectedRowKeys: number[];
}>({
  selectedRowKeys: [],
});

const onSelectChange = (newSelectedRowKeys: number[]) => {
  tableState.selectedRowKeys = newSelectedRowKeys;
};

const isRowSelected = computed(() => tableState.selectedRowKeys.length > 0);

const onCancelSelection = () => {
  tableState.selectedRowKeys = [];
};

const onBulkDelete = async () => {
  const deletedAmount = await props.bulkDeleteInvoiceItem(
    tableState.selectedRowKeys,
  );

  onCancelSelection();

  notification.open(null, {
    message: `${t('pluralization.delete', deletedAmount)} ${t(
      'pluralization.product',
      deletedAmount,
    )}`,
    icon: h(DeleteFilled),
  });
};

const { selectedRowKeys } = toRefs(tableState);

const setDefect = async (invoiceItem: InvoiceItem, reasonId: string) => {
  if (invoiceItem.sku) {
    await props.bulkMarkDefect([invoiceItem.sku.id], reasonId);

    notification.success(`${t('pluralization.marked defect', 1)}`);
    showBulkActionIntro();
  }
};

const setBulkDefect = async (reasonId: string) => {
  const invoiceItems = props.state.invoiceItems.items.filter((item) =>
    tableState.selectedRowKeys.includes(item.id),
  );

  const skuIds = invoiceItems
    .map((item) => item.sku?.id)
    .filter((item) => Number(item)) as number[];

  if (skuIds.length) {
    await props.bulkMarkDefect(skuIds, reasonId);
    onCancelSelection();
    notification.success(`${t('pluralization.marked defect', skuIds.length)}`);
  }
};

const unsetDefect = async (invoiceItem: InvoiceItem) => {
  const skuId = invoiceItem.sku?.id;
  const defect = props.state.defects.find((defect) => defect.skuId === skuId);

  if (skuId && defect) {
    await props.cancelDefect(defect.id);

    notification.success(`${t('pluralization.defect removed', 1)}`);
  }
};

const unsetBulkDefect = async () => {
  const invoiceItems = props.state.invoiceItems.items.filter((item) =>
    tableState.selectedRowKeys.includes(item.id),
  );

  const skuIds = invoiceItems
    .map((item) => item.sku?.id)
    .filter((item) => Number(item)) as number[];

  const defects = props.state.defects.filter((defect) =>
    skuIds.includes(defect.skuId),
  );

  if (defects.length) {
    await props.bulkCancelDefect(defects.map((defect) => defect.id));

    onCancelSelection();

    notification.success(
      `${t('pluralization.defect removed', defects.length)}`,
    );
  }
};

const canBulkUndoDefect = computed(() => {
  const invoiceItems = props.state.invoiceItems.items.filter((item) =>
    tableState.selectedRowKeys.includes(item.id),
  );

  return invoiceItems.some((item) => props.hasDefect(item));
});

const columns = computed(() => {
  return [
    {
      title: t('photo'),
      key: 'photo',
      dataIndex: ['sku', 'photoURL'],
      width: 86,
    },
    {
      title: t('nomenclature'),
      key: 'nomenclature',
      dataIndex: ['sku', 'description'],
    },
    {
      title: 'SKU',
      key: 'sku',
      dataIndex: ['sku', 'title'],
      width: 210,
    },
    {
      title: t('barcode'),
      key: 'barcode',
      dataIndex: ['sku', 'barcode'],
      width: 170,
    },
    {
      title: t('checked'),
      key: 'amount',
      dataIndex: 'expectedAmount',
      width: 160,
    },
  ];
});

watchEffect(() => {
  if (props.state.invoiceItems.items.length) {
    showActionIntro();
  }
});
</script>
<style lang="stylus" scoped>


.content-wrapper
  overflow hidden
  height 100%
  display flex
  flex-direction column

.content-header
  margin-top 30px
  margin-bottom 20px
  display flex
  align-items top
  justify-content space-between
  min-height 22px

.content-table
  max-height 100%
  overflow auto
  :deep().table
    height auto
  &.editable
    :deep() .ant-empty
      min-height calc(100vh - 280px)
  &.stretched
    height 100%

.ellipsis-icon
  color Black()
  font-size 20px
  margin 0 10px
  cursor pointer

.action-submenu
  &-title-wrap
    position relative
  &-title
    width 120px
  &-icon
    position absolute
    right -16px
    top 50%
    transform: translateY(-50%)

.amount-wrapper
  display flex
  justify-content space-between
  align-items center

:deep().row-highlighted
  background #FEFFE6

.nomenclature
  overflow-wrap anywhere

.table-barcode
  display flex
  align-items center
  gap 8px

.table-payment-rate
  display inline-block
  margin-top 10px

.qr-code-container
  margin-bottom 46px

.defect-tag
  background-color #FADB14
  margin-top 10px
</style>

<style lang="stylus">

.action-undo-defect
  color blue-6 !important
  &:hover
    background-color blue-6 !important
    color gray-1 !important

.action-delete-item
  span
    flex: unset!important
  &-title
    padding-left 10px
    text-align left
    color: red-6
  .anticon
    color: red-6
  &:hover
    background-color red-6 !important
    .action-delete-item-title,
    .anticon
      color: gray-1
</style>
