<template>
  <nav class="nav">
    <ui-menu :sidebar="true" mode="inline" theme="dark">
      <template v-for="link in navLinks" :key="link.key">
        <menu-item
          v-if="link.name"
          :key="link.key"
          :class="{
            [activeClass]: isActive(link),
          }"
          :icon="link?.icon"
        >
          <router-link :to="{ name: link.name }">
            <div
              class="text-m"
              :class="[
                `link ${link.key}`,
                {
                  [activeClass]: isActive(link),
                },
              ]"
            >
              {{ $t(link.text) }}
            </div>
          </router-link>
        </menu-item>
        <ui-sub-menu
          v-else-if="link.links"
          :icon="link?.icon"
          @title-click.stop=""
        >
          <template #title>
            <div
              data-test-id="button__boxes"
              class="text-m"
              :class="[
                ` ${link.key}`,
                {
                  [activeClass]: link.links.find(
                    (el) => el && el.name === route.name,
                  ),
                },
              ]"
            >
              {{ $t(link.text) }}
            </div>
          </template>
          <menu-item
            v-for="sublink in link.links"
            :key="sublink.key"
            :class="{
              [activeClass]: isActive(sublink),
            }"
          >
            <router-link
              v-if="sublink"
              :key="sublink.key"
              :to="{ name: sublink.name }"
            >
              <component
                :is="sublink.icon"
                v-if="sublink.icon"
                class="w-4 h-4"
              />
              {{ $t(sublink.text) }}
            </router-link>
          </menu-item>
        </ui-sub-menu>
      </template>
    </ui-menu>
  </nav>
</template>
<script lang="ts">
import { MenuItem } from 'ant-design-vue';
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';

import { EIntroKeys, useFeatureIntro } from '@/features/FeatureIntro';
import { UiMenu, UiSubMenu } from '@/ui';

import { INavLink } from './types';

export default defineComponent({
  name: 'base-nav',
  components: {
    UiMenu,
    UiSubMenu,
    MenuItem,
  },
  props: {
    navLinks: prop<INavLink[] | undefined>({
      type: Array,
      default: () => [],
    }),
  },
  setup() {
    const route = useRoute();
    const [showIntro] = useFeatureIntro(EIntroKeys.BOXES);
    const activeClass = 'active-link text-m';

    const isActive = (link: INavLink) => {
      if (
        route.name === link.name ||
        link.dependences?.find((el) => el === route.name)
      ) {
        return true;
      }

      return false;
    };

    showIntro();

    return {
      MenuItem,
      route,
      activeClass,
      isActive,
    };
  },
});
</script>

<style lang="stylus" scoped>
nav
  display grid
  :deep()
    .ant-menu-inline-collapsed
      width 64px !important

    .ant-menu-item.active-link
      @apply bg-blue-6 text-white

      .ant-menu-item-icon,
      .ant-menu-item-icon > svg
        @apply fill-white

    .ant-menu-item-icon,
    .ant-menu-item-icon > svg
      width 16px
      height 16px

    .ant-menu-item-selected
      background transparent
</style>
