<template>
  <AConfigProvider>
    <template #renderEmpty><slot name="empty" /></template>
    <ATable
      v-bind="{ ...defaultProps, ...$attrs }"
      :class="{ table: true, 'only-header': isTableEmpty }"
      :locale="locale"
    >
      <template v-for="(_, name) in $slots" #[name]="slotData">
        <slot :name="name" v-bind="slotData" />
      </template>
    </ATable>
    <AEmpty
      v-if="!loading && isTableEmpty"
      class="table-empty"
      data-test-id="table__missing-data"
      :description="props.emptyText || defaultProps.emptyText"
    />
  </AConfigProvider>
</template>

<script setup lang="ts">
import {
  ConfigProvider as AConfigProvider,
  Empty as AEmpty,
  Table as ATable,
} from 'ant-design-vue';
import { computed, useAttrs, useSlots } from 'vue';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const defaultProps = {
  pagination: false,
  emptyText: t('empty'),
};

const props = defineProps({
  emptyText: {
    type: String,
    default: null,
  },
  emptyIcon: {
    type: Boolean,
    default: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const locale = {
  filterConfirm: 'Применить',
};

const attrs = useAttrs();
const slots = useSlots();

const isTableEmpty = computed(
  () =>
    !(attrs['data-source'] instanceof Array && attrs['data-source'].length) &&
    !slots['empty'],
);
</script>

<style lang="stylus" scoped>
:deep(.ant-table-content > table)
  border-radius 0!important

:deep(.ant-table-container table>thead>tr:first-child >*:first-child)
  border-start-start-radius 0!important
:deep(.ant-table-container table>thead>tr:first-child >*:last-child)
  border-start-end-radius 0!important
.table
  :deep()
    thead
      height 55px
    th.ant-table-cell
      position sticky
      top 0
      z-index 2
      background #f5f5f5
    .active-filter-color
      color #2495fe

.only-header
  :deep()
    .ant-table-placeholder
      display none

.table-empty
  text-align center
  height calc(100% - 57px)
  display flex
  flex-direction column
  justify-content center
  align-items center
  background-color #fff
  margin 0
  & :deep() .ant-empty
    height 140px
    display flex
    flex-direction column
    justify-content center
    align-items center
</style>
