import { computed, ComputedRef, Ref, ref } from 'vue';

import { ESortingDirection } from '@/components/table/types';

type Sorting<T extends string = string> = `${T}Asc` | `${T}Desc`;

type SortingField<T extends Sorting> = T extends `${infer F}Asc` ? F : never;

export { ESortingDirection as SortingDirection };

export const useSorting = <
  TSorting extends Sorting<string>,
  TField = SortingField<TSorting>,
  TDirection = ESortingDirection,
>(
  initialField: TField | undefined = undefined,
  initialDirection: TDirection | undefined = undefined,
) => {
  const sortingField = ref(initialField) as Ref<TField | undefined>;
  const sortingDirection = ref(initialDirection) as Ref<TDirection | undefined>;

  const sorting = computed(() =>
    sortingField.value && sortingDirection.value
      ? `${sortingField.value}${sortingDirection.value}`
      : undefined,
  ) as ComputedRef<TSorting>;

  const setField = (val: TField | undefined): void => {
    sortingField.value = val;
  };

  const setDirection = (val: TDirection | undefined): void => {
    sortingDirection.value = val;
  };

  const setSorting = (
    field: TField | undefined,
    direction: TDirection | undefined,
  ) => {
    setField(field);
    setDirection(direction);
  };

  const resetSorting = () => {
    setField(undefined);
    setDirection(undefined);
  };

  return {
    sorting,
    setSorting,
    resetSorting,
    sortingField,
    setField,
    sortingDirection,
    setDirection,
  };
};
