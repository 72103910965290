<template>
  <div class="wrapper text-s leading-short">
    <div class="amount-item">
      <span class="label">
        {{ $t('modal.discrepancy.accepted') }}
      </span>
      <span class="count">
        {{
          $t('pc.', { count: `${item.actualAmount} / ${item.expectedAmount}` })
        }}
      </span>
    </div>
    <div class="amount-item">
      <span class="label">
        <template v-if="diffCount > 0">
          {{ $t('modal.discrepancy.lost') }}
        </template>
        <template v-else>
          {{ $t('modal.discrepancy.extra') }}
        </template>
      </span>
      <span class="count lost-count">
        {{
          $t('pc.', {
            count: diffCount > 0 ? diffCount : -1 * diffCount,
          })
        }}
      </span>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';

import { InvoiceItem } from '@/api';

export default defineComponent({
  props: {
    item: {
      type: Object as PropType<InvoiceItem>,
      required: true,
    },
  },
  setup(props) {
    const diffCount = computed(
      () => props.item.expectedAmount - props.item.actualAmount,
    );

    return { diffCount };
  },
});
</script>
<style lang="stylus" scoped>
.wrapper
  display grid
  grid-row-gap 4px
  padding 14px 8px 8px

.amount-item
  display grid
  grid-auto-flow column
  grid-column-gap 4px
  justify-content end

.label
  color Gray(LT8)

.count
  white-space nowrap

.lost-count
  color Red(DK10)
</style>
