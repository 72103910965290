import dayjs from 'dayjs';
import debounce from 'lodash.debounce';

function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const initRunningButton = (ref) => {
  const date = dayjs().format('DD/MM/YYYY');
  const targetDate = dayjs(new Date(2024, 3, 1)).format('DD/MM/YYYY');

  if (date !== targetDate) {
    return;
  }

  let counter = 0;

  ref.onmousemove = debounce(
    () => {
      if (counter > 4) return;

      const arr = [1, -1];
      let x = +ref.style.left.replace('px', '');
      let y = +ref.style.top.replace('px', '');

      x = x < -300 || x > 300 ? 0 : x;
      y = y < -200 || y > 200 ? 0 : y;

      if (counter === 2 || counter > 3) {
        ref.style.left = 0;
        ref.style.top = 0;
      } else {
        ref.style.left =
          x + getRandomInt(280, 300) * arr[getRandomInt(0, 1)] + 'px';
        ref.style.top =
          y + getRandomInt(70, 100) * arr[getRandomInt(0, 1)] + 'px';
      }

      const defaultMessage = 'Теперь точно все 😇';

      const messages = [
        'ХА! НЕ ПОЙМАЕШЬ! 🤪',
        'ПОПРОБУЙ ЕЩЕ РАЗОК! 😉',
        'Ладно, заходи 😎',
        'ШУТКА! 🤣',
        defaultMessage,
      ];

      const textContainer = ref.querySelector('.ant-btn span');

      if (textContainer) {
        textContainer.innerText = messages[counter] || defaultMessage;
      }

      counter++;
    },
    50,
    {
      leading: true,
      trailing: false,
    },
  );

  ref.onclick = () => {
    if (counter < 4) {
      const textContainer = ref.querySelector('.ant-btn span');

      if (textContainer) {
        ref.style.left = 0;
        ref.style.top = 0;
        textContainer.innerText = '😭😭😭😭😭';
        counter = 100;
      }
    }
  };
};
