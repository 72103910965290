<template>
  <modal-wrapper
    class="discrepancy-modal-wrapper"
    :title="$t('modal.discrepancy.title')"
    @close="$emit('close')"
  >
    <div class="modal-content">
      <div class="items-count text-s font-medium leading-short">
        {{ $t('pluralization.position', state.items.totalCount) }}
      </div>
      <base-table
        class="discrepancy-table"
        row-align="top"
        :with-header="false"
        :columns="columns"
        :data="state.items.items"
        :is-grouped="false"
        :has-more="state.items.hasMore"
        :handle-load-more="handleLoadMore"
      >
        <template #image-cell="{ item }">
          <image-cell :url="item" size="xs" />
        </template>
        <template #sku-cell="{ item }">
          <sku-info :sku="item" />
        </template>
        <template #amount-cell="{ item }">
          <amount-info :item="item" />
        </template>
      </base-table>
    </div>

    <template #footer>
      <div>
        <span>{{ $t('confirm.discrepancy.title') }}</span>
        <span class="secondary-text">
          {{ $t('confirm.discrepancy.body') }}
        </span>
      </div>
      <ui-button type="primary" @click="onAcceptClick">
        {{ $t('confirm.discrepancy.accept') }}
      </ui-button>
    </template>
  </modal-wrapper>
</template>

<script lang="ts">
import { defineComponent, PropType, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';

import { InvoiceItem, InvoiceStatus } from '@/api';
import ImageCell from '@/components/table/cells/image-cell.vue';
import BaseTable from '@/components/table/index.vue';
import { IColumn } from '@/components/table/types';
import ModalWrapper from '@/features/Modals/ModalWrapper.vue';
import { UiButton } from '@/ui';

import AmountInfo from './amount-cell.vue';
import { useDiscrepancyApi } from './composables';
import SkuInfo from './sku-cell.vue';

export default defineComponent({
  name: 'discrepancy-modal',
  components: {
    UiButton,
    ModalWrapper,
    ImageCell,
    BaseTable,
    AmountInfo,
    SkuInfo,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    onClose: {
      type: Function as PropType<() => void>,
      default: () => {},
    },
    onAccept: {
      type: Function as PropType<() => void>,
      default: () => {},
    },
    onUpdate: {
      type: Function as PropType<(status: InvoiceStatus) => void>,
      default: () => {},
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const { id } = toRefs(props);

    const { state, handleLoadMore } = useDiscrepancyApi(
      id.value,
      props.onUpdate,
    );

    const columns: IColumn<InvoiceItem>[] = [
      {
        Header: t('photo'),
        id: 'photo',
        resolver: (invoiceItem) => invoiceItem.sku?.photoURL,
        Cell: 'image-cell',
      },
      {
        Header: t('nomenclature'),
        id: 'info',
        resolver: (invoiceItem) => invoiceItem.sku,
        Cell: 'sku-cell',
      },
      {
        Header: t('checked'),
        id: 'amount',
        resolver: (invoiceItem) => invoiceItem,
        Cell: 'amount-cell',
      },
    ];

    const closeModal = () => emit('close');

    const handleClose = () => {
      closeModal();
      props.onClose();
    };

    const onAcceptClick = async () => {
      props.onAccept();
      closeModal();
    };

    return {
      columns,
      state,
      handleLoadMore,
      onAcceptClick,
      handleClose,
    };
  },
});
</script>

<style lang="stylus">
.discrepancy-modal-wrapper .modal-wrapper
  width 580px

.discrepancy-modal-wrapper .modal-content
  width 580px
  max-height 500px
  display grid
  grid-template-rows auto minmax(0, 1fr) auto
  background LightBlue(LT47)
  border-radius 8px

.discrepancy-modal-wrapper
  .modal-header
    @apply text-m leading-short
    color Gray(DK8)
    display flex
    justify-content space-between
    align-items center
    padding 15px 38px 15px 32px

  .modal-content
    display grid
    padding 0

  .modal-footer
    @apply text-xs leading-short
    display grid
    grid-template-columns 1fr auto
    grid-column-gap 40px
    align-items center
    padding 18px 32px
    height unset
</style>

<style lang="stylus" scoped>
.discrepancy-table
  ::v-deep(.table-cell:first-child)
    padding-left 30px
  ::v-deep(.table-cell:last-child)
    padding-right 30px

.discrepancy-modal-content
  display grid

.items-count
  color Gray(DK8)
  padding 16px 32px

.secondary-text
  color Gray(DK8)
</style>
