import {
  useEventListener,
  useMouseInElement,
  useMousePressed,
} from '@vueuse/core';
import {
  computed,
  MaybeRefOrGetter,
  reactive,
  ref,
  toRef,
  toValue,
  watch,
} from 'vue';

const clamp = (value: number, min: number, max: number, loop = false) => {
  if (value < min) return loop ? max : min;
  if (value > max) return loop ? min : max;

  return value;
};

export type UseImagePreviewOptions = {
  initialIndex?: MaybeRefOrGetter<number>;
  initialScale?: number;
  maxScale?: number;
  scaleRatio?: number;
  loop?: boolean;
};

export function useImagePreview(
  images: string[],
  {
    initialIndex = 0,
    initialScale = 1,
    maxScale = 3,
    scaleRatio = 0.5,
    loop = true,
  }: UseImagePreviewOptions = {},
) {
  const el = ref<HTMLElement>();
  const { pressed } = useMousePressed({ target: el });
  const { x, y } = useMouseInElement(el);
  const translate = reactive({ x: 0, y: 0 });
  const resetTranslate = () => Object.assign(translate, { x: 0, y: 0 });

  watch([x, y], ([newX, newY], [oldX, oldY]) => {
    if (!el.value?.parentElement || !pressed.value || isInitialScale.value)
      return;

    const dX = newX - oldX;
    const dY = newY - oldY;
    const image = el.value.getBoundingClientRect();
    const parent = el.value.parentElement.getBoundingClientRect();

    if (
      (image.left < parent.left && dX > 0) ||
      (image.right > parent.right && dX < 0)
    ) {
      translate.x += dX;
    }

    if (
      (image.top < parent.top && dY > 0) ||
      (image.bottom > parent.bottom && dY < 0)
    ) {
      translate.y += dY;
    }
  });

  const scale = ref(initialScale);

  const setScale = (value: number) => {
    resetTranslate();
    scale.value = clamp(value, initialScale, maxScale);
  };

  const resetScale = () => setScale(initialScale);
  const zoomIn = () => setScale(scale.value + scaleRatio);
  const zoomOut = () => setScale(scale.value - scaleRatio);
  const isInitialScale = computed(() => scale.value === initialScale);
  const isMaxScale = computed(() => scale.value >= maxScale);
  const index = ref(toValue(initialIndex));

  const setIndex = (value: number) => {
    resetScale();
    index.value = clamp(value, 0, images.length - 1, loop);
  };

  const initialIndexRef = toRef(initialIndex);
  const resetIndex = () => setIndex(initialIndexRef.value);
  const next = () => setIndex(index.value + 1);
  const prev = () => setIndex(index.value - 1);
  const src = computed(() => images[index.value]);
  const isOpen = ref(false);

  const open = () => {
    isOpen.value = true;
  };

  const close = () => {
    isOpen.value = false;
    resetIndex();
    resetScale();
  };

  useEventListener('keydown', (e) => {
    switch (e.key) {
      case 'ArrowLeft':
        prev();
        break;
      case 'ArrowRight':
        next();
        break;
      default:
        break;
    }
  });

  return {
    isOpen: computed(() => isOpen.value),
    index: computed(() => index.value),
    scale: computed(() => scale.value),
    translate: computed(() => translate),
    el,
    src,
    isInitialScale,
    isMaxScale,
    open,
    close,
    setIndex,
    next,
    prev,
    setScale,
    zoomIn,
    zoomOut,
  };
}
