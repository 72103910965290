<script setup lang="ts">
import { Modal as AModal } from 'ant-design-vue';
import { computed, ref, watch } from 'vue';

interface Props {
  visible?: boolean;
  footer?: boolean;
  padding?: number | true;
  afterClose?: () => void;
}

interface Emits {
  (e: 'update:visible', payload: boolean): void;
  (e: 'close'): void;
  (e: 'ok'): void;
}

const props = withDefaults(defineProps<Props>(), {
  visible: true,
  footer: false,
  padding: 0,
  afterClose: () => {},
});

const emit = defineEmits<Emits>();

const defaultProps = computed(() => ({
  zIndex: 9999,
  bodyStyle: {
    padding: typeof props.padding === 'number' ? props.padding : undefined,
  },
  footer: props.footer ? undefined : null,
}));

const localVisible = ref(props.visible);

watch(
  () => props.visible,
  (newVisible) => {
    localVisible.value = newVisible;
  },
);

watch(localVisible, () => {
  emit('update:visible', localVisible.value);
});

const onAfterClose = () => {
  emit('close');
  props.afterClose?.();
};
</script>

<template>
  <AModal
    v-model:open="localVisible"
    wrap-class-name="ui-modal"
    :destroy-on-close="true"
    v-bind="{ ...defaultProps, ...$attrs }"
    :after-close="onAfterClose"
    @ok="$emit('ok')"
  >
    <template v-for="(_, name) in $slots" #[name]="slotProps">
      <slot v-if="name" :name="name" v-bind="slotProps" />
    </template>
  </AModal>
</template>

<style lang="stylus">
.global-modal
  .ant-modal-content
    padding 0
</style>
