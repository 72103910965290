import debounce from 'lodash.debounce';
import { reactive, ref, watch } from 'vue';

import { skuApi, SkuListEntry, SkuListResponse } from '@/api';
import { useFilters } from '@/composables/useFilters';
import { useQuery } from '@/composables/useQuery';
import {
  ENTITY_INITIAL_STATE,
  PAGE_SIZE,
  TPageableEntity,
} from '@/features/Common';
import { DEBOUNCE_DELAY } from '@/features/Common';
import { clone, keyBy, uniq } from '@/utils/collection';

import { StatusFilter } from './types';
type TSkusState = TPageableEntity<SkuListEntry>;

const skus = reactive<TSkusState>(clone(ENTITY_INITIAL_STATE));
const loadError = ref(false);
const loaded = ref(false);
const { query, boundedQuery, resetQuery } = useQuery();

const { handleFilterChange, isFilterSelected, filters, resetFilters } =
  useFilters<StatusFilter>();

export function useSkusApi() {
  const handleInvoicesResponse = (response: SkuListResponse) => {
    if (response) {
      skus.page = response.page + 1;
      skus.items = uniq([...skus.items, ...response.items]);
      skus.entries = {
        ...skus.entries,
        ...keyBy(response.items, (item) => String(item.id)),
      };
      skus.hasMore = skus.page < response.totalPages;
      skus.totalCount = response.totalItems;
    }
  };

  let abortGetSkuController: AbortController;

  const getSkus = async () => {
    try {
      if (abortGetSkuController) {
        abortGetSkuController.abort();
      }

      abortGetSkuController = new AbortController();

      loadError.value = false;

      const status = filters.length
        ? filters.map((filter) => filter.status)
        : undefined;

      skus.loading = true;

      const response = await skuApi.skuList(
        {
          page: skus.page,
          size: PAGE_SIZE,
          status,
          q: boundedQuery.value,
        },
        {
          signal: abortGetSkuController.signal,
        },
      );

      handleInvoicesResponse(response);
    } catch (err: any) {
      if (err.name !== 'AbortError') {
        loadError.value = true;
      }
    } finally {
      skus.loading = false;
    }
  };

  const resetSkus = () => {
    Object.assign(skus, clone(ENTITY_INITIAL_STATE));
    loaded.value = false;
  };

  const getSkuByBarcode = async (barcode: string) => {
    const skusResponse = await skuApi.skuList({ page: 0, size: 1, barcode });

    if (skusResponse.items.length > 0) {
      const sku = skusResponse.items[0];

      skus.entries[sku.id] = sku;

      popSku(sku.id);

      return sku;
    } else {
      throw new Error('Sku not found');
    }
  };

  const getInitialSkus = async () => {
    if (skus.loading) return;
    loaded.value = false;
    resetSkus();
    await getSkus();
    loaded.value = true;
  };

  const popSku = (id: number) => {
    const sku = skus.entries[id];

    if (!sku) return;

    skus.items = skus.items.filter((s) => s.id !== sku.id);
    skus.items.unshift(sku);
  };

  const handleLoadMore = async () => {
    if (skus.hasMore && !skus.loading) {
      await getSkus();
    }
  };

  watch(filters, getInitialSkus);
  watch(query, debounce(getInitialSkus, DEBOUNCE_DELAY));

  const resetSkusFilters = () => {
    resetFilters();
    resetQuery();
  };

  return {
    skus,
    loadError,
    loaded,
    getInitialSkus,
    getSkus,
    getSkuByBarcode,
    resetSkus,
    popSku,
    handleFilterChange,
    isFilterSelected,
    handleLoadMore,
    query,
    resetSkusFilters,
  };
}
