/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Описывает ошибку, возвращенную сервисом.
 * 
 * Текущие коды ошибок:
 * ```
 * ErrCodeNoMapping  ErrCode = 1000
 * ErrCodeInternal   ErrCode = 1001
 * ErrCodeValidation ErrCode = 1002
 * ErrCodeNotFound   ErrCode = 1003
 * ErrCodeExists     ErrCode = 1004
 * 
 * ErrCodeInvoiceStatusNotAllowed               ErrCode = 1005
 * ErrCodeUnrecognizedInvoiceType               ErrCode = 1006
 * ErrCodeAccountingNotAllowed                  ErrCode = 1007
 * ErrCodeInvoiceItemAlreadyAccepted            ErrCode = 1008
 * ErrCodeChangePhotoRequestStatus              ErrCode = 1009
 * ErrCodeBoxIsNotEmpty                         ErrCode = 1010
 * ErrCodeActualAmountGreaterExpectedAmount     ErrCode = 1011
 * ErrCodePhotoRequestStatusNotAllowed          ErrCode = 1012
 * ErrCodeSkuCanNotSending                      ErrCode = 1013
 * ErrCodeWmsReservationFailed                  ErrCode = 1014
 * ErrCodeSkuAlreadyExistsInThisInvoice         ErrCode = 1015
 * ErrCodeSkuAlreadyExistsInOtherInvoice        ErrCode = 1016
 * ErrCodeSkusAlreadyExistsInOtherReservation   ErrCode = 1017
 * ErrCodeSkusAlreadyExistsOnPhotoStudio        ErrCode = 1018
 * ErrCodeSkuDoesNotNeedToShipOnPhotoStudio     ErrCode = 1019
 * ErrCodePhotoStudioIsInvalid                  ErrCode = 1020
 * ErrCodeUnauthorized                          ErrCode = 1021
 * ErrCodeCantWriteOffSku                       ErrCode = 1022
 * ErrCodeSkuStatusNotAllowed                   ErrCode = 1023
 * ErrCodeSkuAlreadyExistsInSomePhotoRequest    ErrCode = 1024
 * ErrCodeIncompatibleShootingTypes             ErrCode = 1025
 * ErrCodePhotoBoxIsBusy                        ErrCode = 1026
 * ErrCodeHasNoRequiredShootingType             ErrCode = 1027
 * ErrCodeInvoiceTypeNotAllowed                 ErrCode = 1028
 * ErrCodeShootingKindNotCombined               ErrCode = 1029
 * ErrCodeCasting                               ErrCode = 1030
 * ErrCodePermissionDenied                      ErrCode = 1031
 * ErrCodeBoxIDsRequired                        ErrCode = 1032
 * ErrCodeBoxNotAvailableForTransfer            ErrCode = 1033
 * ErrCodeDefectReasonCommentRequired           ErrCode = 1034
 * ErrCodeInvoiceForBoxExist                    ErrCode = 1035
 * ErrCodePaymentForPhotoRequestIDExists        ErrCode = 1037
 * ErrCodeInvoiceWithIdempotencyKeyExists       ErrCode = 1038
 * ErrCodeSkuDoesNotNeedRender                  ErrCode = 1039
 * ErrCodeDraftPhotoRequestForInvoiceExists     ErrCode = 1040
 * ErrCodePaymentServiceDisabled                ErrCode = 1041
 * ErrCodeRequestItemsNotAllIsEmptyPhotoDate    ErrCode = 1042
 * ErrCodeRequestItemsNotAllHasPhotoDate        ErrCode = 1043
 * ErrCodeSkuHasPhotoDefect                     ErrCode = 1045
 * ErrCodeRequestItemsNotChecked                ErrCode = 1046
 * ErrCodeSkuHasSeveralReservations             ErrCode = 1047
 * ErrCodeFolderIsEmpty                         ErrCode = 1048
 * ErrCodeQuantityDetailedPhoto                 ErrCode = 1049
 * ErrCodeUnaffectedPhoto                       ErrCode = 1050
 * ErrCodeNoFolderForPanoramicItem              ErrCode = 1051
 * ErrCodeNotEnoughPanoramicPhoto               ErrCode = 1052
 * ErrCodeExtraFolder                           ErrCode = 1053
 * ErrCodeExtraFile                             ErrCode = 1054
 * ErrCodeWmsShipmentFailed                     ErrCode = 1055
 * ErrCodeBadUserRole                           ErrCode = 1056
 * ErrCodeDefectsExists                         ErrCode = 1057
 * ErrCodePhotoRequestItemDefectReasonTypeIsBad ErrCode = 1058
 * ErrCodePhotoRequestBadStatus                 ErrCode = 1059
 * ErrCodeCancelReservationOnWMS                ErrCode = 1060
 * ErrCodePhotoRequestRenderWithBoxID           ErrCode = 1061
 * ErrCodePhotoRequestBadShootingTypes          ErrCode = 1062
 * ErrCodePaymentRateUnspecified                ErrCode = 1063
 * ErrCodeWMSAssemblyFailed                     ErrCode = 1064
 * ErrCodeLostOnWMSDuringAssembly               ErrCode = 1065
 * ErrCodeBadOrMissingPhotoMetadata             ErrCode = 1066
 * ErrCodePhotoNotFound                         ErrCode = 1067
 * ```
 * @export
 * @interface ResponseError
 */
export interface ResponseError {
    /**
     * код ошибки
     * @type {number}
     * @memberof ResponseError
     */
    code: number;
    /**
     * текст ошибки
     * @type {string}
     * @memberof ResponseError
     */
    message: string;
    /**
     * детали ошибки
     * @type {any}
     * @memberof ResponseError
     */
    detail?: any | null;
}

export function ResponseErrorFromJSON(json: any): ResponseError {
    return ResponseErrorFromJSONTyped(json, false);
}

export function ResponseErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseError {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': json['code'],
        'message': json['message'],
        'detail': !exists(json, 'detail') ? undefined : json['detail'],
    };
}

export function ResponseErrorToJSON(value?: ResponseError | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'message': value.message,
        'detail': value.detail,
    };
}

