import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/vue-query';
import { computed, reactive } from 'vue';

import {
  parseErrorResponse,
  PaymentRate,
  reservationApi,
  ReservationStatus,
  SortByDate,
} from '@/api';
import { ESortingDirection } from '@/components/table/types';
import { useSorting } from '@/composables/useSorting';
import { featureFlags } from '@/features/FeatureFlags';

export type ReservationListFilters = Partial<{
  statuses: ReservationStatus[];
  user: string[];
}>;

export const reservationKeys = {
  all: () => ['reservations'] as const,
  lists: () => [...reservationKeys.all(), 'list'] as const,
  list: (opts: { filters: ReservationListFilters; sorting: SortByDate }) =>
    [...reservationKeys.lists(), opts] as const,
  details: () => [...reservationKeys.all(), 'detail'] as const,
  detail: (id: string, sorting: SortByDate) =>
    [...reservationKeys.details(), id, { sorting }] as const,
};

const defaultFilters = (): ReservationListFilters => ({
  statuses: [
    ReservationStatus.Created,
    ReservationStatus.Reserved,
    ReservationStatus.InShipping,
    ReservationStatus.Canceled,
    ReservationStatus.Completed,
  ],
  user: [],
});

const filters = reactive<ReservationListFilters>(defaultFilters());

const setFilters = (newFilters: ReservationListFilters) => {
  Object.assign(filters, { ...filters, ...newFilters });
};

const resetFilters = () => {
  setFilters(defaultFilters());
};

const { sorting, sortingField, sortingDirection, setSorting, resetSorting } =
  useSorting<SortByDate>('updatedAt', ESortingDirection.DESC);

export const useReservations = () => {
  const queryKey = reservationKeys.list(reactive({ filters, sorting }));

  const { data, error, isLoading, hasNextPage, fetchNextPage, refetch } =
    useInfiniteQuery({
      queryKey,
      queryFn: async ({ pageParam = 0, signal }) => {
        const { items, totalPages } = await reservationApi.getReservations(
          {
            size: 20,
            page: pageParam,
            sort: sorting.value ? [sorting.value] : undefined,
            mine: filters.user?.includes('mine') || undefined,
            reservationStatuses: filters.statuses,
          },
          { signal },
        );

        return {
          items,
          nextPage: pageParam < totalPages - 1 ? pageParam + 1 : undefined,
        };
      },
      getNextPageParam: (lastPage) => lastPage.nextPage,
      refetchOnWindowFocus: false,
      retry: false,
    });

  const pages = computed(() => data.value?.pages ?? []);

  return {
    error,
    isLoading,
    hasNextPage,
    fetchNextPage,
    refetch,
    setSorting,
    resetSorting,
    sorting,
    sortingField,
    sortingDirection,
    setFilters,
    resetFilters,
    filters: computed(() => filters),
    reservations: computed(() => pages.value.flatMap((x) => x.items)),
  };
};

export const useReservation = (id: string) => {
  const { sorting, sortingField, sortingDirection, setSorting } =
    useSorting<SortByDate>('updatedAt', ESortingDirection.DESC);

  const queryKey = computed(() => reservationKeys.detail(id, sorting.value));

  const { data, error, isLoading, hasNextPage, fetchNextPage, refetch } =
    useInfiniteQuery({
      queryKey,
      queryFn: async ({ pageParam = 0, signal }) => {
        const {
          reservation,
          items: { items, totalPages, totalItems },
        } = await reservationApi.getReservationDetailed(
          {
            reservationID: id,
            page: pageParam,
            size: 20,
            sort: sorting.value ? [sorting.value] : undefined,
          },
          { signal },
        );

        return {
          reservation,
          items,
          totalItems,
          nextPage: pageParam < totalPages - 1 ? pageParam + 1 : undefined,
        };
      },
      getNextPageParam: (lastPage) => lastPage.nextPage,
      retry: false,
      refetchOnWindowFocus: false,
    });

  const pages = computed(() => data.value?.pages ?? []);
  const reservation = computed(() => pages.value.at(0)?.reservation);
  const reservationItems = computed(() => pages.value.flatMap((x) => x.items));
  const totalItems = computed(() => pages.value.at(0)?.totalItems ?? 0);

  return {
    reservation,
    reservationItems,
    totalItems,
    error,
    isLoading,
    hasNextPage,
    fetchNextPage,
    setSorting,
    refetch,
    sortingField,
    sortingDirection,
  };
};

class DetailedError<
  TCode extends number | undefined = undefined,
  TDetail = undefined,
> extends Error {
  code?: TCode;
  detail?: TDetail;

  constructor({
    code,
    detail,
    message,
  }: {
    code?: TCode;
    detail?: TDetail;
    message?: string;
  } = {}) {
    super(message);
    this.code = code;
    this.detail = detail;
  }
}

type ValidationError =
  | DetailedError<1003, { skuExternalIDs: number[] }>
  | DetailedError<
      1017 | 1018,
      { skuInfoList: { id: number; externalId: number }[] }
    >;

export type CreateReservationError =
  | ValidationError
  | DetailedError<1002, ValidationError[]>;

export type CreateReservationParams = {
  paymentRate: PaymentRate;
  ids: number[];
  useNewTariff: boolean;
};

const createReservation = async ({
  paymentRate,
  ids,
  useNewTariff,
}: CreateReservationParams) => {
  try {
    const { reservation, error } = await reservationApi.createReservation({
      reservationTemplateV2: featureFlags.useOldTariffs
        ? {
            paymentRate,
            externalSkuIds: ids,
            useOldTariff: !useNewTariff,
          }
        : {
            paymentRate,
            externalSkuIds: ids,
          },
    });

    return {
      reservation,
      error: new DetailedError(error) as CreateReservationError,
    };
  } catch (e) {
    const { code, detail, message } = (await parseErrorResponse(e)) ?? {};

    throw new DetailedError({ code, detail, message });
  }
};

export const useNewReservation = () => {
  const client = useQueryClient();

  const { mutate, error, isLoading, isSuccess } = useMutation<
    Awaited<ReturnType<typeof createReservation>>,
    CreateReservationError,
    CreateReservationParams
  >({
    mutationFn: createReservation,
    onSettled: () => {
      client.invalidateQueries(reservationKeys.lists());
    },
  });

  return {
    isLoading,
    isSuccess,
    error,
    createReservation: mutate,
  };
};
