<template>
  <slot :open="onOpen">
    <AImage
      v-bind="$attrs"
      :preview="hidePreview ? false : { visible: false }"
      :fallback="placeholderUrl"
      @click="onOpen()"
      @error="previewLoadError = true"
    >
      <template v-for="(_, name) in $slots" #[name]="slotProps">
        <slot :name="name" v-bind="{ ...slotProps }" />
      </template>
    </AImage>
  </slot>

  <Preview
    v-if="!hidePreview && !previewLoadError && previewSrcList?.length"
    :images="previewSrcList"
    :visible="visible"
    :initial-index="current"
    @close="onClose()"
    @change="onChange"
  >
    <template v-for="(_, name) in $slots" #[name]="slotProps">
      <slot :name="name" v-bind="{ ...slotProps }" />
    </template>
  </Preview>
</template>

<script setup lang="ts">
import { Image as AImage } from 'ant-design-vue';
import { ref, watch } from 'vue';

import placeholderUrl from '@/assets/placeholder.svg';

import Preview from './Preview.vue';

interface Props {
  current: number;
  previewSrcList?: string[];
  hidePreview?: boolean;
}

interface Emits {
  (e: 'change', payload: number): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();
const visible = ref(false);
const previewLoadError = ref(false);

const onOpen = () => {
  visible.value = true;
};

const onClose = () => {
  visible.value = false;
};

const onChange = (index: number) => {
  emit('change', index);
};

watch(
  () => props.previewSrcList,
  () => {
    previewLoadError.value = false;
  },
);
</script>

<style>
.ant-image-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.ant-image-error {
  width: 100%;
}
</style>
