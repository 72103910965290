<template>
  <Layout style="min-height: 100vh; flex: unset">
    <LayoutSider
      v-model:collapsed="isCollapsed"
      :width="250"
      :collapsed-width="64"
    >
      <div class="sidebar" :class="{ collapsed: isCollapsed }">
        <div class="logo" data-test-id="logo__app">
          <img v-if="!isCollapsed" :src="getLogo()" />
          <component
            :is="isCollapsed ? MenuUnfoldOutlined : MenuFoldOutlined"
            class="text-[#8a8d93] hover:text-white text-[16px] transition duration-700"
            @click="toggleCollapsed"
          />
        </div>
        <div class="nav">
          <div class="nav-top">
            <div v-if="hasLeadingSlot" @click="handleClickMenu">
              <slot name="leading" />
            </div>
            <div v-if="hasSpacerSlot">
              <slot />
            </div>
            <div v-if="hasTrailingSlot">
              <slot name="trailing" />
            </div>
          </div>
          <div v-if="hasAdministrationSlot" @click="handleClickMenu">
            <slot name="administration" />
          </div>
        </div>
        <div class="nav-bottom">
          <div
            v-if="hasProfileSlot"
            class="profile border-blue-8 border-t border-solid border-0"
          >
            <slot name="profile" />
          </div>
        </div>
      </div>
    </LayoutSider>
  </Layout>
</template>

<script lang="ts">
import { computed, defineComponent, useSlots } from 'vue';
export default defineComponent({ name: 'sidebar' });
</script>

<script setup lang="ts">
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons-vue';
import { Layout, LayoutSider } from 'ant-design-vue';

import { COMPANY } from '@/config';

import { useSidebar } from '../layout';

const slots = useSlots();
const { isDrawerSidebar, toggleCollapsed, isCollapsed } = useSidebar();

const getLogo = () => {
  const logoSrcName = `menu-logo_${COMPANY}.svg`;

  return new URL(`/src/assets/${logoSrcName}`, import.meta.url).href;
};

const hasSlot = (name: string) => {
  return !!slots[name];
};

const handleClickMenu = () => {
  if (isDrawerSidebar?.value) {
    toggleCollapsed?.();
  }
};

const hasLeadingSlot = computed(() => hasSlot('leading'));
const hasSpacerSlot = computed(() => hasSlot('default'));
const hasTrailingSlot = computed(() => hasSlot('trailing'));
const hasProfileSlot = computed(() => hasSlot('profile'));
const hasAdministrationSlot = computed(() => hasSlot('administration'));
</script>

<style lang="stylus" scoped>


.panel__sidebar
  width 250px
  margin-left 0;
  transition margin-left 300ms cubic-bezier(0.23, 1, 0.32, 1)

.sidebar
  background #001529
  flex 1
  max-width 250px
  height 100%
  display grid
  align-items center
  grid-template-rows auto 1fr auto
  grid-template-areas 'logo' 'nav' 'profile'
  overflow hidden
  z-index 2

  &.collapsed
    .logo
      display flex
      justify-content center
      width 64px
    .profile
      width 64px
      padding 10px 16px

  svg
    fill Gray(LT16)

  .logo
    grid-area logo
    padding 24px 16px
    display flex
    justify-content space-between
    align-items center

  .nav
    grid-area nav
    height 100%
    display flex
    flex-direction column
    justify-content space-between

  .profile
    grid-area profile
    padding 10px 18px
</style>
