import { InfiniteData, useInfiniteQuery } from '@tanstack/vue-query';
import { refDebounced } from '@vueuse/core';
import {
  computed,
  ComputedRef,
  MaybeRefOrGetter,
  reactive,
  toRef,
  toValue,
} from 'vue';

import {
  PhotoBox,
  photoBoxesApi,
  PhotoRequest,
  photoRequestApi,
  PhotoRequestListResponse,
  PhotoRequestsListRequest,
  PhotoRequestStatus,
  ShootingType,
  SortPhotoRequests,
} from '@/api';
import { TSorting } from '@/components/table/types';
import { SortingDirection } from '@/composables/useSorting';
import { DEBOUNCE_DELAY, PAGE_SIZE } from '@/features/Common';
import { keys } from '@/utils/collection';

const fetchBoxes = async (
  ids: number[],
  { signal }: { signal?: AbortSignal } = {},
): Promise<PhotoBox[]> => {
  const { items } = await photoBoxesApi.getPhotoBoxes(
    {
      ids,
      page: 0,
      size: ids.length,
    },
    { signal },
  );

  return items;
};

type PhotoRequestWithBox = PhotoRequest & { box?: PhotoBox };

const combineWithBoxes = (
  requests: PhotoRequest[],
  boxes: PhotoBox[],
): PhotoRequestWithBox[] => {
  const boxesMap = new Map(boxes.map((b) => [b.id, b]));

  const combined = requests.map((r) => ({
    ...r,
    box: r.photoBoxID ? boxesMap.get(r.photoBoxID) : undefined,
  }));

  return combined;
};

type PhotoRequestsListResponseWithBoxes = PhotoRequestListResponse & {
  items: PhotoRequestWithBox[];
};

type DateRange = [number | undefined, number | undefined];

type FetchRequestsParams = {
  status: PhotoRequestStatus;
  size: number;
  page: number;
  search: string;
  createdAtRange: DateRange;
  updatedAtRange: DateRange;
  sorting: TSorting;
  shootingTypes: ShootingType[];
  retoucherIDs: number[];
};

const toUnixTimestamp = (timestamp: number) => Math.floor(timestamp / 1000);

const toUnixRange = (range: DateRange) =>
  range.map((v) => (v !== undefined ? toUnixTimestamp(v) : v)) as DateRange;

const SECONDS_IN_DAY = 86400;

const parseParams = (p: FetchRequestsParams): PhotoRequestsListRequest => {
  const shootingType = p.shootingTypes.length ? p.shootingTypes : undefined;
  const retoucherIDs = p.retoucherIDs.length ? p.retoucherIDs : undefined;
  const [createdFrom, createdTo] = toUnixRange(p.createdAtRange);
  const [updatedFrom, updatedTo] = toUnixRange(p.updatedAtRange);
  const { field, direction } = p.sorting;

  const sort =
    field && direction
      ? [`${field}${direction}` as SortPhotoRequests]
      : undefined;

  return {
    ...p,
    q: p.search || undefined,
    createdTo: createdTo ? createdTo + SECONDS_IN_DAY : undefined,
    updatedTo: updatedTo ? updatedTo + SECONDS_IN_DAY : undefined,
    createdFrom,
    updatedFrom,
    shootingType,
    retoucherIDs,
    sort,
  };
};

const fetchRequests = async (
  params: FetchRequestsParams,
  { signal }: { signal?: AbortSignal } = {},
): Promise<PhotoRequestsListResponseWithBoxes> => {
  const { items, ...rest } = await photoRequestApi.photoRequestsList(
    parseParams(params),
    { signal },
  );

  const boxIDs = items
    .map((i) => i.photoBoxID)
    .filter((i): i is number => i !== null && i !== undefined);

  if (!boxIDs.length) {
    return {
      items,
      ...rest,
    };
  }

  const boxes = await fetchBoxes(boxIDs, { signal });
  const combined = combineWithBoxes(items, boxes);

  return {
    items: combined,
    ...rest,
  };
};

type RequestsParams = Omit<FetchRequestsParams, 'page' | 'size' | 'status'>;

const params = reactive<RequestsParams>({
  search: '',
  sorting: { field: 'updatedAt', direction: SortingDirection.DESC },
  shootingTypes: [],
  createdAtRange: [undefined, undefined],
  updatedAtRange: [undefined, undefined],
  retoucherIDs: [],
});

export const useRequestsParams = (): RequestsParams => params;

type UseRequestsQueryData = InfiniteData<{
  items: PhotoRequest[] & PhotoRequestWithBox[];
  totalItems: number;
  totalItemCount: PhotoRequestsListResponseWithBoxes;
  nextPage: number | undefined;
}> & {
  items: PhotoRequestWithBox[];
  totalItems: number;
  totalItemCount: number;
};

const initialData: UseRequestsQueryData = {
  pages: [],
  pageParams: [],
  items: [],
  totalItems: 0,
  totalItemCount: 0,
};

export const useRequests = (status: MaybeRefOrGetter<PhotoRequestStatus>) => {
  const params = useRequestsParams();

  const debouncedParams = reactive({
    ...keys(params).reduce(
      (res, key) => ({
        ...res,
        [key]: refDebounced(toRef(params, key), DEBOUNCE_DELAY),
      }),
      {} as typeof params,
    ),
    search: refDebounced(toRef(() => params.search.trim())),
  });

  const queryInfo = useInfiniteQuery({
    queryKey: ['requests', status, debouncedParams],
    queryFn: async ({ signal, pageParam = 0 }) => {
      const { items, totalItems, totalItemCount, totalPages } =
        await fetchRequests(
          {
            ...debouncedParams,
            status: toValue(status),
            page: pageParam,
            size: PAGE_SIZE,
          },
          { signal },
        );

      return {
        items,
        totalItems,
        totalItemCount,
        nextPage: pageParam < totalPages - 1 ? pageParam + 1 : undefined,
      };
    },
    getNextPageParam: (lastPage) => lastPage.nextPage,
    select: (data) => ({
      ...data,
      items: data.pages.flatMap((p) => p.items),
      totalItems: data.pages.at(-1)?.totalItems ?? 0,
      totalItemCount: data.pages.at(-1)?.totalItemCount ?? 0,
    }),
  });

  const data = computed(() => queryInfo.data.value ?? initialData);

  return {
    ...queryInfo,
    data: data as ComputedRef<UseRequestsQueryData>,
  };
};

export const useRequestsCount = (status: PhotoRequestStatus) => {
  const { data, isLoading } = useRequests(status);

  return computed(() => {
    if (isLoading.value)
      return {
        isLoading: true,
        value: 0,
      };

    return {
      isLoading: false,
      value: data.value.totalItems,
    };
  });
};

export const useRequestsCounts = (statuses: PhotoRequestStatus[]) =>
  reactive(
    Object.fromEntries(statuses.map((s) => [s, useRequestsCount(s)])) as Record<
      PhotoRequestStatus,
      ComputedRef<{
        isLoading: boolean;
        value: number;
      }>
    >,
  );
