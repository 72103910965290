const prepareIframe = async (htmlContent: string, image?: HTMLImageElement) =>
  new Promise((resolve, reject) => {
    const iframe = document.createElement('iframe');

    if (!htmlContent) {
      reject('No html to print');
    }

    iframe.srcdoc = htmlContent;

    document.body.appendChild(iframe);
    iframe.focus();

    if (iframe.contentWindow) {
      iframe.contentWindow.onafterprint = () => {
        resolve(document.body.removeChild(iframe));
      };
    }

    iframe.onload = () => {
      if (image) {
        const clonedImage = image.cloneNode() as HTMLImageElement;

        iframe?.contentDocument?.body.appendChild(clonedImage);

        clonedImage.onload = () => {
          iframe.contentWindow?.print();
        };
      } else {
        iframe.contentWindow?.print();
      }
    };

    iframe.style.visibility = 'hidden';
    iframe.style.opacity = '0';
    iframe.style.zIndex = '-1';
    iframe.style.position = 'absolute';
    iframe.style.top = '0';
    iframe.style.left = '-1000px';

    iframe.onabort = iframe.onerror = reject;
  });

export const usePrint = () => {
  return prepareIframe;
};

export const useImagePrint = () => {
  return (image: HTMLImageElement) =>
    prepareIframe('<html><body></body></html>', image);
};

export const useQRCodePrint = () => {
  const print = useImagePrint();

  return (data?: string) => {
    if (!data) return;

    const size = 4;
    const dataEncoded = encodeURIComponent(data);
    const link = `http://qrcoder.ru/code/?${dataEncoded}&${size}&0`;
    const image = document.createElement('img');

    image.src = link;

    image.onload = () => {
      print(image);
    };

    image.onerror = () => {
      window.open(link);
    };
  };
};
