<template>
  <ul
    class="flex flex-wrap gap-3 select-none mb-4"
    data-test-id="requests__statuses"
  >
    <li v-for="status in options" :key="status">
      <StatusFilterOption
        :status="status"
        :active="status === modelValue"
        @click="handleUpdate(status)"
      />
    </li>
  </ul>
</template>

<script setup lang="ts">
import { watch } from 'vue';

import { PhotoRequestStatus } from '@/api';

import StatusFilterOption from './StatusFilterOption.vue';

interface Props {
  modelValue: PhotoRequestStatus;
  options: PhotoRequestStatus[];
}

interface Emits {
  (e: 'update:modelValue', v: PhotoRequestStatus): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const handleUpdate = (status: PhotoRequestStatus) => {
  emit('update:modelValue', status);
};

watch(
  () => props.options,
  (newOptions) => {
    if (!newOptions.length) return;

    if (!newOptions.includes(props.modelValue)) {
      handleUpdate(newOptions[0]);
    }
  },
);
</script>
