<template>
  <div class="image-cell">
    <base-image :url="url" :width="width" />
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

import { BaseImage } from '@/ui';

export type TImageSize = 'xs' | 's';

const sizeToWidth = {
  xs: 42,
  s: 54,
};

export default defineComponent({
  components: {
    BaseImage,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    size: {
      type: String as () => TImageSize,
      default: 's',
    },
  },
  setup(props) {
    return {
      width: sizeToWidth[props.size],
    };
  },
});
</script>
<style lang="stylus" scoped>
.image-cell
  padding 16px 8px 8px
</style>
