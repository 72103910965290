/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BulkCreateOrChangeReasonDefectsRequest,
    BulkCreateOrChangeReasonDefectsRequestFromJSON,
    BulkCreateOrChangeReasonDefectsRequestToJSON,
    BulkCreateOrChangeReasonDefectsResponse,
    BulkCreateOrChangeReasonDefectsResponseFromJSON,
    BulkCreateOrChangeReasonDefectsResponseToJSON,
    BulkDeleteDefectsRequest,
    BulkDeleteDefectsRequestFromJSON,
    BulkDeleteDefectsRequestToJSON,
    DefectsResponse,
    DefectsResponseFromJSON,
    DefectsResponseToJSON,
} from '../models';

export interface BulkCreateOrChangeReasonDefectsOperationRequest {
    bulkCreateOrChangeReasonDefectsRequest?: BulkCreateOrChangeReasonDefectsRequest;
}

export interface BulkDeleteDefectRequest {
    bulkDeleteDefectsRequest?: BulkDeleteDefectsRequest;
}

export interface DeleteDefectRequest {
    defectID: number;
}

export interface ListDefectsRequest {
    page: number;
    size: number;
    skuID?: Array<number>;
}

/**
 * 
 */
export class DefectsApi extends runtime.BaseAPI {

    /**
     * Массовая отметка брака и массовая смена причины, если товар уже помечен браком
     */
    async bulkCreateOrChangeReasonDefectsRaw(requestParameters: BulkCreateOrChangeReasonDefectsOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BulkCreateOrChangeReasonDefectsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/defects`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BulkCreateOrChangeReasonDefectsRequestToJSON(requestParameters.bulkCreateOrChangeReasonDefectsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BulkCreateOrChangeReasonDefectsResponseFromJSON(jsonValue));
    }

    /**
     * Массовая отметка брака и массовая смена причины, если товар уже помечен браком
     */
    async bulkCreateOrChangeReasonDefects(requestParameters: BulkCreateOrChangeReasonDefectsOperationRequest = {}, initOverrides?: RequestInit): Promise<BulkCreateOrChangeReasonDefectsResponse> {
        const response = await this.bulkCreateOrChangeReasonDefectsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Отменить брак
     */
    async bulkDeleteDefectRaw(requestParameters: BulkDeleteDefectRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/defects`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: BulkDeleteDefectsRequestToJSON(requestParameters.bulkDeleteDefectsRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Отменить брак
     */
    async bulkDeleteDefect(requestParameters: BulkDeleteDefectRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.bulkDeleteDefectRaw(requestParameters, initOverrides);
    }

    /**
     * Отменить брак
     */
    async deleteDefectRaw(requestParameters: DeleteDefectRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.defectID === null || requestParameters.defectID === undefined) {
            throw new runtime.RequiredError('defectID','Required parameter requestParameters.defectID was null or undefined when calling deleteDefect.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/defects/{defectID}`.replace(`{${"defectID"}}`, encodeURIComponent(String(requestParameters.defectID))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Отменить брак
     */
    async deleteDefect(requestParameters: DeleteDefectRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteDefectRaw(requestParameters, initOverrides);
    }

    /**
     * Получение брака по списку ску
     */
    async listDefectsRaw(requestParameters: ListDefectsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DefectsResponse>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling listDefects.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling listDefects.');
        }

        const queryParameters: any = {};

        if (requestParameters.skuID) {
            queryParameters['skuID'] = requestParameters.skuID;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/defects`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DefectsResponseFromJSON(jsonValue));
    }

    /**
     * Получение брака по списку ску
     */
    async listDefects(requestParameters: ListDefectsRequest, initOverrides?: RequestInit): Promise<DefectsResponse> {
        const response = await this.listDefectsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
