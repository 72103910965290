import { reactive } from 'vue';

import { Box, BoxError, boxesApi, BoxTransfer, boxTransferApi } from '@/api';

type TBoxTransferState = {
  items: Box[];
  boxErrors: BoxError[];
  boxSuccess: BoxTransfer[];
};

export function useCreateBoxTransfer() {
  const state = reactive<TBoxTransferState>({
    items: [],
    boxErrors: [],
    boxSuccess: [],
  });

  const getBoxByBarcode = async (barcode: string): Promise<Box> => {
    const response = await boxesApi.getBoxes({
      page: 0,
      size: 1,
      barcode: barcode,
    });

    return response.items[0];
  };

  const checkBoxForTransfer = async (boxId: number) => {
    try {
      await boxTransferApi.checkItemBox({
        boxID: boxId,
      });

      return true;
    } catch {
      return false;
    }
  };

  const addBoxToTransfer = async (barcode: string) => {
    const boxExist = state.items.find((el) => el.barcode === barcode);

    if (boxExist) {
      throw new Error('box exist');
    }

    const box = await getBoxByBarcode(barcode);

    if (!box) {
      throw new Error('box not found');
    }

    const canAdd = await checkBoxForTransfer(box.id);

    if (canAdd) {
      state.items.push(box);
    } else {
      throw new Error('box unadded');
    }
  };

  const removeBoxFromTransfer = (box: Box) => {
    const index = state.items.findIndex((el) => el.id === box.id);

    state.items.splice(index, 1);
  };

  const createBoxTransfer = async () => {
    const response = await boxTransferApi.createBoxTransferList({
      createBoxTransferListRequest: {
        boxIds: state.items.map((el) => el.id),
      },
    });

    state.boxSuccess = response.items;
    state.boxErrors = response.boxErrors;
  };

  return {
    state,
    addBoxToTransfer,
    removeBoxFromTransfer,
    createBoxTransfer,
  };
}
