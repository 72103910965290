/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaymentRate,
    PaymentRateFromJSON,
    PaymentRateFromJSONTyped,
    PaymentRateToJSON,
} from './PaymentRate';

/**
 * 
 * @export
 * @interface ReservationTemplateV2
 */
export interface ReservationTemplateV2 {
    /**
     * 
     * @type {PaymentRate}
     * @memberof ReservationTemplateV2
     */
    paymentRate: PaymentRate;
    /**
     * 
     * @type {Array<number>}
     * @memberof ReservationTemplateV2
     */
    externalSkuIds: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof ReservationTemplateV2
     */
    useOldTariff?: boolean;
}

export function ReservationTemplateV2FromJSON(json: any): ReservationTemplateV2 {
    return ReservationTemplateV2FromJSONTyped(json, false);
}

export function ReservationTemplateV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): ReservationTemplateV2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paymentRate': PaymentRateFromJSON(json['paymentRate']),
        'externalSkuIds': json['externalSkuIds'],
        'useOldTariff': !exists(json, 'useOldTariff') ? undefined : json['useOldTariff'],
    };
}

export function ReservationTemplateV2ToJSON(value?: ReservationTemplateV2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'paymentRate': PaymentRateToJSON(value.paymentRate),
        'externalSkuIds': value.externalSkuIds,
        'useOldTariff': value.useOldTariff,
    };
}

