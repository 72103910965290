/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PhotoRequestItemReviewStatus {
    Unspecified = 'UNSPECIFIED',
    NotChecked = 'NOT_CHECKED',
    PhotoDefect = 'PHOTO_DEFECT',
    RetouchingDefect = 'RETOUCHING_DEFECT',
    Accepted = 'ACCEPTED',
    AutoRetouchingDefect = 'AUTO_RETOUCHING_DEFECT',
    ProductDefect = 'PRODUCT_DEFECT'
}

export function PhotoRequestItemReviewStatusFromJSON(json: any): PhotoRequestItemReviewStatus {
    return PhotoRequestItemReviewStatusFromJSONTyped(json, false);
}

export function PhotoRequestItemReviewStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhotoRequestItemReviewStatus {
    return json as PhotoRequestItemReviewStatus;
}

export function PhotoRequestItemReviewStatusToJSON(value?: PhotoRequestItemReviewStatus | null): any {
    return value as any;
}

