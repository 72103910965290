/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StudioPhotoInfo,
    StudioPhotoInfoFromJSON,
    StudioPhotoInfoFromJSONTyped,
    StudioPhotoInfoToJSON,
} from './StudioPhotoInfo';

/**
 * 
 * @export
 * @interface SkuPhotoInfoList
 */
export interface SkuPhotoInfoList {
    /**
     * 
     * @type {number}
     * @memberof SkuPhotoInfoList
     */
    skuID: number;
    /**
     * 
     * @type {Array<StudioPhotoInfo>}
     * @memberof SkuPhotoInfoList
     */
    photoInfo: Array<StudioPhotoInfo>;
}

export function SkuPhotoInfoListFromJSON(json: any): SkuPhotoInfoList {
    return SkuPhotoInfoListFromJSONTyped(json, false);
}

export function SkuPhotoInfoListFromJSONTyped(json: any, ignoreDiscriminator: boolean): SkuPhotoInfoList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'skuID': json['skuID'],
        'photoInfo': ((json['photoInfo'] as Array<any>).map(StudioPhotoInfoFromJSON)),
    };
}

export function SkuPhotoInfoListToJSON(value?: SkuPhotoInfoList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'skuID': value.skuID,
        'photoInfo': ((value.photoInfo as Array<any>).map(StudioPhotoInfoToJSON)),
    };
}

