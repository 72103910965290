import { onUnmounted, reactive } from 'vue';

import { Box, photoBoxesApi } from '@/api';

interface IState {
  id: number | null;
  barcode: string | null;
}

const initialState: IState = {
  id: null,
  barcode: null,
};

export default function useGenerateBarcode() {
  const state = reactive<IState>({ ...initialState });

  const resetState = () => {
    Object.assign(state, initialState);
  };

  const createBox = async (): Promise<Box> => {
    const response = await photoBoxesApi.createPhotoBoxes({
      createPhotoBox: {
        quantity: 1,
      },
    });

    state.id = response[0].id;
    state.barcode = response[0].barcode;

    return response[0];
  };

  const printBarcode = async (): Promise<string> => {
    if (state.id) {
      const response = await photoBoxesApi.printPhotoBoxesBarcode({
        printBarcodes: {
          ids: [state.id],
        },
      });

      return response;
    }

    return '';
  };

  onUnmounted(() => {
    resetState();
  });

  return {
    state,
    createBox,
    printBarcode,
  };
}
