/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Image,
    ImageFromJSON,
    ImageFromJSONTyped,
    ImageToJSON,
} from './Image';
import {
    ShootingType,
    ShootingTypeFromJSON,
    ShootingTypeFromJSONTyped,
    ShootingTypeToJSON,
} from './ShootingType';
import {
    SkuStatus,
    SkuStatusFromJSON,
    SkuStatusFromJSONTyped,
    SkuStatusToJSON,
} from './SkuStatus';

/**
 * 
 * @export
 * @interface Sku
 */
export interface Sku {
    /**
     * 
     * @type {number}
     * @memberof Sku
     */
    id: number;
    /**
     * Штрих-код
     * @type {string}
     * @memberof Sku
     */
    barcode: string;
    /**
     * 
     * @type {string}
     * @memberof Sku
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Sku
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof Sku
     */
    photoURL: string;
    /**
     * 
     * @type {Image}
     * @memberof Sku
     */
    previewImage?: Image;
    /**
     * 
     * @type {SkuStatus}
     * @memberof Sku
     */
    status: SkuStatus;
    /**
     * 
     * @type {string}
     * @memberof Sku
     */
    identifier?: string;
    /**
     * 
     * @type {Array<Image>}
     * @memberof Sku
     */
    images: Array<Image>;
    /**
     * 
     * @type {Array<ShootingType>}
     * @memberof Sku
     */
    shootingTypes: Array<ShootingType>;
    /**
     * 
     * @type {number}
     * @memberof Sku
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof Sku
     */
    updatedAt: number;
}

export function SkuFromJSON(json: any): Sku {
    return SkuFromJSONTyped(json, false);
}

export function SkuFromJSONTyped(json: any, ignoreDiscriminator: boolean): Sku {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'barcode': json['barcode'],
        'title': json['title'],
        'description': json['description'],
        'photoURL': json['photoURL'],
        'previewImage': !exists(json, 'previewImage') ? undefined : ImageFromJSON(json['previewImage']),
        'status': SkuStatusFromJSON(json['status']),
        'identifier': !exists(json, 'identifier') ? undefined : json['identifier'],
        'images': ((json['images'] as Array<any>).map(ImageFromJSON)),
        'shootingTypes': ((json['shootingTypes'] as Array<any>).map(ShootingTypeFromJSON)),
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
    };
}

export function SkuToJSON(value?: Sku | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'barcode': value.barcode,
        'title': value.title,
        'description': value.description,
        'photoURL': value.photoURL,
        'previewImage': ImageToJSON(value.previewImage),
        'status': SkuStatusToJSON(value.status),
        'identifier': value.identifier,
        'images': ((value.images as Array<any>).map(ImageToJSON)),
        'shootingTypes': ((value.shootingTypes as Array<any>).map(ShootingTypeToJSON)),
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
    };
}

