<template>
  <PageLayout>
    <template #header>
      <PageHeader :title="$t('requests')">
        <RetouchersDropdown
          v-if="isPhotostudioAdmin || isRetoucher || isSeniorRetoucher"
          v-model="params.retoucherIDs"
        />

        <EnabledStatusesDropdown v-model:disabled="disabledStatuses" />

        <ShootingTypesDropdown v-model="params.shootingTypes" />

        <DatesDropdown
          v-model:created="params.createdAtRange"
          v-model:updated="params.updatedAtRange"
        />

        <PhotographerFlowButton
          v-if="isPhotographer && !!featureFlags.photographerFlow"
        />

        <Search
          v-model="params.search"
          width="270px"
          :placeholder="$t('requestsPage.searchPlaceholder')"
          data-test-id="input__search"
        />

        <CreateRequestButton v-if="canEditRequests" />
      </PageHeader>
    </template>

    <div class="body">
      <StatusFilter v-model="selectedStatus" :options="statusOptions" />

      <template v-for="status in enabledStatuses" :key="status">
        <RequestsTable v-if="status === selectedStatus" :status="status" />
      </template>
    </div>

    <NewFlowGreeting />

    <ScannerEmulator />
  </PageLayout>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
export default defineComponent({ name: 'requests-page' });
</script>

<script setup lang="ts">
import { useStorage } from '@vueuse/core';

import { PhotoRequestStatus } from '@/api';
import { useAuth } from '@/features/Auth';
import { featureFlags } from '@/features/FeatureFlags';
import {
  statusFlow,
  useRequestsCounts,
  useRequestsParams,
} from '@/features/Requests';
import { ScannerEmulator } from '@/features/ScannerEmulator';
import RetouchersDropdown from '@/pages/requests/components/RetouchersDropdown.vue';
import { PageLayout } from '@/template';
import PageHeader from '@/template/page-header/index.vue';
import Search from '@/ui/search/index.vue';

import CreateRequestButton from './components/CreateRequestButton.vue';
import DatesDropdown from './components/DatesDropdown.vue';
import EnabledStatusesDropdown from './components/EnabledStatusesDropdown.vue';
import NewFlowGreeting from './components/NewFlowGreeting.vue';
import PhotographerFlowButton from './components/PhotographerFlowButton.vue';
import RequestsTable from './components/RequestsTable.vue';
import ShootingTypesDropdown from './components/ShootingTypesDropdown.vue';
import StatusFilter from './components/StatusFilter.vue';

const {
  isPhotographer,
  isPhotostudioAdmin,
  isRetoucher,
  canEditRequests,
  isSeniorRetoucher,
} = useAuth();

const params = useRequestsParams();
const counts = useRequestsCounts(statusFlow.statuses);

const disabledStatuses = useStorage<PhotoRequestStatus[]>(
  'requests-page-disabled-statuses',
  [],
);

const enabledStatuses = computed(() =>
  statusFlow.statuses.filter((s) => !disabledStatuses.value.includes(s)),
);

const selectedStatus = ref(enabledStatuses.value[0]);

const notEmptyStatuses = computed(() =>
  enabledStatuses.value.filter((s) => counts[s].value > 0),
);

const statusOptions = computed(() =>
  params.search ? notEmptyStatuses.value : enabledStatuses.value,
);

const route = useRoute();
const router = useRouter();

onMounted(() => {
  if (route.query.draft) {
    const draftId = +route.query.draft;

    delete route.query.draft;

    router.replace({
      params: {
        id: route.params.id || draftId,
      },
      query: route.query,
    });
  }
});
</script>

<style lang="stylus" scoped>
.filter-search
  width 270px

.header
  height 68px
  padding 0 40px
  display flex
  align-items center

.header-content
  flex 1
  max-width 1200px
  margin-left auto
  margin-right auto
  display flex
  align-items center
  gap 8px

  > *:not(.search)
    flex-shrink 0

.title
  margin 0
  font-size 1.25rem
  font-weight 600

.search
  flex 1

.body
  height 100%
  margin 0 auto
  display flex
  flex-direction column
  gap 16px
</style>
