<template>
  <Chip :value="$t(type)" :color="typeToColor[type]" />
</template>

<script setup lang="ts">
import { ShootingType } from '@/api';
import { Chip, TChipColor } from '@/ui';

interface Props {
  type: ShootingType;
}

defineProps<Props>();

const typeToColor: Record<ShootingType, TChipColor> = {
  [ShootingType.Model]: 'purple',
  [ShootingType.Detailed]: 'yellow',
  [ShootingType.Panoramic]: 'orange',
  [ShootingType.Render]: 'green',
  [ShootingType.Infographic]: 'pink',
  [ShootingType.Unspecified]: 'gray',
};
</script>
