<template>
  <tbody>
    <tr
      :class="[
        'group-title-row',
        {
          'group-title-row_active': isOpened(data.title),
        },
      ]"
      :data-test-id="`button__${data.type.toLowerCase()}`"
      @click="toggleOpened(data.title)"
    >
      <td class="title" :colspan="colspan">
        <slot name="group-title">
          <table-title
            :type="data.type"
            :loading="data.loading"
            :title="data.title"
            :count="data.count ?? data.items.length"
            :is-opened="isOpened(data.title)"
            :with-creation="data.withCreation"
            :on-create-click="data.onCreateClick"
            :create-tooltip-text="data.createTooltipText"
          >
            <template v-for="(_, name) in $slots" #[name]="props">
              <slot :name="name" v-bind="props" />
            </template>
          </table-title>
        </slot>
      </td>
    </tr>
    <template v-if="isOpened(data.title)">
      <template v-for="(row, id) in data.items" :key="row.id">
        <table-row
          data-test-id="row__table"
          :index="id"
          :data="row"
          :row-align="rowAlign"
          :on-click="onRowClick"
          :class="rowClass"
          :columns="columns"
          :hovered="hovered"
        >
          <template v-for="(_, name) in $slots" #[name]="props">
            <slot :name="name" v-bind="props" />
          </template>
        </table-row>
      </template>
      <table-data-loader
        v-if="!loadError"
        :colspan="colspan"
        :has-more="data.hasMore"
        :on-enter="data.handleLoadMore"
      />
      <table-load-error
        v-else
        :on-enter="data.handleLoadMore"
        :has-more="data.hasMore"
        :colspan="colspan"
      />
      <tr
        v-if="data.withCreation"
        class="create-row"
        @click="data.onCreateClick"
      >
        <td :colspan="colspan">
          <button type="button" class="create">
            <slot name="create-row">
              <tooltip :text="data?.createTooltipText">
                <template #default>
                  <icon-ui
                    data-test-id="button__element-create-line"
                    name="operations/add_line"
                    :size="24"
                  />
                  {{ $t('button.create') }}
                </template>
              </tooltip>
            </slot>
          </button>
        </td>
      </tr>
    </template>
  </tbody>
</template>

<script
  setup
  lang="ts"
>
import { useEventManager } from '@/composables/useEventManager';
import { AppEvents } from '@/features/Common';
import { Tooltip } from '@/ui';
import { safeInject } from '@/utils/inject';

import { OpenedKey } from './symbols';
import TableDataLoader from './table-data-loader.vue';
import TableLoadError from './table-load-error.vue';
import TableRow from './table-row.vue';
import TableTitle from './table-title.vue';
import { IColumn, TRowGroup } from './types';

interface Props {
  data: TRowGroup;
  columns: IColumn[];
  colspan: number;
  rowAlign?: string;
  rowClass?: string;
  hovered?: boolean;
  loadError?: boolean;
  onRowClick?: () => void;
}

withDefaults(defineProps<Props>(), {
  rowAlign: '',
  rowClass: '',
  onRowClick: undefined,
});

const { isOpened, toggleOpened } = safeInject(OpenedKey);

const handleOpen = (data: Record<string, boolean>) => {
  Object.keys(data).forEach((el) => {
    toggleOpened(el, data[el]);
  });
};

useEventManager(AppEvents.table.open, handleOpen);
</script>

<style
  lang="stylus"
  scoped
>
.group-title-row
  position relative
  z-index 1
  border-left 1px solid #dfe0e2

.title
  TitleRow()
  position sticky
  background-color #fff

::v-deep(.create)
  TitleRow()
  display grid
  grid-auto-flow column
  grid-column-gap 2px
  align-items center
  justify-content start
  width 100%
  color Gray(DK16)
  transition: .15s background-color
  &:hover
    background-color #e6edf1
  svg
    fill Black(A60)

.create-row
  position sticky
  bottom 0
  z-index 1

  ::v-deep(td)
    padding: 0!important
</style>
