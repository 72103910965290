import { h } from 'vue';

import { SkuDetailed } from '@/api';
import { notification } from '@/features/Notifications';
import QRNotification from '@/pages/invoiceCreation/QRNotification.vue';

export const qrNotification = (
  sku: SkuDetailed,
  onPrint: () => void,
): string => {
  return notification.open(() => h(QRNotification, { sku, onPrint }), {
    duration: 7,
  });
};
