<template>
  <modal-wrapper
    :title="$t('modal.sku.mark as defected title')"
    data-test-id="modal__sku-defect"
    class="sku-defect-modal"
    @close="$emit('close')"
  >
    <div class="sku-defect-modal-info-wrapper">
      <base-image :url="sku.photoURL" :width="54" />
      <div>
        <span class="text-m font-medium leading-short">
          {{ getLang(sku.description) }}
        </span>
        <div class="sku-defect-modal-info">
          <div>
            <div>
              <span class="info-label">SKU</span>
              <span class="info-value">
                {{ sku.title }}
              </span>
            </div>
            <div>
              <span class="info-label">
                {{ $t('barcode') }}
              </span>
              <span class="info-value">
                {{ sku.barcode }}
              </span>
            </div>
          </div>
          <div v-if="sellerName || sku?.seller.phone">
            <template v-if="sellerName">
              <div>
                <span class="info-label">
                  {{ $t('seller') }}
                </span>
                <span class="info-value">
                  {{ sellerName }}
                </span>
              </div>
            </template>
            <template v-if="sku?.seller.phone">
              <div>
                <span class="info-label">
                  {{ $t('modal.sku.contact data') }}
                </span>
                <span class="info-value">
                  {{ sku?.seller.phone }}
                </span>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="defect-form">
      <div class="defect-form-section defect-reasons">
        <span class="defect-form-section-title">
          {{ $t('modal.sku.choose defect reason') }}
        </span>
        <div class="defect-form-section-content defect-reasons-content">
          <template v-if="!state.error">
            <div
              v-for="defectReason in state.defectReasons"
              :key="defectReason.id"
              :class="[
                'defect-reason',
                {
                  'defect-reason--checked': defect.reasonId === defectReason.id,
                },
              ]"
            >
              <label>
                <input
                  v-model="defect.reasonId"
                  type="radio"
                  name="defectReason"
                  :value="defectReason.id"
                  hidden
                />
                <div class="defect-reason-check" />
                <div class="defect-reason-title">
                  {{ defectReason.title }}
                </div>
              </label>
            </div>
          </template>
          <template v-else>
            {{ $t('modal.sku.defect reasons error') }}
          </template>
        </div>
      </div>
      <div>
        <span class="defect-form-section-title">
          {{ $t('modal.sku.type defect comment') }}
        </span>
        <div class="defect-form-section-content defect-comment">
          <textarea v-model="defect.comment" class="defect-comment-text" />
        </div>
      </div>
    </div>
    <template #footer>
      <ui-button
        variant="text"
        data-test-id="button__back-defect"
        @click="handleClose"
      >
        {{ $t('button.back') }}
      </ui-button>
      <ui-button
        :disabled="!canSave"
        data-test-id="button__save-defect"
        :loading="requestPending"
        type="primary"
        @click="handleSave"
      >
        {{ $t('button.save') }}
      </ui-button>
    </template>
  </modal-wrapper>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { Defect, SkuDetailed } from '@/api';
import { AppEvents } from '@/features/Common';
import { eventManager } from '@/features/EventManager';
import ModalWrapper from '@/features/Modals/ModalWrapper.vue';
import { notification } from '@/features/Notifications';
import { BaseImage, UiButton } from '@/ui';
import { getLang } from '@/utils/lang';

import { useSkuDefectApi } from './composables';

export default defineComponent({
  name: 'sku-defect-modal',
  components: {
    ModalWrapper,
    UiButton,
    BaseImage,
  },
  props: {
    sku: {
      type: Object as PropType<SkuDetailed>,
      required: true,
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const { state, addSkuDefect } = useSkuDefectApi();
    const { t } = useI18n();

    const sellerName = computed(() =>
      [
        props.sku?.seller?.lastName,
        props.sku?.seller?.firstName,
        props.sku?.seller?.patronymic,
      ]
        .filter((nameEntry) => !!nameEntry)
        .join(' '),
    );

    const defect: Defect = reactive({
      reasonId: '',
      comment: '',
    });

    const canSave = computed(() => defect.reasonId && defect.comment);
    const requestPending = ref(false);

    const handleSave = async () => {
      requestPending.value = true;

      try {
        await addSkuDefect(props.sku.id, defect);
        notification.success(t('notifications.sku.defect success'));
        eventManager.emit(AppEvents.sku.defect, state.defectDetailed);
      } catch {
        notification.error(t('notifications.sku.defect failure'));
      } finally {
        requestPending.value = false;
      }

      handleClose();
    };

    const handleClose = () => {
      emit('close');
    };

    return {
      state,
      sellerName,
      defect,
      canSave,
      handleSave,
      handleClose,
      getLang,
      requestPending,
    };
  },
});
</script>

<style lang="stylus">
.sku-defect-modal
  .modal-wrapper
    background White()
    display grid
    grid-template-rows 50px minmax(0, 1fr)
    width 675px
    min-height 365px
    border-radius 8px

  .modal-header
    color Gray(DK8)
    border-bottom 1px solid Gray(LT40)
    height 50px
    padding-left 32px
    padding-right 34px
    display flex
    align-items center
    justify-content space-between

  .modal-footer
    display grid
    grid-auto-flow column
    justify-content start
    align-items center
    grid-column-gap 14px
    margin-top 36px
    border none
    padding 0 32px 22px 32px

  .modal-content
    padding 22px 32px 0 32px
</style>

<style lang="stylus" scoped>
.sku-defect-modal-info-wrapper
  display grid
  grid-template-columns auto 1fr
  grid-column-gap 23px
  padding-bottom 12px
  margin-bottom 20px
  border-bottom 1px solid Gray(LT40)

.sku-defect-modal-info
  display grid
  grid-template-columns auto auto
  grid-auto-rows min-content
  grid-column-gap 40px
  margin-top 4px

.info-label
  display inline-block
  margin-right 4px
  color Gray(LT16)

.info-value,
.info-label
  @apply leading-short text-s

.defect-form-section
  margin-bottom 24px

.defect-form-section-title
  @apply text-m font-medium leading-short

.defect-reasons
  border-bottom 1px solid LightGray
  &-content
    display flex
    flex-wrap wrap
    padding 18px 0px

.defect-reason
  border 1px solid LightGray
  border-radius 4px
  color Gray
  transition-duration .15s
  margin-bottom 6px
  margin-right 4px
  label
    cursor pointer
    display flex
    align-items center
    padding 8px
  &-check
    position relative
    width 18px
    height 18px
    border 1px solid Gray
    border-radius 50%
    margin-right 9px
    transition-duration: .15s
    &::before
      content ""
      position absolute
      width 0px
      height 0px
      left 50%
      top 50%
      transform: translate(-50%, -50%)
      border-radius 50%
      background-color Gray
      transition-duration: .15s
  &--checked
    border-color Blue
    color Black
    .defect-reason-check
      border-color Blue
      &::before
        width 10px
        height 10px
        background-color Blue

.defect-comment
  padding-top 8px
  &-text
    width 100%
    height 74px
    padding 12px
    resize none
    border 1px solid LightGray
    border-radius 4px
    transition-duration .15s
    outline none
    &:focus
      border-color Blue
</style>
