<template>
  <div class="search-popup" data-test-id="popup__search">
    <AInput
      ref="searchRef"
      v-model:value="searchValue"
      v-maska="mask"
      :placeholder="placeholder"
      data-test-id="field__search"
      :class="['search-field', { invalid: !isValid }]"
      :maxlength="maxlength"
      @press-enter="handleAccept"
    />
    <AButton
      type="primary"
      size="small"
      class="search-btn"
      data-test-id="button__search"
      @click="handleAccept"
    >
      <SearchOutlined />
      {{ $t('button.find') }}
    </AButton>
    <AButton
      size="small"
      class="search-btn"
      data-test-id="button__reset"
      @click="handleReset"
    >
      {{ $t('button.reset') }}
    </AButton>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';
export default defineComponent({ name: 'search-popup' });
</script>

<script setup lang="ts">
import { SearchOutlined } from '@ant-design/icons-vue';
import { Button as AButton, Input as AInput } from 'ant-design-vue';

const props = defineProps({
  placeholder: {
    type: String,
    default: '',
  },
  maxlength: {
    type: [String, Number],
    default: null,
  },
  mask: {
    type: String,
    default: null,
  },
  unmask: {
    type: Function,
    default: (val: string): string => val,
  },
});

const emit = defineEmits<{
  (e: 'accept', payload: string): void;
  (e: 'reset'): void;
}>();

const searchRef = ref(null);
const searchValue = ref('');
const isValid = ref(true);

const handleAccept = () => {
  emit('accept', props.unmask(searchValue.value));
};

const handleReset = () => {
  searchValue.value = '';
  emit('reset');
};

watch(searchValue, () => {
  isValid.value = true;
});

defineExpose({
  searchValue,
  isValid,
});
</script>

<style lang="stylus" scoped>


.search-popup
  padding 8px

.search-field
  border-radius 4px
  margin-bottom 8px
  width 188px
  display block

.invalid
  border-color Red()
  &:focus
    box-shadow none

.search-btn
  width 90px
  border-radius 4px
  &:not(:last-child)
    margin-right 8px
</style>
