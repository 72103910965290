import { HTTPError } from 'ky';
import { computed, onMounted, onUnmounted, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

import {
  Box,
  boxesApi,
  DefectDetailed,
  DefectReason,
  defectsApi,
  Invoice,
  invoiceApi,
  InvoiceItem,
  InvoiceItemListResponse,
  InvoiceNotAddedItemList,
  InvoiceNotAddedItemListResponse,
  InvoiceStatus,
  InvoiceType,
  PhotoBox,
  photoBoxesApi,
  PhotoRequest,
  photoRequestApi,
  PhotoRequestItem,
  PhotoRequestStatus,
  ShootingType,
  skuApi,
  SkuDetailed,
  SkuListEntry,
} from '@/api';
import { AppEvents } from '@/features/Common';
import {
  ENTITY_INITIAL_STATE,
  PAGE_SIZE,
  TPageableEntity,
} from '@/features/Common';
import { notification } from '@/features/Notifications';
import { useInvoiceCreationApi } from '@/pages/invoiceCreation/composables';
import { useInvoicesApi } from '@/pages/invoices/composables';
import { AppRoutes } from '@/router';
import { clone, keyBy, uniq, uniqBy } from '@/utils/collection';
export interface IPhotoRequest {
  request: PhotoRequest;
  items: PhotoRequestItem[];
  barcode?: string;
  color?: string;
  type?: string;
}

export type TInvoiceItemsState = {
  invoice: Invoice | null;
  box: Box | null;
  loading: boolean;
  error: string;
  discrepancyError?: boolean;
  invoiceItems: TPageableEntity<InvoiceItem>;
  notAddedInvoiceItems: TPageableEntity<InvoiceNotAddedItemList>;
  photorequests: IPhotoRequest[];
  activePhotorequest: IPhotoRequest | null;
  lastPhotorequestItemAdded: IPhotoRequest | null;
  defects: DefectDetailed[];
  defectReasons: {
    available: DefectReason[];
    all: DefectReason[];
  };
  markDefectErrors: string[];
};

export function useInvoiceItemsApi(
  invoiceID: number,
  onUpdate?: (invoiceId: number) => void,
) {
  const state = reactive<TInvoiceItemsState>({
    invoice: null,
    box: null,
    loading: true,
    error: '',
    invoiceItems: clone(ENTITY_INITIAL_STATE),
    notAddedInvoiceItems: clone(ENTITY_INITIAL_STATE),
    photorequests: [],
    activePhotorequest: null,
    lastPhotorequestItemAdded: null,
    defects: [],
    defectReasons: {
      available: [],
      all: [],
    },
    markDefectErrors: [],
  });

  const { getInvoiceById, invoices } = useInvoicesApi();
  const { popCreatedInvoice } = useInvoiceCreationApi();
  const router = useRouter();
  const { t } = useI18n();

  const setPhotorequestDotColor = (): string => {
    if (!photorequestColorsPool.length) {
      photorequestColorsPool = [...photorequestColors];
    }

    return photorequestColorsPool?.shift() || '';
  };

  let photorequestColorsPool: string[] = [];

  const photorequestColors = [
    'yellow',
    'geekblue',
    'orange',
    'cyan',
    'purple',
    'magenta',
  ];

  const setPhotorequestTypeLetter = (
    items: PhotoRequestItem[] | void,
  ): string => {
    if (!items || !items.length) return '';

    const shootingTypes = items[0].shootingTypes;

    if (shootingTypes.includes(ShootingType.Model)) return 'M';
    if (shootingTypes.includes(ShootingType.Detailed)) return 'D';

    return '';
  };

  const handleInvoiceItemsResponse = (response: InvoiceItemListResponse) => {
    if (response) {
      state.invoiceItems.page = response.page + 1;
      state.invoiceItems.items = uniqBy(
        [...state.invoiceItems.items, ...response.items],
        (item) => String(item.id),
      );
      state.invoiceItems.entries = {
        ...state.invoiceItems.entries,
        ...keyBy(response.items, (item) => String(item.id)),
      };
      state.invoiceItems.hasMore =
        state.invoiceItems.page < response.totalPages;
      state.invoiceItems.totalCount = response.totalItems;
    }
  };

  const handleNotAddedInvoiceItemsResponse = (
    response: InvoiceNotAddedItemListResponse,
  ) => {
    if (response) {
      state.notAddedInvoiceItems.page = response.page + 1;
      state.notAddedInvoiceItems.items = response.items;
      state.notAddedInvoiceItems.hasMore =
        state.notAddedInvoiceItems.page < response.totalPages;
      state.notAddedInvoiceItems.totalCount = response.totalItems;
    }
  };

  const getInvoiceItems = async (
    invoiceID: number,
    notAdded: boolean = false,
  ) => {
    state.invoiceItems.loading = true;

    const response = await invoiceApi.getInvoiceItems({
      invoiceID,
      page: state.invoiceItems.page,
      size: PAGE_SIZE,
    });

    if (state.invoice?.type === InvoiceType.Sending) {
      await getDefects(response.items);
    }

    state.invoiceItems.loading = false;
    handleInvoiceItemsResponse(response);
  };

  const getNotAddedInvoiceItems = async (invoiceID: number) => {
    state.notAddedInvoiceItems.loading = true;

    const response = await invoiceApi.getInvoiceNotAddedItems({
      invoiceID,
      page: state.invoiceItems.page,
      size: PAGE_SIZE,
    });

    if (state.invoice?.type === InvoiceType.Sending) {
      await getDefects(response.items);
    }

    state.notAddedInvoiceItems.loading = false;
    handleNotAddedInvoiceItemsResponse(response);
  };

  const resetInvoiceItems = () => {
    state.invoiceItems = clone(ENTITY_INITIAL_STATE);
  };

  const getInvoiceItemBySku = async (
    skuID: number,
  ): Promise<InvoiceItem | undefined> => {
    if (invoiceID) {
      const invoiceItem = (
        await invoiceApi.getInvoiceItems({
          invoiceID: invoiceID,
          page: 0,
          size: 1,
          skuID,
        })
      ).items[0];

      if (invoiceItem) {
        if (state.invoiceItems.items.some((item) => item.sku?.id === skuID)) {
          state.invoiceItems.items = state.invoiceItems.items.filter(
            (item) => item.sku?.id !== skuID,
          );
        }

        state.invoiceItems.items.unshift(invoiceItem);
        state.invoiceItems.entries[invoiceItem.id] = invoiceItem;

        return invoiceItem;
      }
    }
  };

  const getInvoiceAndBox = async (invoiceID: number) => {
    const initialInvoice = popCreatedInvoice();

    if (initialInvoice && initialInvoice.id == invoiceID) {
      state.invoice = initialInvoice;
    } else {
      state.invoice = await invoiceApi.getInvoiceByID({
        invoiceID,
      });
    }

    const boxes = await boxesApi.getBoxes({
      ids: [state.invoice.boxId],
      page: 0,
      size: 1,
    });

    if (boxes?.items.length) {
      state.box = boxes?.items[0];
    }
  };

  const getInitialInvoiceItems = async (invoiceID: number) => {
    resetInvoiceItems();

    try {
      state.loading = true;
      await getInvoiceAndBox(invoiceID);
      await getInvoiceItems(invoiceID);
      await getNotAddedInvoiceItems(invoiceID);
    } catch (e) {
      if (e instanceof HTTPError) {
        await router.push({ name: AppRoutes.empty.name });
      }

      state.error = 'Ошибка при получении данных';
    } finally {
      state.loading = false;
    }
  };

  const acceptInvoiceItem = async (
    itemID: number,
    actualAmount: number,
  ): Promise<InvoiceItem | undefined> => {
    if (state.invoiceItems.loading) return;

    try {
      state.invoiceItems.loading = true;

      const invoiceItem = await invoiceApi.setProductAmountV2({
        itemID,
        setProductAmount: {
          actualAmount,
        },
      });

      const updatedInvoiceItem: InvoiceItem = {
        ...invoiceItem,
        sku: state.invoiceItems.entries[invoiceItem.id].sku,
      };

      const index = state.invoiceItems.items.findIndex(
        (item) => item.id === updatedInvoiceItem.id,
      );

      state.invoiceItems.items[index] = updatedInvoiceItem;
      state.invoiceItems.entries[invoiceItem.id] = updatedInvoiceItem;

      if (state.invoice) {
        const invoicesFromList: Invoice[] = invoices[state?.invoice.type].items;

        const currentInvoiceFromList: Invoice | undefined =
          invoicesFromList?.find(({ id }) => state.invoice?.id === id);

        state.invoice.actualAmount += actualAmount;

        if (currentInvoiceFromList) {
          currentInvoiceFromList.actualAmount += actualAmount;
        }
      }

      return updatedInvoiceItem;
    } finally {
      state.error = 'Ошибка при принятии товара';
      state.invoiceItems.loading = false;
    }
  };

  const acceptInvoiceItemByBarcode = async (barcode: string) => {
    const sku = await getSkuByBarcode(barcode);

    const invoiceItem = (
      await invoiceApi.getInvoiceItems({
        skuID: sku.id,
        page: 0,
        size: 1,
        invoiceID,
      })
    ).items[0];

    if (!invoiceItem) {
      ({
        code: 'skuNotExits',
        text: 'modal.error.codes.skuNotExits',
        withTranslation: true,
      });

      notification.error(t('modal.error.codes.skuNotExits'));

      throw Error('skuNotExits');
    }

    state.invoiceItems.items = state.invoiceItems.items.filter(
      (item) => item.id !== invoiceItem.id,
    );
    state.invoiceItems.items.unshift(invoiceItem);

    state.invoiceItems.entries[invoiceItem.id] = invoiceItem;

    const newActualAmount = invoiceItem.actualAmount + 1;

    return acceptInvoiceItem(invoiceItem.id, newActualAmount);
  };

  const deleteInvoiceItem = async (itemID: number) => {
    try {
      await invoiceApi.deleteInvoiceItem({ itemID });
      state.invoiceItems.items = state.invoiceItems.items.filter(
        (item) => item.id !== itemID,
      );

      state.invoice &&
        state.invoice.expectedAmount-- &&
        state.invoiceItems.totalCount--;

      return true;
    } catch {
      state.error = 'Ошибка при удалении товара из накладной';
    }
  };

  const getSkuByBarcode = async (barcode: string) => {
    const sku = await skuApi
      .skuList(
        { page: 0, size: 1, barcode },
        { afterResponseHookOptions: { handleBadResponse: false } },
      )
      .then((res) => res.items.at(0))
      .catch(() => undefined);

    if (!sku) {
      notification.error(t('modal.error.codes.skuNotFound'));

      throw Error('skuNotFound');
    }

    return sku;
  };

  const getSkuDetailed = async (id: number): Promise<SkuDetailed> => {
    const sku = await skuApi.getSkuByID({ skuID: id });
    const item = state.invoiceItems.items.find((item) => item.sku?.id === id);

    item?.sku?.identifier === sku.identifier;

    return sku;
  };

  const addSku = async (sku: SkuListEntry) => {
    if (!state.invoice) {
      throw Error('Invoice is not created');
    }

    const invoiceItem = await invoiceApi
      .addItemToInvoice({
        invoiceID: state.invoice.id,
        invoiceItemTemplate: {
          skuID: sku.id,
        },
      })
      .catch(async (err) => {
        const res = await err.response.json();
        const errCode = `modal.error.codes.${res.code}`;

        notification.error(t(errCode), { key: errCode });
      });

    if (!invoiceItem) {
      throw Error('Invoice item not added');
    }

    await getNotAddedInvoiceItems(invoiceID);

    const index = state.invoiceItems.items.findIndex(
      (item) => item.id === invoiceItem.id,
    );

    if (index === -1) {
      state.invoiceItems.items.unshift({ ...invoiceItem, sku });
      state.invoiceItems.totalCount++;
    } else {
      state.invoiceItems.items[index] = { ...invoiceItem, sku };
    }

    state.invoice && state.invoice.expectedAmount++;

    if (state.invoice?.type === InvoiceType.Sending) {
      await getDefects([invoiceItem]);
    }
  };

  const startInvoiceAccounting = async () => {
    const invoice = await invoiceApi.changeInvoiceStatus({
      invoiceID,
      changeInvoiceStatus: { status: InvoiceStatus.InAccounting },
    });

    onUpdate?.(invoice.id);

    if (state.invoice) {
      updateInvoiceStatus(invoice.status);
    }
  };

  const finishInvoiceAccounting = async (saveWithDiscrepancy = false) => {
    state.discrepancyError = false;

    const items = await invoiceApi.getInvoiceItems({
      invoiceID,
      page: 0,
      size: 1,
      differentAmounts: true,
    });

    if (items.totalItems > 0 && !saveWithDiscrepancy) {
      state.discrepancyError = true;

      return false;
    } else {
      await changePhotorequestsStatus();
      await deleteEmptyPhotorequests();
      await changeStatusToDone();

      return true;
    }
  };

  const changeStatusToDone = async () => {
    const invoice = await invoiceApi.changeInvoiceStatus({
      invoiceID,
      changeInvoiceStatus: { status: InvoiceStatus.Done },
    });

    onUpdate?.(invoice.id);

    if (state.invoice) {
      updateInvoiceStatus(invoice.status);
    }
  };

  const updateInvoiceStatus = (status: InvoiceStatus) => {
    if (state.invoice) {
      state.invoice.status = status;
    }
  };

  const changeStatusToCreated = async () => {
    const invoice = await invoiceApi.changeInvoiceStatus({
      invoiceID,
      changeInvoiceStatus: { status: InvoiceStatus.Created },
    });

    onUpdate?.(invoice.id);

    if (state.invoice) {
      updateInvoiceStatus(invoice.status);
    }
  };

  const changePhotorequestsStatus = async () => {
    const photorequests = state.photorequests.filter((el) => el.items.length);

    if (photorequests.length) {
      await photoRequestApi.changePhotoRequestStatusBulk({
        changeRequestStatusBulkRequest: {
          ids: photorequests.map((el) => el.request.id) as number[],
          status: PhotoRequestStatus.Created,
        },
      });
    }
  };

  const expressAccept = async () => {
    const invoice = await invoiceApi.changeInvoiceStatus({
      invoiceID,
      changeInvoiceStatus: { status: InvoiceStatus.Incoming },
    });

    onUpdate?.(invoice.id);

    if (state.invoice) {
      updateInvoiceStatus(invoice.status);
    }
  };

  const deleteInvoice = () => invoiceApi.deleteInvoice({ invoiceID });

  const handleLoadMore = async () => {
    if (
      state.invoiceItems.items.length > 0 &&
      state.invoiceItems.hasMore &&
      !state.invoiceItems.loading
    ) {
      await getInvoiceItems(invoiceID);
    }
  };

  const fetchAllPhotoRequests = async (
    page = 0,
    result: PhotoRequest[] = [],
  ): Promise<PhotoRequest[]> => {
    const { items, totalPages } = await photoRequestApi.photoRequestsList({
      page: page,
      size: PAGE_SIZE,
      invoiceIDs: [invoiceID],
    });

    const combinedResult = uniq([...result, ...items]);
    const nextPage = page + 1;

    if (nextPage < totalPages) {
      return await fetchAllPhotoRequests(nextPage, combinedResult);
    }

    return combinedResult;
  };

  const getPhotorequests = async (page = 0) => {
    const response = await photoRequestApi.photoRequestsList({
      page: page,
      size: PAGE_SIZE,
      invoiceIDs: [invoiceID],
    });

    const boxIds = response.items
      .map((el) => el.photoBoxID)
      .filter((el) => !!el) as number[];

    let boxes;

    if (boxIds.length) {
      boxes = (
        await photoBoxesApi.getPhotoBoxes({
          ids: boxIds,
          page: 0,
          size: response.items.length,
        })
      ).items;
    }

    for (const item of response.items) {
      if (item.status === PhotoRequestStatus.Draft) {
        const photorequest = {
          request: item,
          items: [],
          barcode:
            boxes?.find((box: PhotoBox) => box.id === item.photoBoxID)
              ?.barcode || '',
          color: setPhotorequestDotColor(),
          type: '',
        };

        await getPhotorequestItems(photorequest);
        state.photorequests = uniqBy(
          [...state.photorequests, photorequest],
          (item) => String(item.request.id),
        );
      }
    }

    const nextPage = page + 1;

    if (nextPage < response.totalPages) {
      await getPhotorequests(nextPage);
    }
  };

  const createAndSetBox = async (box: PhotoBox) => {
    const response = await photoRequestApi.createPhotoRequest({
      photoRequestTemplate: {
        photoBoxID: box.id,
        invoiceID: invoiceID,
      },
    });

    const boxes = await photoBoxesApi.getPhotoBoxes({
      ids: [box.id],
      page: 0,
      size: 1,
    });

    const photorequest = {
      request: response,
      items: [],
      barcode: boxes?.items[0]?.barcode,
      color: setPhotorequestDotColor(),
    };

    state.photorequests.push(photorequest);
    state.activePhotorequest = photorequest;

    eventManager.emit(AppEvents.modals.error.close);
  };

  const createPhotorequest = async (barcode: string) => {
    const boxes = await photoBoxesApi.getPhotoBoxes({
      barcode,
      page: 0,
      size: 1,
    });

    if (boxes.totalItems === 0) {
      notification.error(t(`modal.error.codes.unknownBoxBarcode`));
      throw Error('Not valid box barcode');
    }

    const box = boxes?.items[0];

    await createAndSetBox(box);
  };

  const deletePhotorequest = async (photoRequestID: number) => {
    await photoRequestApi.deletePhotoRequest({
      photoRequestID,
    });

    state.photorequests = state.photorequests.filter(
      (el) => el.request.id !== photoRequestID,
    );
  };

  const deleteEmptyPhotorequests = async () => {
    const emptyPhotorequests = state.photorequests
      .filter((el) => !el.items.length)
      .map((el) => el.request.id) as number[];

    if (emptyPhotorequests.length) {
      await photoRequestApi.bulkDletePhotoRequest({
        bulkDeletePhotoRequestRequest: {
          ids: emptyPhotorequests,
        },
      });

      state.photorequests = state.photorequests.filter(
        (el) => !emptyPhotorequests.includes(el.request.id),
      );
    }
  };

  const getPhotorequestItems = async (
    photoRequest: IPhotoRequest,
    page = 0,
  ) => {
    const response = await photoRequestApi.photoRequestItemsList({
      photoRequestID: photoRequest.request.id,
      page: page,
      size: 20,
    });

    photoRequest.items = uniq([...photoRequest.items, ...response.items]);

    photoRequest.type = setPhotorequestTypeLetter(photoRequest.items);

    const nextPage = page + 1;

    if (nextPage < response.totalPages) {
      await getPhotorequestItems(photoRequest, nextPage);
    }
  };

  const addPhotorequestItem = async (
    photoRequestID: number,
    skuID: number,
  ): Promise<InvoiceItem | undefined> => {
    const invoiceItem = state.invoiceItems.items.find(
      (el: InvoiceItem) => el.sku?.id === skuID,
    );

    if (
      invoiceItem?.id &&
      invoiceItem.sku &&
      invoiceItem?.actualAmount !== invoiceItem.expectedAmount
    ) {
      await acceptInvoiceItemByBarcode(invoiceItem.sku.barcode);
    }

    const photoRequestItem = await photoRequestApi.addItemToPhotoRequest({
      photoRequestID,
      photoRequestItemTemplate: {
        skuID,
      },
    });

    const photorequest = state.photorequests.find(
      (el) => el.request.id == photoRequestID,
    );

    if (photorequest) {
      photorequest.items.unshift(photoRequestItem);
      state.lastPhotorequestItemAdded = photorequest;
      photorequest.type = setPhotorequestTypeLetter(photorequest.items);
    }

    return invoiceItem;
  };

  const deletePhotorequestItem = async (
    photoRequestID: number,
    itemID: number,
  ) => {
    await photoRequestApi.deletePhotoRequestItem({
      itemID,
    });

    const photorequest = state.photorequests.find(
      (el) => el.request.id == photoRequestID,
    );

    if (photorequest) {
      photorequest.items = photorequest.items.filter((el) => el.id !== itemID);
      photorequest.type = setPhotorequestTypeLetter(photorequest.items);
    }
  };

  const toggleActivePhotorequest = (item?: IPhotoRequest) => {
    if (state.activePhotorequest === item || !item) {
      state.activePhotorequest = null;
    } else {
      state.activePhotorequest = item;
    }
  };

  const getDefects = async (
    invoiceItems: InvoiceItem[] | InvoiceNotAddedItemList[],
  ): Promise<void> => {
    const skuIds = invoiceItems
      .map((item) => item.sku?.id)
      .filter((id) => Number(id)) as number[];

    if (skuIds.length) {
      const response = await defectsApi.listDefects({
        skuID: skuIds,
        page: 0,
        size: skuIds.length,
      });

      state.defects = uniq([...state.defects, ...response.items]);
    }
  };

  const getDefectReasons = async (): Promise<void> => {
    const reasons = await skuApi.getSkuDefectReasons();

    state.defectReasons.all = reasons;
    state.defectReasons.available = reasons.filter(
      (reason) => !reason.commentRequired,
    );
  };

  const bulkDeleteInvoiceItem = async (itemIds: number[]): Promise<number> => {
    await invoiceApi
      .bulkDdeleteInvoiceItems({
        invoiceID,
        bulkDeleteInvoiceItemsRequest: {
          itemIds,
        },
      })
      .then(() => {
        state.invoiceItems.items = state.invoiceItems.items.filter(
          (item) => !itemIds.includes(item.id),
        );

        state.invoiceItems.totalCount =
          state.invoiceItems.totalCount - itemIds.length;

        if (state.invoice) {
          getInvoiceById(state.invoice.id);
          state.invoice.expectedAmount -= itemIds.length;
          state.invoiceItems.totalCount -= itemIds.length;
        }
      });

    return itemIds.length;
  };

  const bulkCancelDefect = async (defectIds: number[]): Promise<void> => {
    if (defectIds.length) {
      await defectsApi
        .bulkDeleteDefect({
          bulkDeleteDefectsRequest: {
            ids: defectIds,
          },
        })
        .then(() => {
          state.defects = state.defects.filter(
            (defect) => !defectIds.includes(defect.id),
          );
        });
    }
  };

  const cancelDefect = async (defectId: number): Promise<void> => {
    await defectsApi
      .deleteDefect({
        defectID: defectId,
      })
      .then(() => {
        state.defects = state.defects.filter(
          (defect) => defect.id !== defectId,
        );
      });
  };

  const bulkMarkDefect = async (
    skuIds: number[],
    reasonId: string,
  ): Promise<number> => {
    return await defectsApi
      .bulkCreateOrChangeReasonDefects({
        bulkCreateOrChangeReasonDefectsRequest: {
          skuIds,
          reasonId,
        },
      })
      .then((res) => {
        const defectIds = res.defects.map((el) => el.id);

        state.defects = state.defects.filter(
          (defect) => !defectIds.includes(defect.id),
        );
        state.defects = uniq([...state.defects, ...res.defects]);

        state.markDefectErrors = res.errors;

        return res.defects.length;
      });
  };

  const getInvoiceItemByBarcode = async (
    barcode: string,
  ): Promise<InvoiceItem> => {
    const invoiceItem = state.invoiceItems.items.find(
      (el: InvoiceItem) => el?.sku?.barcode === barcode,
    );

    if (!invoiceItem) {
      const sku = await getSkuByBarcode(barcode);
      const foundedInvoiceItem = await getInvoiceItemBySku(sku.id);

      if (foundedInvoiceItem) {
        return foundedInvoiceItem;
      } else {
        notification.error(t('modal.error.codes.skuNotExits'));

        throw Error('skuNotExits');
      }
    } else {
      return invoiceItem;
    }
  };

  const getDefectedRowClass = (record: InvoiceItem): string => {
    return hasDefect(record) ? 'row-highlighted' : '';
  };

  const hasDefect = (invoiceItem: InvoiceItem): DefectDetailed | undefined => {
    const defect = state.defects.find(
      (defect) => defect.skuId === invoiceItem.sku?.id,
    );

    return defect;
  };

  const getDefectTitle = (invoiceItem: InvoiceItem): string => {
    const defect = hasDefect(invoiceItem);

    const reason = state.defectReasons.all.find(
      (reason) => reason.id === defect?.reasonId,
    );

    return reason?.title ? reason.title : '';
  };

  const addDefect = (defect: DefectDetailed): void => {
    if (state.defects.some((d) => d.id === defect.id)) return;

    state.defects.push(defect);
  };

  const totalNotAddedProducts = computed(() => {
    return state.notAddedInvoiceItems.items.flatMap((item) => item.items)
      .length;
  });

  onMounted(async () => {
    await getInitialInvoiceItems(invoiceID);

    if (state.invoice?.type === InvoiceType.Sending) {
      await getDefectReasons();
    }

    eventManager.on(AppEvents.sku.defect, addDefect);
  });

  onUnmounted(() => {
    eventManager.off(AppEvents.sku.defect, addDefect);
  });

  return {
    state,
    totalNotAddedProducts,
    getInitialInvoiceItems,
    getInvoiceItemBySku,
    acceptInvoiceItem,
    acceptInvoiceItemByBarcode,
    deleteInvoiceItem,
    startInvoiceAccounting,
    finishInvoiceAccounting,
    changeStatusToDone,
    changeStatusToCreated,
    expressAccept,
    deleteInvoice,
    handleLoadMore,
    addSku,
    updateInvoiceStatus,
    getPhotorequests,
    createPhotorequest,
    deletePhotorequest,
    getPhotorequestItems,
    addPhotorequestItem,
    deletePhotorequestItem,
    toggleActivePhotorequest,
    deleteEmptyPhotorequests,
    getDefects,
    getDefectReasons,
    bulkDeleteInvoiceItem,
    cancelDefect,
    bulkCancelDefect,
    bulkMarkDefect,
    getInvoiceItemByBarcode,
    getDefectedRowClass,
    hasDefect,
    getDefectTitle,
    fetchAllPhotoRequests,
    getSkuByBarcode,
    getSkuDetailed,
    getNotAddedInvoiceItems,
  };
}
