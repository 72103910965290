/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Image,
    ImageFromJSON,
    ImageFromJSONTyped,
    ImageToJSON,
} from './Image';
import {
    StudioPhotoInfo,
    StudioPhotoInfoFromJSON,
    StudioPhotoInfoFromJSONTyped,
    StudioPhotoInfoToJSON,
} from './StudioPhotoInfo';

/**
 * Элемент заявки для сервиса автозагрузки
 * @export
 * @interface PhotoRequestItemDetailedAutoUpload
 */
export interface PhotoRequestItemDetailedAutoUpload {
    /**
     * Идентификатор элемента заявки на фото
     * @type {number}
     * @memberof PhotoRequestItemDetailedAutoUpload
     */
    id: number;
    /**
     * Внешний идентификатор товара
     * @type {number}
     * @memberof PhotoRequestItemDetailedAutoUpload
     */
    skuExternalId: number;
    /**
     * Идентификатор товара
     * @type {number}
     * @memberof PhotoRequestItemDetailedAutoUpload
     */
    productId: number;
    /**
     * Идентификатор магазина
     * @type {number}
     * @memberof PhotoRequestItemDetailedAutoUpload
     */
    shopId: number;
    /**
     * Идентификатор группы товаров
     * @type {number}
     * @memberof PhotoRequestItemDetailedAutoUpload
     */
    skuGroupId: number;
    /**
     * Штрихкод товара
     * @type {string}
     * @memberof PhotoRequestItemDetailedAutoUpload
     */
    skuBarcode: string;
    /**
     * Список изображений товара
     * @type {Array<Image>}
     * @memberof PhotoRequestItemDetailedAutoUpload
     */
    skuImages: Array<Image>;
    /**
     * 
     * @type {Array<StudioPhotoInfo>}
     * @memberof PhotoRequestItemDetailedAutoUpload
     */
    studioPhotos: Array<StudioPhotoInfo>;
}

export function PhotoRequestItemDetailedAutoUploadFromJSON(json: any): PhotoRequestItemDetailedAutoUpload {
    return PhotoRequestItemDetailedAutoUploadFromJSONTyped(json, false);
}

export function PhotoRequestItemDetailedAutoUploadFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhotoRequestItemDetailedAutoUpload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'skuExternalId': json['skuExternalId'],
        'productId': json['productId'],
        'shopId': json['shopId'],
        'skuGroupId': json['skuGroupId'],
        'skuBarcode': json['skuBarcode'],
        'skuImages': ((json['skuImages'] as Array<any>).map(ImageFromJSON)),
        'studioPhotos': ((json['studioPhotos'] as Array<any>).map(StudioPhotoInfoFromJSON)),
    };
}

export function PhotoRequestItemDetailedAutoUploadToJSON(value?: PhotoRequestItemDetailedAutoUpload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'skuExternalId': value.skuExternalId,
        'productId': value.productId,
        'shopId': value.shopId,
        'skuGroupId': value.skuGroupId,
        'skuBarcode': value.skuBarcode,
        'skuImages': ((value.skuImages as Array<any>).map(ImageToJSON)),
        'studioPhotos': ((value.studioPhotos as Array<any>).map(StudioPhotoInfoToJSON)),
    };
}

