/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InvoiceStatus,
    InvoiceStatusFromJSON,
    InvoiceStatusFromJSONTyped,
    InvoiceStatusToJSON,
} from './InvoiceStatus';

/**
 * 
 * @export
 * @interface ChangeInvoiceStatus
 */
export interface ChangeInvoiceStatus {
    /**
     * 
     * @type {InvoiceStatus}
     * @memberof ChangeInvoiceStatus
     */
    status: InvoiceStatus;
}

export function ChangeInvoiceStatusFromJSON(json: any): ChangeInvoiceStatus {
    return ChangeInvoiceStatusFromJSONTyped(json, false);
}

export function ChangeInvoiceStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeInvoiceStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': InvoiceStatusFromJSON(json['status']),
    };
}

export function ChangeInvoiceStatusToJSON(value?: ChangeInvoiceStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': InvoiceStatusToJSON(value.status),
    };
}

