import { InvoiceStatus, InvoiceType } from '@/api';

export interface StatusFilter {
  type: InvoiceType;
  status: InvoiceStatus;
}

export enum EInvoicePlacement {
  LEADING = 'leading',
  TRAILING = 'trailing',
  INPLACE = 'inplace',
}
