<template>
  <ModalWrapper
    class="transfer-wrapper"
    :title="$t('box transfer.new transfer')"
    @close="emit('close')"
  >
    <div class="modal-content">
      <div class="scan-title">{{ $t('box transfer.scan box') }}</div>
      <div id="transferWrapper">
        <UiInput
          v-model:value="barcode"
          class="scan-input"
          placeholder="80-XXXXXXXXXX"
          :maxlength="13"
          @change="handleInput"
          @press-enter="handleAddItem()"
        />
      </div>
      <div v-if="state.items.length" class="selected-boxes">
        <div class="selected-boxes-title">
          {{ $t('box transfer.selected boxes') }}
        </div>
        <div class="selected-boxes-items">
          <div
            v-for="item in state.items"
            :key="item.id"
            class="selected-boxes-item font-sf-pro"
          >
            {{ item.barcode }}
            <icon-ui
              name="navigation/dissmiss_line"
              class="dissmiss-box"
              :size="16"
              fill="#A0A2A7"
              @click="removeBoxFromTransfer(item)"
            />
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <div class="transfer-footer">
        <UiButton
          type="primary"
          :disabled="!state.items.length"
          @click="handleSave"
        >
          {{ $t('box transfer.create') }}
        </UiButton>
      </div>
    </template>
  </ModalWrapper>
</template>

<script lang="ts">
import { defineComponent, onMounted, PropType, ref } from 'vue';
export default defineComponent({ name: 'create-box-transfer-modal' });
</script>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

import { BoxTransfer } from '@/api';
import { useSoundSignal } from '@/composables/useSoundSignal';
import { EBarcodeEvents, useBarcodeScan } from '@/features/Barcode';
import { AppEvents } from '@/features/Common';
import { EIntroKeys, useFeatureIntro } from '@/features/FeatureIntro';
import ModalWrapper from '@/features/Modals/ModalWrapper.vue';
import { notification } from '@/features/Notifications';
import { UiButton, UiInput } from '@/ui';

import { useCreateBoxTransfer } from './composables';

const props = defineProps({
  boxTransfer: {
    type: Object as PropType<BoxTransfer>,
    default: null,
  },
  onSave: {
    type: Function as PropType<(boxTransferList: BoxTransfer[]) => void>,
    required: true,
  },
});

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const { t, te } = useI18n();
const wrapSound = useSoundSignal();

const { state, addBoxToTransfer, removeBoxFromTransfer, createBoxTransfer } =
  useCreateBoxTransfer();

const [showAddBoxIntro] = useFeatureIntro(EIntroKeys.ADD_BOX_TRANSER_ITEM);

const [showMultiplyTransfer] = useFeatureIntro(
  EIntroKeys.MULTIPLY_BOX_TRANSFER,
);

onMounted(() => {
  setTimeout(() => {
    showAddBoxIntro();
  }, 300);
});

const barcode = ref('');
const isShowBarcodeErrorModal = ref<boolean>(false);

const validateBarcode = (barcode: string) => {
  if (barcode.length < 13 || !barcodeRegex.test(barcode)) {
    wrapSound(
      // eslint-disable-next-line no-async-promise-executor
      new Promise(async (_resolve, reject) => {
        notification.error(t(`modal.error.codes.barcodeInvalid`));
        reject();
      }),
    );

    return;
  }

  return true;
};

useBarcodeScan(
  EBarcodeEvents.BOXES,
  (barcode) => handleBarcodeScan(barcode),
  false,
  validateBarcode,
);

const handleBarcodeScan = (barcode: string) => {
  if (!barcode) return;
  handleAddItem(barcode);
};

const barcodeRegex = /^80-[0-9]{10}$/;
const numberRegex = /^[0-9]$/;

const handleInput = (e: InputEvent) => {
  if (e.data) {
    if (e.data.length === 1 && !numberRegex.test(e.data)) {
      barcode.value = barcode.value.substr(0, barcode.value.length - 1);
    } else if (e.data && barcode.value.length === 1) {
      barcode.value = `80-${e.data}`;
    } else if (e.data.length > 1) {
      barcode.value = barcodeRegex.test(e.data) ? e.data : '';
    }
  } else {
    if (barcode.value.length === 3) {
      barcode.value = '';
    }
  }
};

const isAdding = ref(false);

const handleAddItem = async (payload?: string) => {
  if (isAdding.value) return;

  const param = payload ? payload : barcode.value;

  if (!param || !validateBarcode(param)) return;

  try {
    isAdding.value = true;

    if (param) {
      await wrapSound(addBoxToTransfer(param));
      showMultiplyTransfer();
    }
  } catch (e) {
    const errMessage = `box transfer.error.${e.message}`;

    if (te(errMessage)) {
      notification.error(t(errMessage));
    }
  } finally {
    barcode.value = '';
    isAdding.value = false;
  }
};

const handleSave = async () => {
  await createBoxTransfer();
  notification.success(
    `${t('pluralization.box', state.boxSuccess.length)} ${t(
      'box transfer.success',
    )} ${t('pluralization.transfered', state.boxSuccess.length)}`,
  );
  props.onSave(state.boxSuccess);

  emit('close');

  if (state.boxErrors.length) {
    isShowBarcodeErrorModal.value = true;
    eventManager.emit(AppEvents.modals.boxTransferErrors.open, {
      items: state.boxErrors,
    });
  }
};
</script>

<style lang="stylus">
.transfer-wrapper .ant-modal-body
  width 500px
  min-height 225px


.transfer-wrapper

  .modal-content
    flex 1
    display flex
    justify-content center
    flex-direction column
    .loader
      width 60px
      height 60px

  .modal-footer
    display block
</style>

<style lang="stylus" scoped>

.scan-title
  font-weight 600
  margin-bottom 10px

.scan-input
  background-color Gray(L48)
  border none
  border-radius 4px
  padding 12px 9px
  display block
  width 100%

.selected-boxes
  margin-top 22px
  &-title
    font-weight 500
    font-size 12px
    margin-bottom 8px
  &-items
    display flex
    flex-wrap wrap
    margin 0 -4px
  &-item
    border 1px solid #D9D9D9
    border-radius 2px
    background-color #FAFAFA
    padding 2px 8px
    margin 4px
    display flex
    align-items center
    font-size .875rem

.dissmiss-box
  cursor pointer
  margin-left 5px
</style>
