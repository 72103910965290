/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PhotoRequestItemTemplate
 */
export interface PhotoRequestItemTemplate {
    /**
     * 
     * @type {number}
     * @memberof PhotoRequestItemTemplate
     */
    skuID: number;
    /**
     * 
     * @type {boolean}
     * @memberof PhotoRequestItemTemplate
     */
    changeShootingTypes?: boolean;
}

export function PhotoRequestItemTemplateFromJSON(json: any): PhotoRequestItemTemplate {
    return PhotoRequestItemTemplateFromJSONTyped(json, false);
}

export function PhotoRequestItemTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhotoRequestItemTemplate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'skuID': json['skuID'],
        'changeShootingTypes': !exists(json, 'changeShootingTypes') ? undefined : json['changeShootingTypes'],
    };
}

export function PhotoRequestItemTemplateToJSON(value?: PhotoRequestItemTemplate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'skuID': value.skuID,
        'changeShootingTypes': value.changeShootingTypes,
    };
}

