import { HTTPError } from 'ky';

import { ResponseError } from '@/api';

export function isHTTPError(error: unknown): error is HTTPError {
  return error instanceof HTTPError;
}

export async function parseErrorResponse(
  error: unknown,
): Promise<ResponseError | null> {
  if (!isHTTPError(error)) return null;

  return error.response.json();
}

export async function isIncompatibleShootingTypeError(
  error: unknown,
): Promise<boolean> {
  if (!isHTTPError(error)) return false;

  const resp = await parseErrorResponse(error);

  return resp?.code === 1025;
}
