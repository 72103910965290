/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InternalSku,
    InternalSkuFromJSON,
    InternalSkuFromJSONTyped,
    InternalSkuToJSON,
} from './InternalSku';

/**
 * 
 * @export
 * @interface InternalSkuListResponse
 */
export interface InternalSkuListResponse {
    /**
     * 
     * @type {Array<InternalSku>}
     * @memberof InternalSkuListResponse
     */
    items: Array<InternalSku>;
}

export function InternalSkuListResponseFromJSON(json: any): InternalSkuListResponse {
    return InternalSkuListResponseFromJSONTyped(json, false);
}

export function InternalSkuListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternalSkuListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(InternalSkuFromJSON)),
    };
}

export function InternalSkuListResponseToJSON(value?: InternalSkuListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(InternalSkuToJSON)),
    };
}

