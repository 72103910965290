/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Actor,
    ActorFromJSON,
    ActorFromJSONTyped,
    ActorToJSON,
} from './Actor';

/**
 * 
 * @export
 * @interface PhotoRequestActorChange
 */
export interface PhotoRequestActorChange {
    /**
     * 
     * @type {Actor}
     * @memberof PhotoRequestActorChange
     */
    old: Actor;
    /**
     * 
     * @type {Actor}
     * @memberof PhotoRequestActorChange
     */
    _new: Actor;
}

export function PhotoRequestActorChangeFromJSON(json: any): PhotoRequestActorChange {
    return PhotoRequestActorChangeFromJSONTyped(json, false);
}

export function PhotoRequestActorChangeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhotoRequestActorChange {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'old': ActorFromJSON(json['old']),
        '_new': ActorFromJSON(json['new']),
    };
}

export function PhotoRequestActorChangeToJSON(value?: PhotoRequestActorChange | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'old': ActorToJSON(value.old),
        'new': ActorToJSON(value._new),
    };
}

