/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Reference
 */
export interface Reference {
    /**
     * Идентификатор референса
     * @type {number}
     * @memberof Reference
     */
    id: number;
    /**
     * Идентификатор категории
     * @type {number}
     * @memberof Reference
     */
    categoryID: number;
    /**
     * Название референса
     * @type {string}
     * @memberof Reference
     */
    name: string;
    /**
     * Ссылки на фото
     * @type {Array<string>}
     * @memberof Reference
     */
    photoLinks: Array<string>;
}

export function ReferenceFromJSON(json: any): Reference {
    return ReferenceFromJSONTyped(json, false);
}

export function ReferenceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Reference {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'categoryID': json['categoryID'],
        'name': json['name'],
        'photoLinks': json['photoLinks'],
    };
}

export function ReferenceToJSON(value?: Reference | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'categoryID': value.categoryID,
        'name': value.name,
        'photoLinks': value.photoLinks,
    };
}

