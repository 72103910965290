import { COUNTRY } from '@/config';

const phoneSettings = {
  RU: {
    mask: '8 (###) ### ## ##',
    unmaskSlice: 2,
    unmaskLength: 10,
    convertMatch: /^(\d{3})(\d{3})(\d{2})(\d{2})$/,
    convertFormat: '(#) #-#-#',
    fullPhone: '8#',
    phonePlaceholder: '8 (900) 255-00-00',
  },
  UZ: {
    mask: '+998 ## #######',
    unmaskSlice: 1,
    unmaskLength: 12,
    convertMatch: /^(\d{3})(\d{2})(\d{7})$/,
    convertFormat: '+# # #',
    fullPhone: '#',
    phonePlaceholder: '+998 90 2550000',
  },
};

const localPhoneSettings = phoneSettings[COUNTRY];

export const phoneMask = localPhoneSettings.mask;

export const phonePlaceholder = localPhoneSettings.phonePlaceholder;

export const unmaskPhone = (phone: string) =>
  phone.slice(localPhoneSettings.unmaskSlice).replace(/(\(|\)| )/g, '');

export const validatePhone = (phone: string) =>
  unmaskPhone(phone).length === localPhoneSettings.unmaskLength;

export const maskPhone = (phone: string) => {
  let i = 0;

  return phoneMask.replace(/#/g, () => phone[i++]);
};

export const convertPhone = (phone: string): string => {
  const cleaned = ('' + phone).replace(/\D/g, '');
  const match = cleaned.match(localPhoneSettings.convertMatch);

  if (match) {
    let formattedPhone = localPhoneSettings.convertFormat;

    match.slice(1).forEach((part) => {
      formattedPhone = formattedPhone.replace('#', part);
    });

    return formattedPhone;
  }

  return phone;
};

export const getFullPhone = (phone: string) => {
  return localPhoneSettings.fullPhone.replace('#', phone);
};
