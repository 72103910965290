import { HTTPError } from 'ky';
import { onMounted, reactive } from 'vue';
import { useRouter } from 'vue-router';

import {
  DefectDetailed,
  Invoice,
  PhotoRequest,
  photoRequestApi,
  skuApi,
  SkuDetailed,
  SkuStatus,
} from '@/api';
import {
  lockHooklessModals,
  unlockHooklessModals,
} from '@/features/Modals/useModal';
import { AppRoutes } from '@/router';

interface IState {
  loading: boolean;
  sku: SkuDetailed | null;
  invoice: Invoice | null;
  defect: DefectDetailed | null;
  defectError: boolean;
  photoRequest: PhotoRequest | null;
  error?: boolean;
}

const initialState: IState = {
  loading: true,
  sku: null,
  invoice: null,
  photoRequest: null,
  defect: null,
  defectError: false,
};

export function useSkuApi(skuID: number) {
  const state = reactive<IState>({ ...initialState });
  const router = useRouter();

  const getSku = async () => {
    try {
      state.loading = true;
      state.sku = await skuApi.getSkuByID({ skuID });
      state.invoice = await getSkuActiveInvoice(state.sku.id);

      if (state.sku.photoRequestId) {
        state.photoRequest = await getPhotoRequest(state.sku.photoRequestId);
      }

      if (state.sku.status !== SkuStatus.Sent) {
        state.defect = await getSkuDefect(state.sku.id);
      }
    } catch (e) {
      if (e instanceof HTTPError) {
        await router.push({ name: AppRoutes.empty.name });
      }

      state.error = true;
    } finally {
      state.loading = false;
    }
  };

  const getSkuActiveInvoice = async (
    skuID: number,
  ): Promise<Invoice | null> => {
    try {
      lockHooklessModals();

      const invoices = await skuApi.getActiveInvociceForSku({
        isActive: true,
        skuID,
      });

      if (!invoices.length) {
        return null;
      } else {
        return invoices[0];
      }
    } catch {
      return null;
    } finally {
      unlockHooklessModals();
    }
  };

  const getSkuBarcode = async (id: number) => {
    try {
      const response = await skuApi.getSkuBarcodes({
        printBarcodes: { ids: [id] },
      });

      return response;
    } catch (e) {
      if (
        e instanceof Error &&
        e.message === 'Request failed with status code 403'
      ) {
        throw Error(e.message);
      }

      return null;
    }
  };

  const getSkuDefect = async (
    skuID: number,
  ): Promise<DefectDetailed | null> => {
    try {
      lockHooklessModals();
      state.defectError = false;

      const defects = await skuApi.getDefects({
        skuID,
        page: 0,
        size: 1,
        isSent: false,
      });

      if (!defects.items.length) {
        return null;
      } else {
        return defects.items[0];
      }
    } catch {
      state.defectError = true;

      return null;
    } finally {
      unlockHooklessModals();
    }
  };

  const getPhotoRequest = async (
    photoRequestID: number,
  ): Promise<PhotoRequest | null> => {
    return await photoRequestApi.photoRequestDetail({
      photoRequestID,
    });
  };

  const resetState = () => {
    state.sku = initialState.sku;
    state.loading = initialState.loading;
    state.error = initialState.error;
    state.defect = initialState.defect;
    state.defectError = initialState.defectError;
  };

  onMounted(() => {
    getSku();
  });

  return {
    state,
    getSku,
    getSkuBarcode,
    resetState,
  };
}
