<template>
  <div
    ref="tableWrapperRef"
    :class="['content-table', { stretched: !props.itemsSource.items.length }]"
  >
    <UiTable
      :columns="columns"
      :data-source="computedTableRecords"
      bordered
      :custom-row="handleRowClick"
      :row-class-name="
        (record) => `${props.getDefectedRowClass(record)} row__invoice`
      "
      :loading="state.loading"
    >
      <template #bodyCell="{ column, text, record }">
        <template v-if="column.key === 'photo'">
          <template v-if="record.type === TableRecordType.REQUEST">
            <RouterLink
              :to="{
                name: AppRoutes.requests.name,
                params: {
                  id: record.photoRequestID,
                },
              }"
            >
              {{ $t('request') }} №{{ record.photoRequestID }}
            </RouterLink>
          </template>
          <BaseImage v-else :url="text" />
        </template>
        <template v-if="column.key === 'nomenclature'">
          <div class="table-nomenclature">{{ getLang(text) }}</div>
          <span v-if="record.paymentRate" class="table-payment-rate">
            <PaymentRateChip
              :rate="record.paymentRate"
              :tooltip="false"
              use-title
            />
          </span>
          <UiTag
            v-if="props.hasDefect(record)"
            class="defect-tag"
            data-test-id="tag__defect-item-invoice"
          >
            {{ props.getDefectTitle(record) }}
          </UiTag>
        </template>
        <template v-if="column.key === 'title'">
          {{ record.sku?.title }}
        </template>
        <template v-if="column.key === 'barcode'">
          <BarcodeCell :state="state" :record="record" />
        </template>
        <template v-if="column.key === 'amount'">
          <template v-if="!isSending">
            <AmountCell
              :item="record"
              :is-disabled="isAccountingDisabled"
              :accept-invoice-item="acceptInvoiceItem"
            />
          </template>
          <template v-else>
            <span>{{ $t('pc.', { count: text }) }}</span>
          </template>
        </template>
      </template>
    </UiTable>
    <InfiniteLoader
      :key="props.itemsSource.page"
      :has-more="props.itemsSource.hasMore"
      :on-enter="handleInfiniteLoader"
    />
    <LinearLoader
      v-if="props.itemsSource.hasMore"
      :style="{ width: '100%', margin: '0 0 1em 0' }"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, PropType, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import {
  DefectDetailed,
  InvoiceItem,
  InvoiceNotAddedItemList,
  InvoiceType,
  PhotoRequest,
} from '@/api';
import InfiniteLoader from '@/components/InfiniteLoader.vue';
import PaymentRateChip from '@/components/PaymentRateChip.vue';
import LinearLoader from '@/components/ui/linear-loader/index.vue';
import { useIsInViewWrapper } from '@/composables/useIsInView';
import { TPageableEntity } from '@/features/Common';
import BarcodeCell from '@/pages/invoice/components/barcodeCell.vue';
import Qrcode from '@/pages/sku/components/Qrcode.vue';
import { AppRoutes } from '@/router';
import { BaseImage, CopyButton, UiTable, UiTag } from '@/ui';
import { getLang } from '@/utils/lang';

import AmountCell from '../amount-cell.vue';
import { TInvoiceItemsState } from '../composables';

enum TableRecordType {
  REQUEST = 'REQUEST',
  PRODUCT = 'PRODUCT',
}
type TableRecord = {
  type: TableRecordType;
  [key: string]: any;
};
type PropsType = {
  itemsSource: TPageableEntity<InvoiceNotAddedItemList>;
  hasCount: boolean;
  isSending: boolean;
  isDraft: boolean;
  isAccountingDisabled: boolean;
  handleRowClick: (row: InvoiceItem) => any;
  handleRemoveItem: (item: any) => void;
  acceptInvoiceItem: (
    itemID: number,
    actualAmount: number,
  ) => Promise<InvoiceItem | undefined>;
  handleInfiniteLoader: () => void;
  getDefectedRowClass: (record: InvoiceItem) => string;
  hasDefect: (invoiceItem: InvoiceItem) => DefectDetailed | undefined;
  getDefectTitle: (invoiceItem: InvoiceItem) => string;
  getRequests: () => Promise<PhotoRequest[]>;
  state: TInvoiceItemsState;
};

const props = withDefaults(defineProps<PropsType>(), {
  hasCount: false,
  isSending: false,
  isDraft: false,
  isAccountingDisabled: true,
  handleRowClick: () => {},
  handleRemoveItem: () => {},
  acceptInvoiceItem: () => Promise.resolve(undefined),
  handleInfiniteLoader: () => {},
  getRequests: () => Promise.resolve([]),
});

const computedTableRecords = computed(() => {
  const items: TableRecord[] = [];

  props.itemsSource.items.forEach((record) => {
    items.push({
      type: TableRecordType.REQUEST,
      photoRequestID: record.photoRequestID,
    });
    record.items.forEach((invoiceItem) => {
      items.push({
        type: TableRecordType.PRODUCT,
        ...invoiceItem,
      });
    });
  });

  return items;
});

const { t } = useI18n();
const tableWrapperRef = ref();

useIsInViewWrapper(tableWrapperRef);

const hideCellIfRecordIsRequest = (cell) => {
  if (cell.type === TableRecordType.REQUEST) {
    return { colSpan: 0 };
  }
};

const columns = computed(() => {
  return [
    {
      title: t('photo'),
      key: 'photo',
      dataIndex: ['sku', 'photoURL'],
      width: 86,
      customCell: (cell) => {
        if (cell.type === TableRecordType.REQUEST) {
          return {
            colspan: 5,
            style: {
              backgroundColor: '#f5f5f5',
            },
          };
        }
      },
    },
    {
      title: t('nomenclature'),
      key: 'nomenclature',
      dataIndex: ['sku', 'description'],
      customCell: hideCellIfRecordIsRequest,
    },
    {
      title: 'SKU',
      key: 'title',
      dataIndex: ['sku', 'title'],
      width: 210,
      customCell: hideCellIfRecordIsRequest,
    },
    {
      title: t('barcode'),
      key: 'barcode',
      dataIndex: ['sku', 'barcode'],
      width: 170,
      customCell: hideCellIfRecordIsRequest,
    },
    {
      title: t('checked'),
      key: 'amount',
      dataIndex: 'expectedAmount',
      width: 160,
      customCell: hideCellIfRecordIsRequest,
    },
  ];
});
</script>

<style lang="stylus" scoped>
.content-wrapper
  overflow hidden
  height 100%
  display flex
  flex-direction column

.content-header
  margin-top 30px
  margin-bottom 20px
  display flex
  align-items flex-start
  justify-content space-between
  gap 20px

.content-table
  max-height 100%
  overflow auto
  :deep() .ant-empty
    min-height calc(100vh - 280px)
  &.stretched
    height 100%

.action
  cursor pointer
  border none
  background none
  height 80px
  opacity 0
  visibility hidden

.table-nomenclature
  overflow-wrap anywhere

.table-payment-rate
  display inline-block
  margin-top 10px

.defect-tag
  background-color #FADB14
  margin-top 10px

:deep().row-highlighted
  background #FEFFE6

.requests
  list-style none
  display flex
  justify-content flex-end
  flex-wrap wrap
  column-gap 16px
  row-gap 8px
  color blue-6
</style>
