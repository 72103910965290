<template>
  <PageLayout>
    <ScannerEmulator v-if="isDevelopment" />
    <template #header>
      <PageHeader :title="$t('box transfer.title')">
        <UiButton
          id="newTransfer"
          :disabled="!canEditBoxes"
          type="primary"
          @click="openCreateBoxTransfer()"
        >
          {{ $t('box transfer.new transfer') }}
        </UiButton>
      </PageHeader>
    </template>
    <div class="content-wrapper">
      <div ref="tableWrapperRef" class="table-wrapper">
        <div
          data-test-id="table__content"
          :style="{
            height: !state.items.length ? '100%' : undefined,
          }"
        >
          <UiTable
            :data-source="state.items"
            :columns="columns"
            :custom-header-row="() => ({ 'data-test-id': 'table__header' })"
            bordered
            @change="handleTableChange"
          >
            <template v-if="state.loading || error" #empty>
              <LinearLoader
                v-if="state.loading"
                :style="{ width: '100%', margin: '2em 0 1em 0' }"
              />
              <LoadError v-else-if="error" @retry="getInitialBoxTransfers" />
            </template>
            <template #bodyCell="{ column, record }">
              <template v-if="column.key === 'created'">
                <DateCell :key="record.id" no-padding :value="record.created" />
              </template>
              <template v-else-if="column.key === 'box'">
                {{ record.box.barcode }}
              </template>
            </template>
            <template #customFilterDropdown>
              <SearchPopup
                ref="searchPopup"
                :placeholder="$t('search barcode')"
                :mask="barcodeMask"
                @accept="handleAcceptSearch"
                @reset="handleResetSearch"
              />
            </template>
            <template #customFilterIcon>
              <div :title="$t('search')">
                <SearchOutlined :class="{ 'active-filter-color': query }" />
              </div>
            </template>
          </UiTable>
        </div>

        <InfiniteLoader
          :key="state.page"
          :has-more="state.hasMore"
          :on-enter="handleInfiniteLoader"
        />

        <LinearLoader
          v-if="state.items.length && state.hasMore"
          :style="{ width: '100%', margin: '2em 0 1em 0' }"
        />
      </div>
    </div>
  </PageLayout>
  <create-box-transfer-modal
    v-if="isShowCreateBoxTransfer"
    :on-save="handleCreateBoxTransfer"
    :box-transfer="creationModalBoxTransfer"
    @close="closeCreateBoxTransfer"
  />
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
export default defineComponent({ name: 'transfer-page' });
</script>

<script setup lang="ts">
import { SearchOutlined } from '@ant-design/icons-vue';
import { useI18n } from 'vue-i18n';

import { BoxTransfer } from '@/api';
import InfiniteLoader from '@/components/InfiniteLoader.vue';
import DateCell from '@/components/table/cells/date-cell.vue';
import { ESortingDirection } from '@/components/table/types';
import LinearLoader from '@/components/ui/linear-loader/index.vue';
import { useIsInViewWrapper } from '@/composables/useIsInView';
import { IS_DEVELOPMENT as isDevelopment } from '@/config';
import { useAuth } from '@/features/Auth';
import {
  EBarcodeEvents,
  isBoxesBarcode,
  useBarcodeScan,
} from '@/features/Barcode';
import { EIntroKeys, useFeatureIntro } from '@/features/FeatureIntro';
import { ScannerEmulator } from '@/features/ScannerEmulator';
import { PageHeader, PageLayout } from '@/template';
import { CopyButton, LoadError, SearchPopup, UiButton, UiTable } from '@/ui';
import { barcodeMask } from '@/utils/barcode';

import CreateBoxTransferModal from './components/createBoxTransferModal/index.vue';
import { useBoxTransfer } from './composables';

const {
  state,
  handleLoadMore,
  getInitialBoxTransfers,
  error,
  query,
  sorting,
  addBoxTransferList,
} = useBoxTransfer();

const { t } = useI18n();
const tableWrapperRef = ref();
const isShowCreateBoxTransfer = ref(false);
const creationModalBoxTransfer = ref<BoxTransfer | undefined>();
const { canEditBoxes } = useAuth();

useIsInViewWrapper(tableWrapperRef);

const handleInfiniteLoader = () => {
  handleLoadMore();
};

const [subscribe, unsubscrube] = useBarcodeScan(
  EBarcodeEvents.BOXES,
  (barcode) => handleBarcodeScan(barcode),
);

const handleBarcodeScan = async (barcode: string) => {
  handleAcceptSearch(barcode);
};

const handleTableChange = (pagination, filters, sorter) => {
  const { order, columnKey } = sorter;

  if (order) {
    if (columnKey === 'created') {
      sorting.field = 'createdAt';
    }

    if (order === 'descend') {
      sorting.direction = ESortingDirection.DESC;
    } else {
      sorting.direction = ESortingDirection.ASC;
    }
  } else {
    sorting.direction = null;
  }
};

const handleAcceptSearch = (payload) => {
  if (isBoxesBarcode(payload)) {
    query.value = payload;
  } else {
    searchPopup.value.isValid = false;
  }
};

const handleResetSearch = () => {
  query.value = '';
};

const handleCreateBoxTransfer = (boxTransferList: BoxTransfer[]) => {
  if (!query.value) {
    addBoxTransferList(boxTransferList);
  }
};

const closeCreateBoxTransfer = () => {
  isShowCreateBoxTransfer.value = false;
  subscribe();
};

const openCreateBoxTransfer = (boxTransfer?: BoxTransfer) => {
  if (boxTransfer) creationModalBoxTransfer.value = boxTransfer;

  isShowCreateBoxTransfer.value = true;
  unsubscrube();
};

const searchPopup = ref();

const columns = [
  {
    title: t('barcode'),
    key: 'box',
    dataIndex: ['box', 'barcode'],
    customFilterDropdown: true,
    onFilterDropdownVisibleChange: (visible) => {
      setTimeout(() => {
        if (visible) {
          searchPopup.value.$refs.searchRef.$el.focus();

          if (!query.value) {
            searchPopup.value.searchValue = '';
          } else if (query.value !== searchPopup.value.searchValue) {
            searchPopup.value.searchValue = query.value;
          }
        }
      }, 0);
    },
  },
  {
    title: t('box transfer.created date and time'),
    key: 'created',
    dataIndex: 'created',
    sorter: true,
  },
];

const [showIntro] = useFeatureIntro(EIntroKeys.NEW_BOX_TRANSER);

onMounted(() => {
  showIntro();
});
</script>

<style lang="stylus" scoped>


.content-wrapper
  border-bottom 1px solid #f0f0f0
  padding-bottom 25px
  height 100%

.table-wrapper
  position relative
  overflow auto
  height 100%
  :deep() td
    padding-top 0
    padding-bottom 0
  :deep() .anticon
    text-align right


.active-filter-color
  color #2495fe
</style>
