/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PhotoRequestStatus,
    PhotoRequestStatusFromJSON,
    PhotoRequestStatusFromJSONTyped,
    PhotoRequestStatusToJSON,
} from './PhotoRequestStatus';

/**
 * 
 * @export
 * @interface ChangePhotoRequestStatus
 */
export interface ChangePhotoRequestStatus {
    /**
     * 
     * @type {PhotoRequestStatus}
     * @memberof ChangePhotoRequestStatus
     */
    status: PhotoRequestStatus;
}

export function ChangePhotoRequestStatusFromJSON(json: any): ChangePhotoRequestStatus {
    return ChangePhotoRequestStatusFromJSONTyped(json, false);
}

export function ChangePhotoRequestStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangePhotoRequestStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': PhotoRequestStatusFromJSON(json['status']),
    };
}

export function ChangePhotoRequestStatusToJSON(value?: ChangePhotoRequestStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': PhotoRequestStatusToJSON(value.status),
    };
}

