/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CancelReservationRequest
 */
export interface CancelReservationRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof CancelReservationRequest
     */
    itemIds: Array<string>;
}

export function CancelReservationRequestFromJSON(json: any): CancelReservationRequest {
    return CancelReservationRequestFromJSONTyped(json, false);
}

export function CancelReservationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CancelReservationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'itemIds': json['itemIds'],
    };
}

export function CancelReservationRequestToJSON(value?: CancelReservationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'itemIds': value.itemIds,
    };
}

