import { COMPANY, LOCALE } from '@/config';

export enum Lang {
  'ru-RU' = 'ru',
  'uz-UZ' = 'uz',
}

const defaultLang = Lang[LOCALE || 'ru-RU'];

export const getLang = (text: string, lang: string = defaultLang) => {
  if (COMPANY === 'KE') return text;

  try {
    const textObj = JSON.parse(text);

    return textObj[lang] ? textObj[lang] : textObj[defaultLang] || text;
  } catch {
    return text;
  }
};

export const localizedCategories = (text = '') => {
  if (COMPANY === 'KE') return text;

  const delimiter = ' / ';
  const textArr = text.split(delimiter);

  textArr.shift();

  return textArr.map((el: string) => getLang(el)).join(delimiter);
};
