<template>
  <div v-if="state && !state.loading">
    <div class="content-header">
      <Count
        :actual-amount="state.invoice?.actualAmount"
        :expected-amount="state.invoice?.expectedAmount"
        :has-count="hasCount"
      />
      <div class="photorequest-wrapper">
        <div class="photorequest-list">
          <div
            v-for="(item, index) in state.photorequests"
            :key="item.request.id"
            :class="[
              'photorequest photorequest-closable',
              photorequestActiveClass(item),
              photorequestLatestClass(index),
            ]"
            @click="
              toggleActivePhotorequest(item),
                (skuRequestScanWaiting = null),
                handleCancelAdding()
            "
          >
            <span :class="`dot dot_${item.color}`">
              {{ item.items.length }}
            </span>
            <span class="photorequest-main-info">
              {{
                item.type &&
                $t(`modal.invoice.photorequest type mark.${item.type}`)
              }}
              {{ item.request.id }}
            </span>
            {{ item.barcode }}
            <icon-ui
              class="dissmiss-icon"
              name="navigation/dissmiss_line"
              :size="16"
              @click="handleRemovePhotorequest(item.request.id)"
            />
          </div>
        </div>
        <div class="photorequest-adding" data-test-id="button__create-request">
          <template v-if="!isRequestScanWaiting">
            <UiButton
              v-if="!state.photorequests.length"
              type="text"
              class="create-btn"
              :disabled="!canEditInvoices"
              @click="handleCreatePhotorequest"
            >
              {{ $t('modal.invoice.actions.create photorequest') }}
            </UiButton>
            <UiButton
              v-else
              type="text"
              :disabled="!canEditInvoices"
              class="create-btn"
              @click="handleCreatePhotorequest"
            >
              <PlusSquareTwoTone class="plus-icon" />
            </UiButton>
          </template>
          <div
            v-else
            class="photorequest photorequest-closable photorequest-scan-waiting"
          >
            {{ $t('modal.invoice.new photorequest') }}
            <span class="scan-label">
              {{ $t('modal.invoice.scan box barcode') }}
            </span>
            <icon-ui
              class="dissmiss-icon"
              name="navigation/dissmiss_line"
              :size="16"
              @click="handleCancelAdding"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      ref="tableWrapperRef"
      :style="{
        height: !state.invoiceItems.items.length ? '100%' : undefined,
      }"
      :class="[
        'content-table',
        { stretched: !state.invoiceItems.items.length },
      ]"
    >
      <UiTable
        :columns="columns"
        :data-source="state.invoiceItems.items"
        bordered
        :custom-row="handleRowClick"
      >
        <template #bodyCell="{ column, text, record }">
          <template v-if="column.key === 'barcode'">
            {{ record.sku.barcode }}
            <CopyButton :text="record.sku.barcode" />
          </template>
          <template v-if="column.key === 'sku'">
            {{ record.sku.title }}
          </template>
          <template v-if="column.key === 'photo'">
            <BaseImage :url="text" />
          </template>
          <template v-if="column.key === 'nomenclature'">
            <div class="table-nomenclature">{{ getLang(text) }}</div>
            <div
              v-if="record.sku.shootingTypes.length"
              class="mt-2 flex gap-1 flex-wrap"
            >
              <ShootingTypeChip
                v-for="st in record.sku.shootingTypes"
                :key="st"
                :type="st"
              />
            </div>
            <span v-if="record.paymentRate" class="table-payment-rate">
              <PaymentRateChip
                :rate="record.paymentRate"
                :tooltip="false"
                use-title
              />
            </span>
          </template>
          <template v-if="column.key === 'photorequest'">
            <div :ref="'photorequest' + record.id" class="table-photorequest">
              <template v-if="loaded">
                <div
                  v-if="skuRequestScanWaiting === text"
                  class="photorequest-table-scan"
                  @click.stop="skuRequestScanWaiting = null"
                >
                  <span class="dot" />
                  <span class="scan-label">
                    {{ $t('modal.invoice.scan box barcode') }}
                  </span>
                </div>
                <UiDropdown
                  v-else
                  :trigger="['click']"
                  placement="bottomRight"
                  :get-popup-container="() => $refs['photorequest' + record.id]"
                  destroy-popup-on-hide
                >
                  <a
                    :class="`select-link ${isLastSelectedClass(record.sku.id)}`"
                    @click.prevent.stop="handleSelectClick"
                  >
                    <div>
                      <span
                        v-if="getSkuPhotorequestBarcode(text)"
                        :class="`dot dot_${getSkuPhotorequestColor(text)}`"
                      >
                        {{ getSkuPhotorequestItemCount(text) }}
                      </span>
                      <span class="select-link-type">
                        {{
                          getSkuPhotorequestType(text) &&
                          $t(
                            `modal.invoice.photorequest type mark.${getSkuPhotorequestType(
                              text,
                            )}`,
                          )
                        }}
                      </span>
                      <span
                        :class="{
                          'select-link-barcode':
                            getSkuPhotorequestBarcode(text),
                        }"
                      >
                        {{
                          getSkuPhotorequestBarcode(text) ||
                          $t('modal.invoice.no photorequest')
                        }}
                      </span>
                    </div>
                    <UpOutlined class="arrow-icon" />
                  </a>
                  <template #overlay>
                    <UiMenu class="photorequest-menu">
                      <UiMenuItem
                        v-for="item in state.photorequests"
                        :key="item.request.id"
                        :class="{
                          'active-item':
                            getSkuPhotorequestId(text) === item.request.id,
                        }"
                        @click.stop="
                          handleSetPhotorequestFromTable(
                            item.request.id,
                            text,
                            getSkuPhotorequestId(text),
                          )
                        "
                      >
                        <div class="photorequest-menu-item">
                          <span :class="`dot dot_empty dot_${item.color}`" />
                          {{ item.barcode }}
                        </div>
                      </UiMenuItem>
                      <UiMenuDivider />
                      <UiMenuItem
                        class="photorequest-menu-item-add"
                        @click.stop="
                          (skuRequestScanWaiting = text),
                            toggleActivePhotorequest(),
                            handleCancelAdding()
                        "
                      >
                        {{ $t('modal.invoice.new photorequest') }}
                      </UiMenuItem>
                      <UiMenuItem
                        v-if="getSkuPhotorequestId(text)"
                        @click.stop="
                          handleSetPhotorequest(
                            getSkuPhotorequestId(text) || 0,
                            text,
                            getSkuPhotorequestId(text),
                          )
                        "
                      >
                        {{ $t('modal.invoice.no photorequest') }}
                      </UiMenuItem>
                    </UiMenu>
                  </template>
                </UiDropdown>
              </template>
            </div>
          </template>
          <template v-if="column.key === 'amount'">
            <AmountCell
              :item="record"
              :is-disabled="isAccountingDisabled || !canEditInvoices"
              :accept-invoice-item="acceptInvoiceItem"
            />
          </template>
        </template>
      </UiTable>
      <InfiniteLoader
        :key="state.invoiceItems.page"
        :has-more="state.invoiceItems.hasMore"
        :on-enter="handleInfiniteLoader"
      />
      <LinearLoader
        v-if="state.invoiceItems.hasMore"
        :style="{ width: '100%', margin: '0 0 1em 0' }"
      />
    </div>
  </div>
  <SkuModal
    v-if="isShowSkuModal && skuId"
    :id="skuId"
    @close="handleCloseSkuModal"
  />
</template>

<script setup lang="ts">
import { PlusSquareTwoTone, UpOutlined } from '@ant-design/icons-vue';
import { computed, onMounted, PropType, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { InvoiceItem, PhotoRequestItem } from '@/api';
import InfiniteLoader from '@/components/InfiniteLoader.vue';
import PaymentRateChip from '@/components/PaymentRateChip.vue';
import ShootingTypeChip from '@/components/ShootingTypeChip.vue';
import LinearLoader from '@/components/ui/linear-loader/index.vue';
import { useIsInViewWrapper } from '@/composables/useIsInView';
import { playError, useSoundSignal } from '@/composables/useSoundSignal';
import { useAuth } from '@/features/Auth';
import {
  EBarcodeEvents,
  isPhotoBoxBarcode,
  useBarcodeScan,
} from '@/features/Barcode';
import { EIntroKeys, useFeatureIntro } from '@/features/FeatureIntro';
import SkuModal from '@/features/Modals/sku/index.vue';
import { notification } from '@/features/Notifications';
import {
  BaseImage,
  CopyButton,
  UiButton,
  UiDropdown,
  UiMenu,
  UiMenuDivider,
  UiMenuItem,
  UiTable,
} from '@/ui';
import { getLang } from '@/utils/lang';

import AmountCell from '../amount-cell.vue';
import { IPhotoRequest, TInvoiceItemsState } from '../composables';
import Count from './count.vue';

const props = defineProps({
  state: {
    type: Object as PropType<TInvoiceItemsState>,
    default: null,
  },
  hasCount: {
    type: Boolean,
    default: false,
  },
  isSending: {
    type: Boolean,
    default: false,
  },
  isDraft: {
    type: Boolean,
    default: false,
  },
  isAccountingDisabled: {
    type: Boolean,
    default: true,
  },
  handleRowClick: {
    type: Function as PropType<(row: InvoiceItem) => Record<string, unknown>>,
    default: () => {},
  },
  handleRemoveItem: {
    type: Function as PropType<(item: any) => void>,
    default: () => {},
  },
  acceptInvoiceItem: {
    type: Function as PropType<
      (itemID: number, actualAmount: number) => Promise<InvoiceItem | undefined>
    >,
    default: () => {},
  },
  handleInfiniteLoader: {
    type: Function as PropType<() => void>,
    default: () => {},
  },
  getPhotorequests: {
    type: Function as PropType<() => Promise<void>>,
    default: () => {},
  },
  createPhotorequest: {
    type: Function as PropType<(barcode: string) => Promise<void>>,
    default: () => {},
  },
  deletePhotorequest: {
    type: Function as PropType<(photoRequestID: number) => Promise<void>>,
    default: () => {},
  },
  addPhotorequestItem: {
    type: Function as PropType<
      (
        photoRequestID: number,
        itemID: number,
      ) => Promise<InvoiceItem | undefined>
    >,
    default: () => {},
  },
  toggleActivePhotorequest: {
    type: Function as PropType<(item?: IPhotoRequest) => void>,
    default: () => {},
  },
  deletePhotorequestItem: {
    type: Function as PropType<
      (photoRequestID: number, itemID: number) => Promise<void>
    >,
    default: () => {},
  },
  subscribeSkuScan: {
    type: Function as PropType<() => void>,
    default: () => {},
  },
  unsubscribeSkuScan: {
    type: Function as PropType<() => void>,
    default: () => {},
  },
  getInvoiceItemByBarcode: {
    type: Function as PropType<(barcode: string) => Promise<InvoiceItem>>,
    default: () => {},
  },
});

const [showCreateIntro] = useFeatureIntro(EIntroKeys.CREATE_PHOTOREQUEST);
const [showCreatedIntro] = useFeatureIntro(EIntroKeys.PHOTOREQUEST_CREATED);

const [showItemAddedIntro] = useFeatureIntro(
  EIntroKeys.PHOTOREQUEST_ITEM_ADDED,
);

const [showAddFromTableIntro] = useFeatureIntro(
  EIntroKeys.PHOTOREQUEST_ADD_FROM_TABLE,
);

const [showItemAddedFromTableIntro] = useFeatureIntro(
  EIntroKeys.PHOTOREQUEST_ITEM_ADDED_FROM_TABLE,
);

const wrapSound = useSoundSignal();

const [subscribeRequestScan, unsubscribeRequestScan] = useBarcodeScan(
  EBarcodeEvents.REQUEST,
  (barcode) => handleBarcodeScan(barcode),
);

const loaded = ref(false);
const skuRequestScanWaiting = ref(null);
const isRequestScanWaiting = ref(false);
const isAdding = ref(false);
const isShowSkuModal = ref<boolean>(false);
const skuId = ref<number | null>(null);

const handleCloseSkuModal = () => {
  isShowSkuModal.value = false;
  skuId.value = null;
};

const toggleActivePhotorequestByScan = (barcode: string) => {
  const photorequest = props.state.photorequests.find(
    (el) => el.barcode === barcode,
  );

  if (photorequest) {
    if (photorequest !== props.state.activePhotorequest) {
      props.toggleActivePhotorequest(photorequest);
    }

    successSound();
  } else {
    errorSound();
  }
};

const setPhotorequestByScan = async (barcode: string) => {
  try {
    const invoiceItem = await props.getInvoiceItemByBarcode(barcode);

    const prevPhotorequest = props.state.photorequests.find(
      (el: IPhotoRequest) =>
        el.items.find((item: PhotoRequestItem) =>
          item.sku ? item.sku.id === invoiceItem?.sku?.id : false,
        ),
    );

    if (props.state.activePhotorequest === prevPhotorequest) {
      playError();
      notification.error(t(`modal.error.codes.skuExistInPhotorequest`));

      return;
    }

    if (props.state.activePhotorequest && invoiceItem.sku) {
      await wrapSound(
        handleSetPhotorequest(
          props.state.activePhotorequest.request.id,
          invoiceItem.sku.id,
          prevPhotorequest?.request.id,
        ),
      ).then(() => {
        if (invoiceItem?.sku) {
          isShowSkuModal.value = true;
          skuId.value = invoiceItem.sku.id;
        }
      });
    }
  } catch (e) {
    playError();
  }
};

const createPhotorequestByScan = async (barcode: string) => {
  await wrapSound(props.createPhotorequest(barcode));
};

const createPhotorequestFromTableByScan = async (barcode: string) => {
  const photorequest = props.state.photorequests.find((el: IPhotoRequest) =>
    el.items.find(
      (item: PhotoRequestItem) => item.sku.id === skuRequestScanWaiting.value,
    ),
  );

  await props.createPhotorequest(barcode).catch(() => {
    errorSound();
  });

  if (props.state.activePhotorequest && skuRequestScanWaiting.value) {
    await wrapSound(
      handleSetPhotorequest(
        props.state.activePhotorequest.request.id,
        skuRequestScanWaiting.value,
        photorequest?.request.id,
      ),
    );
  }
};

const handleBarcodeScan = async (barcode: string) => {
  if (isAdding.value) return;
  handleCloseSkuModal();

  try {
    isAdding.value = true;

    if (isRequestScanWaiting.value) {
      await createPhotorequestByScan(barcode);

      setTimeout(() => {
        showCreatedIntro();
      }, 0);
    } else if (props.state.activePhotorequest) {
      if (isPhotoBoxBarcode(barcode)) {
        toggleActivePhotorequestByScan(barcode);
      } else {
        await setPhotorequestByScan(barcode).then(() => {
          showItemAddedIntro();
        });
      }
    } else if (skuRequestScanWaiting.value) {
      await createPhotorequestFromTableByScan(barcode);
    }

    handleCancelAdding();
    skuRequestScanWaiting.value = null;
  } finally {
    isAdding.value = false;
  }
};

watch(
  [
    isRequestScanWaiting,
    skuRequestScanWaiting,
    () => props.state?.activePhotorequest,
  ],
  (val) => {
    const [requestScan, requestSkuFromTable, skuScan] = val;

    if (requestScan || skuScan || requestSkuFromTable) {
      props.unsubscribeSkuScan();
      subscribeRequestScan();
    } else {
      unsubscribeRequestScan();
      props.subscribeSkuScan();
    }
  },
);

watch(
  () => props.state?.loading,
  (loading) => {
    if (!loading) {
      showCreateIntro();
    }
  },
);

const handleCreatePhotorequest = () => {
  isRequestScanWaiting.value = true;
  props.toggleActivePhotorequest();
  skuRequestScanWaiting.value = null;
};

const handleCancelAdding = () => {
  isRequestScanWaiting.value = false;
};

const isRemoving = ref(false);

const handleRemovePhotorequest = async (id: number) => {
  if (isRemoving.value) return;

  isRemoving.value = true;
  await props.deletePhotorequest(id);
  isRemoving.value = false;
};

const handleSetPhotorequest = async (
  photorequestId: number,
  skuId: number,
  prevPhotorequestId?: number,
) => {
  if (prevPhotorequestId) {
    const prevPhotorequest = props.state.photorequests.find(
      (el: IPhotoRequest) => el.request.id === prevPhotorequestId,
    );

    const item = prevPhotorequest?.items.find(
      (el: PhotoRequestItem) => el.sku.id === skuId,
    );

    if (prevPhotorequest && item) {
      await props.deletePhotorequestItem(prevPhotorequest.request.id, item.id);
    }
  }

  if (photorequestId && photorequestId !== prevPhotorequestId) {
    await props.addPhotorequestItem(photorequestId, skuId);
  }
};

const handleSetPhotorequestFromTable = async (
  photorequestId: number,
  skuId: number,
  prevPhotorequestId?: number,
) => {
  await handleSetPhotorequest(photorequestId, skuId, prevPhotorequestId)
    .then(() => {
      showItemAddedFromTableIntro();
    })
    .catch(() => {
      errorSound();
    });
};

const errorSound = () => {
  wrapSound(new Promise((resolve, reject) => reject(true)));
};

const successSound = () => {
  wrapSound(new Promise((resolve) => resolve(true)));
};

const getPhotoRequest = (skuId: number): IPhotoRequest | undefined => {
  if (props.state) {
    const photorequest = props.state.photorequests.find((el: IPhotoRequest) =>
      el.items.find((item: PhotoRequestItem) => item.sku.id === skuId),
    );

    if (photorequest) {
      return photorequest;
    }
  }
};

const getSkuPhotorequestId = (skuId: number): number | undefined => {
  return getPhotoRequest(skuId)?.request.id;
};

const getSkuPhotorequestColor = (skuId: number): string | undefined => {
  return getPhotoRequest(skuId)?.color;
};

const getSkuPhotorequestBarcode = (skuId: number): string | undefined => {
  return getPhotoRequest(skuId)?.barcode;
};

const getSkuPhotorequestItemCount = (skuId: number): number | undefined => {
  return getPhotoRequest(skuId)?.items.length;
};

const getSkuPhotorequestType = (skuId: number): string | undefined => {
  return getPhotoRequest(skuId)?.type;
};

const handleSelectClick = () => {
  showAddFromTableIntro();
};

const { t } = useI18n();
const { canEditInvoices } = useAuth();
const tableWrapperRef = ref();

useIsInViewWrapper(tableWrapperRef);

const isLastSelectedClass = (skuId: number): string => {
  if (props.state.lastPhotorequestItemAdded) {
    return props.state.lastPhotorequestItemAdded.items[0]?.sku.id === skuId
      ? 'select-link-current'
      : '';
  }

  return '';
};

onMounted(async () => {
  unsubscribeRequestScan();
  await props.getPhotorequests();
  loaded.value = true;
});

const photorequestLatestClass = (idx: number): string => {
  return props.state.photorequests.length === idx + 1
    ? 'photorequest-latest'
    : '';
};

const photorequestActiveClass = (item: IPhotoRequest): string => {
  return props.state.activePhotorequest?.request.id === item.request.id
    ? 'photorequest-active'
    : '';
};

const columns = computed(() => [
  {
    title: t('photo'),
    key: 'photo',
    dataIndex: ['sku', 'photoURL'],
    width: 86,
  },
  {
    title: t('nomenclature'),
    key: 'nomenclature',
    dataIndex: ['sku', 'description'],
  },
  {
    title: 'SKU',
    key: 'sku',
    dataIndex: ['sku', 'title'],
    width: 210,
  },
  {
    title: t('barcode'),
    key: 'barcode',
    dataIndex: ['sku', 'barcode'],
    width: 170,
  },
  {
    title: t('modal.invoice.request box'),
    key: 'photorequest',
    dataIndex: ['sku', 'id'],
    width: 170,
  },
  {
    title: t('checked'),
    key: 'amount',
    dataIndex: 'expectedAmount',
    width: 160,
  },
]);
</script>

<style lang="stylus" scoped>


.content-wrapper
  overflow hidden
  height 100%
  display flex
  flex-direction column

.content-header
  margin-top 30px
  margin-bottom 20px
  display flex
  align-items top
  justify-content space-between

.content-table
  max-height 100%
  overflow auto
  :deep().table
    height auto
  &.stretched
    height 100%

.action
  cursor pointer
  border none
  background none
  height 80px
  opacity 0
  visibility hidden

.create-btn
  color #1890FF

.scan-label
  color #F5222D
  margin-left 8px

.table-nomenclature
  overflow-wrap anywhere

.table-payment-rate
  display inline-block
  margin-top 10px

.photorequest-list
  display flex
  flex-wrap wrap
  justify-content flex-end

.photorequest-wrapper
  display flex
  align-items top
  justify-content flex-end

.photorequest
  display flex
  align-items center
  font-size 14px
  padding 5px
  transition-duration .15s
  margin-left 24px
  margin-top 2px
  margin-bottom 2px
  border 1px solid transparent
  border-radius 2px
  cursor pointer
  white-space nowrap
  &:hover
    background-color #fff


.photorequest-closable
  position relative
  &:hover
    border-color #BFBFBF
    .dissmiss-icon
      opacity .9
  .dissmiss-icon
    opacity 0
    height 100%
    cursor pointer
    position absolute
    right 0
    background-color #fff

.photorequest-active
  border-color #1890FF
  background-color #fff
  &:hover
      border-color #1890FF

.photorequest-adding
  height 38px
  display flex
  align-items center
  .photorequest
    cursor default

.photorequest-table-scan
  display flex
  align-items center
  position relative
  cursor pointer
  .scan-label
    margin 0

.photorequest-menu
  width 200px
  max-height 200px
  overflow-y auto

.photorequest-menu-item
  &-add
    color #1890FF

.photorequest-main-info
  font-weight 500
  padding-right 4px

.select-link
  display flex
  justify-content space-between
  align-items center
  width 170px
  padding 16px 8px
  color #1F1F1F
  border 1px solid transparent
  border-radius 2px
  :deep() &.ant-dropdown-open
    border-color #1890FF
    .arrow-icon
      opacity 1
      transform rotateZ(0)
  .arrow-icon
    opacity 0
    color #1890FF
    transform rotateZ(180deg)
    perspective 1000px
    transition-duration .15s
  &-type:not(:empty)
    padding-right 5px
  &:hover
    border-color #1890FF
    .arrow-icon
      opacity 1

.plus-icon
  font-size 16px

:deep().active-item
  background-color #efefef
  &:hover
    background-color #e4e4e4

.dot
  display inline-block
  margin-right 8px
  padding: 0 5px
  height 20px
  border-radius 10px
  &_empty {
    padding: 0
    height: 15px
    width: 15px
  }
  &_yellow {
    background-color yellow-7
  }
  &_geekblue {
    background-color geekblue-6
  }
  &_orange {
    background-color orange-6
  }
  &_cyan {
    background-color cyan-6
  }
  &_purple {
    background-color purple-6
  }
  &_magenta {
    background-color magenta-6
  }
</style>
