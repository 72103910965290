/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SkuPrice,
    SkuPriceFromJSON,
    SkuPriceFromJSONTyped,
    SkuPriceToJSON,
} from './SkuPrice';

/**
 * 
 * @export
 * @interface Prices
 */
export interface Prices {
    /**
     * 
     * @type {Array<SkuPrice>}
     * @memberof Prices
     */
    items: Array<SkuPrice>;
    /**
     * 
     * @type {number}
     * @memberof Prices
     */
    totalPrice: number;
}

export function PricesFromJSON(json: any): Prices {
    return PricesFromJSONTyped(json, false);
}

export function PricesFromJSONTyped(json: any, ignoreDiscriminator: boolean): Prices {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(SkuPriceFromJSON)),
        'totalPrice': json['totalPrice'],
    };
}

export function PricesToJSON(value?: Prices | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(SkuPriceToJSON)),
        'totalPrice': value.totalPrice,
    };
}

