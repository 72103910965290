<template>
  <table-wrapper
    :generateType="EGenerateTypes.PHOTOREQUEST"
    :composable="usePhotorequestBarcodes"
    :mask="photorequestBarcodeMask"
  />
</template>

<script setup lang="ts">
import { useEventManager } from '@/composables/useEventManager';
import { AppEvents } from '@/features/Common';
import TableWrapper from '@/pages/barcodes/components/tableWrapper.vue';
import { photorequestBarcodeMask } from '@/utils/barcode';

import { usePhotorequestBarcodes } from '../composables';
import { EGenerateTypes } from '../data';

const { addBox } = usePhotorequestBarcodes();

const handleAddBox = ({ box, type }) => {
  if (type === EGenerateTypes.PHOTOREQUEST) addBox(box);
};

useEventManager(AppEvents.modals.generateBarcode.update, handleAddBox);
</script>
