import { onMounted, reactive, ref, watch } from 'vue';

import {
  BoxTransfer,
  boxTransferApi,
  BoxTransferList,
  GetBoxTransfersByQueryRequest,
  SortBoxTransfer,
} from '@/api';
import { TSorting } from '@/components/table/types';
import { useQuery } from '@/composables/useQuery';
import {
  ENTITY_INITIAL_STATE,
  PAGE_SIZE,
  TPageableEntity,
} from '@/features/Common';
import { clone, uniq } from '@/utils/collection';

type TBoxTransfersState = TPageableEntity<BoxTransfer>;

export function useBoxTransfer() {
  const state = reactive<TBoxTransfersState>(clone(ENTITY_INITIAL_STATE));
  const error = ref(false);

  const handleBoxTransferResponse = (response: BoxTransferList) => {
    if (response) {
      state.page = response.page + 1;
      state.items = uniq([...state.items, ...response.items]);
      state.hasMore = state.page < response.totalPages;
      state.totalCount = response.totalItems;
    }
  };

  const getBoxTransfers = async () => {
    state.loading = true;
    error.value = false;

    const { field, direction } = sorting;
    let sort;

    if (field && direction) {
      sort = [`${field}${direction}` as SortBoxTransfer];
    }

    const requestParams = {
      page: state.page,
      size: PAGE_SIZE,
      sort,
    } as GetBoxTransfersByQueryRequest;

    if (query.value) {
      requestParams.barcodes = [query.value];
    }

    try {
      const response =
        await boxTransferApi.getBoxTransfersByQuery(requestParams);

      handleBoxTransferResponse(response);
    } catch (e) {
      error.value = true;
    } finally {
      state.loading = false;
    }
  };

  const addBoxTransferList = (boxTransferList: BoxTransfer[]): void => {
    state.items.splice(0, 0, ...boxTransferList);
  };

  const resetState = () => {
    Object.assign(state, clone(ENTITY_INITIAL_STATE));
  };

  const handleLoadMore = async () => {
    if (state.items.length > 0 && state.hasMore && !state.loading) {
      getBoxTransfers();
    }
  };

  const getInitialBoxTransfers = async () => {
    resetState();
    await getBoxTransfers();
  };

  const { query } = useQuery(getInitialBoxTransfers);
  const sorting = reactive<TSorting>({});

  watch(sorting, getInitialBoxTransfers);

  onMounted(async () => {
    await getInitialBoxTransfers();
  });

  return {
    state,
    getBoxTransfers,
    addBoxTransferList,
    handleLoadMore,
    getInitialBoxTransfers,
    sorting,
    query,
    error,
  };
}
