<template>
  <div class="modal-content">
    <div class="barcode">
      <BarcodeOutlined class="barcode-icon" />
    </div>
    <div>
      <div class="title">{{ $t('scan sku') }}</div>
      <div>
        {{ $t('for system to determine what request to open') }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { BarcodeOutlined } from '@ant-design/icons-vue';
import { watchEffect } from 'vue';

import { SkuDetailed } from '@/api';
import { EBarcodeEvents, useBarcodeScan } from '@/features/Barcode';
import { usePhotographerFlow } from '@/features/PhotographerFlow';

interface Emits {
  (e: 'sku', payload: SkuDetailed): void;
}

const emit = defineEmits<Emits>();
const { startPhotoShoot } = usePhotographerFlow();

const handleBarcode = async (barcode: string) => {
  const data = await startPhotoShoot(barcode);

  if (!data) return;

  emit('sku', data.sku);
  unsubFromSkuScan();
};

const [subToSkuScan, unsubFromSkuScan] = useBarcodeScan(
  EBarcodeEvents.SKU,
  handleBarcode,
  true,
);

watchEffect(() => {
  subToSkuScan();
});
</script>

<style lang="stylus" scoped>


.modal-content
  display flex
  gap 12px
  padding 24px
  color gray-10

.title
  font-size 16px
  font-weight 600
  margin-bottom 4px

.barcode
  position relative
  display flex
  justify-content center
  align-items center
  width 64px
  height 64px
  background #f8f7fa
  border-radius 12px
  overflow hidden

  &::after
    content ''
    position absolute
    top 0
    left 0
    display block
    width 100%
    height 4px
    background red-5
    opacity 0.6
    animation scan 2s linear infinite

@keyframes scan
  0%
    transform translateY(-100%)

  50%
    transform translateY(68px)

  100%
    transform translateY(68px)

.barcode-icon
  font-size 32px
</style>
