<template>
  <div class="linear-progress-bar">
    <div class="line" />
    <div class="subline inc" />
    <div class="subline dec" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({ name: 'linear-loader' });
</script>

<style lang="stylus" scoped>
.linear-progress-bar
  width calc(100% - 20px)
  margin 0 10px
  height 5px
  position relative
  overflow-x hidden

.line
  background #deefff
  height 5px
  position absolute
  width 150%

.subline
  background #1890ff
  height 5px
  position absolute

.inc
  animation increase 2s infinite

.dec
  animation decrease 2s 0.5s infinite

@keyframes increase
  from
  left -5%
  width 5%
  to
  left 130%
  width 100%
@keyframes decrease
  from
    left -80%
    width 80%
  to
    left 110%
    width 10%
</style>
