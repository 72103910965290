<template>
  <div class="amount-wrapper text-s leading-short font-regular">
    <text-field
      v-model="amount"
      :class="`actual-amount ${showWarning ? 'border-orange' : ''}`"
      data-test-id="input__actual-amount"
      type="number"
      :disabled="isDisabled"
      @keypress.enter="debouncedAcceptItem"
      @blur="debouncedAcceptItem"
      @click.stop
    />
    <span>/</span>
    <span>{{ $t('pc.', { count: expectedAmount }) }}</span>
    <icon-ui
      v-if="showCheckmark"
      data-test-id="icon__complete-checkmark"
      name="marks/checkmark__fill"
      class="complete-checkmark"
      :size="18"
    />
    <warning-outlined
      v-if="showWarning"
      class="warning-sign"
      data-test-id="icon__warning"
    />
  </div>
</template>
<script lang="ts">
import { WarningOutlined } from '@ant-design/icons-vue';
import debounce from 'lodash.debounce';
import { computed, defineComponent, PropType, ref, watch } from 'vue';

import { InvoiceItem } from '@/api';
import { useSoundSignal } from '@/composables/useSoundSignal';
import { DEBOUNCE_DELAY } from '@/features/Common';
import { TextField } from '@/ui';

export default defineComponent({
  components: {
    TextField,
    WarningOutlined,
  },
  props: {
    item: {
      type: Object as () => InvoiceItem,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    acceptInvoiceItem: {
      type: Function as PropType<
        (
          itemID: number,
          actualAmount: number,
        ) => Promise<InvoiceItem | undefined>
      >,
      default: () => {},
    },
  },
  setup(props) {
    const wrapSound = useSoundSignal();
    const amount = ref(props.item.actualAmount);

    const showCheckmark = computed(
      () => +amount.value === props.item.expectedAmount,
    );

    const showWarning = computed(
      () => amount.value > props.item.expectedAmount,
    );

    const handleAcceptItem = async () => {
      if (Number(amount.value) === props.item.actualAmount) return;

      try {
        await wrapSound(
          props.acceptInvoiceItem(props.item.id, Number(amount.value)),
        );
      } catch (e) {
        amount.value = props.item.actualAmount;
      }
    };

    const debouncedAcceptItem = debounce(handleAcceptItem, DEBOUNCE_DELAY, {
      leading: true,
      trailing: false,
    });

    watch(
      () => props.item.actualAmount,
      (val) => (amount.value = val),
    );

    return {
      amount,
      showCheckmark,
      showWarning,
      expectedAmount: props.item.expectedAmount,
      handleAcceptItem,
      debouncedAcceptItem,
    };
  },
});
</script>
<style lang="stylus" scoped>


.amount-wrapper
  display grid
  grid-auto-flow column
  grid-template-columns 46px auto 40px auto
  grid-column-gap 8px
  align-items center
  justify-content flex-start
  padding 20px 8px
  min-width 150px
  :deep() .border-orange
    border-color Orange(LT10)

.actual-amount
  height 32px

.complete-checkmark
  fill LightGreen()

.warning-sign
  color Orange(LT10)
  font-size 16px
</style>
